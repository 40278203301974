<template>
  <div class="footer" id="footer">
    <div class="footer-btns">

      <div class="d-flex mx-2 mx-sm-5 py-1 py-sm-2">
      <b-button
        class="button btn-footer-info"
        variant="primary btn-lg"
        data-bs-toggle="modal"
        data-bs-target="#infoModal"
      >
        ?
      </b-button>
    </div>
      
      <div  class="footer-progressbar w-100">
        <b-progress v-if="!$store.state.survey.navText.hideProgressBar" :max="100" >
          <b-progress-bar
            :value="progress"

            variant="primary"
          >
          {{ progress }}%
          </b-progress-bar>
        </b-progress>
      </div>


      <div class="d-flex mx-2 mx-sm-5 py-1 py-sm-2">
      <span
        id="goBackBtn-tooltip"
        data-bs-html="true"
        :title="$store.state.survey.navText?.noGoBackTooltip"
      >
        <b-button
          class="button"
          variant="btn btn-lg btn-footer-left"
          @click="previousPage"
          :class="{
            disabled: !canGoBack,
            'btn-primary': canGoBack,
            'btn-outline-primary': !canGoBack,
          }"
          >
          &larr;
        </b-button>
      </span>

      <span
        id="goNextBtn2-tooltip"
        data-bs-html="true"
        :title="$store.state.survey.navText?.noGoNextTooltip"
      >
        <b-button
          ref="goNextBtn"
          class="button btn-footer-right"
          variant="btn btn-lg"
          @click="nextPage"
          @key.enter="nextPage"
          :class="{
            disabled: !canGoNext,
            'btn-primary': canGoNext,
            'btn-outline-primary': !canGoNext,
          }"
          >
          &rarr;
        </b-button>
      </span>
      </div>
    </div>
    <InfoModal />
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import InfoModal from "./InfoModal.vue";

export default {
  name: "Footer",
  components: {
    InfoModal,
  },
  data() {
    return {
      tooltipNext: null,
      tooltipBack: null,
    };
  },
  computed: {
    progress() {
      return Math.round(this.$store.getters.percentageCompleted);
    },
    currentPage() {
      return this.$store.state.navigation.currentPage + 1;
    },
    maxQuestions() {
      return this.$store.state.navigation.agenda.length;
    },
    canGoBack() {
      let val = this.$store.state.navigation.canGoBack;
      if (val) {
        this.tooltipBack?.hide();
        this.tooltipBack?.disable();
      } else {
        this.tooltipBack?.enable();
      }
      return val;
    },
    canGoNext() {
      let val = this.$store.state.navigation.canGoNext;
      if (val) {
        this.tooltipNext?.hide();
        this.tooltipNext?.disable();
      } else {
        this.tooltipNext?.enable();
      }
      return val;
    },
    techContact() {},
  },
  methods: {
    previousPage() {
      if (this.canGoBack);
      this.$store.dispatch("go", -1);
    },
    nextPage() {
      if (this.canGoNext);
      this.$store.dispatch("go", 1);
    },
  },
  mounted() {
    var el = document.getElementById("goNextBtn2-tooltip");
    this.tooltipNext = new Tooltip(el);
    // var el = document.getElementById("goBackBtn-tooltip");
    // this.tooltipBack = new Tooltip(el);
  },
};
</script>

<style scoped>

.footer-btns {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.btn{
  
    width: 55px;
    height: 55px;
     border-radius: 20em;
     padding:0;
     font-size: 1.8em;
     margin-left: 7px;
     margin-right: 7px;
}

.btn-footer-left {
  
  }
  .btn-footer-right {
    padding:0;
  }
  .btn-footer-info {
    
  }

.progress-bar {
  background: var(--bs-primary) !important;
  border-radius: 10em !important;
}
.progress {
  height: 20px;
  border-radius: 10em;
} 



@media (max-width: 767px) {
  
  .btn {
     margin: 0 5px;
  }
  
}

.disabled {
  opacity: 0.5 !important;
}
a {
  color: var(--bs-primary);
  text-decoration: underline;
}
</style>
