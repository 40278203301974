<template>
  <div class="home home-120">
    <div
      class="
        d-flex
        flew-row
        align-items-start
        mt-1
        gap-3
        flex-wrap flex-lg-nowrap flex-xl-nowrap
      "
    >
    <!-- left column -->
    <div style="flex: 1" class="d-flex flex-column align-items-center">
        <transition-group :name="dimTransistion ?? 'slide-top-dim'">
          <div :key="dimension.id" v-for="(dimension, index) in dimensionLoop">
            <div v-if="taskIndex == 0">
              <div class="px-4 pt-4 pb-3 mx-1 mb-3 border bg-white rounded-3 shadow" style="min-width: 350px">
                <Description>
                  <p>
                    {{ $store.state.survey?.pages?.dimensionWeighting?.pre }}
                    <span
                      class="fw-bold text-decoration-underline text-primary"
                    >
                      {{ this.dimensions[0].name }}
                    </span>
                   </p>
                   <p>
                  <span>
                    {{$store.state.survey?.pages?.dimensionWeighting?.pre2}}
                  </span>
                  <ul>
                  <li>
                    {{$store.state.survey?.pages?.dimensionWeighting?.from}}
                  <div class="d-inline-block border rounded-3 px-1 mx-1 my-1">{{ worstLevel(dimensions[0])}} </div>
                  </li>
                  <li>
                  {{$store.state.survey?.pages?.dimensionWeighting?.to}}
                  <div class="d-inline-block border px-1 rounded-3 mx-1 my-1">{{ bestLevel(dimensions[0])}} </div>
                  </li>
                  </ul>
                  </p>

                  <div
                    v-html="$store.state.survey?.pages?.dimensionWeighting?.html"
                  ></div> 
                </Description>
              </div>
            </div>
            <div v-else>
              <div v-if="taskIndex < dimensions.length">
                <Card
                  spacingClasses="px-4 pt-4 pb-5 mx-1 mb-5"
                  :key="dimension.id"
                >
                  <div class="h3 text-primary">
                    {{ dimension.name }}
                  </div>

                  <div class="d-flex flex-row fs-5 py-4">
                    <div
                      class="border px-3 py-2 rounded-3"
                      style="flex-basis: 45%"
                    >
                      {{ worstLevel(dimension)}}
                    </div>
                    <div class="level-arrow display-3 mx-2">→</div>
                    <div
                      class="border px-3 py-2 rounded-3"
                      style="flex-basis: 45%"
                    >
                      {{ bestLevel(dimension)}}
                    </div>
                  </div>
                  <div
                    class="
                      lead
                      pb-3
                      pt-1
                      fs-5
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <div class="text-center">
                      <div class="fs-5 fw-bold">
                        {{
                          $store.state.survey?.pages?.dimensionWeighting
                            ?.valCardTitle
                        }}
                      </div>
                    </div>
                  </div>
                  <slider
                    class="dim-weight-slider"
                    :values="dimension.swingWeight"
                    :key="dimension.zeroWeight"
                    :disabled="dimension.zeroWeight"
                    :dimension="dimension"
                    :revealOnClick="false"
                    :name="index"
                    :config="sliderConfig"
                    @update="updateDimensionWeight($event, dimension)"
                  />

                  <div class="pt-2" style="z-index: 4; margin-left: 5px;">
                    <b-form-checkbox
                      :id="dimension.id"
                      name="checkbox-1"
                      v-model="dimension.zeroWeight"
                      @change="updateDimensionZeroWeight($event, dimension)"
                    >
                      {{
                        $store.state.survey?.pages?.dimensionWeighting
                          ?.valCardUnimportant
                      }}
                    </b-form-checkbox>
                  </div>
                </Card>
              </div>

              <div v-else>
                <div class="d-flex flex-column" style="flex: 1">
                  <Card
                    spacingClasses="px-4 pt-4 pb-5 mx-1 mb-5"
                    :key="dimension.id"
                  >
                  <div v-html="$store.state.survey?.pages?.dimensionWeighting?.figText"></div>
                    <div class="mt-3">
                      <bar-charts />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <!-- </div > -->
          </div>
        </transition-group>
      </div>
        
        <!-- right column -->
      <div style="flex: 1" class="d-flex flex-column align-items-center">
        <Card spacingClasses="px-4 pt-4 pb-5 mx-1 mb-2">
          <div class="h3 text-primary">
            {{ dimensions[0].name }}
          </div>

          <div class="d-flex flex-row fs-5 py-4">
            <div class="border px-3 py-2 rounded-3" style="flex-basis: 45%">
              {{ worstLevel(dimensions[0])}}
            </div>
            <div class="level-arrow display-3 mx-2">→</div>
            <div class="border px-3 py-2 rounded-3" style="flex-basis: 45%">
              {{ bestLevel(dimensions[0])}}
            </div>
          </div>

          <div
            class="
              lead
              pb-3
              pt-1
              fs-5
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <div class="text-center">
              <div class="fs-5 fw-bold">
                {{ $store.state.survey?.pages?.dimensionWeighting?.refCardTitle }}
              </div>
              <div class="text-secondary" style="font-size: 0.75em" v-html="$store.state.survey?.pages?.dimensionWeighting?.refCardSubTitle">
              </div>
            </div>
          </div>

          <slider
            class="disabled dim-weight-slider"
            @update="updateDimensionWeight($event, dimensions[0])"
            :dimension="dimensions[0]"
            :revealOnClick="false"
            :config="sliderConfig"
            :values="[100]"
            :disabled="true"
          />
        </Card>
        <!-- </div > -->
      </div>


    </div>
    <GoNextBtn
      :promptGoNext="promptGoNext"
      :customMsg="
        taskIndex == 0
          ? $store.state.survey?.pages?.dimensionWeighting?.firstTaskPrompt
          : ''
      "
    />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import Slider from "@/components/Slider.vue";
import BarCharts from "@/components/BarCharts.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

let sliderConfig = {
  tooltips: [wNumb({ decimals: 0 })],
  start: 40,
  connect: "lower",
  pips: {
    mode: "positions",
    values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    density: 2,
  },
  range: { min: [0], max: [100] },
};
export default {
  name: "DimensionWeighting",
  render() {
    return h("div", "<p>xxxFor example,</p>");
  },
  components: {
    Description,
    Card,
    Slider,
    GoNextBtn,
    BarCharts,
  },
  data() {
    return {
      levels: null,
      sliderConfig,
      subscribe: null,
      taskIndex: 0,
      dimTransistion: "none",
      dimWeightingOrder: [],
    };
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this)
    );
    if(this.$store.state.results.dimWeightingOrder.length > 0){
      this.dimWeightingOrder = this.$store.state.results.dimWeightingOrder;
    } else {
      if(this.$store.state.survey?.pages?.levelRating?.randomiseOrder){
        this.$store.commit("setDimWeightingOrder",{randomise: true});
      } else {
        this.$store.commit("setDimWeightingOrder",{randomise: false});
      }
      this.dimWeightingOrder = this.$store.state.results.dimWeightingOrder;
    }
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.taskIndex = this.$store.getters.getDimWeightTaskIndex;
    if (this.taskIndex > 0 && this.taskIndex < this.dimensions.length - 1) {
      this.$store.commit("disableGoNext");
    } 
  },
  watch: {
    taskIndex(newTaskIndex) {
      if (newTaskIndex != this.$store.getters.getDimWeightTaskIndex)
        this.$store.commit("setDimWeightTaskIndex", newTaskIndex);
    },
  },
  unmounted() {
    this.subscribe();
    this.$store.commit("disableInternalNav");
  },

  methods: {
    next() {
      if (this.taskIndex >= this.dimensions.length) {
        this.$store.commit("disableInternalNav");
        return;
      }
      this.dimTransistion = "slide-bottom-dim";
      this.taskIndex++;
      if (this.taskIndex < this.dimensions.length) {
        this.$store.commit("disableGoNext");
      }
    },
    back() {
      if (this.taskIndex <= 0) {
        this.$store.commit("disableInternalNav");
        return;
      }
      this.dimTransistion = "slide-top-dim";
      this.taskIndex--;
      this.$store.commit("enableGoNext");
    },
    bestLevel(dimension) {
      return dimension.levels.reduce((curr, next) => {
        return curr.rank < next.rank ? curr : next;
      }).label;
    },
    worstLevel(dimension) {
      return dimension.levels.reduce((curr, next) => {
        return curr.rank > next.rank ? curr : next;
      }).label;
    },
    isTheHighestPriority(dimension) {
      let resultsDimensions = this.$store.state.results.ds;
      for (let i = 0; i < resultsDimensions.length; i++) {
        if (resultsDimensions[i].id == dimension.id) {
          if (resultsDimensions[i].isTheHighestPriority == true) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    updateDimensionZeroWeight(event, dimension) {
      let val = !(event == false);
      if (val) {
        this.$store.commit("updateDimensionWeight", {
          weight: 0,
          dimension,
        });
      }
      this.$store.commit("enableGoNext");
      this.$store.commit("updateDimensionZeroWeight", {
        zeroWeight: val,
        dimension,
      });
    },
    updateDimensionWeight(weight, dimension) {
      if (weight > 0 || dimension.zeroWeight) {
        this.$store.commit("enableGoNext");
      }
      this.$store.commit("updateDimensionWeight", {
        weight: parseFloat(weight[0]),
        dimension,
      });
    },
  },
  computed: {
    dimensionLoop() {
      if (this.taskIndex == this.dimensions.length) {
        return [{ id: "" }];
      }
      return this.dimensions.filter((dimension, index) => {
        return index == this.dimWeightingOrder[this.taskIndex=== 0 ? 0 : this.taskIndex - 1];
      });
    },

    dimensions() {
      if (
        this.$store.state.results.ds === undefined ||
        this.$store.state.survey.ds == undefined
      ) {
        console.error("no dimensions found");
        return [];
      }

      let temp = this.$store.state.results.ds;
      let temp2 = this.$store.state.survey.ds;
      let orderedDims = [];
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < temp2.length; j++) {
          if (temp[j].rank == i) {
            let blendedDimension = temp2[j];
            blendedDimension.rank = temp[j].rank;
            blendedDimension.swingWeight =
              temp[j].swingWeight == undefined ? [0] : [temp[j].swingWeight];
            blendedDimension.zeroWeight =
              temp[j].zeroWeight == undefined ? false : temp[j].zeroWeight;
            orderedDims.push(blendedDimension);
          }
        }
      }
      return orderedDims;
    },
    promptGoNext() {
      return this.$store.state.navigation.canGoNext;
    },
  },
};
</script>

<style scoped>
.dim-weight-slider {
  margin-top: 30px; 
  margin-bottom: 50px; 
  margin-left: 10px;
  margin-right: 10px;
}
.level-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
