<template>
  <div
    class="home home-80 welcome-page"
    style="margin-top: 10em"
    v-if="$store.state.navigation.agenda.length > 0"
  >
    <HomeHeader
      :title="content?.headerTitle ?? ''"
      :subtitle="content?.title ?? ''"
      :logoLeft="content?.logoLeft ?? ''"
      :logoRight="content?.logoRight ?? ''"
    />


<CustomModal :title="'Unsupported device'" :visible="isMobile && !content.mobileFriendly">
  This website is not optimized for mobile devices.
  We recommend using a desktop computer or laptop.
</CustomModal>


    <div class="fs-5">
      <div
        class="text-secondary mb-5"
        v-if="content?.isParticipantInfoSheet"
      >
        {{ $store.state.survey?.general?.date }}
      </div>

      <div class="card mb-5">
        <div class="card-body px-4">
          <div v-html="content?.html ?? ''"></div>

      <!-- <div
        class="mb-5"
        v-if="content?.isParticipantInfoSheet"
      >
        <div class="fw-bold mt-3">
          {{ $store.state.survey?.general?.authorName }}
        </div>
        {{ $store.state.survey?.general?.authorAffiliation }}<br />
        <a target="_blank" rel="noopener noreferrer" :href="href">
          {{ $store.state.survey?.general?.authorEmail }}
        </a>
      </div> -->
    </div>
        </div>
      
      
        <!-- <hr /> -->


        <div class="card mb-5">
          <div class="card-body px-4">
          
      <div v-if="content?.isParticipantInfoSheet">
        <div class="w-100 d-flex flex-column align-items-end">
          <button class="btn btn-outline-primary btn-sm " v-print>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
              />
            </svg>
            {{ content.printBtn }}
          </button>
        </div>

        <div v-html="content.participantInfo"></div>
      </div>

      <div
      v-if="!content?.hideContact"
        :class="
          content?.isParticipantInfoSheet ? 'mb-5' : 'my-4'
        "
      >
        <div v-html="content?.contactPrompt"></div>
        <div class="fw-bold mt-3">
          {{ $store.state.survey?.general?.authorName }}
        </div>
        <div>
          {{$store.state.survey?.general?.authorAffiliation ?? ''}}
        </div>
        <div>
          {{$store.state.survey?.general?.authorPhone ?? ''}}
        </div>
        <a target="_blank" rel="noopener noreferrer" :href="href">
          {{ $store.state.survey?.general?.authorEmail }}
        </a>
      </div>

      <!-- <hr /> -->

    </div>
        </div>

      <div class="card">
          <div class="card-body px-4">
      <div
        v-if="content.finalNote"
        class="mt-3 fw-bold"
        v-html="content.finalNote"
      ></div>

      

      <b-form @submit.stop.prevent>
        <div
          class="
            d-flex
            flex-column
            mb-3
          "
        >
          <div
            v-if="$store.state.survey?.general?.askForId ?? false"
            style="flex-grow: 0; flex-basis: 70%"
          >
            <div>
              <div class="mx-1 mt-4" v-html="content?.participantIdLabel">

              </div>
              <b-form-input
                :id="content?.participantIdType ?? 'text'"
                :type="content?.participantIdType ?? 'text'"
                v-model="userId"
                @input="validateForm"
                @keyup.enter="requestAccess"
                :placeholder="
                  content?.participantIdPlaceholder
                "
                role="button"
                size="lg"
                style="max-width: 500px;"
                :state="validation"
                required
              />
              <b-form-invalid-feedback :state="validation">
                {{ content?.participantIdValidation }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            class="align-items-end justify-content-center d-flex"
            style="flex-grow: 0; flex-basis: 40%"
          >
            <b-button
              @click="requestAccess"
              @keyup.enter="requestAccess"
              variant=""
              class="btn-cta mt-3 me-auto"
              style="white-space: nowrap"
            >
              {{ content?.cta ?? "" }}
            </b-button>
          </div>
        </div>
      </b-form>

      <div class="mt-4" v-html="content?.expectedDuration"></div>
    </div>
        </div>


    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import print from "vue3-print-nb";
import CustomModal from "@/components/CustomModal.vue";
import randomAnimalName from 'random-animal-name';

const axios = require("axios");

export default {
  name: "Home",
  components: {
    HomeHeader,
    CustomModal,
  },
   data() {
    return {
      userId: "",
      validation: null,
    };
  },
  directives: {
    print,
  },
  beforeUnmount() {
    this.$store.commit("setEditingInProgress", true);
  },
  beforeMount() {
    document.title = "Valorem health";
  },
  mounted() {
    this.$store.dispatch("fetchSurvey", this.$route.params.surveyId);
    setTimeout(() => {
    this.userId = this.initUserId();
    }, 200);
  },
  computed: {
    content(){
      return this.$store.state.survey?.pages?.home ?? {};
    },
    href() {
      return `mailto: ${this.$store.state.survey?.general?.authorEmail}`;
    },
    skipPage(){
      return this.content?.skip ?? false;
    },
    isMobile() {

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || screen.width <= 760)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    initUserId() {
      if (this.$route.query.id != null)
        return this.$route.query.id;

      if (this.$store.state.results.userId != null)
        return this.$store.state.results.userId;
      
      if (this.$store.state.survey?.general?.suggestRandomId)
        return randomAnimalName();
        
      return "";
    },

    updateUserId(inputUserId) {
      this.$store.commit("updateUserId", inputUserId);
    },

    validateForm(event) {
      if (
        !this.$store.state.survey.general.askForId
      ) {
        this.validation = null;
      } else {
        this.validation = event.length > 0 ? null : false;
      }
    },

    requestAccess() {
      // if ID not required, go to next page
      if (!this.$store.state.survey.general.askForId) {
        if (this.$route.query.id != null) {
          this.$store.commit("updateUserId", this.$route.query.id);  
        } else {
          this.$store.commit("updateUserId", "noId");
        }
        this.$store.dispatch("go", 1);
        return;
      }

      if(this.$store.state.survey.general.askForId &&
      this.$store.state.survey.general.validIdRequired){
        if (
          this.userId === "" ||
          this.userId === " " ||
          this.userId === undefined ||
          this.userId === null
        ) {
          // alert user to input an ID because it is required
          this.validation = false;
          return;
        }
      }

      if(this.$store.state.survey.general.askForId &&
      !this.$store.state.survey.general.validIdRequired &&
      !this.$store.state.survey.general.accessIsRestricted
      ){
        if (
          this.userId === "" ||
          this.userId === " " ||
          this.userId === undefined ||
          this.userId === null
        ) {
          this.userId = "noId-" + Math.random().toString(36).substring(2, 15)
          this.$store.commit("updateUserId", this.userId);
        }
      }

      if (!this.$store.state.survey.general.accessIsRestricted) {
        this.$store.commit("updateUserId", this.userId);
        this.$store.dispatch("go", 1);
        return;
      }

      let userId = this.userId;
      var host = process.env.NODE_ENV == "development" ? "http://localhost:4000" : "";
      axios
        .post(host + "/api/survey/access/" + this.$route.params.surveyId, {
          userId,
        })
        .then(
          function (response) {
            if (response.data.success) {
              this.$store.commit("updateUserId", this.userId);


              if(this.$store.state.survey?.general?.rewardOnComplete){
                // console.log("voucher")
                axios
                .post(host + "/api/survey/rwd/" + this.$route.params.surveyId, {
                  userId,
                  })
                  .then(
                    function (response) {
                      if (response.data.success) {
                        // console.log(response)
                        this.$store.commit("updateReward", response.data.voucher);
                        }
                    }.bind(this)
                    );
              }

              this.$store.dispatch("go", 1);
              return;
            }
            this.validation = false;
          }.bind(this)
        );
    },
  },
  watch: {
    skipPage(value) {
      if(value){
        this.requestAccess();
      }
    },
  },
  created() {

  if(this.$gtag){
    this.$gtag.event('page_view', {
      page_title: this.$route.params.surveyId,
      page_location: '/Home',
    })
  }
  },
};
</script>

<style>
.welcome-page h3 {
  font-size: 1.1em;
  margin-top: 2em;
  margin-bottom: 0.25em;
}
.btn-cta {
    background: #fff;
    background-image: linear-gradient(to right bottom, #ffffff,  #ffedfe, #d1d9fe);
    background-repeat: no-repeat;
    background-size: 250% 100%;
    color: #333;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 8px 28px;
    border-radius: 18px;
    border: #d1d1d1 1px solid;
    margin-right: 10px;
    position: relative;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in;
}

.btn-cta.disabled {
    outline: none;
    opacity: 0.5;
    color: rgb(131, 131, 131);
}

.btn-cta:hover {
  /* make the background slightly gray */
  background-position: right center;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.4);
}
</style>
