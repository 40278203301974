import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


import "./assets/customBootstrap5.scss";
import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";


const app = createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(VueApexCharts);

app.mount("#app");