<template>
  <div class="home home-100">
    <div class="d-flex flew-row flex-wrap align-items-start mt-1 mb-5">
      <Description 
      class="bg-white border rounded-3 shadow py-4 px-4 mx-3 mb-4 mb-md-0"
      style="flex-basis: 50%; min-width: 20ch; max-width:60ch; flex-grow: 1;"
      >
        <div v-html="$store.state.survey?.pages?.eqVas?.html ?? '' "></div>
        <div
          class="
            d-flex
            flew-row
            flex-nowrap
            align-items-center
            justify-content-center
            my-5
          "
          v-if="
            $store.state.results?.ownState?.overallHealthLevel != null ||
            $store.state.results?.ownState?.overallHealthLevel != undefined
          "
        >
          {{ $store.state.survey?.pages?.eqVas?.resultLabel ?? "" }}
          <div class="flex-fill"></div>
          <form>
          <input  
          type="number"
          min="10" max="100"
          class="eq-vas-res-box"
          @input="updateSlider"
          v-model="$store.state.results.ownState.overallHealthLevel"
          >
          </form>
            
          <div class="flex-fill"></div>
        </div>
      </Description>

      <Card spacingClasses="px-4 pt-2 pb-4 mx-3" style="flex-basis: 40%">
        <div
          class="
            slider-vertical
            d-flex
            flex-column flex-nowrap
            justify-content-center
            align-items-center
          "
        >
          <div
            :style="{ visibility: resultsRevealed ? 'hidden' : 'visible' }"
            class="text-left w-100 pb-1 text-primary fw-bold"
          >
            {{ $store.state.survey?.pages?.eqVas?.helpLabel }}
          </div>
          <div class="mb-3 text-center">
            {{ $store.state.survey?.pages?.eqVas?.to ?? "" }}
          </div>
          <slider
            @update="updateValue"
            @set="updateValue"
            @handleMadeVisible.once="revealResult"
            styleClass="eq-vas-slider"
            id="eq-vas-slider"
            :revealOnClick="true"
            :config="sliderConfig"
            :values="sliderValue"
          />
          <div class="mt-3 text-center">
            {{ $store.state.survey?.pages?.eqVas?.from ?? "" }}
          </div>
        </div>
      </Card>
    </div>

    <GoNextBtn :promptGoNext="resultsRevealed" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import Slider from "@/components/Slider.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

let sliderConfig = {
  tooltips: [wNumb({ decimals: 0 })],
  start: 40,
  connect: [true, false],
  pips: {
    mode: "positions",
    values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    density: 2,
  },
  direction: "rtl",
  orientation: "vertical",
  range: { min: [0], max: [100] },
};

export default {
  name: "EqVas",
  components: {
    Description,
    Card,
    Slider,
    GoNextBtn,
  },
  data() {
    return {
      resultsRevealed: false,
      sliderConfig,
      counter: null,
    };
  },

  methods: {
    updateValue(value) {
      this.$store.commit(
        "updateOverallHealthStatusLevel",
        Math.round(value[0])
      );
    },
    updateSlider() {
      document.querySelector("#eq-vas-slider").noUiSlider.set(
        this.$store.state.results.ownState.overallHealthLevel
      );
    },
    revealResult() {
        this.resultsRevealed = true;
        document.querySelector(".eq-vas-res-box").classList.add("reveal");
        this.$store.commit("enableGoNext");
    },
  },
  mounted() {
    if (!this.resultsRevealed) {
      this.$store.commit("disableGoNext");
    }
  },
  computed: {
    sliderValue() {
      return [this.$store.state.results?.ownState?.overallHealthLevel ?? 0];
    },
  },
};
</script>

<style lang="scss">
.eq-vas-slider {
  #eq-vas-slider {
    height: 380px;
  }
  .noUi-connect {
    cursor: crosshair !important;
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
    z-index: 2 !important;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 0.5em !important;
    width: 30px !important;
    margin-bottom: 0.6em;
    height: 12px !important;
    border: 3px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    cursor: crosshair !important;
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .noUi-pips-vertical {
    padding: 0;
    margin: 0;
    margin-left: -29px;
    padding-left: 10px;
    pointer-events: none;
    z-index: 0 !important;
  }
  .noUi-value {
    margin-left: 15px;
    color: #0d213a !important;
  }
  .noUi-marker-vertical.noUi-marker.noUi-marker-large {
    width: 40px !important;
    left: -1px;
    right: 0px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 20px !important;
  }
  .noUi-tooltip {
    font-weight: bold;
    border: none !important;
    background: none !important;
  }
}

.eq-vas-res-box {
  background-color: white;
  border: 1px solid black;
  border-radius: 0.3em;
  padding: 0.4em 0em;
  display: inline;
  text-align: center;
  font-size: 1.1em;
  color: var(--bs-primary);
  font-weight: bold;
  visibility: hidden;
  width: 60px;
}

.eq-vas-res-box::-webkit-outer-spin-button,
  .eq-vas-res-box::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .eq-vas-res-box[type=number] {
    -moz-appearance: textfield;
  }


.reveal {
  visibility: visible;
}
</style>
