<template>
  <div class="login-screen-container">
    
    <div class="left-login">
      <div class="pt-4 ps-3 ps-md-4 ps-lg-5 w-100" style="max-width: 600px">

        <div class="text-light fw-light fs-1 pb-1 ps-1">
            <span class="accent">
              <span class="text-decoration-underline">v</span>alorem</span>.<span class="">health</span>
          </div>
      </div>
        <div class="bg-white me-auto" style="width: 70%; min-width: 25ch; height: 2px;"/>
      <div class="ps-3 ps-md-4 ps-lg-5 w-100" style="max-width: 600px">
        <div class="text-start text-light fw-light fs-3">
          Admin dashboard
          <span class="accent fw-lighter small">prototype</span>

        </div>
        <div class="fs-3 text-start header-title text-white">
          /<span class="text-white font-monospace">{{surveyId}}</span>
        </div>
      </div>
    </div>
    <div class="right-login">
      <div class="login-form px-5">
        <form class="fs-4" @submit.prevent="signIn">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              v-model="username"
              placeholder="username"
              name="username"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              v-model="password"
              placeholder="password"
              name="password"
              type="password"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <button
              class="btn btn-primary btn-lg mt-3 d-inline"
              :disabled="loading"
            >
              <span>Login</span>
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm ms-2"
              ></span>
            </button>
          </div>
          <div
            v-if="message"
            style="position: absolute"
            class="alert fs-6 alert-danger px-3 py-2 mt-3 mb-2"
            role="alert"
          >
            {{ message }}
          </div>
        </form>
        <!-- <div class="small text-secondary">Admin console</div> -->
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");

export default {
  name: "Login",
  components: {},
  data() {
    return {
      message: null,
      loading: false,
      username: null,
      password: null,
    };
  },
  computed: {
    surveyId() {
      return this.$route.params.surveyId;
    },
  },
  created() {},
  methods: {
    signIn(user) {
      this.loading = true;
      var host = process.env.NODE_ENV == "development" ? "http://localhost:4000" : "";
      axios
        .post(`${host}/api/auth/signin`, {
          username: this.username,
          password: this.password,
          project: this.surveyId,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            let token = res.data.token;
            this.$store.commit("setToken", token);
            localStorage.setItem("token", JSON.stringify(token));
            this.$store.commit("grantAccess");
          } else {
            this.message = res.data.message;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.message;
          console.error(error);
        });
    },
  },
  watch: {
    message: function (val) {
      if (val) {
        setTimeout(() => {
          this.message = null;
        }, 8000);
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");

*,
html {
  box-sizing: border-box;
}

.accent{
  color: #ff6450; 
  }

.login-screen-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  background-color: #F5F6F7;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.left-login {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gradient background color  */
  background: linear-gradient(
    to bottom left,
    #5c5c5c,
    #363637
    
  );
}
.right-login {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0 0px;
  box-sizing: border-box;
}

.login-form {
  min-width: 300px;
  max-width: 500px;
}

.header-title {
  font-family: "Major Mono Display", monospace;
  font-weight: lighter;
  color: white;
  text-align: center;
}
</style>
