<template>
  <div class="home home-100 w-100">
    <div class="d-flex flex-column">
      <div
        class="border border-dark m-3 py-3 px-3 mx-5 fw-bold"
        style="border-radius: 1.5em; background-color: lightyellow"
      >
        Which is better, Life A or Life B or are they about the same?
      </div>

      <b-overlay
        id="tto-overlay"
        :show="isLoading"
        variant="overlay-tto"
        rounded="true"
        opacity="1"
        class="w-100"
        spinner-variant="primary"
        z-index="1"
      >
        <div
          class="d-flex flex-column align-items-center"
          style="flex-basis: 100%"
        >
          <!-- option A -->
          <div class="d-flex w-100 align-items-end">
            <div
              style="width: 20%"
              class="container d-flex flex-column align-items-center"
            >
              <button
                class="btn btn-dark rounded-circle fs-4 px-3"
                :disabled="taskEnded"
                @click="choice('A')"
              >
                A
              </button>
            </div>

            <div 
            class="d-flex flex-column align-items-center"
            style="flex-basis: 80%; flex-grow: 0; flex-shrink: 0"
            >
              <!-- FH bubble -->
              <div
                class="bubble bg-fh speech-up rounded-3"
                :class="{
                  'align-self-center': !wtd,
                  'align-self-start': wtd,
                  'ms-5': wtd,
                }"
              >
                <div class="mx-3 py-2 ps-1 pe-5">Full health</div>
              </div>

              <!-- FH top arrow span -->
              <div class="align-self-start px-3">{{ fhYears }} years</div>
              <div
                class="align-self-start d-flex align-items-center"
                style="height: 20px"
                :style="{ width: (fhYears / fhHorizon) * 100 + '%' }"
              >
                <div class="arrow arrow-left"></div>
                <div class="flex-fill arrow-span"></div>
                <div class="arrow arrow-right"></div>
              </div>

              <!-- FH year blocks -->
              <div class="w-100 d-flex border">
                <span
                  v-for="(year,i) in fhYearCols"
                  :key="i"
                  class="text-center"
                  style="width: 2.5%; height: 50px"
                  :style="{
                    border: 'solid 0.1em lightgray',
                    height: '50px',
                    width: 100 / (fhHorizon * 4) + '%',
                    backgroundColor: year,
                  }"
                >
                </span>
              </div>
            </div>
          </div>

          <!-- vertical space -->
          <div class="w-100 d-flex align-items-center">
            <div
              class="align-items-center mt-4 mt-md-5 align-self-start"
              style="width: 23%"
            >
              <button
                class="
                  btn btn-dark
                  rounded-3
                  ms-0 ms-sm-2 ms-md-3
                  me-2 me-sm-3 me-md-4 me-lg-5
                "
                @click="choice('equal')"
                :disabled="taskEnded"
              >
                A and B are about the same
              </button>
            </div>
            <div class="w-100" style="position: relative;">
            <div 
            v-if="taskEnded && !isLoading" 
            class="bg-white card border shadow-lg rounded-3 p-4 text-center" 
            style="position: absolute; z-index:2; left: 20%; right: 20%; transform: translateY(-50%); top: 50%;"
            >
              <b class="pb-3">Task completed.</b>
              Click on the button below to continue.
              <p class="font-monospace text-primary pt-3">
                  The utility score is {{ utilityScore }}
              </p>
              <p class = "text-secondary w-75 mx-auto lh-sm small">
                Note: The app randomly selects a new health state everytime you click next – it's an infinite loop, the fun never ends... 
              </p>
            </div>
            </div>
          </div>

          <!-- option B -->
          <div class="d-flex w-100">
            <div
              style="flex-basis: 20%; flex-grow: 0; flex-shrink: 0"
              class="container d-flex flex-column align-items-center"
            >
              <button
                class="btn btn-dark rounded-circle fs-4 px-3 mt-5"
                @click="choice('B')"
                :disabled="taskEnded"
              >
                B
              </button>
            </div>

            <div
            class="d-flex flex-column align-items-center"
            style="flex-basis: 80%; flex-grow: 0; flex-shrink: 0"
            >
              <!-- HS top arrow span -->
              <div class="w-100 d-flex">
                <div v-if="wtd" class="align-self-end px-3">{{ hsYears }} years</div>
                <div class="align-self-end ms-auto px-3">{{ hsYears }} years</div>
              </div>

          <div class="d-flex w-100">
            <!-- lead time FH if WTD -->
              <div
              v-if="wtd"
                  class="align-self-start d-flex align-items-center"
                  style="height: 20px"
                  :style="{ width: (hsYears / hsHorizon) * 100 + '%' }"
                >
                  <div class="arrow arrow-left"></div>
                  <div class="flex-fill arrow-span"></div>
                  <div class="arrow arrow-right"></div>
                </div>

                <div
                  class="align-self-start d-flex align-items-center"
                  style="height: 20px"
                  :style="{ width: (hsYears / hsHorizon) * 100 + '%' }"
                >
                  <div class="arrow arrow-left"></div>
                  <div class="flex-fill arrow-span"></div>
                  <div class="arrow arrow-right"></div>
                </div>
            </div>
              <!-- HS year blocks -->
              <div class="w-100 d-flex border">
                <span
                  v-for="(year,i) in hsYearCols"
                  :key="i"
                  :style="{
                    border: 'solid 0.1em lightgray',
                    height: '50px',
                    backgroundColor: year,
                    width: (hsYearCols.length) + '%',
                  }"
                >
                </span>
              </div>

              <!-- HS bubble -->
              <div
                class="d-flex w-100 justify-content-between align-items-start"
              >
                <!-- lead FH bubble if WTD-->
                <div
                  v-if="wtd"
                  class="bubble bg-fh speech-down-fh rounded-3"
                  style="margin-top: 34px"
                >
                  <div class="mx-3 py-2 ps-1 pe-5">Full health</div>
                </div>

                <div
                  class="bubble bg-hs speech-down rounded-3 mx-3"
                  :class="{ 'align-self-start': !wtd, 'align-self-end': wtd }"
                  style="margin-top: 34px"
                >
                  <div class="mx-2 py-2 ps-1 pe-5 lh-sm">
                    <ul class="m-0">
                      <li v-for="(dim, i) in dimLabels">
                        {{ dim }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100 flex-fill d-flex">
          <div
            class="align-self-start d-flex flex-column align-items-center"
            style="flex-basis: 20%; flex-grow: 0; flex-shrink: 0"
          >
            <button
              class="btn btn-dark rounded-3 align-self-center"
              @click="resetTask()"
            >
              Reset
            </button>
          </div>
          </div>
        </div>
      </b-overlay>
    </div>
    <div class="w-100">
      <GoNextBtn :promptGoNext="taskEnded" />
    </div>
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import { ttoEqvt, eqvtUtilEval } from "@/utils/ttoEqvt.js";
export default {
  name: "Tto",
  components: {
    GoNextBtn,
  },
  data() {
    return {
      fhHorizon: 10,
      fhYears: 10,
      fhYearCols: [],
      hsHorizon: 10,
      hsYears: 10,
      hsYearCols: [],
      wtd: false,
      taskEnded: false,
      taskIndex: 0,
      isLoading: false,
      ttoPath: [],
      ttoRes: [],
    };
  },
  methods: {
    choice(choice) {
      this.ttoPath.push(choice);
      if (choice === "equal") {
        this.taskEnded = true;
        return;
      }
      // apply eqvt algorithm and set new values accordingly
      Object.assign(this, ttoEqvt(this.ttoPath));
    },

    next() {
      this.ttoRes.push({
        years: this.fhYears,
        state: this.dimLabels,
        wtd: this.wtd,
        utility: eqvtUtilEval(this.fhYears,this.wtd)
      });
      if (this.taskIndex < this.ttoSet.length - 1) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.taskIndex++;
          this.resetTask()
        }, 1000);
      } else {
        this.$store.commit("setTtoResults", this.ttoRes);
        this.$store.commit("disableInternalNav");
      }
    },

    back() {
      console.error("back not implemented yet");
    },

    resetTask() {
      this.taskEnded = false;
      this.ttoPath = [];
      this.fhYears = 10;
      Object.assign(this, ttoEqvt([]));
    },
  },
  computed: {
    dimLabels() {
      return this.$store.state.survey.ds.map((d, i) => {
        return d.levels
          .filter((l) => {
            return l.id == this.ttoSet[this.taskIndex][i];
          })
          .map((l) => {
            return l.label;
          })[0];
      });
    },
    ttoSet() {
      if(this.$store.state.survey?.pages?.tto?.states) {
        return this.$store.state.survey.pages.tto.states;
      }
      let randomHs = this.$store.state.survey.ds.map((d, i) => {
        let randomLvl = Math.floor(Math.random() * 5);
        return d.levels
          .filter((l,j) => {
            return j == randomLvl;
          })
          .map((l) => {
            return l.id;
          })[0];
    });
      let infiniteHsSet = [];
      infiniteHsSet[this.taskIndex] = randomHs
      infiniteHsSet[this.taskIndex+1] = randomHs
      return infiniteHsSet;
    },
  },
  watch: {
    taskEnded() {
      if (this.taskEnded) {
        this.utilityScore = eqvtUtilEval(this.fhYears,this.wtd);
        this.$store.commit("enableGoNext");
      } else {
        this.$store.commit("disableGoNext");
      }
    },
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.$store.commit("disableGoNext");
    Object.assign(this, ttoEqvt([]));
    // CLEAR COMMITED TTO PATH
  },
  beforeUnmount() {
    this.subscribe();
  },
};
</script>

<style scoped lang="scss">
$tto-fh: #65ba5f;
$tto-hs: #b0dcf5;
$arrow-color: #0000be;

.arrow {
  display: block;
  width: 0px;
  height: 0px;
  border: 7px solid transparent;
}

.arrow-left {
  border-right-color: $arrow-color;
  border-left-width: 0px;
}
.arrow-right {
  border-left-color: $arrow-color;
  border-right-width: 0px;
}
.arrow-span {
  background-color: $arrow-color;
  height: 5px;
  /* flex fill */
}

.bubble {
  position: relative;
}
.bg-fh {
  background-color: #65ba5f;
}
.bg-hs {
  background-color: $tto-hs;
}

.bubble:before,
.bubble:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

.speech-up:after {
  left: 25%;
  bottom: -24px;
  border: 12px solid;
  border-color: $tto-fh transparent transparent $tto-fh;
}
.speech-down-fh:after {
  right: 25%;
  top: -24px;
  border: 12px solid;
  border-color: transparent $tto-fh $tto-fh transparent;
}
.speech-down:before {
  right: 25%;
  top: -24px;
  border: 12px solid;
  border-color: transparent $tto-hs $tto-hs transparent;
}

.btn-dark {
  &:not(:disabled):hover {
    transform: scale(1.05);
    background-color: #00517f;
  }
  &:disabled {
    pointer-events: all !important;
    cursor: not-allowed !important;
  }
}
</style>
