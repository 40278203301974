////////////////////////////////////////////////////////////////////////////////
// POSITION OF DEAD ALGORITHM
////////////////////////////////////////////////////////////////////////////////
const deadPosAlgorithm = function (
  trail,
  ds,
  dsExpanded,
  allStatesValued,
  maxSteps = 6
) {
  taskEnded = false;
  hsLabels = [];
  hsLvlIds = [];

  if (trail.length == 0) {
    
    for (let i = 0; i < ds.length; i++) {
      let worstLvl = ds[i].levelRating.reduce((a, b) => {
        return (a.rank > b.rank) ? a : b
      })
      hsLabels.push(worstLvl.label);
      hsLvlIds.push(worstLvl.id);
    }
    res = {
      taskEnded: false,
      hsLabels: hsLabels,
      hsLvlIds: hsLvlIds,
      currentPercentile: 100,
      currentScaledUtility: 0,
    };
    
    return res;
    
  }

    function rankDuplicate(arr) {
      const sorted = [...new Set(arr)].sort((a, b) => b - a);
      const rank = new Map(sorted.map((x, i) => [x, i + 1]));
      return arr.map((x) => rank.get(x));
    }

    stateRanks = rankDuplicate(allStatesValued);
    // console.log("stateRanks", stateRanks)

    len = Math.max(...stateRanks);
      
    space_max = len * 2;
    space_min = 1;
    is_at = len;

  if (trail.length > 0) {
    // loop throuhg trail values
    for (let i = 0; i < trail.length; i++) {
      trail_i = trail[i];

      if (trail_i == "b") {
        // B = Dead > HS
        space_max = is_at - 1;
        is_at = Math.floor((space_max + space_min) / 2);
      }

      if (trail_i == "a") {
        // A = HS > DEAD
        if (i == 0) {
          taskEnded = true;
        } else {
          space_min = is_at;
          is_at = Math.floor((space_max + space_min) / 2);
        }
      }
    }
  }
    hs_index = stateRanks.indexOf(is_at);
    currentPercentile = Math.round((is_at / len) * 1000) / 10;

    if (trail.length >= maxSteps) {
      taskEnded = true;
    }

    for (let i = 0; i < dsExpanded[hs_index].length; i++) {
      hsLabels.push(ds[i].levelRating[dsExpanded[hs_index][i]].label);
      hsLvlIds.push(ds[i].levelRating[dsExpanded[hs_index][i]].id);
    }
  // }
  res = {
    taskEnded: taskEnded,
    hsLabels: hsLabels,
    hsLvlIds: hsLvlIds,
    currentPercentile: currentPercentile,
    currentScaledUtility: allStatesValued[hs_index],
    test: "test"
  };
  return res;
};

module.exports = {
  deadPosAlgorithm,
};
