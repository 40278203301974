function lvlRatingSeqHandler(maxLvlCount, index) {
  // start at the middle of an array and then half the array iteratively
  // easier to just use a lookup than to write the algorithm
  var lvlDisplaySequence = [];

  switch (maxLvlCount) {
    case 1:
      console.error(
        "ERROR: getLvlRatingSliderOptionseq: maxLvlCount must be >= 3"
      );
      break;
    case 2:
      console.error(
        "ERROR: getLvlRatingSliderOptionseq: maxLvlCount must be >= 3"
      );
      break;
    case 3:
      lvlDisplaySequence = [1];
      break;
    case 4:
      lvlDisplaySequence = [1, 2];
      break;
    case 5:
      lvlDisplaySequence = [2, 1, 3];
      break;
    case 6:
      lvlDisplaySequence = [2, 3, 1, 4];
      break;
    case 7:
      lvlDisplaySequence = [3, 1, 5, 2, 4];
      break;
    case 8:
      lvlDisplaySequence = [3, 4, 2, 6, 1, 5];
      break;
    case 9:
      lvlDisplaySequence = [4, 2, 7, 3, 6, 1, 5];
      break;
    case 10:
      lvlDisplaySequence = [4, 5, 3, 8, 2, 7, 1, 6];
      break;
    case 11:
      lvlDisplaySequence = [5, 4, 6, 3, 7, 2, 8, 1, 9];
      break;
    case 12:
      lvlDisplaySequence = [5, 6, 4, 9, 3, 8, 2, 7, 1, 10];
      break;
    default:
      console.error(`maxLvlCount is ${maxLvlCount}, >12 levels not supported!`);
      return;
  }
  if (index >= lvlDisplaySequence.length) {
    console.error(
      `currentSequencePos = ${index} > ${lvlDisplaySequence.length - 1}`
    );
    // abort parental function with error
    return;
  }

  return lvlDisplaySequence[index];
}


export {
  lvlRatingSeqHandler
};