<template>
  <div class="home home-60 d-flex flex-column w-100">

    <Description 
    v-html="$store.state.survey?.pages?.demographicQuestions.html"
    >
      </Description>

<div v-for="(category, cIndex) in categories">
    <Card
    v-if="cIndex <= taskIndex"
      :key="category"
      :ref="category"
      spacingClasses="mx-0 mx-md-4 px-2 px-md-4 my-4 py-4"
    >
    <h3 class="text-primary"> {{category}}</h3>
      <div v-if="cIndex <= taskIndex">
        <div v-for="(question, qindex) in questions" :key="question.id">
          <div
            class="px-4 py-4"
            v-if="question.category == category"
          >
            <div class="fw-bold pb-1" v-html="question.label"></div> 
      
      
            <div v-if="question.type == 'likert'">
              <form  class="mt-2 likert-form">
                <ul class='likert'>
                  <li v-for="option in question.options">
                    <input 
                    type="radio" 
                    name="likert" 
                    :disabled="ptns[qindex]"
                    :value="option.value"
                    v-model="selected[qindex]"
                    >
                    <label>{{option.text}}</label>
                  </li>
                </ul>
                </form>


            </div>



            <div v-if="question.type == 'select'">
              <b-form-select
                v-model="selected[qindex]"
                :disabled="ptns[qindex]"
              >
                <option
                  v-for="option in question.options"
                  :key="option.value"
                  :value="option.value"
                  :disabled="option.disabled"
                >
                  {{ option.text }}
                </option>
              </b-form-select>
            </div>

            <div v-if="question.type == 'radio'">
              <b-form-radio-group
                v-model="selected[qindex]"
                :options="question.options"
                :disabled="ptns[qindex]"
              >
              </b-form-radio-group>
            </div>


            <div v-if="question.type == 'checkbox'">
              <b-form-checkbox-group
                :class="question.class ?? ''"
                v-model="selected[qindex]"
                :options="question.options"
                :disabled="ptns[qindex]"
              >
              </b-form-checkbox-group>
            </div>

            <div v-if="question.type == 'text'">
              <b-form-input
                v-model="selected[qindex]"
                :disabled="ptns[qindex]"
              >
              </b-form-input>
            </div>

            <div v-if="question.type == 'textarea'">
              <b-form-textarea
                v-model="selected[qindex]"
                :disabled="ptns[qindex]"
                rows="3"
                :placeholder="question.placeholder ?? ''"
              >
              </b-form-textarea>
            </div>

            <div v-if="question.type == 'numeric'">
              <b-form-input
                v-model="selected[qindex]"
                :disabled="ptns[qindex]"
                class="w-50"
                type="number"
                :placeholder="question.placeholder ?? ''"
              >
              </b-form-input>
            </div>


            <div v-if="question.supportsPreferNotToSay" class="pt-1">
              <b-form-checkbox 
              v-model="ptns[qindex]">
                {{$store.state.survey?.pages?.demographicQuestions.preferNotToSayLabel}}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </Card>
    </div>

    <GoNextBtn :promptGoNext="taskEnded" />
  </div>
</template>

<script>

import GoNextBtn from "../components/GoNextBtn.vue";
import Description from "../components/Description.vue";
import Card from "../components/Card.vue";

export default {
  name: "demographicQuestions",
  components: {
    GoNextBtn,
    Description,
    Card
  },
  data() {
    return {
      selected: [],
      ptns: [],
      taskIndex: 0,
    };
  },
  mounted() {
    this.selected = this.$store.state.results.demographicQuestions.map((q) => {
      return q.response;
    });
    this.$store.commit("disableGoNext");
  },
  beforeUnmount() {
    let payload = this.questions.map((question, index) => {
      return {
        id: question.id,
        question: question.label,
        response: this.ptns[index] ? 'prefer not to say' : this.selected[index],
      };
    });
    this.$store.commit("updateDemographicQuestions", payload);
  },
  methods: {
    next() {
      if(this.taskIndex < this.categories.length - 1) this.taskIndex++;
      
      if(this.categories[this.taskIndex])
        setTimeout(() => {
          this.$refs[this.categories[this.taskIndex]][0].$el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 300);
    },
  },
  computed: {
    questions() {
      return this.$store.state.survey?.pages?.demographicQuestions.questions;
    },
    categories() {
      let categories = this.$store.state.survey?.pages?.demographicQuestions.questions.map(
        (question) => question.category
      );
      return [...new Set(categories)]; // unique values
    },
    categoryCompleted() {
      return this.questions.reduce((acc, curr, index) => {
        if (curr.category == this.categories[this.taskIndex]) {
          if (!curr.required) {
            return acc++;
          } else {
            if (
              (this.selected[index] != "" &&
                this.selected[index] != undefined) ||
              this.ptns[index] 
            ) {
              return acc + 1;
            } else {
              return acc;
            }
          }
        } else {
          return acc;
        }
      }, 0);
    },
    categoryRequired() {
      return this.questions.reduce((acc, curr, index) => {
        if (curr.category == this.categories[this.taskIndex]) {
          if (curr.required) {
            return acc + 1;
          } else {
            return acc;
          }
        } else {
          return acc;
        }
      }, 0);
    },
    taskEnded() {
      let counter = 0;
      this.questions.forEach((el, i) => {
        if (
          (this.selected[i] != "" && this.selected[i] != undefined) ||
          this.ptns[i] || !this.questions[i].required
        ) {
          counter++;
        }
      });
      if (counter == this.questions.length) {
        this.$store.commit("enableGoNext");
        return true;
      } else {
        this.$store.commit("disableGoNext");
        return false;
      }
    },
  },
  watch: {
    ptns: {
      handler() {
        this.ptns.filter((el, i) => {
          if(el){
            this.questions[i].type == "checkbox"
              ? this.selected[i] = []
              : this.selected[i] = "";
          }
        });
      },
      deep: true,
    },
    categoryCompleted() {
      if (this.categoryCompleted == this.categoryRequired) {
        this.next();
      }
    },
  },
};
</script>

<style scoped>
form .likert {
  list-style:none;
  width:100%;
  margin:0;
  padding:0;
  display:block;
  border-bottom:2px solid #efefef;
}
form .likert:last-of-type {border-bottom:0;}
form .likert:before {
  content: '';
  position:relative;
  top:12px;
  left:9.5%;
  display:block;
  background-color:#e6e5e5;
  height:6px;
  width:78%;
}
form .likert li {
  display:inline-block;
  width:19%;
  text-align:center;
  vertical-align: top;
}
form .likert li input[type=radio] {
  display:block;
  position:relative;
  top: -0.2em;
  width: 100%;
  height: 1.5em;
  
}
form .likert li label {
  display:block;
  position:relative;
  top: -0.4em;
  padding: 0;
  padding-top: 0.5em;
  font-size: 0.9em;
  text-align:center;
}
.likert-form {
  margin-right:-1.5em;
  margin-left:-0.5em;
}


</style>