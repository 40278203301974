<template>
  <div class="home home-100 d-flex flex-column w-100">

    <Description class="d-flex my-1 mx-auto"  >
      <div 
      v-if="step===0" 
      v-html="$store.state.survey?.pages?.anchorVas?.intro1"
      />
      <div
      v-else
      v-html="intro2"
      />
    </Description>

    <div class="text-center fw-bold fs-5 pb-4 w-100">
      <div v-if="step===0" v-html="$store.state.survey?.pages?.anchorVas?.question1"/>
      <div v-else v-html="question2"/>
    </div>




<!-- 1st STEP: 'Which is better' -->
    <div 
    v-if="step === 0"
    style="width: 100ch; max-width: 100vw;"
    class="d-flex align-items-stretch justify-content-stretch"
    >
      <State
        :stateId="'pit'"
        :class="'me-3'"
        :scenarioTitle="titleLabelA"
        :dimLabels="dimLabels"
        :labels="pitLabels"
        @select="makeFirstChoice"
        :btnText="selectBtnLabel"
        :appendLvlsWithDims="appendLvlsWithDims"
      />
      <State
      :stateId="'dead'"
        :class="'ms-3'"
        :scenarioTitle="titleLabelB"
        :labels="[anchorLabel]"
        :textAlign="'center'"
        @select="makeFirstChoice"
        :btnText="selectBtnLabel"
        :appendLvlsWithDims="false"
      />
    </div>

    
    <!-- 2nd STEP: 'VAS' -->
    <div
      v-if="step === 1"
      class="d-flex flew-row justify-content-stretch align-items-start"
    >
      <div
        class="d-flex pe-0 pe-md-3 pe-lg-5"
        :class="anchorStateAtBottom ? 'flex-column-reverse' : 'flex-column'"
        style="flex-basis: 60%; flex-grow: 1; flex-shrink: 1 ;"
      >
      <!-- state to value -->
      <State
      :stateId="'deadStage2'"
        :class="'my-1 my-sm-2 my-md-3 my-lg-4'"
        :scenarioTitle="titleLabelB"
        :labels="[anchorLabel]"
        :textAlign="'center'"
        :btn="false"
        :appendLvlsWithDims="false"
      />
      <!-- 0 state -->
      <State
        :stateId="'pitStage2'"
        :class="'my-1 my-sm-2 my-md-3 my-lg-4'"
        :scenarioTitle="titleLabelA"
        :dimLabels="dimLabels"
        :labels="pitLabels"
        :btn="false"
        :appendLvlsWithDims="appendLvlsWithDims"
      />    
      </div>


    <!-- va-scale -->
      <div
        style="flex-basis: 35%; flex-grow: 1; flex-shrink: 1; max-width: 55%"
        class="mx-3 mx-sm-4 mb-2 mb-sm-3 mb-md-5"
      >
        <Card spacingClasses="px-2 pt-2 pb-3 ">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <div
              class="text-primary px-2 w-100 lh-sm small"
              :style="{'visibility': resultsRevealed ? 'hidden' : 'visible'}"
            >
              {{ $store.state.survey?.pages?.anchorVas?.helpLabel }}
            </div>

            <div
              style="margin: 0.75rem 0"
              class="badge bg-light text-dark border fs-6"
            >
              {{ $store.state.survey?.pages?.anchorVas.topAnchor }}
            </div>
            <anchorVasSlider
              @set="setValue"
              @update="updateValue"
              styleClass="dead-vas-slider"
              :config="anchorSliderConfig"
              :hiddenOnStart="true"
              @movingHandle="movingHandleHandler"
              @bottomHandle="bottomHandleHandler"
              @handleMadeVisible="revealLine"
            />
            <div style="margin: 0.75rem 0" class="badge bg-dark fs-6">
            {{anchorStateAtBottom ? titleLabelB : titleLabelA}}
            </div>
          </div>
        </Card>
      </div>
    </div>

    <div class="align-self-end">
      <GoNextBtn :promptGoNext="promptGoNext" />
    </div>

  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";
import ScenarioTable from "@/components/ScenarioTable.vue";
import State from "@/components/State.vue";
import AnchorVasSlider from "@/components/AnchorVasSlider.vue";
import LeaderLine from "leader-line-vue";

export default {
  name: "AnchorVas",
  components: {
    ScenarioTable,
    State,
    Card,
    AnchorVasSlider,
    LeaderLine,
    Description,
    GoNextBtn,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      vasValue: null,
      choice: null,
      anchorStateAtBottom: null,

      promptGoNext: false,
      resultsRevealed: false,
      
      bottomLine: null,
      movingLine: null,
      
      anchorSliderConfig: {
        start: [50,70],
        connect: false,
        pips: {
          mode: "positions",
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          density: 2,
        },
        direction: "rtl",
        orientation: "vertical",
        behaviour: 'unconstrained-tap',
        range: { min: [0], max: [100] },
        animate: true,
        animationDuration: 100,
      },
      bottomListener: false,
      movingListener: false,
    };
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.prev();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.$store.commit("disableGoNext");
    if(this.$store.state.results.AnchorVas)
      this.$store.commit("setCustom", {property: "AnchorVas",value: null});
    // this.$store.commit("disableGoThroughDeadVas");
    // this.$store.commit("clearPodChoice"); // allow moving back and forth
    // this.$store.commit("setLifeAndDeathContinuedTouched", false);
    // this.$store.commit("updatePositionOfPits", null);

    
  },
  methods: {
    makeFirstChoice(target) {
      this.choice = target;
      this.anchorStateAtBottom = target === "pit";
      this.$store.commit("enableGoNext");
      this.promptGoNext = true;
    },
    bottomHandleHandler(target) {
      let bottomLineEnd = target;
      let bottomStart = this.anchorStateAtBottom ? "#state-deadStage2" : "#state-pitStage2"
      let bottomLineStart = document.querySelector(bottomStart);
      let bottomColor = "black"
        this.bottomLine = LeaderLine.setLine(bottomLineStart, bottomLineEnd, {
          color: bottomColor,
          endPlugSize: 1,
          startSocket: "right",
          endSocket: "left",
        });
        this.bottomListener = true;
        window.addEventListener("resize", this.updateBottomLine);
    },
    movingHandleHandler(target) {
      let lineEnd = target;
      let lineStart = this.anchorStateAtBottom ? "#state-pitStage2" : "#state-deadStage2"
      let lineStartEl = document.querySelector(lineStart);
      let lineColor = "transparent"
      this.movingLine = LeaderLine.setLine(lineStartEl, lineEnd, {
        color: lineColor,
        endPlugSize: 1,
        startSocket: "right",
        endSocket: "left",
      });
      this.movingListener = true;
      window.addEventListener("resize", this.updateMovingLine);
    },
    next() {
      if(this.step===1) {
        let val = Number(this.vasValue)/100;
        let pitValue = this.choice === 'pit' ? val : 1-(1/(1-val));
        this.$store.commit("setCustom", {
          property: 'AnchorVas',
          value: {
            preferred: this.choice,
            vasValueOfPreferred: this.vasValue,
            pitStateUtility: pitValue,
          }
        });
        this.$store.commit("disableInternalNav");
      }
      if(this.step===0){
        this.step = 1;
        this.$store.commit("disableGoNext");
        this.promptGoNext = false;

      setTimeout(() => {
        let s1 = document.querySelector("#state-deadStage2");
        let s2 = document.querySelector("#state-pitStage2");
        let sMax = Math.max(s1.offsetHeight, s2.offsetHeight);
        if(s1.offsetHeight == sMax) 
          s2.style.height = sMax + "px";
        else 
          s1.style.height = sMax + "px";
      
      setTimeout(() => {
          this.updateBottomLine()
          this.updateMovingLine()
        }, 0);

      }, 0);


      } 
    },
    prev() {
      if(this.step===0) {
        this.$store.commit("disableInternalNav");
        return;
      } else {
        this.step = 0;
        this.$store.commit("disableGoNext");
        if (this.movingLine) this.movingLine.remove();
        if (this.bottomLine) this.bottomLine.remove();
      }
    },
    revealLine() {
          this.$store.commit("enableGoNext");
          this.promptGoNext = this.resultsRevealed = true;
          if(this.movingLine) this.movingLine.setOptions({ color: "black" });
    },    
    updateValue(value) {
      this.vasValue = value;
      this.updateMovingLine()
    },
    setValue(value) {
      this.vasValue = value;
      setTimeout(() => this.updateMovingLine(), 100);
      setTimeout(() => this.updateMovingLine(), 250);
      setTimeout(() => this.updateMovingLine(), 350);
    },
    updateMovingLine() {
      if (this.movingLine) this.movingLine.position();
    },
    updateBottomLine() {
      if (this.bottomLine) this.bottomLine.position();
    },
    worstLevel(dimension) {
      return dimension.levels.reduce((curr, next) => {
        return curr.rank > next.rank ? curr : next;
      }).label;
    },
  },
  computed: {
    ds() {
      return this.$store.state.results.ds;
    },
    pit() {
      return this.ds.map((dim) => {
        return dim.levelRating.reduce((a, b) => {
          return a.rank > b.rank ? a : b;
        });
      });
    },
    titleLabelA(){
      return this.$store.state.survey?.pages?.anchorVas?.scenarioALabel
    },
    titleLabelB(){
      return this.$store.state.survey?.pages?.anchorVas?.scenarioBLabel
    },
    anchorLabel() {
      return this.$store.state.survey?.pages?.anchorVas?.anchorLabel ?? 'Being Dead';
    },
    pitLabels() {
      return this.pit.map((dim) => {
        return dim.label;
      });
    },
    dimLabels() {
      return this.$store.state.survey.ds.map((d) => d.name);
    },
    selectBtnLabel(){
      return this.$store.state.survey?.pages?.anchorVas?.selectBtnLabel;
    },
    intro2(){
      return this.anchorStateAtBottom ? this.$store.state.survey?.pages?.anchorVas?.intro2A : this.$store.state.survey?.pages?.anchorVas?.intro2B;
    },
    question2(){
      return this.anchorStateAtBottom ? this.$store.state.survey?.pages?.anchorVas?.question2A : this.$store.state.survey?.pages?.anchorVas?.question2B;
    },
    appendLvlsWithDims(){
      return this.$store.state.survey?.pages?.anchorVas?.appendLvlsWithDims
  },
  },
  beforeUnmount() {
    this.subscribe();
    if(this.bottomListener) window.removeEventListener("resize", this.updateBottomLine);
    if(this.movingListener) window.removeEventListener("resize", this.updateMovingLine);
    if (this.movingLine != null) this.movingLine.remove();
    if (this.bottomLine != null) this.bottomLine.remove();
  },
  watch: { 
    // choice() {
    //   if (this.choice) {
    //     this.$store.commit("enableGoNext");
    //     this.promptGoNext = true;
    //   } else {
    //     this.$store.commit("disableGoNext");
    //     this.promptGoNext = false;
    //   }
    // },
  },
};
</script>

<style lang="scss">
.leader-line {
  color: red;
  z-index: 1;
}

/* style used from POD */
#dead-vas-slider {
  height: 350px;
  cursor: crosshair;
}
#dead-vas-slider .noUi-base:after {
  content: "";
  position: absolute;
  top: -5%;
  left: -75%;
  bottom: 0;
  width: 300%;
  opacity: 0.2;
  cursor: crosshair;
}

.dead-vas-slider {
  .noUi-base,
  .noUi-connects {
    position: static;
  }

  .noUi-connect {
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 0.5em !important;
    width: 30px !important;
    margin-bottom: 0.6em;
    height: 12px !important;
    border: 3px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .noUi-pips-vertical {
    padding: 0;
    margin: 0;
    margin-left: -29px;
    padding-left: 10px;
    pointer-events: none;
  }
  .noUi-value {
    margin-left: 15px;
    color: #0d213a !important;
  }
  .noUi-marker-vertical.noUi-marker.noUi-marker-large {
    width: 40px !important;
    left: -1px;
    right: 0px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 20px !important;
  }
  .noUi-tooltip {
    font-weight: bold;
    border: none !important;
    background: none !important;
    z-index: 2 !important;
    color: var(--bs-primary) !important;
    text-shadow: white 0px 0px 0px !important;
    top: 10px;
  }
}

.btn-select {
  width: 50%;
  font-size: 1.25em;
  padding: 0.2em 0.5em;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  white-space: nowrap;
}
.btn-select:hover {
  color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
  background-color: var(--bs-primary) !important;
}
</style>
