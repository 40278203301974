<template>
  <div class="home home-100" style="overflow-x: auto">
    <CustomModal
      :title="'Small screen detected'"
      :visible="isMobile && step === 0"
    >
      <p>Some elements of this survey may not be displayed
      correctly on small screen.</p> 
      <p>Try to rotate your device to landscape mode, if possible.</p> 
      Click 'Ok' to continue.
    </CustomModal>

    <transition name="fade" mode="out-in">
      <div :key="step" class="home-100 mt-3">
        <QuestionCard
          :content="content"
          :context="step === 0 ? content.intro1 : intro2"
          :question="step === 0 ? content.question1 : question2"
        />

        <div class="d-flex 
        justify-content-start
        justify-content-sm-center">
          <!-- pits vs dead / step0 show row, step1 show column  -->

          <div
            style="width: 100%"
            class="d-flex"
            :class="
              step == 0
                ? 'align-items-stretch px-3'
                : anchorStateAtBottom
                ? 'flex-column '
                : 'flex-column-reverse'
            "
          >
            <!-- pits state -->
            <div
              class="card mb-3"
              :class="choice=='pit' && step ==0 ? 'magnify':''"
              id="pit-card"
              style="flex-basis: 50%; margin-left: auto"
            >
              <div
                class="card-header bg-primary text-white text-center fw-bold"
              >
                {{ titleLabelA }}
              </div>
              <div class="card-body">
                <ul>
                  <div v-for="(lab, i) in pitLabels">
                    <li class="text-start mb-1">
                      <span v-if="content?.appendLevelLabel" class="fw-bold"
                        >{{ dimLabels[i] }}:
                      </span>
                      {{ lab }}
                    </li>
                  </div>
                </ul>
                <div 
                v-if="content.labelANote"
                v-html="content.labelANote"
                class="text-secondary small pb-3 px-1"
                >
                </div>
                <div v-if="step == 0" class="d-flex flex-column px-5">
                  <input
                    type="radio"
                    class="btn-check"
                    id="pit"
                    name="selected-scenario"
                    autocomplete="off"
                    value="pit"
                    v-model="choice"
                  />
                  <label class="btn btn-lg btn-outline-primary" for="pit">
                    {{ content?.selectBtnLabel ?? "Select" }}
                  </label>
                </div>
              </div>
            </div>

            <!-- spacer -->
            <div class="mx-1 mx-sm-1 mx-md-2 mx-lg-4"></div>

            <!-- dead -->
            <div
              class="card mb-3"
              id="dead-card"
              :class="choice=='dead' && step ==0 ? 'magnify':''"
              style="flex-basis: 50%; margin-left: auto"
            >
              <div
                class="card-header bg-primary text-white text-center fw-bold"
              >
                {{ titleLabelB }}
              </div>
              <div class="card-body d-flex flex-column">
                <div class="my-auto mx-auto" v-html="anchorLabel"></div>
                <div 
                v-if="content.labelBNote"
                v-html="content.labelBNote"
                class="text-secondary small pb-3 px-1"
                >
                </div>
                <div v-if="step == 0" class="d-flex flex-column px-5">
                  <input
                    type="radio"
                    class="btn-check"
                    id="dead"
                    name="selected-scenario"
                    autocomplete="off"
                    value="dead"
                    v-model="choice"
                  />
                  <label class="btn btn-lg btn-outline-primary" for="dead">
                    {{ content?.selectBtnLabel ?? "Select" }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- va-scale -->
          <div
            style="width: 100%"
            v-if="step === 1"
            class="d-flex flex-column ms-2 ms-md-3 ms-lg-5"
          >
            <div class="card me-auto mb-5 pb-3">
              <div
                class="card-body d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  class="text-primary px-2 w-100 lh-sm small"
                  :style="{
                    visibility: resultsRevealed ? 'hidden' : 'visible',
                  }"
                >
                  {{ $store.state.survey?.pages?.anchorVas?.helpLabel }}
                </div>

                <div class="w-100 d-flex justify-content-start">
                <div
                  style="margin-top: 0.75rem; margin-bottom: 0.75rem"
                  class="py-1 px-1 border fw-bold bg-light text-dark border text-center mx-auto rounded-3"
                >
                <span v-html="$store.state.survey?.pages?.anchorVas.topAnchor"></span>
                </div>
              </div>
                <anchorVasSlider
                  @set="setValue"
                  @update="updateValue"
                  styleClass="dead-vas-slider"
                  :config="anchorSliderConfig"
                  :hiddenOnStart="true"
                  @movingHandle="movingHandleHandler"
                  @bottomHandle="bottomHandleHandler"
                  @handleMadeVisible="revealLine"
                />
                <div style="margin: 0.75rem 0" class="badge bg-dark fs-6">
                  {{ anchorStateAtBottom ? titleLabelB : titleLabelA }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 2nd STEP: 'VAS' -->
        <div
          v-if="step === 1"
          class="d-flex flew-row justify-content-stretch align-items-start"
        >
          <div
            class="d-flex pe-0 pe-md-3 pe-lg-5"
            :class="anchorStateAtBottom ? 'flex-column-reverse' : 'flex-column'"
            style="flex-basis: 60%; flex-grow: 1; flex-shrink: 1"
          ></div>
        </div>
        <div
          v-if="step === 1"
          class="d-flex flew-row justify-content-stretch align-items-start"
        >
          <div
            class="d-flex pe-0 pe-md-3 pe-lg-5"
            :class="anchorStateAtBottom ? 'flex-column-reverse' : 'flex-column'"
            style="flex-basis: 60%; flex-grow: 1; flex-shrink: 1"
          ></div>
        </div>
      </div>
    </transition>
    <div class="align-self-end">
      <GoNextBtn :promptGoNext="promptGoNext" />
    </div>
  </div>
</template>

<script>
import QuestionCard from "@/components/QuestionCard.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";
import AnchorVasSlider from "@/components/AnchorVasSlider.vue";
import LeaderLine from "leader-line-vue";
import CustomModal from "@/components/CustomModal.vue";

export default {
  name: "AnchorVas",
  components: {
    QuestionCard,
    AnchorVasSlider,
    LeaderLine,
    CustomModal,
    GoNextBtn,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      vasValue: null,
      choice: null,
      anchorStateAtBottom: null,

      promptGoNext: false,
      resultsRevealed: false,

      bottomLine: null,
      movingLine: null,

      anchorSliderConfig: {
        start: [50, 70],
        connect: false,
        pips: {
          mode: "positions",
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          density: 2,
        },
        tooltips: [false, wNumb({decimals: 0})],
        direction: "rtl",
        orientation: "vertical",
        behaviour: "unconstrained-tap",
        range: { min: [0], max: [100] },
        animate: true,
        animationDuration: 100,
      },
      bottomListener: false,
      movingListener: false,
    };
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.prev();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.$store.commit("disableGoNext");
    if (this.$store.state.results.AnchorVas)
      this.$store.commit("setCustom", { property: "AnchorVas", value: null });
  },
  methods: {
    bottomHandleHandler(target) {
      setTimeout(() => {
        let bottomLineEnd = target;
        let bottomStart = this.anchorStateAtBottom ? "#dead-card" : "#pit-card";
        let bottomLineStart = document.querySelector(bottomStart);
        let bottomColor = "black";
        this.bottomLine = LeaderLine.setLine(bottomLineStart,
          LeaderLine.obj.pointAnchor(
          bottomLineEnd, {x:-4, y: 4 }), 
          // bottomLineEnd, 
          {
          color: bottomColor,
          endPlugSize: 1,
          startSocket: "right",
          endSocket: "left",
          lineOffset: [500, 500],
        });
        this.bottomListener = true;
        window.addEventListener("resize", this.updateBottomLine);
      }, 100);
    },
    movingHandleHandler(target) {
      let lineEnd = target;
      let lineStart = this.anchorStateAtBottom ? "#pit-card" : "#dead-card";
      let lineStartEl = document.querySelector(lineStart);
      let lineColor = "transparent";
      this.movingLine = LeaderLine.setLine(lineStartEl,
      LeaderLine.obj.pointAnchor(lineEnd, {x: -25}), {
        color: lineColor,
        endPlugSize: 1,
        startSocket: "right",
        endSocket: "left",
      });
      this.movingListener = true;
      window.addEventListener("resize", this.updateMovingLine);
    },
    next() {
      if (this.step === 1) {
        let val = Number(this.vasValue) / 100;
        let pitValue = this.choice === "pit" ? val : 1 - 1 / (1 - val);
        this.$store.commit("setCustom", {
          property: "AnchorVas",
          value: {
            preferred: this.choice,
            vasValueOfPreferred: this.vasValue,
            pitStateUtility: pitValue,
          },
        });
        this.$store.commit("disableInternalNav");
      }
      if (this.step === 0) {
        this.step = 1;
        this.$store.commit("disableGoNext");
        this.promptGoNext = false;

        setTimeout(() => {
          // smooth scroll to top of window
          window.scrollTo({ top: 0, behavior: "smooth" });
          let s1 = document.querySelector("#dead-card");
          let s2 = document.querySelector("#pit-card");

          let sMax = Math.max(s1.offsetWidth, s2.offsetWidth);
          if (s1.offsetWidth == sMax) s2.style.width = sMax + "px";
          else s1.style.width = sMax + "px";
          this.updateBottomLine();
          this.updateMovingLine();
        }, 300);
      }
    },
    prev() {
      if (this.step === 0) {
        this.$store.commit("disableInternalNav");
        return;
      } else {
        this.step = 0;
        this.$store.commit("disableGoNext");
        if (this.movingLine) this.movingLine.remove();
        if (this.bottomLine) this.bottomLine.remove();
      }
    },
    revealLine() {
      this.$store.commit("enableGoNext");
      this.promptGoNext = this.resultsRevealed = true;
      if (this.movingLine) this.movingLine.setOptions({ color: "black" });
    },
    updateValue(value) {
      this.vasValue = value;
      this.updateMovingLine();
    },
    setValue(value) {
      this.vasValue = value;
      setTimeout(() => this.updateMovingLine(), 100);
      setTimeout(() => this.updateMovingLine(), 250);
      setTimeout(() => this.updateMovingLine(), 350);
    },
    updateMovingLine() {
      if (this.movingLine) this.movingLine.position();
    },
    updateBottomLine() {
      if (this.bottomLine) this.bottomLine.position();
    },
    worstLevel(dimension) {
      return dimension.levels.reduce((curr, next) => {
        return curr.rank > next.rank ? curr : next;
      }).label;
    },
  },
  computed: {
    content() {
      return this.$store.state.survey?.pages?.anchorVas ?? {};
    },
    ds() {
      return this.$store.state.results.ds;
    },
    pit() {
      return this.ds.map((dim) => {
        return dim.levelRating.reduce((a, b) => {
          return a.rank > b.rank ? a : b;
        });
      });
    },
    titleLabelA() {
      return this.content?.scenarioALabel;
    },
    titleLabelB() {
      return this.content?.scenarioBLabel;
    },
    anchorLabel() {
      return this.content?.anchorLabel ?? "Being Dead";
    },
    pitLabels() {
      return this.pit.map((dim) => {
        return dim.label;
      });
    },
    dimLabels() {
      return this.$store.state.survey.ds.map((d) => d.name);
    },
    intro2() {
      return this.anchorStateAtBottom
        ? this.content?.intro2A
        : this.content?.intro2B;
    },
    question2() {
      return this.anchorStateAtBottom
        ? this.content?.question2A
        : this.content?.question2B;
    },
    isMobile() {
      if (screen.width <= 540) return true;
      return false;
    },
  },
  beforeUnmount() {
    this.subscribe();
    if (this.bottomListener)
      window.removeEventListener("resize", this.updateBottomLine);
    if (this.movingListener)
      window.removeEventListener("resize", this.updateMovingLine);
    // try catch
    try {
      if (this.movingLine) this.movingLine.remove();
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.bottomLine) this.bottomLine.remove();
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    choice(val) {
      this.anchorStateAtBottom = val === "pit";
      if (val) {
        this.$store.commit("enableGoNext");
        this.promptGoNext = true;
      }
    },
    // choice() {
    //   if (this.choice) {
    //     this.$store.commit("enableGoNext");
    //     this.promptGoNext = true;
    //   } else {
    //     this.$store.commit("disableGoNext");
    //     this.promptGoNext = false;
    //   }
    // },
  },
};
</script>

<style lang="scss">

.leader-line {
  z-index: 1;
}

.dead-vas-slider {
  #anchorVasSlider {
    min-height: 300px;
  }

  .noUi-base,
  .noUi-connects {
    position: static;
  }

  .noUi-connect {
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 0.5em !important;
    width: 30px !important;
    margin-bottom: 0.6em;
    height: 12px !important;
    border: 3px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .noUi-pips-vertical {
    padding: 0;
    margin: 0;
    margin-left: -29px;
    padding-left: 10px;
    pointer-events: none;
  }
  .noUi-value {
    margin-left: 15px;
    color: #0d213a !important;
  }
  .noUi-marker-vertical.noUi-marker.noUi-marker-large {
    width: 40px !important;
    left: -1px;
    right: 0px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 20px !important;
  }
  .noUi-tooltip {
    font-weight: bold;
    border: none !important;
    background: none !important;
    z-index: 2 !important;
    left: -30px;
    color: var(--bs-primary) !important;
    text-shadow: white 0px 0px 0px !important;
    font-size: 1.1em;
  }
}

.btn-select {
  width: 50%;
  font-size: 1.25em;
  padding: 0.2em 0.5em;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  white-space: nowrap;
}
.btn-select:hover {
  color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
  background-color: var(--bs-primary) !important;
}

.magnify {
  transform: scale(1.03);
  transition: transform 0.2s;
  box-shadow: 0 0 0 0.1rem var(--bs-primary);
  
}

</style>
