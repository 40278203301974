import router from "@/router";

export default {
  state: {
    currentPage: -1,
    lastPage: -1,
    navDirection: 0,
    agenda: [],
    preventReturn: [],
    editingInProgress: false,
    animateTransitions: true,
    
    canGoNext: false,
    canGoBack: true,

    internalTrigger: false,
    goThroughDeadVas: false,
    lvlRatingTaskIndex: 0,
    dimWeightTaskIndex: 0,
    internalNav: false,
    customPercentage: false,
    timeoutTimer: false,
    
    dimensions: [],
    dimensionNavigationIndex: 0,
    levelInDimensionNavigationIndex: 0,
    hasCompletedLevelsInDimensionNavigation: false,
  },

  getters: {
    percentageCompleted: (state) => {
      if (typeof state.customPercentage === "number") {
        return state.customPercentage;
      }
      return ( (state.currentPage+1) / (state.agenda.length)) * 100;
    },
    getDimWeightTaskIndex: (state) => {
      return state.dimWeightTaskIndex;
    },
    getCurrentPage: (state) => {
      return state.agenda[state.currentPage];
    },
  },
  mutations: {
    setCustomPercentage(state, percentage) {
      state.customPercentage = percentage;
    },
    setDimWeightTaskIndex(state, payload) {
      state.dimWeightTaskIndex = payload;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setLastPage(state, page) {
      state.lastPage = page;
    },
    setNavDirection(state, direction) {
      state.navDirection = direction;
    },
    enableInternalTrigger(state) {
      state.internalTrigger = true;
    },
    disableInternalTrigger(state) {
      state.internalTrigger = false;
    },
    setEditingInProgress(state, value) {
      state.editingInProgress = value;
    },
    disableGoNext(state) {
       state.canGoNext = false;
    },
    enableGoNext(state) {
      state.canGoNext = true;
    },
    disableGoBack(state) {
       state.canGoBack = false;
    },
    enableGoBack(state) {
      state.canGoBack = true;
    },
    enableInternalNav(state) {
      state.internalNav = true;
    },
    disableInternalNav(state) {
      state.internalNav = false;
    },
    setLvlRatingTaskIndex(state, payload) {
      state.lvlRatingTaskIndex = payload;
    },
    declareDimensionWeighted(state, payload) {
      state.dimensionsWeighted.push(payload);
    },
    enableGoThroughDeadVas(state) {
      state.goThroughDeadVas = true;
    },
    disableGoThroughDeadVas(state) {
      state.goThroughDeadVas = false;
    },
    loadAgenda(state, payload) {
      state.agenda = payload;
    },
    loadPreventReturn(state, payload) {
      state.preventReturn = payload;
    },
    // completeLevelsInDimensionNavigation(state, payload) {
    //   state.hasCompletedLevelsInDimensionNavigation = payload;
    // },
  },
  actions: {

    go({ state, commit, dispatch }, where) {
      if(state.timeoutTimer) return;
      state.timeoutTimer = true;
      setTimeout(() => {state.timeoutTimer = false}, 350)
      
      if (typeof where == "string") {
        let newPos = state.agenda.indexOf(where)
        
          if (newPos >= 0) {
            commit("setCurrentPage", newPos);
            commit("setNavDirection", newPos - state.currentPage);
            commit("enableInternalTrigger");
            dispatch('updateTime', state.agenda[state.currentPage-1], { root: true });
            router.push({ name: where });
            return;
          } else {
            console.error("trying to navigate to a page (sring) that does not exist");
            return;
          }
      }
      
      if (state.internalNav) return;

      dispatch('updateTime', state.agenda[state.currentPage], { root: true });

      if(!state.goThroughDeadVas) {
        if (state.agenda[state.currentPage + where] == "PositionOfPits") {
          where = where == 1 ? 2 : -2;
        }
      }
      
      commit("setCurrentPage", state.currentPage + where);
      commit("setNavDirection", where);  

      if (state.currentPage == -1) {
        router.push({ name: "Home" });
        return;
      } 
      
      commit("enableInternalTrigger");
      if (state.currentPage > state.agenda.length - 1) {
        console.error("exceeded max page index");
        router.push({ name: "Error404" });
        return;
      }
    
      router.push({ name: state.agenda[state.currentPage] });
      
    },
  },
};
