<template>
  <div
    v-if="$store.state.admin.loggedIn"
    class="d-flex flex-column sidebar-menu"
  >
    <ul
    v-if="$store.state.admin.mode ==='analyse'"
    class="nav flex-column mb-auto"
    >
        <router-link
        v-for="(component, index) in DsAnalysisComponents"
        :key="index"
        class="nav-item nav-link"
        :to="{ name: component.routeName }">
          {{component.linkName}}
        </router-link>
    </ul>
    <ul
    v-if="$store.state.admin.mode ==='create'"
    class="nav flex-column mb-auto"
    >
        <router-link
        v-for="(component, index) in DsCreateComponents"
        :key="index"
        class="nav-item nav-link"
        :to="{ name: component.routeName }">
          {{component.linkName}}
        </router-link>
    </ul>
    <!-- <div class="mt-auto mb-2 ps-3 fw-lighter small">
      &copy; {{new Date().getFullYear()}} Paul Schneider
      </div> -->
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      DsCreateComponents: [
        { routeName: "DsCreateSurvey", linkName: "Create" },
      ],
    };
  },
  computed: {
    DsAnalysisComponents() {
      let components = [
        { routeName: "DsHome", linkName: "Home" }
      ];
      if (this.$store.state?.admin?.responses?.length > 0) {
        components.push(...[
          { routeName: "DsValueSet", linkName: "Value set" },
          { routeName: "DsCompare", linkName: "Compare" },
          { routeName: "DsSpaghetti", linkName: "PUFs" },
          { routeName: "DsWeighting", linkName: "Weighting" },
          { routeName: "DsRating", linkName: "Rating" },
          { routeName: "DsAnchoring", linkName: "Anchoring" },
          { routeName: "DsDemQuest", linkName: "Survey questions" },
          { routeName: "DsFeedback", linkName: "Assess feedback" },
          { routeName: "DsDurations", linkName: "Completion times" },
          { routeName: "DsDownload", linkName: "Download data" }
        ]);
      }
      components.push(
        { routeName: "DsManageSurvey", linkName: "Manage survey" }
      );

      return components;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.sidebar-menu {
  border-top: 1px solid rgb(197, 197, 197) !important;
  position: fixed;
  width: 220px;
  top: 50px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  /* box shadow only to the right side but not to the top of the box */
  
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 1), 0 0 0 0 rgba(31, 73, 125, 0.8);


  z-index: 10;
  font-size: 1.1em;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
.nav-link {
    color: #333333;
}
.nav-link:hover, .nav-link:focus {
    color: #fff;
    background-color: #404346;
}
.router-link-active {
  background: var(--bs-primary) !important;
}

</style>
