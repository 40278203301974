<template>
  <div class="home home-100 w-100">

    <div class="d-flex w-100 bg-dark" style="width: 100%">


        <div class="d-flex flex-column p-3" style="flex-basis: 33%">
            <div class="card">Card 1</div>
            <div class="card">Card 2</div>
            <div class="card">Card 3</div>
        </div>


        <div class="d-flex flex-column p-3" style="flex-basis: 66%">
            <div
            class="p-2 border rounded bg-white m-1"
            style="width: 200px; height: 180px;"
            >
            1
            </div>
            <div
            class="p-2 border rounded bg-white m-1"
            style="width: 200px; height: 180px;"
            >
            2
            </div>
            <div
            class="p-2 border rounded bg-white m-1"
            style="width: 200px; height: 180px;"
            >
            3
            </div>
        </div>
    </div>


  <div class="row">
    <div class="col-3">
      <h3>Draggable 1</h3>
      <draggable class="list-group" :list="list1" group="people" @change="log" :move="checkMove">
      <template #item="{ element }">
        <div
          class="list-group-item"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
        </template>
      </draggable>
    </div>

    <div class="col-3">
      <h3>Draggable 2</h3>
      <draggable class="list-group" :list="list2" group="people" @change="log" :move="checkMove">
      <template #item="{ element }">
        <div
          class="list-group-item"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
        </template>
      </draggable>
    </div>


  </div>



  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    name: "two-lists",
  display: "Two Lists",
  order: 1,
    components: {
        draggable,
    },
    data() {
    return {
      list1: [
        { name: "John", id: 1 },
        { name: "Joao", id: 2 },
        { name: "Jean", id: 3 },
        { name: "Gerard", id: 4 }
      ],
      list2: [
        { name: "Juan", id: 5 },
        { name: "Edgard", id: 6 },
        { name: "Johnson", id: 7 }
      ]
    };
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    checkMove: function(evt){
     console.log(evt.relatedContext.list.length);
     return true;
    },
    log: function(evt) {
      window.console.log(evt);
    }
  },
};
</script>

<style>

</style>