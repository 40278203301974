<template>
  <div class="card mx-3 mb-3" style="flex-basis: 100%">
    <div class="card-body">
      <div v-html="context ?? content?.context"></div>
      <slot> </slot>
      <div v-if="question || content?.question" class="question" v-html="question ?? content?.question"></div>
      <Details
        v-if="details || content?.details"
        :label="
          detailsLabel ??
          $store.state.survey.components?.detailsStr ??
          'Show details'
        " 
      >
        <div v-html="details ?? content?.details"></div>
      </Details>
      <div v-else class="py-1"></div>
    </div>
  </div>
</template>

<script>
import Details from "@/components/Details.vue";
export default {
  name: "QuestionCard",
  components: {
    Details,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    question: {
      type: String,
      required: false,
    },
    context: {
      type: String,
      required: false,
    },
    detailsLabel: {
      type: String,
      required: false,
    },
    details: {
      type: String,
      required: false,
    },
  },
};
</script>
