<template>
  <div class="home home-100 w-100">



    <!--  top ROW: comtext + question +  details -->
    <QuestionCard :content="content"></QuestionCard>


    <!-- info1 + state | info2 + task -->
    <div
      class="d-flex align-items-start flex-wrap flex-sm-nowrap justify-content-stretch"
    >
      <!-- Info 1 -->
      <div
        class="card  mx-3 mb-3"
        style="flex-basis: 50%; flex-grow: 1"
      >
        <div class="card-body">
          <div class="" v-html="content.info1"></div>
          <!-- state -->
          <transition name="fade" mode="out-in">
            <ul class="mt-3 mb-2" :key="taskIndex">
              <div v-for="(s, i) in currentStateLabels" 
              class=""
              :key="i"
              >
                <li 
                :class="i==currentHighlighted ? 'fw-bold bigger-font text-primary' : 'text-secondary'"
                class="pb-1"
                >
                  {{ s }}
                </li>
              </div>
            </ul>
            <!-- <div class="border border-dark  mt-3 mb-2" :key="taskIndex">
              <div v-for="(s, i) in currentStateLabels" 
              class="p-2"
              :key="i"
              :class="i>0?'border-top':null">
                <div :class="i==currentHighlighted ? 'fw-bold' : 'text-secondary'">
                  {{ s }}
                </div>
              </div>
            </div> -->
          </transition>
        </div>
      </div>

      
      
      <!-- info 2 + task -->
      <div class="align-items-stretch"
      style="flex-basis: 50%; flex-grow: 1"
      >
        <div
        class="card  mx-3 mb-3"
      >
          <div class="card-body pb-5">
            
            <!-- info 2 -->
            <div 
            style="max-width: 300px;"
            class="mb-3 px-3 mx-auto" 
            v-html="content.info2"
            
            ></div>
            
            <!-- Task -->
            
            <!-- numeric input -->
            <transition name="fade" mode="out-in">
              <div :key="taskIndex">
            <b-input-group size="lg" :prepend="content.currency ?? '$'" append=".00" class="w-50 mx-auto" 
            :key="taskIndex"
            style="min-width: 210px;"
            >
              <b-form-input
                autofocus
                id="wtp-1"
                class="text-center"
                placeholder=""
                @keydown="enforceNumberInput"
                :formatter="formatter"
                :disabled="nwtp[taskIndex]"
                v-model="wtp[taskIndex]"
                :min="0"
              />
            </b-input-group>
            <!-- checkbox -->
            <b-input-group 
            v-if="content.ptns"
            class="mt-2 ps-2 small">
              <b-form-checkbox
                v-model="nwtp[taskIndex]"
                @change="nwtp[taskIndex] ? (wtp[taskIndex] = 0) : null"
                class=""
              >
                {{content.ptns}}
              </b-form-checkbox>
            </b-input-group>
          </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <GoNextBtn :promptGoNext="taskCompleted" />
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import QuestionCard from "@/components/QuestionCard.vue";
export default {
  name: "Wtp",
  components: {
    GoNextBtn,
    QuestionCard,
  },
  data() {
    return {
      wtp: [],
      nwtp: [],
      taskIndex: 0,
    };
  },
  methods: {
    next() {
      if (this.taskIndex >= this.states.s.length - 1) {
        console.log("1");
        this.$store.commit("disableInternalNav");
      } else {
        console.log("2");
        this.taskIndex++;
      }
    },
    back() {
      if (this.taskIndex <= 0) {
        this.$store.commit("disableInternalNav");
      } else {
        this.taskIndex--;
      }
    },
    formatter(value) {
      if (value === "" || value === null) return null;
      value = value.replace(/^0+/, "");
      value = Number(value);
      return value;
    },
    enforceNumberInput(e) {
      // enforce number input or arrows or delete
      if (
        !(
          (e.keyCode >= 48 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          e.keyCode == 8 ||
          e.keyCode == 37 ||
          e.keyCode == 39
        )
      ) {
        e.preventDefault();
      }
    },
  },
  beforeMount() {
    this.$store.commit("enableInternalNav");
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("disableGoNext");
    if(this.$store.state.results?.wtp?.wtp?.length > 0){
      this.wtp = this.$store.state.results.wtp.wtp;
      this.nwtp = this.$store.state.results.wtp.nwtp;
    }
  },
  beforeUnmount() {

    let responses = this.states.h.map((h, i) => {
      return {
        dim: this.dimensions[h].id,
        wtp: this.wtp[i],
        nwtp: this.nwtp[i],
        state: this.states.s[i],
      }
    });

    let payload = {
      property: "wtp",
      value: responses,
    };



    this.$store.commit("setCustom",payload)
    this.subscribe();
  },
  computed: {
    content() {
      return this.$store.state.survey?.pages?.wtp;
    },
    ownState() {
      let ownState = this.$store.state.results?.ownState?.state?.map((s) => parseInt(s.substring(3, 4)));
      if(ownState.length === 0){
        ownState = this.dimensions.map(d => 0);
      }
      return ownState;
    },
    states() {
      let states = [];
      let highlighted = [];
      for (let i = 0; i < this.ownState.length; i++) {
        let curr = this.ownState[i];
        let max = this.dimensions[i].levels.length - 1;
        if (curr < max) {
          states.push(this.ownState.map(o => o));
          states[states.length - 1][i] = curr + 1;
          highlighted.push(i);
        }
      }
      if (states.length == 0) states.push(this.ownState);

      return {s: states, h: highlighted};
    },
    currentState() {
      return this.states.s[this.taskIndex];
    },
    currentHighlighted() {
      return this.states.h[this.taskIndex];
    },
    currentStateLabels() {
      return this.currentState?.map((s, i) => {
        return this.dimensions[i].levels[s].label;
      });
    },
    taskCompleted() {
      let check = !isNaN(this.wtp[this.taskIndex]) && this.wtp[this.taskIndex] !== null;
      if (check) this.$store.commit("enableGoNext");
      else this.$store.commit("disableGoNext");
      return check;
    },
    dimensions() {
      return this.$store.state.survey.ds;
    },
  },
  watch: {
    taskIndex() {
      if (this.taskIndex > 0) {
        this.$store.commit("enableGoBack");
      }
    },
  },
};
</script>

<style scoped>
.bigger-font {
  font-size: 1.1em;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
