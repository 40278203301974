<template>
  <div>
    <!-- Modal -->
    <div
      v-if="OpenClose"
      class="modal show fade backdrop"
      tabindex="-1"
      aria-hidden="true"
      aria-modal="true"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border shadow">
          
          <!-- header -->
          <div 
          v-if="title"
          class="modal-header"
          >
          <!-- warning triangle -->
          <div v-html="exclamationTriangle"></div>
            <div class="align-bottom ms-2 h5 mb-0" v-html="title"/>

            <button
              v-if="!blocking"
              type="button"
              @click="OpenCloseFun()"
              class="btn-close"
            ></button>
            <div v-else></div>
          </div>
          
          <!-- content -->
          <div class="modal-body px-4 py-4 fs-5">
            <slot></slot>
            <div class="w-100 px-0 pt-4">
              <button
                v-if="!blocking"
                @click="OpenCloseFun()"
                class="btn btn-primary btn-lg"
              >
                Ok
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exclamationTriangle } from "@/assets/img/iconSvgs.json";
export default {
  name: "CustomModal",
  components: {
    exclamationTriangle,
  },
  props: {
    visible: null,
    msg: String,
    title: String,
    blocking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OpenClose: this.visible,
    };
  },
  emits: ["closed"],
  methods: {
    OpenCloseFun() {
      this.$emit("closed");
      this.OpenClose = false;
    },
  },
  computed: {
    exclamationTriangle() {
      return exclamationTriangle;
    },
  },
  watch: {
    visible(val) {
      this.OpenClose = val;
    },
  },
  mounted() {
    if (!this.blocking) {
      document.addEventListener("click", (e) => {
        if (e.target.classList.contains("backdrop")) {
          this.OpenCloseFun();
        }
      });
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", (e) => {
      if (e.target.classList.contains("backdrop")) {
        this.OpenCloseFun();
      }
    });
  },
};
</script>

<style scoped>
.backdrop {
  z-index: 2 !important;
  background-color: rgba(255, 255, 255, 0.8);
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: grayscale(1) blur(2px);
    -webkit-backdrop-filter: grayscale(1) blur(2px);
  }
}

.modal-dialog {
  z-index: 3 !important;
  width: calc(100vw - 5px) !important;
  max-width: 700px !important;
  height: calc(100vh - 5px) !important;
  height: 100px !important;
}
.modal-content {
  max-height: calc(100vh - 5px) !important;
  overflow-y: auto;
}
</style>
