import axios from "axios";
import router from "@/router";

export default {
  state: {
    mode: "",
    loggedIn: false,
    responses: null,
    completedCount: null,
    consentedCount: null,
    token: null,
    loadingResponses: false,
    loadingMessages: null,
    loginMessage: null,
    loginStatus: null,
    surveyId: null,
    host: process.env.NODE_ENV == "development" ? "http://localhost:4000" : "",
  },
  getters: {},
  mutations: {
    logout(state, msg) {
      state.token = null;
      state.loggedIn = false;
      localStorage.removeItem("token");
      state.loginMessage = msg ?? "You have been logged out.";
      state.loginStatus = "danger";
    },
    setResponses(state, responses) {
      state.responses = responses;
      state.completedCount = responses.length;
      let allUserIds = responses.map((r) => r.userId);
      state.responses.forEach((response, index) => {
        //if user name is duplicate, create unique
        if (allUserIds.indexOf(response.userId) != index)
          response.userId = response.userId + "-" + index;
        
        
        // collate all other question responses
        state.responses[index].demQuest = [];
        if (response.screener && response.screener.length > 0) {
          state.responses[index].demQuest.push(...response.screener);
        }
        if (response.questions) {
          Object.keys(response.questions).forEach((key) => {
            state.responses[index].demQuest.push({ id: key, response: response.questions[key] });
          });
          state.responses[index].demQuest.push(...response.screener);
        }
        if (response.otherQuestions && response.otherQuestions.length > 0) {
          if (Object.keys(response.otherQuestions[0]).find(k => k == "id") == undefined) {
            Object.keys(response.otherQuestions[0]).forEach((key) => {
              state.responses[index].demQuest.push({ id: key, response: response.otherQuestions[0][key] });
            });
          } else {
            state.responses[index].demQuest.push(...response.otherQuestions);
          } 
        }
        if (
          response.demographicQuestions &&
          response.demographicQuestions.length > 0
        ) {
          state.responses[index].demQuest.push(
            ...response.demographicQuestions
          );
        }
      });
    },      
    setSurveyId(state, surveyId) {
      state.surveyId = surveyId;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLoadingMessage(state, msg) {
      state.loadingMessages = msg;
      setTimeout(() => {
        state.loadingMessages = null;
      }, 5000);
    },
    grantAccess(state) {
      state.loggedIn = true;
      state.loginMessage = "You successfully logged in.";
      state.loginStatus = "success";
    },
    setLoginMessage(state, msg) {
      state.loginMessage = msg;
    },

    loadSurveyAdminInfo(state, survey) {
      state.quota = survey.general.quota;
    },
  },
  actions: {
    fetchFullSurveyInfo({ commit, state }, payload) {
      
      axios
        .get(`${state.host}/api/fullsurveyInfo/${state.surveyId}`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(
          function (response) {
            if (!response.data.success) {
              if (response.data.status===404){
                // go to create survey admin dashboard
                state.mode = "create";
                return router.push({name: "DsCreateSurvey"});
              } 

              if (response.data.error) return console.error(response.data.error);
              else return console.error(payload + " not found.");
            } else {
              if (response.data.survey.general?.meta)
                document.title =
                  response.data.survey.general?.meta + " - admin console";
              state.mode = "analyse";
              const dimensionsCleaned = response.data.survey.ds.map(
                (dimension) => {
                  return {
                    id: dimension.id,
                  };
                }
              );
              const dimensionsWithLevelsCleaned = response.data.survey.ds.map(
                (dimension) => {
                  return {
                    id: dimension.id,
                    levelRating: dimension.levels.map((level, index) => {
                      return {
                        id: dimension.id + "_" + index,
                        label: level.label,
                        rank: level.rank,
                      };
                    }),
                  };
                }
              );
              commit("loadAgenda", response.data.survey.agenda);
              commit("loadSurvey", response.data.survey);
              commit("loadDsDimensions", dimensionsWithLevelsCleaned);
              commit("loadDsDimensions", dimensionsWithLevelsCleaned);
            }
          }.bind(this)
        );
    },

    fetchResponses({ commit, state }) {
      if(!state.loggedIn) return;
      if (state.responses) return;

      state.loadingResponses = true;

      axios
        .get(`${state.host}/api/${state.surveyId}/fetchResponses`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          if(response.data?.status === 404) 
            return console.log("No response data found");

          if (
            !response.data ||
            !response.data.responses ||
            response.data.responses.length == 0
          ) {
            commit("setResponses", false);
            state.responses = false;
            state.loadingResponses = false;
            throw new Error("No participant data found");
          }
          commit("setResponses", response.data.responses);
          state.loadingResponses = false;
        })
        .catch((error) => {
          state.loadingResponses = false;
          if (error.response) {
            console.log(error.response);

            commit(
              "setLoadingMessage",
              error.response.status + " " + error.response.data.message
            );
          }
          console.error(error);
        });
    },

    fetchQuota({ commit, state }) {
      
      
      if (state.consentedCount) return;

      
      axios
        .post(
          `${state.host}/api/IeFpm0VGSaQTQFS8OwP9/screen/info`,
          {  "identifier": state.surveyId }
        )
        .then((response) => {
          if(response.data?.status === 404) return console.log("No quota data found")
          if (!response.data || !response.data.prettyScreenInfo)
            return console.error("Count data fetch error");
          state.quota = response.data.prettyScreenInfo;
        })
        .catch((error) => {
          return console.error(error);
        });
    },

    fetchCounts({ commit, state }) {
      
      if (state.consentedCount) return;

      axios
        .get(`${state.host}/api/${state.surveyId}/countConsentForm`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          if (!response.data || response.data.length == 0)
            return console.error("Count data fetch error");

          state.consentedCount = response.data.count;
        })
        .catch((error) => {
          return console.error(error);
        });
    },

    verifyToken({ commit, state }) {
      console.log("verify call");
      console.log("state.token:", state.token);
      console.log("path:",`${state.host}/${state.surveyId}/verifyToken`)
      axios
        .get(`${state.host}/${state.surveyId}/verifyToken`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          console.log("Token verified");
          console.log("response.data")
          console.log(response.data)
          if (!response.data?.tokenValid)
            // commit("logout", "You do not have access to this project.");
            console.log("ERROR in !response.data?.tokenValid")
          else commit("setLoginMessage", "You have successfully logged in.");
          return;
        })
        .catch((error) => {
          commit(
            "logout",
            "Something went wrong. You might not have access to this project."
          );
          console.error(error);
        });
    },

    updateSurveyStatus({ commit, state }, payload) {
      axios
        .post(
          `${state.host}/api/survey/IeFpm0VGSaQTQFS8OwP9/updateStatus`,
            {identifier: state.surveyId, status: payload},
            {headers: {"Authorization": `Bearer ${state.token}` }},
        )
        .then((response) => {
          if (!response.data.success) return console.error(response.data.error);
            
          let newStatus = response.data?.updatedSurvey?.active;
          commit("setLocalSurveyStatus", newStatus);
          let message = newStatus ? 'Active' : 'Inactive';
          state.loginMessage = 'New survey status: ' + message;
          state.loginStatus = response.data?.updatedSurvey?.active ? 'success' : 'secondary' ?? 'danger';
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteSurvey({ commit, state }, subId = null) {
      let surveyId = subId ? subId : state.surveyId;
      axios
        .post(`${state.host}/api/survey/IeFpm0VGSaQTQFS8OwP9/delete`, 
        {identifier: surveyId},
        {headers: { Authorization: `Bearer ${state.token}` },})
        .then((response) => {
          if (!response.data.success) return console.error(response.data.error);
          commit("logout", "Survey deleted");
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
