import { createRouter, createWebHistory } from "vue-router";
import Info from "../views/Info.vue";
import store from "../store";


const views = require.context("../views", true, /\.vue$/)
  .keys()
  .map(key => key.replace(/(\.\/|\.vue)/g, ""));
const homeView = views.find((view) => view === "Home");
views.splice(views.indexOf(homeView), 1);
views.unshift(homeView);

const routes = views.map((view) => {
  return {
    path: "/:surveyId",
    name: view,
    component: () => import(`../views/${view}.vue`),
  };
});

routes.push({
  path: "/",
  name: "Info",
  component: Info,
});

routes.push({
  path: "/privacy",
  name: "Privacy",
  component: () => import(`../views/Privacy.vue`),
});

// TO DO:
  // GET CONTEXT AND REMOVE UNUSED DS FILES
// admin dashboard
import Admin from "../dashboard/Admin.vue";
const DsComponents = [
  "DsHome",
  "DsValueSet",
  "DsSpaghetti",
  "DsCompare",
  "DsWeighting",
  "DsRating",
  "DsAnchoring",
  "DsDemQuest",
  "DsFeedback",
  "DsDurations",
  "DsDownload",
  "DsManageSurvey",

  "DsCreateSurvey",
];

routes.push({
  path: "/:surveyId/admin",
  name: "Admin",
  component: Admin,
  children: DsComponents.map((dsComponent) => {
    return {
      path: "/:surveyId/admin/",
      name: dsComponent,
      component: () => import(`../dashboard/${dsComponent}.vue`),
    };
  }),
});

// scroll to top on route change
const scrollBehavior = (to) => {
  if(to.fullPath.includes("#")) return;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        top: to.meta?.scrollTop || 0,
        left: to.meta?.scrollLeft || 0,
      });
    }, 500);
  });
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

// set page on store and handle transitions after route change
router.afterEach((to) => {
  let currentPageIndex = store.state?.navigation.currentPage;
  store.commit("setLastPage", currentPageIndex);
  store.commit("disableInternalTrigger");

  let preventReturn = false;
  if (store.state?.navigation?.preventReturn?.length > 0)
    preventReturn = store.state?.navigation?.preventReturn[(currentPageIndex ?? null) - 1] ?? false;

  if (currentPageIndex < 1 || preventReturn) {
    store.commit("disableGoBack");
  } else {
    store.commit("enableGoBack");
  }

  

  if (to.meta.transition != undefined) {
    return;
  }

  function transitionMaker(
    currentPageIndex,
    navDirection,
    animateTransitions = false
  ) {
    if (!animateTransitions) return "none";
    if (currentPageIndex == -1) return "none";
    if (currentPageIndex == 0 && navDirection == 1) return "none";
    return "fade";
    // if (navDirection > 0) return "slide-bottom";
    // if (navDirection < 0) return "slide-top";
    // return "none";
  }

  let transition = transitionMaker(
    currentPageIndex,
    store.state.navigation.navDirection,
    store.state.navigation.animateTransitions
  );

  to.meta.transition = transition;
});

// confirm page close during active survey
router.beforeEach((to) => {
  if (to.params.confirmed) return true;

  if (
    store.state.navigation.internalTrigger &&
    store.state.navigation.currentPage > 0 &&
    store.state.navigation.currentPage <
    store.state.navigation.agenda.length - 1
  )
    return true;

  if (
    (!store.state.navigation.internalTrigger &&
      store.state.navigation.editingInProgress) ||
    (store.state.navigation.internalTrigger &&
      store.state.navigation.currentPage == -1)
  ) {
    const answer = window.confirm(
      "You have unsaved changes. Are you sure you want to leave?"
    );
    if (answer) {
      store.state.navigation.currentPage = -1;
      store.state.navigation.navDirection = 0;
      router.push({ name: "Home", params: { confirmed: true } });
      return;
    } else {
      store.state.navigation.currentPage = store.state.navigation.lastPage;
      return false;
    }
  }

  return true;
});

export default router;
