<template>
  <div class="home home-80 d-flex flex-column w-100">

      <transition 
      :name="transStyle ?? 'slide-top'" 
      mode='out-in'
      @enter="onEnter"
      >
      <div :key="taskIndex">
          <Card 
          spacingClasses="py-2 py-sm-4 px-3 px-sm-5 mx-0 mx-sm-3"
          > 

            <!-- first task instruction -->
            <transition name="fade">
                <div 
                v-if="taskIndex==0 && firstView" 
                style="top:0;  left:0; right:0; z-index: 8; max-height: 80vh; overflow: auto; position: absolute; background-color: rgba(0,0,0,0.5);"
                class="bg-white rounded-3 px-5 py-4  shadow-lg border border-dark"
                >

          <Description v-if="taskIndex==0" class="d-flex flex-column flex-fill">
          <div class="mb-1">
            <div  v-html="$store.state.survey?.pages?.levelRating.html" class="d-inline"/>
            <span class="ms-1 text-primary fw-bold"> {{dimension.name}}.</span> 
            <div  v-html="$store.state.survey?.pages?.levelRating.html2" class="mt-3"/>
          </div>
          <div 
          v-if="$store.state.survey?.pages?.levelRating.appendLevelLabel"
          class="fw-bold"> 
          >
          {{ dimension.name }}:
          </div>
          <ul>
          <li>
            <div class="outside-label d-inline-block me-1">
              {{ worstLevelLab }} 
            </div>
            <span v-html="$store.state.survey?.pages?.levelRating.descrWorst" />
          </li>    
          <li class="mb-3">
            <div class="outside-label d-inline-block me-1" style="text-align: left;">
              {{ bestLevelLab }}
            </div>
              <span v-html="$store.state.survey?.pages?.levelRating.descrBest" /> 
          </li>
        </ul>
          <span 
          class="question my-1 mb-3"
          > 
            {{$store.state.survey?.pages?.levelRating.question}} 
            </span>
          <ul>
            <div
            v-for="(level, index) in dimMidLvlsShow"
            :key="level.id"
            class="d-flex align-items-center"
            >
                <li class="outside-label my-1">
                  {{ level.label }}
                </li>
          </div>
        </ul>
        <div class="d-flex justify-content-end align-items-center">
          <span class="me-5 text-primary lh-sm">
            {{$store.state.survey?.pages?.levelRating.firstTaskPrompt ?? ''}}
          </span>
          <b-button
          @click="firstView = false"
          class="button btn-lg me-3 mt-2 al"
          variant="primary"
          >
          {{$store.state.survey?.pages?.levelRating.startBtn ?? 'Start'}}
          </b-button>
          </div>
        
        </Description>


                </div>
              </transition>

            <h3 class="align-self-start h3 text-primary mb-1">{{dimension.name}}</h3>
            <div class="row">
      

      <!-- bucket side -->
              <div class="col-7 col-sm-6 d-flex flex-column">
                <div 
                class="question my-0 my-sm-3 lh-sm" 
                :style="{'opacity': checkComplete() ? 0.3 : 1}"
                >
                  {{ $store.state.survey?.pages?.levelRating.question }}
                  </div>
                <div
                :key="taskIndex"
                  class="
                    d-flex flex-column 
                    w-100
                    align-items-center 
                  "
                  style="min-height: 75px;"
                >
              <div
              class="text-secondary mt-4"
                v-if="checkComplete()"
                >
                  {{ $store.state.survey?.pages?.levelRating.allDone }}
                </div>
                  <div
                    v-for="(level, index) in dimMidLvlsShow"
                    :key="level.id"
                    class="d-flex align-items-center"
                  >
                    <div
                      class="drag-handle d-flex d-flex align-items-center"
                      draggable="true"
                      @dragstart="dragStart"
                      @dragend="dragEnd"
                      @touchstart="touchStart"
                      @touchmove="touchMove"
                      @touchend="touchEnd"
                      @touchcancel="touchEnd"
                      :data-dim="dimension.id"
                      :data-lvl="level.id"
                      :data-label="level.label"
                    >
                      <div class="outside-label" v-html="tooltipStringBreaker(level.label)"/>
                      <div class="noUi-handle handle-outside">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-fill"/>
                  <div class="small text-secondary pt-4 pb-2 lh-1">
                    <span
                    v-show="taskIndex == 0"
                    v-html="$store.state.survey?.pages?.levelRating?.dragInstructionsNote ?? ''">
                    </span>
                  </div>
              </div>
              



              <!-- slider side -->
            <div class="
            col-5 col-sm-6 pb-5 pt-5 
            d-flex flex-column align-items-center justify-content-center 
            ">
              
                <div 
                class="dropzone d-flex flex-column align-items-center me-3"
                @dragenter="dragEnter"
                @dragleave="dragLeave"
                @dragover="dragOver"
                @drop="drop"
                >
                  <div :id="'slider' + dimension.id" class="lvlRatingSlider"></div>
                </div>
              </div>
          </div>
        </Card>

        <div class="w-100 pe-5 me-5">
          <GoNextBtn :promptGoNext="checkComplete()" v-if="!firstView || taskIndex>0" />
        </div>

      </div>

    </transition>
            


  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";
import noUiSlider from "nouislider";

export default {
  name: "LevelRatingDD",
  components: {
    Description,
    Card,
    GoNextBtn,
  },
  data() {
    return {
      taskIndex: 0,
      ping: false,
      firstView: true,
      transStyle: "slide-top",
      slider: null,
      dimRatingOrder: null,
      connects: [],
      colActive: "cyan",
      colQuiet: "gray",
      lit: false,
      sliderValues: [],
      sliderLabels: [],
      sliderLvlIds: [],
      activeHandle: null,
      touchGhost: null,
      options: {
        step: 1,
        connect: true,
        // tooltips: [],
        start: [0, 100],
        behaviour: "unconstrained-tap",
        pips: {
          mode: "positions",
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          density: 2,
        },
        format: {
          to: (v) => v | 0,
          from: (v) => v | 0,
        },
        direction: "rtl",
        orientation: "vertical",
        range: { min: [0], max: [100] },
        animate: true,
        animationDuration: 100,
      },
    };
  },
  methods: {
    next() {
      this.saveRatings();
      if (this.taskIndex < this.dimensions.length - 1) {
        this.transStyle = "slide-bottom";
        this.taskIndex++;
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
    onEnter() {
          this.setupParams();
          var canGoNext = this.checkComplete();
          this.createSlider();
          this.$store.commit("setLvlRatingTaskIndex", this.taskIndex);
          setTimeout(() => {
            this.$store.commit("enableGoBack");
          }, 0);
          if (!canGoNext)
            setTimeout(() => {
              this.$store.commit("disableGoNext");
            }, 0);
    },
    back() {
      this.saveRatings();
      if (this.taskIndex > 0) {
        this.transStyle = "slide-top";
        this.taskIndex--;
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
    tooltipStringBreaker(str) {
      if (str) return str.match(/.{1,25}(\s|$)/g).join("<br>");
    },
    saveRatings() {
      this.sliderValues[this.taskIndex].forEach((val, i) => {
        this.$store.commit("updateDimensionLevelRating", {
          dimension: { id: this.dimension.id },
          level: { id: this.sliderLvlIds[this.taskIndex][i] },
          rating: Math.round(val)
        });
      });
    },
    colorMaker(hue, sat, light) {
      return `hsl(${hue}, ${sat}%, ${light}%)`;
    },
    setupParams() {
      this.colActive = this.colorMaker(this.dimension.hexColor, 100, 66);
      this.colQuiet = this.colorMaker(this.dimension.hexColor, 100, 33);

      let i = this.taskIndex;

      this.sliderValues[i] = [];
      this.sliderLabels[i] = [];
      this.sliderLvlIds[i] = [];

      this.sliderValues[i].push(0);
      this.sliderLabels[i].push(this.worstLevelLab);
      this.sliderLvlIds[i].push(this.worstLevelId);

      this.sliderValues[i].push(100);
      this.sliderLabels[i].push(this.bestLevelLab);
      this.sliderLvlIds[i].push(this.bestLevelId);

      this.dimMidLevelRatings.forEach((val, i) => {
        if (val || val == 0) {
          if (this.sliderValues[this.taskIndex].includes(val)) {
            let iter = val > 50 ? -1 : 1;
            while (this.sliderValues[this.taskIndex].includes(val)) {
              val = val + iter;
            }
          }
          this.sliderValues[this.taskIndex].push(val);
          this.sliderLabels[this.taskIndex].push(this.dimMidLvls[i].label);
          this.sliderLvlIds[this.taskIndex].push(this.midLevelIds[i]);
        }
      });
      // this.setHandleListener();
    },
    checkComplete() {
      if (this.sliderValues[this.taskIndex]) {
        if (
          this.sliderValues[this.taskIndex].length ==
          this.dimension.levels.length
        ) {
          this.$store.commit("enableGoNext");
          return true;
        } else {
          this.$store.commit("disableGoNext");
          return false;
        }
      } else {
        this.$store.commit("disableGoNext");
        return false;
      }
    },

    // DRAG N DROP FUNCTIONS
    dragStart(evt) {
      this.activeHandle = evt.target;
      evt.target.style.opacity = 0.5;
    },
    dragEnd(evt) {
      evt.target.style.opacity = 1;
      this.activeHandle = null;
    },
    dragEnter(evt) {
      evt.preventDefault();
      this.sliderLightUp(this.colActive);
    },
    dragOver(evt) {
      evt.preventDefault();
      this.sliderLightUp(this.colActive);
    },
    dragLeave(evt) {
      evt.preventDefault();
      this.sliderLightUp(this.colQuiet);
    },
    drop(evt) {
      evt.preventDefault();
      this.sliderLabels[this.taskIndex].push(this.activeHandle.dataset.label);
      this.sliderLvlIds[this.taskIndex].push(this.activeHandle.dataset.lvl);
      var newVal = this.slider.noUiSlider.calcPointToPercentage(
        evt.y + window.pageYOffset
      );
      this.sliderValues[this.taskIndex].push(newVal);
      this.checkComplete();
      this.activeHandle.remove();
      this.createSlider();
    },

    // PSEUDO DRAG N DROP FUNCTIONS FOR TOUCH DEVICES
    touchStart(evt) {
      this.activeHandle = evt.target;
      this.touchGhost = this.activeHandle.cloneNode(true);
      let left = evt.touches[0].pageX;
      let top = evt.touches[0].pageY;
      this.touchGhost.style.position = "absolute";
      this.touchGhost.style.left = left - this.activeHandle.offsetWidth + "px";
      this.touchGhost.style.top =
        top - this.activeHandle.offsetHeight / 2 + "px";
      this.touchGhost.style.opacity = 0.5;
      this.touchGhost.style.pointerEvents = "none";
      this.activeHandle.style.opacity = 0.5;
      document.body.appendChild(this.touchGhost);
    },

    touchMove(e) {
      e.preventDefault();

      var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      var touch = evt.touches[0] || evt.changedTouches[0];
      var touchX = touch.pageX - window.pageXOffset;
      var touchY = touch.pageY - window.pageYOffset;
      const touchedBelow = document.elementFromPoint(touchX, touchY);

      let left = e.touches[0].pageX;
      let top = e.touches[0].pageY;
      this.touchGhost.style.position = "absolute";
      this.touchGhost.style.left = left - this.activeHandle.offsetWidth + "px";
      this.touchGhost.style.top =
        top - this.activeHandle.offsetHeight / 2 + "px";

      if (!touchedBelow) {
        this.connects.forEach(
          (el) => (el.style.backgroundColor = this.colQuiet)
        );
        return;
      }

      if (
        touchedBelow.classList.contains("noUi-base") ||
        touchedBelow.classList.contains("dropzone")
      ) {
        this.connects.forEach(
          (el) => (el.style.backgroundColor = this.colActive)
        );
      } else {
        this.connects.forEach(
          (el) => (el.style.backgroundColor = this.colQuiet)
        );
      }
    },
    
    touchEnd(e) {
      e.preventDefault();
      var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      var touch = evt.touches[0] || evt.changedTouches[0];
      var touchX = touch.pageX - window.pageXOffset;
      var touchY = touch.pageY - window.pageYOffset;
      const touchedBelow = document.elementFromPoint(touchX, touchY);

      if (!touchedBelow) {
        this.touchReset();
        return;
      }

      if (
        !touchedBelow.classList.contains("noUi-base") &&
        !touchedBelow.classList.contains("dropzone")
      ) {
        this.touchReset();
        return;
      }
      this.touchGhost.remove();
      this.touchGhost = null;
      var newVal = this.slider.noUiSlider.calcPointToPercentage(touch.pageY);
      this.sliderValues[this.taskIndex].push(newVal);
      this.sliderLabels[this.taskIndex].push(this.activeHandle.dataset.label);
      this.sliderLvlIds[this.taskIndex].push(this.activeHandle.dataset.lvl);
      this.checkComplete();
      this.activeHandle.remove();
      this.createSlider();
    },
    touchReset() {
      this.touchGhost.remove();
      this.touchGhost = null;
      this.activeHandle.style.opacity = 1;
    },

    // SLIDER FUNCTIONS
    createSlider() {
      if (this.slider.noUiSlider) 
        this.slider.noUiSlider.destroy();

      this.slider = document.querySelector("#slider" + this.dimension.id);
      var sliderOptions = this.options;
      sliderOptions.start = this.sliderValues[this.taskIndex];
      sliderOptions.tooltips = [];

      this.sliderLabels[this.taskIndex].forEach((lab) => {
        let wrappedLab = this.tooltipStringBreaker(lab);
        let tooltip = {
          to: function (value) {
            return "<div class ='tooltip-label'>" + wrappedLab + "</div>";
          },
        };
        sliderOptions.tooltips.push(tooltip);
      });

      noUiSlider.create(this.slider, sliderOptions);
      this.slider.noUiSlider.on("set", (t) => {
        this.sliderValues[this.taskIndex] = t;
      });
      const origins = this.slider.getElementsByClassName("noUi-origin");
      origins[0].setAttribute("disabled", true);
      origins[0].firstChild.firstChild.style.cursor = "not-allowed";
      origins[0].firstChild.firstChild.style.zIndex = "0";
      origins[1].setAttribute("disabled", true);
      origins[1].firstChild.firstChild.style.cursor = "not-allowed";
      var col = this.checkComplete() ? this.colActive : this.colQuiet;
      this.connects = document.querySelectorAll(".noUi-connect");
      this.connects.forEach((el) => (el.style.backgroundColor = col));
      this.lit = col;
    },

    sliderLightUp(col) {
      if (this.lit == col) return;
      var connects = document.querySelectorAll(".noUi-connect");
      connects.forEach((el) => (el.style.backgroundColor = col));
      this.lit = col;
    },
  },

  computed: {
    dimensions() {
      if (this.$store.state.survey.ds === undefined) return [];
      return this.$store.state.survey.ds;
    },
    dimension() {
      return this.dimensions[this.dimRatingOrder[this.taskIndex]];
    },
    dimMidLvls() {
      return this.dimension.levels.filter((value, index) => {
        return index > 0 && index < this.dimension.levels.length - 1
          ? true
          : false;
      });
    },
    dimMidLvlsShow() {
      return this.dimMidLvls.filter((val, i) => {
        return (
          this.dimMidLevelRatings[i] == undefined ||
          this.dimMidLevelRatings[i] == null
        );
      });
    },
    bestLevelLab() {
      return this.dimension.levels.find((level) => level.rank == 0).label;
    },
    worstLevelLab() {
      return this.dimension.levels.find(
        (level) => level.rank == this.dimension.levels.length - 1
      ).label;
    },
    dimMidLevelRatings() {
      const mid = this.$store.state.results.ds[
        this.dimRatingOrder[this.taskIndex]
      ].levelRating.filter((lvl) => {
        return lvl.rank > 0 && lvl.rank < this.dimension.levels.length - 1;
      });
      return mid.map((lvl) => lvl.rating);
    },
    midLevelIds() {
      var midLvls = this.dimension.levels.filter((level) => {
        return level.rank > 0 && level.rank < this.dimension.levels.length - 1;
      });
      return midLvls.map((lvl) => lvl.id);
    },
    bestLevelId() {
      return this.dimension.levels.find((level) => level.rank == 0).id;
    },
    worstLevelId() {
      return this.dimension.levels.find(
        (level) => level.rank == this.dimension.levels.length - 1
      ).id;
    },
  },

  beforeMount() {
    this.taskIndex = this.$store.state.navigation.lvlRatingTaskIndex;
    if (this.$store.state.results.lvlRatingOrder.length > 0) {
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    } else {
      if (this.$store.state.survey?.pages?.levelRating?.randomiseOrder) {
        this.$store.commit("setLevelRatingOrder", { randomise: true });
      } else {
        this.$store.commit("setLevelRatingOrder", { randomise: false });
      }
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    }


    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
  },

  mounted() {
    this.firstView = true;
    this.$store.commit("enableInternalNav");
    this.slider = document.querySelector("#slider" + this.dimension.id);
    this.setupParams();
    this.checkComplete(); // edge case
    this.createSlider();
  },

  unmounted() {
    this.subscribe();
  },

};
</script>

<style lang="scss">

@import "~nouislider/dist/nouislider.min.css";

.dropzone {
  width: 120px;
  padding-left: 10px;
}

.drag-handle {
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 7;
}
.drag-handle:hover {
  transform: scale(1.01);
}

.handle-outside {
  position: relative;
  width: 36px;
  height: 30px;
  border: solid 1px #9b9b9b;
  padding: 10px;
  padding-right: 20px;
  padding-bottom: 15px;
  display: inline-block;
  box-shadow: -1px 1px 2px 1px #ccc;
  transform: rotate(-90deg);
  border: 1px solid #ccc;
  pointer-events: none;
}

.outside-label {
  text-align: right;
  padding: 0.2em 0.3em;
  border-radius: 0.3em;
  border: 1px solid #ccc;
  background: white;
  line-height: 1.25em;
  box-shadow: 1px 1px 2px 1px #ccc;
  pointer-events: none;
}

.lvlRatingSlider{

  height: 350px;

  .tooltip-label {
    text-align: right;
    font-size: 0.8rem;
    font-weight: normal;
    letter-spacing: -0.05em;
    line-height: 1em;
  }
  .noUi-tooltip {
    padding: 0.2em 0.3em;
    border: 1px solid #ccc;
    z-index: 5;
    pointer-events: all;
  }

  .noUi-connect {
    background-color: lightgray;
  }

  .connect-active {
    background-color: cyan;
  }


  .noUi-pips,
  .noUi-pips-vertical,
  .noUi-value,
  .noUi-connects {
    pointer-events: none;
  }

  .noUi-touch-area {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .noUi-touch-area:disabled {
    cursor: not-allowed !important;
  }

  .noUi-touch-area:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }


}

.drag-handle,
.handle-outside {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.drag-handle:active,
.handle-outside:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

@media (max-width: 576px) {
  .noUi-tooltip:hover {
  opacity: 0.2;
}
  .outside-label {
    font-size: 0.9em;
    line-height: 1em;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
