<template>
  <div class="home home-120">

    <Description class="mx-auto" style="max-width: 60ch">
      <div
      v-html="$store.state.survey?.pages?.positionOfDead?.html">
      </div>
    </Description>

    <div v-if="choiceSet != null" class="mt-4" style="overflow: scroll"> 
      <table class="pod-table" style="wh">
        <colgroup>
          <col span="1" style="width: 20%" />
          <col span="1" style="width: 5%" />
          <col span="1" style="width: 35%" />
          <col span="1" style="width: 5%" />
          <col span="1" style="width: 35%" />
        </colgroup>

        <tr>
          <td colspan="2"></td>
          <td
          colspan="3"
          class="text-center question fs-5 pb-5"
          >
            {{$store.state.survey?.pages?.positionOfDead.question}}
          </td>
        </tr>

        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"
          class="scenario-top scenario-cell">
            {{$store.state.survey?.pages?.positionOfDead.scenarioALabel}}
          </th>
          <th scope="col"></th>
          <th scope="col" class="scenario-top scenario-cell">
            {{$store.state.survey?.pages?.positionOfDead.scenarioBLabel}}
          </th>
        </tr>

        <tr v-for="(attr, index) in choiceSetForDisplay">
          <th scope="row">{{ dimLabels[index] }}</th>
          <td />
          <td
          class="scenario-cell"
            v-if="!isLoading"
            :class="[
              index == choiceSetForDisplay.length - 1
                ? 'scenario-bottom'
                : 'scenario-middle',
                taskEnded ? 'disabled-cell' : '',
            ]"
          >
            {{ attr }}
          </td>
          <td
            v-if="isLoading && index == 0"
            :rowspan="choiceSetForDisplay.length"
            class="scenario-bottom scenario-cell"
          >
            <b-spinner
              variant="primary"
              style="width: 3em; height: 3em; border-width: 0.4em;"
            ></b-spinner>
          </td>
          <td />
          <td
            v-if="index == 0"
            :rowspan="choiceSetForDisplay.length"
            class="text-center fw-normal align-middle display-6 ct border border-secondary"
            style="background-color: white;"
          >
            {{$store.state.survey?.pages?.positionOfDead.deadLabel}}
          </td>
        </tr>
        <tr>
          <th scope="row"></th>
          <td />
          <td class="scenario-btn-cell scenario-cell">
            <input
            type="radio"
            class="btn-check"
            id="btn-a"
            :disabled="(taskEnded || isLoading) ? true : false"
            name="selected-scenario"
            autocomplete="off"
            value="a"
            v-model="choice"
          />
          <label class="btn btn-lg btn-outline-primary" for="btn-a">
            {{$store.state.survey?.pages?.positionOfDead.selectLabel}}
          </label>
          </td>
          <td />
          <td class="scenario-btn-cell scenario-cell">
          <input
            type="radio"
            class="btn-check"
            id="btn-b"
            :disabled="(taskEnded || isLoading) ? true : false"
            name="selected-scenario"
            autocomplete="off"
            value="b"
            v-model="choice"
          />
          <label class="btn btn-lg btn-outline-primary" for="btn-b">
            {{$store.state.survey?.pages?.positionOfDead.selectLabel}}
          </label>
          


          </td>
        </tr>
      </table>
    </div>
      <GoNextBtn :promptGoNext="promptGoNext" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

import {
  dsExpand,
  pufScaled,
  stateValuator,
} from "@/utils/puf";

import { deadPosAlgorithm } from "@/utils/deadPosAlgorithm.js";

export default {
  name: "PositionOfDead",
  components: {
    Description,
    Card,
    GoNextBtn,
  },
  data() {
    return {
      isLoading: false,
      choiceSetTrail: [],
      choiceSet: null,
      choiceSetForDisplay: null,
      isFirstChoice: true,
      choice: null,
      taskEnded: false,
      promptGoNext: false,
    };
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.$store.commit("disableInternalNav");
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.$store.commit("disableGoNext");
    this.$store.commit("disableGoThroughDeadVas");
    this.$store.commit("clearPodChoice"); // allow moving back and forth
    this.$store.commit("setLifeAndDeathContinuedTouched", false);
    this.$store.commit("updatePositionOfPits", null);
    // first choice set
    this.createChoiceSet();
  },
  methods: {
    next() {
      this.makeChoice(this.choice);
    },
    createChoiceSet() {
      try {
      this.choiceSet = deadPosAlgorithm(
        this.choiceSetTrail,
        this.ds,
        this.dsExpand,
        this.allStatesValued,
        this.$store.state.survey?.pages?.positionOfDead.iterations
      );
      if(this.choiceSet.hsLabels.length > 0 && !this.choiceSet.taskEnded){
        this.choiceSetForDisplay =  this.choiceSet.hsLabels;
      }
      return;
      } catch (error) {
        this.choiceSet.taskEnded = true;
        this.choiceSet.choice = "ERROR in deadPosAlgorithm"
        console.error("ERROR in deadPosAlgorithm - abort and proceed to next task");
      }
    },


    makeChoice(choice) {
      
      if(this.choice === null) return;
      this.choice = null;

      const payload = {
        hs: this.choiceSet.hsLabels,
        hsLvlIds: this.choiceSet.hsLvlIds,
        choice: choice,
        hsPercentile: 100 - this.choiceSet.currentPercentile,
        hsScaledUtility: this.choiceSet.currentScaledUtility,
      };

      this.$store.commit("makePodChoice", payload);
      this.choiceSetTrail.push(choice);
      this.createChoiceSet();
      this.taskEnded = this.choiceSet.taskEnded;

      if (this.taskEnded) {
        this.$store.commit("disableInternalNav");

      if(this.isFirstChoice){
      this.$store.commit("enableGoThroughDeadVas");
      } else {
          this.$store.commit("makePodChoice",
          {
            hs: this.choiceSet.hsLabels,
            hsLvlIds: this.choiceSet.hsLvlIds,
            choice: "estimated PoD",
            hsPercentile: 100 - this.choiceSet.currentPercentile,
            hsScaledUtility: this.choiceSet.currentScaledUtility,
            });
      }
    }

    this.isFirstChoice = false;
    if(!this.taskEnded){
      this.isLoading = true;
    }
    
    setTimeout(() => {
      this.isLoading = false;
        }, 1500);


    },


  },
  computed: {
    // heavy lifting done here
    ds() {
      return this.$store.state.results.ds;
    },
    dsExpand() {
      return dsExpand(this.ds);
    },
    allStatesValued() {
      return stateValuator(this.dsExpand, pufScaled(this.ds));
    },
    dimLabels() {
      return this.$store.state.survey.ds.map(
        (d) => d.name
      );
    },
  },
  beforeUnmount() {
    this.subscribe();
  },
  watch: {
    choice() {
      if(this.choice){
        this.$store.commit("enableGoNext");
        this.promptGoNext = true;
      } else {
        this.$store.commit("disableGoNext");
        this.promptGoNext = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-select {
  width: 50%;
  font-size: 1.25em;
  padding: 0.2em 0.5em;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  white-space: nowrap;
}
.btn-select:hover {
  color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
  background-color: var(--bs-primary) !important;
}

</style>




