<template>
  <div class="bg-clear">
  <div class="admin-home">

    <!-- <div class="ms-5 ps-5 bg-dark p-5 ">
      <div class="bg-primary ms-5 ps-5">
        <button class="btn btn-primary btn-lg" @click="x">x</button>
      </div>
    </div> -->

    <transition :name="loggedIn ? 'header-in' : 'header-out'">
      <Header v-if="loggedIn" :adminConsole="true" />
    </transition>

    <transition>
      <Login v-if="!loggedIn" />
    </transition>

    <transition :name="loggedIn ? 'slide-top' : 'slide-bottom'">
      <DsSidebar v-if="loggedIn" />
    </transition>

    <!-- <div class="mx-auto w-100">
      <b-overlay
      :show="$store.state.admin.loadingResponses"
      spinner-variant="primary"
      spinner-size="lg"
      rounded
      opacity="0.5"
      blur="0.5"
      > -->

        <router-view v-if="loggedIn"/>
<!-- 
        <template #overlay>
         <div class="d-flex flex-column align-items-center justify-content-center mb-5">
           <div class="h3 my-3">Loading data...</div>
           <br>
          <b-spinner 
          style="width: 3rem; height: 3rem;"
          label="Loading..."
          />
        </div>
      </template>

      </b-overlay>
    </div> -->


    <DsNotify
    :msg="$store.state.admin.loginMessage"
    :variant="$store.state.admin.loginStatus"
    />


  </div>
</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Login from "@/dashboard/Login.vue";
import DsSidebar from "@/dashboard/DsSidebar.vue";
import DsNotify from "@/dashboard/DsNotify.vue";

export default {
  name: "Admin",
  components: {
    Login,
    Header,
    DsSidebar,
    DsNotify,
  },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    loggedIn() {
      return this.$store.state.admin.loggedIn;
    },
  },
  beforeCreate() {
    },
  beforeMount() {
    if(!this.$store.state.admin.surveyId)
      this.$store.commit("setSurveyId", this.$route.params.surveyId);
    let token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      this.$store.commit("grantAccess");
      this.$store.commit("setToken", token);
      setTimeout(() => {
        this.$store.dispatch("verifyToken");
      }, 100);
    }
  },

  watch: {
    loggedIn(newVal) {
      if (newVal) {
        this.$store.dispatch("fetchFullSurveyInfo", this.$route.params.surveyId);
        this.$store.dispatch("fetchResponses");
        this.$store.dispatch("fetchCounts");
        this.$store.dispatch("fetchQuota");
      }
    },
  },
};
</script>

<style scoped>

.bg-clear {
  background-image: url("../assets/img/bgGradient.jpg");
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.admin-home {
  margin-left: 220px;
  width: calc(100% - 220px);
  padding-bottom: 7em !important;
  padding-top: 6.5em !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  max-width: 1500px;
}

@media screen and (max-width: 768px) {
  .admin-home {
      margin-top: 5em;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
  }
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s ease-in;
}
.v-enter-active {
  transition-delay: 0.3s;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}

.header-out-leave-active {
  position: fixed;
  top:0;
  right:0;
  left: 0;
  transform: translateY(-100px) 
}


.header-in-enter-active,
.header-out-enter-active,
.header-in-leave-active,
.header-out-leave-active {
  transition: all 0.5s ease-in !important;
  transition-delay: 0ms;
}
</style>
