<template>
  <div class="home home-80">
    <div
      class="d-flex flex-column align-items-center justify-content-center pt-5 my-auto"
    >
      <div
        class="w-100 text-center py-5 display-6"
      >
      Survey completed.
      </div>
      
      <div class="card" style="max-width: 800px">
      <div class="card-header bg-white h4">
        Your responses
      </div>
        <div class="fs-5 card-body">
          <div class="px-3 pb-4">
            
            <table>
            <tr class="border-bottom">
              <!-- <td>Rank</td> -->
              <td class="px-2">Dimension</td>
              <td>Score</td>
            </tr>
              <tr v-for="(val, index) in dimRanking" :key="index">
                <!-- <td class="text-danger">{{ index+1 }}.</td> -->
                <td class="px-2">{{ val.label }}</td>
                <td class="text-center">{{ val.weight }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div
        class="w-100 text-center py-5 display-6"
      >
      Thank you for your participation!
      </div>
      


    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";

const axios = require("axios");

export default {
  name: "ThankYouEqual",
  components: {
    HomeHeader,
    Description,
    Card,
  },
  data() {
    return {
      sendAwayUserId: null,
      reward: null,
      CopytoClipboardText: "Copy to clipboard",
      copyIndicator: false,
    };
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.event("page_view", {
        page_title: this.$route.params.surveyId + " - Thank you",
        page_location: "ended",
      });
    }

    if (this.$store.state.survey.general.sendAwayLink) {
      this.sendAwayUserId = this.$store.state.results.userId;
    }

    if (this.$store.state.survey.general.rewardOnComplete) {
      this.rewardUser();
    }

    if (this.$store.state.survey.general.invalidateIdOnComplete) {
      this.egress();
    }

    if (this.$store.state.survey.general.quota.update) {
      console.log("updating quota");
      this.updateQuota();
    }

    this.save();

    this.$store.commit("disableGoBack");
    this.$store.commit("disableGoNext");
    this.$store.commit("setEditingInProgress", false);
  },

  methods: {
    downloadRawResponses() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.$store.state.results));
      const dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "OPUF_raw.json");
      dlAnchorElem.click();
    },

    rewardUser() {
      this.reward = this.$store.state.survey.reward;
    },

    egress() {
      // console.log(this.$store.state.results.userId);
      axios
        .post(this.$store.state.host + "/api/survey/egress/" + this.surveyId, {
          userId: this.$store.state.results.userId,
        })
        .then(function (response) {
          // console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateQuota() {
      console.log("payload", this.$store.state.results.screener);
      axios
        .post(this.$store.state.host + "/api/screen/update/", {
          identifier: this.surveyId,
          update: this.$store.state.results.screener,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    copyToClipboard() {
      this.CopytoClipboardText = "Copied!";
      this.copyIndicator = true;
      setTimeout(() => {
        this.CopytoClipboardText = "Copy to clipboard";
        this.copyIndicator = false;
      }, 2000);
      navigator.clipboard.writeText(
        this.$store.state.survey.general.completionCode
      );

      // dummy.select();
      // document.execCommand("copy");
      // document.body.removeChild(dummy);
    },

    save() {
      if (this.$store.state.survey.general.usePseudoId) {
        this.$store.commit("replaceUserIdWithPseudonym", this.surveyId);
      }

      let store = this.$store.state;
      let results = store.results;
      let sendAwayUserId = this.sendAwayUserId;

      axios
        .post(
          store.host + "/api/survey/results/" + this.surveyId,
          results
        )
        .then(function (response) {

          if (process.env.NODE_ENV === "development") {
            console.log(response);
            console.log(results);
          }

          if (store.survey.general.autoSendAway) {
              let str = store.survey.general.sendAwayLink;
              if (store.survey.general.appendUserIdToSendAwayLink) {
                str = str + sendAwayUserId;
              }
              window.location.href = str;
            }

        })
        .catch(function (error) {
          console.error(error);
        })
    },
  },
  computed: {

    dimRanking() {
      let res = [];
      this.$store.state.results.ds.forEach((d, i) => {
          let label = this.$store.state.survey.ds.find((ds) => ds.id === d.id)
            .name;
        let weight = d.swingWeight;
        res[i] = { label: label, weight: weight };
      });

      // reorder by weight
      res.sort((a, b) => {
        return b.weight - a.weight;
      });
      
      return res;
    },

    href() {
      return `mailto: ${this.$store.state.survey?.general?.authorEmail}`;
    },
    sendAwayLink() {
      let str = this.$store.state.survey.general.sendAwayLink;
      if (this.$store.state.survey.general.appendUserIdToSendAwayLink)
        str = str + this.sendAwayUserId;
      return str;
    },
    surveyId() {
      return this.$route.params.surveyId;
    },
  },
};
</script>

<style>
.reward img {
  max-width: 100%;
  height: auto;
  overflow: hidden;
}
</style>
