<template>
  <div class="home home-100 w-100">
    <CustomModal :title="'Sorry...'" :visible="showErrorModal" :blocking="true">
      {{ this.$store.state.survey.general.rejectionText ?? "" }}
      <div class="d-flex flex-column">
        <a
          :href="this.$store.state.survey.general.rejectionLink ?? '/'"
          class="btn btn-primary mx-auto mt-3"
          >{{
            this.$store.state.survey.general.rejectionLinkText ?? "End survey"
          }}
        </a>
      </div>
    </CustomModal>

    <QuestionCard
      :content="content"
      :question="question"
      ref="questionCard"
      :context="context"
    />

    <!-- info | task -->
    <div
      class="d-flex align-items-start flex-wrap flex-sm-nowrap justify-content-stretch"
    >
      <!-- Info -->
      <div class="card mx-3 mb-3" style="flex-basis: 40%; flex-grow: 1">
        <div class="card-body">
          <div v-html="content.info"></div>
        </div>
      </div>

      <!-- task -->
      <transition name="fade" mode="out-in">
        <div class="mx-3 flex-grow-1" style="flex-basis: 60%" :key="taskIndex">
          <div v-for="(lvl, i) in levelsRankedWithRatings" :key="lvl.id">
            <div class="card card-body mb-3">
              <div>
                <span v-if="content.appendLevelLabel" class="fw-bold"
                  >{{ dimension.name }}:</span
                >
                {{ lvl.label }}
              </div>

              <div class="d-flex align-items-center">
                <div class="w-100 me-4">
                  <b-input-group
                    @mousedown="lvl.touched = true"
                    @touchstart="lvl.touched = true"
                  >
                    <b-form-input
                      :disabled="
                        i == 0 || i == levelsRankedWithRatings.length - 1
                      "
                      type="range"
                      min="0"
                      max="100"
                      @change="lvl.touched = true"
                      @focus="lvl.touched = true"
                      v-model.number="lvl.value"
                    />
                  </b-input-group>
                </div>

                <div style="width: 80px">
                  <b-form-input
                    class="b-form-control text-center"
                    type="number"
                    min="0.00"
                    v-model.number="lvl.value"
                    :style="{ color: lvl.touched ? 'black' : 'transparent' }"
                    :state="lvl.value >= 0 && lvl.value <= 100 ? null : false"
                    @change="lvl.touched = true"
                    @focus="lvl.touched = true"
                    @click="clickInput(lvl)"
                    :disabled="
                      i == 0 || i == levelsRankedWithRatings.length - 1
                    "
                  />
                </div>
              </div>
              <span
                v-if="i == 0 || i == levelsRankedWithRatings.length - 1"
                class="text-secondary"
                style="position: absolute; bottom: 5px; font-size: 0.7em"
              >
                {{
                  i == 0 ? content.bestReferenceStr : content.worstReferenceStr
                }}
              </span>
              <b-form-invalid-feedback :state="validate(lvl.value)">
                {{ validationMsg(lvl.value) }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div
            v-show="goNextWarning"
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
            ref="warning"
          >
            {{
              content.consistencyWarning ??
              "Are you sure about these ratings? Higher ranked levels usually get higher ratings."
            }}
          </div>
        </div>
      </transition>
    </div>

    <GoNextBtn :promptGoNext="canGoNext && !goNextWarning" />
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import QuestionCard from "@/components/QuestionCard.vue";
import CustomModal from "../components/CustomModal.vue";
import axios from "axios";

export default {
  name: "LevelRating2",
  components: {
    GoNextBtn,
    QuestionCard,
    CustomModal,
  },
  data() {
    return {
      dimRatingOrder: [],
      taskIndex: 0,
      goNextWarning: false,
      subscribe: null,
      warningCounter: [],
      errorCounter: [],
      showErrorModal: false,
    };
  },
  computed: {
    question() {
      let q = this.content?.question ?? "";
      let lvls = this.levelsRankedWithRatings;
      q = this.replaceAll(q, "{{dimension.name}}", this.dimension.name);
      q = this.replaceAll(q, "{{bestLvl}}", lvls[0].label);
      q = this.replaceAll(q, "{{worstLvl}}", lvls[lvls.length - 1].label);
      q = this.replaceAll(
        q,
        "{{midLvl}}",
        lvls[Math.round(lvls.length / 2 - 1)]?.label
      );
      [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
        q = this.replaceAll(q, `{{lvl${i}}}`, lvls[i]?.label ?? "");
      });
      return q;
    },
    content() {
      return this.$store.state?.survey?.pages?.levelRating ?? {};
    },
    context() {
      let c = this.content?.context ?? "";
      let lvls = this.levelsRankedWithRatings;
      if (lvls.length < 3) return null;
      c = this.replaceAll(c, "{{dimension.name}}", this.dimension.name);
      c = this.replaceAll(c, "{{bestLvl}}", lvls[0].label);
      c = this.replaceAll(c, "{{worstLvl}}", lvls[lvls.length - 1].label);
      c = this.replaceAll(
        c,
        "{{midLvl}}",
        lvls[Math.round(lvls.length / 2 - 1)]?.label
      );
      [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
        c = this.replaceAll(c, `{{lvl${i}}}`, lvls[i]?.label ?? "");
      });
      return c;
    },
    dimensions() {
      if (this.$store.state.survey.ds === undefined) return [];
      return this.$store.state.survey.ds;
    },
    dimension() {
      return this.dimensions[this.dimRatingOrder[this.taskIndex]];
    },
    levelsRanked() {
      return this.dimension?.levels?.sort((a, b) => a.rank - b.rank);
    },
    levelsRankedWithRatings() {
      return this.levelsRanked?.map((lvl, i) => {
        if (lvl.touched === undefined) lvl.touched = false;
        if (lvl.value === undefined) {
          if (i === 0) {
            lvl.value = 100;
            lvl.touched = true;
          } else if (i === this.levelsRanked.length - 1) {
            lvl.value = 0;
            lvl.touched = true;
          } else {
            lvl.value = 0;
          }
        }
        return lvl;
      });
    },
    canGoNext() {
      let step1 = this.levelsRankedWithRatings.every((lvl) => lvl.touched);
      let step2 = this.levelsRankedWithRatings.every((lvl) =>
        this.validate(lvl.value)
      );
      let canGoNext = step1 && step2;
      if (canGoNext) this.$store.commit("enableGoNext");
      else this.$store.commit("disableGoNext");

      return canGoNext;
    },
  },
  methods: {
    next() {
      let dimValid = this.validateDimension();

      if (this.monitorErrorCount()) this.rejectParticipant();

      if (!dimValid) return;

      this.levelsRankedWithRatings.forEach((lvl) => {
        this.$store.commit("setLevelRating", {
          dimId: this.dimension.id,
          lvlId: lvl.id,
          rating: parseInt(lvl.value),
        });
      });
      if (this.taskIndex >= this.dimRatingOrder.length - 1) {
        this.$store.commit("disableInternalNav");
      } else {
        this.goNextWarning = false;
        this.taskIndex++;
        setTimeout(() => {
          // scrolintoview element with ref 'questionCard'
          this.$refs.questionCard?.$el?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
      }
    },
    back() {
      if (this.taskIndex <= 0) {
        this.$store.commit("disableInternalNav");
      } else {
        this.taskIndex--;
      }
    },
    clickInput(lvl) {
      if (lvl.value === 0) lvl.value = null;
      if (!lvl.touched) lvl.touched = true;
    },
    validate(value) {
      if (
        value === undefined ||
        value === "" ||
        value === null ||
        value === NaN
      )
        return false;
      if (value < 0 || value > 100) return false;
      return true;
    },
    validationMsg(value) {
      if (this.validate(value)) return "";
      else
        return (
          this.content.validationMsg ?? "Please enter a value between 0 and 100"
        );
    },
    validateDimension() {
      let valid = true;
      this.levelsRankedWithRatings?.forEach((lvl) => {
        if (lvl.value < 0 || lvl.value > 100) valid = false;
      });
      if (!valid) return valid;

      // check if ratings are decreasing and unique
      let warning = false;
      let prev = this.levelsRankedWithRatings[0].value;
      for (let i = 1; i < this.levelsRankedWithRatings.length; i++) {
        let curr = this.levelsRankedWithRatings[i].value;
        if (curr > prev) {
          warning = true;
          break;
        }
        prev = curr;
      }
      if (this.levelsRankedWithRatings.length > 3) {
        let values = this.levelsRankedWithRatings
          .slice(1, this.levelsRankedWithRatings.length - 1)
          .map((lvl) => lvl.value);
        if (values.every((val) => val === values[0])) {
          warning = true;
        }
      }
      if (!valid) return valid;
      if (warning) {
        // if warning is already shown->next, but keep record
        if (this.goNextWarning) {
          this.errorCounter[this.taskIndex] = true;
          return valid;
        }
        this.warningCounter[this.taskIndex] = true;
        this.goNextWarning = true;
        this.$nextTick(() => {
          this.$refs.warning?.scrollIntoView({ behavior: "smooth" });
        });
        return false;
      }

      this.errorCounter[this.taskIndex] = false;
      return valid;
    },
    monitorErrorCount() {
      let errTol = this.content.errorTol;
      if (errTol === null || errTol === undefined) return false;

      let count = this.errorCounter.reduce((a, b) => a + b, 0);
      if (count > errTol) {
        this.$store.commit("setEditingInProgress", false);
        return true;
      } else {
        this.$store.commit("setEditingInProgress", true);
        return false;
      }
    },
    rejectParticipant() {
      let link = this.$store.state.survey.general.rejectionUrl ?? false;
      if (!link) return (this.showErrorModal = true);


      if (this.$store.state.survey.general.appendUserIdToRejectionUrl) {
        link = link + this.$store.state.results.userId;
      }

      axios
        .post(this.$store.state.host + "/api/screen/reject", {
          identifier: this.$route.params.surveyId,
        })
        .then((res) => console.log("rejected"))
        .catch((err) => console.log(err))
        .finally(() => {
          if (link) window.location.href = link;
          else this.showErrorModal = true;
        });
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    },

    replaceAll(str, find, replace) {
      if (!str) return str;
      return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
    },
  },
  mounted() {
    this.$store.commit("enableInternalNav");
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
    if (this.$store.state.results.lvlRatingOrder.length > 0) {
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    } else {
      if (this.$store.state.survey?.pages?.levelRating?.randomiseOrder) {
        this.$store.commit("setLevelRatingOrder", { randomise: true });
      } else {
        this.$store.commit("setLevelRatingOrder", { randomise: false });
      }
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    }
  },
  beforeUnmount() {
    this.subscribe();
    this.$store.commit("setCustom", {
      property: "levelRatingErrorConfirmed",
      value: this.errorCounter,
    });
    this.$store.commit("setCustom", {
      property: "levelRatingWarningShown",
      value: this.warningCounter,
    });
  },
  watch: {
    taskIndex() {
      if (this.taskIndex > 0) {
        this.$store.commit("enableGoBack");
      }
    },
  },
};
</script>

<style scoped>
/* transistion fade */

.form-range:disabled,
.form-control:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
}
/* remove arrows from numeric input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
