<template>
  <div style="height: 300px;">
    <vue3-chart-js
      :id="chartData.id"
      ref="chartRef"
      :type="chartData.type"
      :data="chartData.data"
      :options="chartData.options"
    ></vue3-chart-js>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  setup() {
    const store = useStore();
    const chartRef = ref(null);

      let temp = store.state.results.ds;
      let temp2 = store.state.survey.ds;
      let orderedDims = [];
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < temp2.length; j++) {
          if (temp[j].rank == i) {
            let blendedDimension = temp2[j]
            blendedDimension.rank = temp[j].rank;
            blendedDimension.swingWeight = temp[j].swingWeight == undefined ? [0] : [temp[j].swingWeight];
            orderedDims.push(blendedDimension);
          }
        }
      }
      orderedDims.sort((a, b) => b.swingWeight - a.swingWeight);
      function tooltipStringBreaker(str) {
        return str.match(/.{1,25}(\s|$)/g);
      }
    let data = orderedDims.map((d) => d.swingWeight)
    let labels = orderedDims.map((d) => tooltipStringBreaker(d.name));
//    let backgroundColor = orderedDims.map((d) => d.hexColor);


    let chartData = {
      type: "bar",
      options: {
        barPercentage: 0.7,
        min: 0,
        max: 100,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
          responsiveAnimationDuration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip:{
            enabled: false,
          }
        },
        scales: {
          y: {
             suggestedMin: 1,
             suggestedMax: 100,

            ticks: {
              count: 6,
              
              callback: function (value) {
                return `${value}`;
              },
            },
          },
        },
      },
      data: {
        labels
      }
    };

    const updateChart = () => {
      let temp = store.state.results.ds;
      let temp2 = store.state.survey.ds;
      let orderedDims = [];
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < temp2.length; j++) {
          if (temp[j].rank == i) {
            let blendedDimension = temp2[j]
            blendedDimension.rank = temp[j].rank;
            blendedDimension.swingWeight = temp[j].swingWeight == undefined ? [0] : [temp[j].swingWeight];
            orderedDims.push(blendedDimension);
          }
        }
      }
      orderedDims.sort((a, b) => b.swingWeight - a.swingWeight);
    let data = orderedDims.map((d) => d.swingWeight)
    let labels = orderedDims.map((d) => d.name);
    let backgroundColor = orderedDims.map((d) => "hsl(" + d.hexColor + ", 100%, 50%, .55)");
    let backgroundColorHover = orderedDims.map((d) => "hsl(" + d.hexColor + ", 100%, 50%, .9)");

      chartData.data.datasets = [
        {
          // label: "",
          data,
          backgroundColor,
          hoverBackgroundColor: backgroundColorHover,
          barPercentage: 0.8,
          borderRadius: 10,
          borderColor: "black",
          borderWidth: 1,
          hoverBorderWidth: 1.5,
        },
      ];

      chartRef.value?.update();
    };

    watch(
      () => store.state.results.ds,
      function () {
        updateChart();
      },
      { deep: true }
    );

    return {
      chartData,
      updateChart,
      chartRef,
    };
  },
  mounted() {
    this.updateChart();
  },
};
</script>
