<template>
  <div class="home home-100">
      <Description>
        <p>
          {{
            $store.state.survey?.pages?.dimensionSelection?.description ?? ''
          }}
        </p>
      </Description>

      <!-- <div v-for="dimension in dimensions" :key="dimension.id">
          {{dimension.text}}
        </div> -->
      <Card style="max-width: none;">
        <div class="container d-flex flex-row">
          <b-form-group
            label-class="fs-4 text-center mx-auto mb-4"
            class="mb-0 mt-1 d-flex flex-column justify-content-center"
            label="Which dimension of health is most important to you?"
            v-slot="{ selectionLabel }"
          >
            <b-form-checkbox-group
              id="dimension-selection"
              :options="dimensions"
              v-model="selected"
              :state="minSelectionReached"
              class="flex-wrap justify-content-center flex-grow-1"
              button-variant="secondary btn-selection"
              size="md"
              name="checkbox-validation"
              buttons
            >
            </b-form-checkbox-group>
            <div v-if="maxSelection > 0">
              <b-form-invalid-feedback
              :state="minSelectionReached"
                >
                Please select {{ minSelection }} to {{ maxSelection }} dimensions.

                </b-form-invalid-feedback
              >
              <b-form-valid-feedback
              :state="minSelectionReached"
              >
              <div v-if="!maxSelectionReached">
                  You can select {{ maxSelection-minSelection }} more dimensions, if you want to.
                </div>
                <div v-else>
                You have selected the maximum number of dimensions.
                </div>
                </b-form-valid-feedback
              >
            </div>
          </b-form-group>
        </div>
      </Card>
      <GoNextBtn :promptGoNext="minSelectionReached"/>
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Footer from "@/components/Footer.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "DimensionalSelection",
  components: {
    Description,
    Footer,
    GoNextBtn,
    Card,
  },
  data() {
    return {
      selected: [],
      maxSelection: 4,
      minSelection: 6,
      subscribe: null,
      userMovesOn: false,
    };
  },
  mounted() {

    this.minSelection = this.$store.state.survey?.pages?.dimensionSelection?.minSelection;
    this.maxSelection = this.$store.state.survey?.pages?.dimensionSelection?.maxSelection;

    this.$store.commit("disableGoNext");
    
    if(this.$store.state.results.ds_inactive?.length > 0){
      this.selected = this.$store.state.survey.ds.map(d => d.id);
      this.$store.commit("restoreDimensionSelectionResults");
      this.$store.commit("restoreDimensionSelectionSurvey");
    }

    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (action.type == "go" && action.payload == 1) {
          this.userMovesOn = true;
        }
      }.bind(this)
    );
  },
  unmounted() {
    if(this.userMovesOn){
      this.$store.commit("filterDimensionSelectionResults", this.selected);
      this.$store.commit("filterDimensionSelectionSurvey", this.selected);
    }
    this.subscribe();
  },
  computed: {
    dimensions() {
      if (this.$store.state.survey.ds === undefined) {
        return [];
      }
      return this.$store.state.survey.ds.map(
        (dimension) => {
          return {
            text: dimension.name,
            value: dimension.id,
            disabled: !this.selected.includes(dimension.id) && this.maxSelectionReached ? true : false,
          };
        }
      );
    },
    maxSelectionReached() {
      return this.selected.length >= this.maxSelection;
    },
    minSelectionReached() {

       if(this.selected.length >= this.minSelection){
        this.$store.commit("enableGoNext");
        return true;
       }
       else {
        this.$store.commit("disableGoNext");
        return false;
       }
      },
  },
};
</script>

<style lang="scss" scoped>
:deep{
  .btn-selection {
    /*  professional button */
    background-color: white;
    border-color: #e5e5e5;
    color: #333;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .btn-selection:hover {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #333;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
}
</style>>

