<template>
  <div :class="styleClass" class="w-100 px-3">
      <div
      id="anchorVasSlider2"
      class="vue-nouislider"
      @click="revealHandle"
      ></div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
export default {
  name: "AnchorVasSlider",
  props: {
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      default: [0,100]
    },
    id: {
      type: String,
      default() {
        return "id-" + Math.random().toString(36).substr(2, 4);
      },
    },
    styleClass: {
      type: String,
      default: "",
    },
    revealOnClick: {
      type: Boolean,
      default: false,
    },
    hiddenOnStart: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      init: false,
      handleMadeVisible: false,
      movingHandle: null,
    };
  },
  emits: ["update", "handleMadeVisible", "set","movingHandle", "bottomHandle"],
  computed: {
    slider() {
      return document.querySelector('#anchorVasSlider2');
    },
  },
  mounted() {
    this.config.start = this.values;
    noUiSlider.create(this.slider, this.config);

    var lowerOrigins = this.slider.querySelectorAll('.noUi-origin')[0];
    lowerOrigins.setAttribute('disabled', true);

    let handles = this.slider.querySelectorAll('.noUi-handle')
    this.movingHandle = handles[1];
    if(this.hiddenOnStart) this.movingHandle.style.visibility = "hidden";
    else this.movingHandle.style.visibility = "visible";

    this.$emit('bottomHandle', handles[0])
    this.$emit('movingHandle', handles[1])

        this.slider.noUiSlider.on("update", this.updateValue);
        this.slider.noUiSlider.on("set", this.setValue);
    setTimeout(() => {
    }, 0);
  },
  methods: {
    updateValue(value, handle) {
        if(handle === 0) return;
      this.$emit("update", value[handle]);
      if(value[handle] < 100 && !this.handleMadeVisible)
        this.revealHandle();
    },
    setValue(value, handle) {
        if(handle === 0) return;
      this.$emit("set", value[handle]);
      if(value[handle] < 100 && !this.handleMadeVisible)
        this.revealHandle();
    },
    revealHandle() {
      if(!this.handleMadeVisible) 
        this.$emit("handleMadeVisible", this.handleMadeVisible);
      
      if(this.movingHandle.style.visibility === "hidden")
        this.movingHandle.style.visibility = "visible";

      this.handleMadeVisible = true;
      }
    },
};
</script>

<style lang="scss">
@import "~nouislider/dist/nouislider.min.css";

#anchorVasSlider2 {
  
  width: 100%;
  margin: 10px auto;

//   .noUi-origin .noUi-handle {
//     visibility: visible;
//   }

.noUi-origin .noUi-handle {
  cursor: grab;
}

.vue-nouislider .noUi-connect {
  background-color: var(--dimension-color) ;
  box-shadow: none ;
}

.noUi-connects  {
 cursor: crosshair;
 z-index: 2 !important;
}
.noUi-pips{
  pointer-events: none;
  z-index: 1 !important;
}
[disabled] .noUi-handle, [disabled].noUi-target, [disabled] .noUi-connects {
  cursor: not-allowed !important;
}
[disabled] .noUi-connects {
  opacity: 0.5;
}



}


</style>
