<template>
  <div class="home home-100 w-100">
    <div
      class="bg-white border border-dark w-100 py-3 d-flex flex-column"
      style="min-height: 80vh"
    >
      <!-- first page -->
      <div v-if="dimIndex === -1" class="d-flex flex-column mt-5 px-5">
        <div class="mx-auto">
          <img :src="require(`@/assets/img/eq5d5l.jpg`)" alt="" />
        </div>
        <div class="text-center py-5">
          <h3>{{content.eqIntroTitle}}</h3>
        </div>
        <div class="text-center py-5">
          <h3>{{content.eqIntroSubtitle}}</h3>
        </div>
      </div>

      <div v-if="(dimIndex > -1) & (dimIndex < 5)" class="px-5 pt-3">
        <div>
          {{ content.info }}
        </div>

        <div class="mt-5">
          <div class="text-uppercase fw-bold">
            {{ dimensions[dimIndex].name }}
          </div>

          <div v-for="(l, i) in options[dimIndex]">
            <div
              class="d-flex align-items-center justify-content-between pe-2"
              :key="l.value"
            >
              <div>
                {{ l.text }}
              </div>
              <b-form-checkbox
                size="lg"
                :id="'checkbox-' + l.value"
                @change="toggleCheckbox(i)"
                :value="l.value"
                unchecked-value="null"
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dimIndex === 5">
        <div class="d-flex w-100 justify-content-evenly">
          <div class="d-flex flex-column">
            <div class="mt-5" v-html="content.eqVasQuestion">
            </div>

            <div
              class="py-2 px-5 bg-dark ms-auto me-5 text-center"
              style="margin-top: 100px"
            >
              <div class="fw-lighter text-white">
                <div v-html="content.eqVasBoxLabel"></div>
                <div class="eq-vas-res">{{ sliderValue[0] }}</div>
              </div>
            </div>
          </div>

          <div
            class="slider-vertical d-flex flex-column flex-nowrap justify-content-center align-items-center"
          >
            <div class="mb-2 small text-center fw-bold">
              <div v-html="content.topAnchor"></div>
            </div>
            <slider
              @update="updateValue"
              @set="updateValue"
              @handleMadeVisible.once="revealResult"
              styleClass="eq-vas-slider"
              id="eq-vas-slider"
              :revealOnClick="true"
              :config="sliderConfig"
              :values="[0]"
            />
            <div class="mt-1 small text-center fw-bold">
              <div v-html="content.bottomAnchor"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-auto"></div>

      <div class="px-5">
        <div class="small text-secondary">
          <div v-html="content.copright"></div>
        </div>
      </div>

      <div
        class="w-100 d-flex justify-content-between border-top mt-4 pt-2 px-4"
      >
        <button
          class="btn px-3 py-2 border border-dark fw-bold"
          @click="prevDim"
          :disabled="dimIndex === -1"
        >
          &larr; {{ content.prevBtn }}
        </button>
        <button
          class="btn px-3 py-2 border border-dark fw-bold"
          @click="nextDim"
          :disabled="!dimCompleted && dimIndex > -1"
        >
          {{ content.nextBtn }} &rarr;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import Slider from "@/components/Slider.vue";

let sliderConfig = {
  tooltips: [wNumb({ decimals: 0 })],
  start: 40,
  connect: [true, false],
  pips: {
    mode: "positions",
    values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    density: 2,
  },
  direction: "rtl",
  orientation: "vertical",
  range: { min: [0], max: [100] },
};

export default {
  name: "OwnStateEQ",
  components: {
    GoNextBtn,
    Slider,
  },
  data() {
    return {
      dimIndex: -1,
      levels: [],
      selected: [],
      resultsRevealed: false,
      sliderConfig,
    };
  },
  methods: {
    nextDim() {
      if (this.dimIndex === 5) return this.$store.dispatch("go", 1);

      this.dimIndex++;
      this.checkCheckbox();
    },
    prevDim() {
      this.dimIndex--;
      this.checkCheckbox();
    },
    toggleCheckbox(i) {
      // Check
      this.options[this.dimIndex].forEach((l, index) => {
        if (index === i) return;
        document.querySelector("#checkbox-" + l.value).checked = false;
      });
      document.querySelector;
      this.levels[this.dimIndex] = this.options[this.dimIndex][i].value;
    },
    checkCheckbox(i) {
      if (this.dimIndex === 5) return;
      if (this.levels[this.dimIndex] !== undefined) {
        setTimeout(() => {
          try {
            document.querySelector(
              "#checkbox-" + this.levels[this.dimIndex]
            ).checked = true;
          } catch (e) {
            console.log(e);
          }
        }, 300);
      }
    },
    updateValue(value) {
      this.$store.commit(
        "updateOverallHealthStatusLevel",
        Math.round(value[0])
      );
    },
    revealResult() {
      this.resultsRevealed = true;
      document.querySelector(".eq-vas-res").classList.add("reveal");
      this.$store.commit("enableGoNext");
    },
  },
  mounted() {
    this.levels = this.$store.state.results.ownState.state;
    this.$store.commit("disableGoNext");
  },
  computed: {
    dimCompleted() {
      return this.levels[this.dimIndex] != undefined || this.resultsRevealed;
    },
    content() {
      return this.$store.state.survey?.pages?.ownState;
    },
    taskCompleted() {
      let taskIsCompleted = this.questionIndex == this.dimensions.length;
      if (taskIsCompleted) {
        this.$store.commit("enableGoNext");
      } else {
        this.$store.commit("disableGoNext");
      }
      return taskIsCompleted;
    },

    questionIndex() {
      return this.levels.reduce((acc, cur) => {
        if (cur == undefined) {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
    },
    dimensions() {
      return this.$store.state.survey.ds;
    },
    options() {
      return this.dimensions.map((dimension) => {
        return dimension.levels.map((level, index) => {
          return {
            text: level.ownStateLabel ?? level.label,
            value: dimension.id + "_" + index,
          };
        });
      });
    },
    sliderValue() {
      return [this.$store.state.results?.ownState?.overallHealthLevel ?? 0];
    },
  },
};
</script>

<style lang="scss" scoped>
.lg {
  width: 20px;
  height: 20px;
}
.btn:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
  pointer-events: all !important;
}

::v-deep .eq-vas-slider {
  #eq-vas-slider {
    height: 580px;
  }
  .noUi-connect {
    cursor: crosshair !important;
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
    z-index: 2 !important;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 15em !important;
    width: 20px !important;
    margin-bottom: 0.6em;
    height: 20px !important;
    border: 1px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    left: -20px;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    cursor: crosshair !important;
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .noUi-pips-vertical {
    padding: 0;
    margin: 0;
    margin-left: -29px;
    padding-left: 10px;
    pointer-events: none;
    z-index: 0 !important;
  }
  .noUi-value {
    margin-left: 15px;
    color: #0d213a !important;
  }
  .noUi-marker-vertical.noUi-marker.noUi-marker-large {
    width: 20px !important;
    left: 9px;
    right: 0px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 10px !important;
    left: 15px;
    background: black;
  }
  .noUi-tooltip {
    font-weight: bold;
    border: none !important;
    background: none !important;
  }
  .noUi-connect {
    background: transparent !important;
  }
}

.eq-vas-res {
  font-weight: bold;
  visibility: hidden;
  font-size: 1.1em;
}

.reveal {
  visibility: visible;
}
</style>
