<template>

  <div class="home home-70 text-page">

    <div class="w-100 d-flex justify-content-end">
      <button
            v-print="printObj"
            class="btn btn-primary mt-3 btn-sm"
            v-if="$store.state.survey?.pages?.textPage?.printBtn"
          >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
              />
            </svg>
          {{$store.state.survey?.pages?.textPage?.printBtn}}
      </button>
      </div>
      <div id="text-page">

    <div>
      <div v-html="$store.state.survey?.pages?.textPage?.html ?? ''" >
        </div>

      <div v-html="$store.state.survey?.pages?.textPage?.participantInfo ?? ''" >
        </div>

      <p>
      <div class="fw-bold mt-3">
          {{ $store.state.survey?.general?.authorName }}
        </div>

        {{ $store.state.survey?.general?.authorAffiliation }}<br />
        <a target="_blank" rel="noopener noreferrer" :href="href">
          {{ $store.state.survey?.general?.authorEmail }}
        </a>
      </p>
    </div>
          </div>
      <GoNextBtn :promptGoNext="isTrue" :customMsg="$store.state.survey?.pages?.textPage?.goNextPromptMsg"/>
  </div>
</template>

<script>

import GoNextBtn from "@/components/GoNextBtn.vue";
import HomeHeader from "@/components/HomeHeader.vue";
import print from "vue3-print-nb";

export default {
    name: 'TextPage',
    components: {
        GoNextBtn,
        HomeHeader,
    },
    directives: {
    print,
  },
    data() {
        return {
            isTrue: true,
            printObj: {
              id: "text-page",
              title: "Info"
          },
        }
    },
    mounted() {
        this.$store.commit("enableGoNext");
    },
    computed: {
        href() {
            return `mailto: ${this.$store.state.survey?.general?.authorEmail}`;
      },
    },
    }
</script>

<style >
  .text-page p {
      font-size: 1.25em;
      margin-bottom: 1.25em;
  }
  .text-page h3 {
      font-size: 1.35em;
      margin-top: 2em;
      margin-bottom: 0.25em;
      font-weight: bold;
    }
</style>