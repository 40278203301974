<template>
  <div class="home home-80">
    <Description class="mx-auto home-60">
      <p class="fw-bold">
        {{
          $store.state.survey?.pages?.dimensionSelectionByCategory?.description ??
          ""
        }}
      </p>
    </Description>

    <Card style="max-width: none" class="w-100" spacingClasses="px-4 py-3">
      <h3>{{ categories[taskIndex] }}</h3>

      <div class="container d-flex flex-row">
        <b-form-group
          label-class="fs-4 text-center mx-auto mb-4"
          class="mb-0 mt-1 d-flex flex-column justify-content-center"
          v-slot="{ selectionLabel }"
        >
          <b-form-checkbox-group
            id="dimension-selection"
            :options="currentDimensions"
            v-model="selected[taskIndex]"
            :state="minSelectionReached"
            class="flex-wrap justify-content-center flex-grow-1"
            button-variant="outline-primary btn-selection"
            size="md"
            name="checkbox-validation"
            buttons
          >
          </b-form-checkbox-group>
          <div v-if="maxSelection > 0">
            <b-form-invalid-feedback :state="minSelectionReached">
              Please select {{ minSelection }} to {{ maxSelection }} dimensions.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="minSelectionReached">
              <div v-if="!maxSelectionReached">
                You can select
                {{ maxSelection - selected[taskIndex]?.length }} more
                dimensions, if you want to.
              </div>
              <div v-else>
                You have selected the maximum number of dimensions.
              </div>
            </b-form-valid-feedback>
          </div>
        </b-form-group>
      </div>
    </Card>
    <GoNextBtn :promptGoNext="minSelectionReached" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Footer from "@/components/Footer.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "DimensionalSelectionByCategory",
  components: {
    Description,
    Footer,
    GoNextBtn,
    Card,
  },
  data() {
    return {
      selected: [],
      maxSelection: 4,
      minSelection: 6,
      subscribe: null,
      userMovesOn: false,
      taskIndex: 0,
    };
  },
  mounted() {
    this.minSelection =
      this.$store.state.survey?.pages?.dimensionSelectionByCategory?.minSelection;
    this.maxSelection =
      this.$store.state.survey?.pages?.dimensionSelectionByCategory?.maxSelection;

    this.$store.commit("disableGoNext");

    if (this.$store.state.results.ds_inactive?.length > 0) {
      // this.selected = this.$store.state.survey.ds.map(d => d.id);
      this.$store.commit("restoreDimensionSelectionResults");
      this.$store.commit("restoreDimensionSelectionSurvey");
    }

    this.$store.commit("enableInternalNav");
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );

    // this.subscribe = this.$store.subscribeAction(
    //   function (action, state) {
    //     if (action.type == "go" && action.payload == 1) {
    //       this.userMovesOn = true;
    //     }
    //   }.bind(this)
    // );
  },
  methods: {
    next() {
      this.userMovesOn = true;
      if (this.taskIndex < this.categories.length - 1) {
        this.dimTransistion = "slide-bottom-dim";
        this.taskIndex++;
        setTimeout(() => {
          this.$store.commit("disableGoNext");
        }, 0);
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
    back() {
      this.userMovesOn = false;
      if (this.taskIndex > 0) {
        this.dimTransistion = "slide-top-dim";
        this.taskIndex--;
        setTimeout(() => {
          this.$store.commit("enableGoNext");
        }, 0);
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
  },
  unmounted() {
    if (this.userMovesOn) {
      this.$store.commit(
        "filterDimensionSelectionResults",
        this.selected.flat(1)
      );
      this.$store.commit(
        "filterDimensionSelectionSurvey",
        this.selected.flat(1)
      );
    }
    this.subscribe();
  },
  computed: {
    dimensions() {
      if (this.$store.state.survey.ds === undefined) {
        return [];
      }
      return this.$store.state.survey.ds.map((dimension) => {
        return {
          text: dimension.name,
          value: dimension.id,
          disabled:
            !this.selected[this.taskIndex]?.includes(dimension.id) &&
            this.maxSelectionReached
              ? true
              : false,
          category: dimension.category,
          levels: dimension.levels,
        };
      });
    },
    currentDimensions() {
      var currentDims = this.dimensions.filter((dimension) => {
        return dimension.category === this.categories[this.taskIndex];
      });

      if (
        this.$store.state.survey?.pages?.dimensionSelectionByCategory?.combinedDimLvl
      ) {
        currentDims.forEach((dim, i) => {
          let worstLvl = dim.levels.reduce((prev, curr) => {
            if (prev.rank > curr.rank) {
              return prev;
            } else {
              return curr;
            }
          });
          currentDims[i].text = currentDims[i].text +  ": " + worstLvl.label;
        })
      }
      return currentDims; 
    },
    categories() {
      let categories = this.dimensions.map((dimension) => dimension.category);
      return [...new Set(categories)];
    },
    maxSelectionReached() {
      if (this.selected[this.taskIndex] === undefined) {
        return false;
      }
      return this.selected[this.taskIndex].length >= this.maxSelection;
    },
    minSelectionReached() {
      if (this.selected[this.taskIndex] === undefined) {
        this.$store.commit("disableGoNext");
        return false;
      }
      if (this.selected[this.taskIndex].length >= this.minSelection) {
        this.$store.commit("enableGoNext");
        return true;
      } else {
        this.$store.commit("disableGoNext");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .btn-selection {
    color: black;
    text-align: left;
    margin: 0.25em 0.25em;
    cursor: pointer;
    font-size: 1.1rem;
  }
  .btn-selection:hover {
    color: white;
    transform: scale(1.02);
  }
}
</style>>

