<template>

    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" :id="modalId + 'Label'" >
          {{general.title ?? 'About'}}
          </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div v-html="content.html"></div>

        <div v-html="content.contactMsg"></div>
        <div class="text-center">
          <b>{{general.authorName}}</b><br>
        {{general.authorAffiliation}}<br>
          <a :href="href" target="_blank" rel="noopener noreferrer"
          >{{general.authorEmail}}</a
          >
      </div>
        
        
        


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "InfoModal",
    data() {
        return {};
    },
    props: {
        modalId: {
            type: String,
            default: 'infoModal'
        },
    },
  computed: {
    href() {
      return (
        "mailto:" +
        this.$store.state.survey.general.authorEmail
        // "p.schneider@sheffield.ac.uk" +
        // "?subject=Technical Issue (survey:" +
        // this.$store.state.survey.identifier +
        // "; on page: " +
        // this.$store.state.navigation.currentPage +
        // ")"
      );
    },
    content() {
      return this.$store.state.survey?.infoModal ?? {};
    },
    general() {
      return this.$store.state.survey?.general ?? {};
    },
    defaultContent() {
      return '';
    },
    }
    

}
</script>
<style>
    .modal-backdrop {
    z-index: 2 !important;
  }
  .modal-content {
    border-radius: 18px !important;
  }
</style>