<template>
  <div class="home home-100 w-100">


    <QuestionCard :content="content" >
      <div v-html="special"></div>
    </QuestionCard>

    <!-- main ROW: key | task -->
    <div
      class="d-flex align-items-start flex-wrap flex-sm-nowrap justify-content-stretch"
    >

    <!-- key -->
      <div class="flex-grow-1 mx-3 mt-2" style="flex-basis: 40%;">

        <div class="card mb-3">
          <div class="card-body">
            <div v-html="info"></div>
          </div>
        </div>

  <!-- Chart -->
        <!-- <div class="card mb-3">
        <div class="card-body">
            <div v-html="content.figText ?? ''"></div>
            <div class="mt-3">
              <DimWeightsChart :dimWeightingResults="allDims" />
            </div>
        </div>
      </div> -->

      </div>

      <!-- task -->
      <div class="flex-grow-1 mx-3" style="flex-basis: 60%">
        <div class="card border border-primary mb-4 mt-2">
          <div class="card-header bg-primary">
            <span class="text-white lead">
             {{content.referenceStr ?? 'Reference'}}
            </span>
          </div>
          <div class="card-body pb-1">
            {{content.considerStr ?? 'Consider an improvement'}}
            <div
              class="d-flex flex-column align-items-start border-start ms-3 my-2"
            >
              <div class="px-2 py-1" style="flex: 1">
                <div class="small text-secondary lh-1">
                  {{content.fromStr ?? 'from:'}}
                </div>
                {{appendLevelLabel(firstDimension)}}
                {{ worstLevel(firstDimension) }}
              </div>

              <div class="px-2 py-1" style="flex: 1">
                <div class="small text-secondary lh-1">
                  {{content.toStr ?? 'to:'}}
                </div>
                {{appendLevelLabel(firstDimension)}}
                {{ bestLevel(firstDimension) }}
              </div>
            </div>

            <div class="pt-2 pb-2 lh-sm" style="font-size: 1em">
              {{content.yardStickInfo ?? 'This improvement has a rating of 100 points, because it is the most important one - use it as a reference to rate the other improvements.'}}
            </div>

            <div class="d-flex align-items-center">
              <div class="w-100 me-4">
                <b-input-group
                  @mousedown="firstDimension.touched = true"
                  @touchstart="firstDimension.touched = true"
                >
                  <b-form-input
                    :disabled="true"
                    type="range"
                    min="0"
                    max="100"
                    @change="firstDimension.touched = true"
                    @focus="firstDimension.touched = true"
                    v-model.number="firstDimension.swingWeight"
                  />
                </b-input-group>
              </div>

              <div style="width: 80px">
                <b-form-input
                  class="b-form-control text-center"
                  type="number"
                  min="0.00"
                  v-model="firstDimension.swingWeight"
                  :style="{
                    color: firstDimension.touched ? 'black' : 'transparent',
                  }"
                  :state="
                    firstDimension.swingWeight >= 0 &&
                    firstDimension.swingWeight <= 100
                      ? null
                      : false
                  "
                  @change="firstDimension.touched = true"
                  @focus="firstDimension.touched = true"
                  @click="firstDimension.touched = true"
                  :disabled="0 == 0"
                />
              </div>
            </div>
            <div
              class="text-secondary pt-1 pb-2 lh-sm"
              style="font-size: 0.7em"
            >
            {{content.fixedStr ?? 'This is fixed.'}}
            </div>
          </div>
        </div>

        <div :key="dimension.id" v-for="dimension in dimensions">
          <div class="card my-4" style="flex-basis: 50%; flex-grow: 1">
            <div class="card-body pb-3">
              {{content.considerStr ?? 'Consider an improvement'}}
              <div
                class="d-flex flex-column align-items-start border-start ms-3 my-2"
              >
                <div class="px-2 py-1" style="flex: 1">
                  <div class="small text-secondary lh-1">
                    {{content.fromStr ?? 'from:'}}
                  </div>
                  {{appendLevelLabel(dimension)}}
                  {{ worstLevel(dimension) }}
                </div>
                <div class="px-2 py-1" style="flex: 1">
                  <div class="small text-secondary lh-1">
                    {{content.toStr ?? 'to:'}}
                  </div>
                  {{appendLevelLabel(dimension)}}
                  {{ bestLevel(dimension) }}
                </div>
              </div>

              <div
                class="fw-bold pt-2 pb-2 lh-sm"
                style="font-size: 1em"
                :class="dimension.touched ? 'visible' : 'visible'"
              >
               {{ content.comparePrompt ?? 'Compared to the reference above, how many points would you give to this improvement? '}}
              </div>

              <div class="d-flex align-items-center">
                <div class="w-100 me-4">
                  <b-input-group
                    @mousedown="dimension.touched = true"
                    @touchstart="dimension.touched = true"
                  >
                    <b-form-input
                      type="range"
                      min="0"
                      max="100"
                      @change="dimension.touched = true"
                      @focus="dimension.touched = true"
                      v-model.number="dimension.swingWeight"
                    />
                  </b-input-group>
                </div>

                <div style="width: 80px">
                  <b-form-input
                    class="b-form-control text-center"
                    type="number"
                    min="0.00"
                    v-model.number="dimension.swingWeight"
                    :style="{
                      color: dimension.touched ? 'black' : 'transparent',
                    }"
                    :state="
                      dimension.swingWeight >= 0 && dimension.swingWeight <= 100
                        ? null
                        : false
                    "
                    @change="dimension.touched = true"
                    @focus="dimension.touched = true"
                    @click="clickInput(dimension)"
                  />
                </div>
              </div>
              <b-form-invalid-feedback :state="validate(dimension.swingWeight)">
              {{content.validationMsg ?? "Please enter a value between 0 and 100"}}
            </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GoNextBtn :promptGoNext="canGoNext" />
  </div>
</template>

<script>
import DimWeightsChart from "@/components/DimWeightsChart.vue";
import QuestionCard from "../components/QuestionCard.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";
import Details from "@/components/Details.vue";

export default {
  name: "dimWeighting2",
  components: {
    GoNextBtn,
    DimWeightsChart,
    QuestionCard,
    Details,
  },
  data() {
    return {
      dimensions: [],
      firstDimension: { swingWeight: 100, touched: true, levels: null },
      allDims: [],
    };
  },

  beforeMount() {
    this.$store.commit("disableGoNext");
    if(this.$store.state.results?.dimWeightingOrder?.length > 0){
      this.dimWeightingOrder = this.$store.state.results.dimWeightingOrder;
    } else {
      if(this.$store.state.survey?.pages?.levelRating?.randomiseOrder){
        this.$store.commit("setDimWeightingOrder",{randomise: true});
      } else {
        this.$store.commit("setDimWeightingOrder",{randomise: false});
      }
      this.dimWeightingOrder = this.$store.state.results.dimWeightingOrder;
    }
  },

  mounted() {
    let temp = this.$store.state?.results?.ds;
    let temp2 = this.$store.state?.survey?.ds;

    if (temp === undefined || temp2 === undefined) {
      return console.error("no dimensions found");
    }

    let orderedDims = [];
    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < temp2.length; j++) {
        if (temp[j].rank == i) {
          let iWeight =
            i == 0
              ? 100
              : temp[j].swingWeight == undefined
              ? 0
              : temp[j].swingWeight;
          let iDim = {
            ...temp2[j],
            rank: temp[j].rank,
            swingWeight: iWeight,
            zeroWeight: temp[j].zeroWeight ? true : false,
            touched: temp[j].zeroWeight || iWeight ? true : false,
          };
          orderedDims.push(iDim);
        }
      }
    }
    this.allDims = orderedDims;
    this.firstDimension = orderedDims[0];
    this.dimensions = orderedDims.slice(1);
  },
  beforeUnmount() {
    this.allDims.forEach((dim) => {
      this.$store.commit("updateDimensionWeight", {
        weight: parseFloat(dim.swingWeight),
        dimension: { id: dim.id },
      });
    });
  },
  unmounted() {},

  methods: {
    bestLevel(dimension) {
      return dimension?.levels?.reduce((curr, next) => {
        return curr.rank < next.rank ? curr : next;
      }).label;
    },
    worstLevel(dimension) {
      return dimension?.levels?.reduce((curr, next) => {
        return curr.rank > next.rank ? curr : next;
      }).label;
    },
    appendLevelLabel(dimension) {
      return this.content.appendLevelLabel ? dimension.name + ": " : "";
    },
    validate(value) {
      if (
        value === undefined ||
        value === "" ||
        value === null ||
        value === NaN
      )
        return false;
      if (value < 0 || value > 100) return false;
      return true;
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    },

    replaceAll(str, find, replace) {
      return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
    },
    clickInput(dimension) {
      if (dimension.swingWeight === 0) dimension.swingWeight = null;
      if(!dimension.touched) dimension.touched = true;
    },
  },
  computed: {
    content() {
      return this.$store.state.survey?.pages?.dimensionWeighting ?? {};
    },
    special() {
      return this.replaceAll(
        this.content?.special ?? "",
        "{{worstLevel(firstDimension)}}",
        (this.content.appendLevelLabel ? this.firstDimension.name + ": " : "") + this.worstLevel(this.firstDimension) ?? "dimension"
      );
    },
    // question() {
    //   return this.replaceAll(
    //     this.content?.question ?? "",
    //     "{{worstLevel(firstDimension)}}",
    //     this.worstLevel(this.firstDimension) ?? "dimension"
    //   );
    // },
    // special2() {
    //   return this.replaceAll(
    //     this.content?.special2 ?? "",
    //     "{{firstDimension?.name}}",
    //     this.firstDimension?.name ?? "dimension"
    //   );
    // },
    info() {
      return this.replaceAll(
        this.content?.info ?? "",
        "{{firstDimension?.name}}",
        this.firstDimension?.name ?? "dimension"
      );
    },
    canGoNext() {
      let step1 = this.dimensions?.every((dim) => dim.touched);
      let step2 = this.dimensions?.every((dim) =>
        this.validate(dim.swingWeight)
      );
      let canGoNext = step1 && step2;
      if (canGoNext) this.$store.commit("enableGoNext");
      else this.$store.commit("disableGoNext");

      return canGoNext;
    },
  },
};
</script>

<style scoped>
.form-range:disabled,
.form-control:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.level-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
