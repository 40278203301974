<template>
  <div>
  <div class="header">
    <div class="text-end ps-3 lh-1">

      <div @click="visitHp" class="valorem-logo text-dark fw-normal fs-5">
        <span class="logo-1">
          <span class="text-decoration-underline">v</span>alorem</span
        >.<span class="logo-2">health</span>
      </div>
    </div>
    <div class="header-sep d-none d-sm-inline" />
    <div v-if="adminConsole" class="text-nowrap">
      <span class="text-dark font-monospace fw-light ps-2"> Admin console </span>
      <div class="header-sep d-none d-sm-inline" />
    </div>
    <div v-if="!adminConsole" class="header-survey-title d-none d-sm-inline">
      {{$store.state.survey?.pages?.home?.title ?? "" }}
    </div>
    <div v-else class="font-monospace">
      /{{surveyId}}
    </div>

    <div class="w-100">
      <div class="footer-progressbar ms-3 ms-lg-4 ms-xl-5 me-0 me-lg-1 me-xl-4">
        <b-progress
          v-if="(!$store.state.survey.navText?.hideProgressBar ?? false) && !adminConsole"
          :max="100"
        >
          <b-progress-bar :value="progress" variant="primary">
            {{ progress }}%
          </b-progress-bar>
        </b-progress>
      </div>
    </div>

    <div class="mx-4 pb-1">
      <b-link
        v-if="adminConsole && $store.state.admin.loggedIn"
        class="header-link"
        @click="logout()"
      >
        Logout
      </b-link>
      <div v-else>
        <b-link
        class="text-dark"
        data-bs-toggle="modal"
        data-bs-target="#infoModal"
      >
      <div v-html="infoCircle"></div>
      </b-link>
      </div>
    </div>
  </div>
  <InfoModal v-if="!adminConsole && !$store.state.admin.loggedIn"/>
  </div>
</template>

<script>
import { infoCircle } from "@/assets/img/iconSvgs.json";
import InfoModal from "./InfoModal.vue";
export default {
  name: "Header",
  components: {
    infoCircle,
    InfoModal,
  },
  props: {
    adminConsole: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    infoCircle() {
      return infoCircle;
    },
    progress() {
      return Math.round(this.$store.getters.percentageCompleted);
    },
    currentPage() {
      return this.$store.state.navigation.currentPage + 1;
    },
    maxQuestions() {
      return this.$store.state.navigation.agenda.length;
    },
    surveyId() {
      return this.$route.params?.surveyId ?? '';
    },
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
    openInfoModal() {
      this.$bvModal.show("info-modal");
    },
    visitHp() {
      window.open("https://valorem.health", "_blank");
    },
  },
};
</script>

<style scoped>
.logo-1 {
  color: var(--accent);
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  font-size: 1.2em;
}
.logo-2 {
  font-family: 'Inter', sans-serif;
  font-weight: lighter;
}
.valorem-logo {
  cursor: pointer;
}
.header {
  background-color: #ffffff;
  /* 
  background-color: rgba(255, 255, 255, 0.9); 
  backdrop-filter: blur(12px);
  */

  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.header-sep {
  width: 10px;
  height: 90%;
  border-left: #3e3e3e 1px solid;
  margin-left: 12px;
  padding-left: 10px;
}

.header-survey-title {
  color: #3e3e3e;
  font-family: sans-serif;
  font-weight: lighter;
  font-size: 1em;
  padding-top: 2px;
  white-space: nowrap;
}

.header-link {
  color: #3e3e3e;
  font-family: serif;
  font-size: 0.9em;
  padding-top: 2px;
  white-space: nowrap;
  text-decoration: underline;
}
.progress-bar {
  background: var(--accent) !important;
  border-radius: 8px !important;
}
.progress {
  height: 18px;
  border-radius: 8px;
}

</style>
