<template>
 <div class="description">
  <slot></slot>
 </div>
</template>

<script>
export default {
 name: "Description",
};
</script>

<style scoped>
.description {
 line-height: 1.5 ;
 font-size: 1.1em;
}
</style>
