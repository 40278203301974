<template>
  <div class="home home-100 d-flex flex-column w-100">
    
    
    <div>
      <QuestionCard :content="content" />
    </div>

    <div class="d-flex justify-content-start w-100 py-3"
    style="overflow-x:scroll;">
      <!-- 
        <State
          :stateId="'A'"
          :class="'me-3'"
          :scenarioTitle="'titleLabelA'"
          :dimLabels="dimLabels"
          :labels="stateALabels"
          @select="selectState('A')"
          :btnText="'selectBtnLabel'"
          :appendLvlsWithDims="false"
        />
        <State
          :stateId="'B'"
          :class="'me-3'"
          :scenarioTitle="'titleLabelB'"
          :dimLabels="dimLabels"
          :labels="stateBLabels"
          @select="selectState('B')"
          :btnText="'selectBtnLabel'"
          :appendLvlsWithDims="false"
          :isLoading="isLoading"
        />
    </div> -->

      <!--  STATE A -->
      <div class="card mx-3" style="flex-basis: 100%">
        <div class="card-header text-center fw-bold">
          {{content.scenarioALabel ?? 'Scenario A'}}
        </div>
        <div class="card-body">
          <b-overlay
            id="overlayB"
            :show="isLoading"
            variant="dce-overlay"
            rounded="true"
            opacity="1"
            class="w-100 d-flex flex-column align-items-stretch justify-content-center my-auto"
            spinner-variant="primary"
            z-index="1"
          >
            <ul>
              <div v-for="(label,i) in stateALabels" :key="label" class="py-1"
              >
                <li 
                :class="overlapClass[i]" 
                :style="overlapStyle[i]"
                v-html="label"
                />
              </div>
            </ul>
          
          <div class="w-100 d-flex flex-column">
            <div class="mx-auto">
              <div class="px-3">
                <input
                  type="radio"
                  class="btn-check"
                  id="btn-a"
                  name="selected-scenario"
                  autocomplete="off"
                  value="A"
                  v-model="selected[taskIndex]"
                  :disabled="isLoading"
                  @click="next('A')"
                />
                <label class="btn btn-lg btn-outline-primary" for="btn-a">
                  {{content.selectBtnLabel ?? 'Select'}}
                </label>
              </div>
            </div>
          </div>
        </b-overlay>
        </div>
      </div>

      <!-- STATE B -->
      <div class="card mx-3" style="flex-basis: 100%">
        <div class="card-header text-center fw-bold">
          {{content.scenarioBLabel ?? 'Scenario B'}}
        </div>
        <div class="card-body">
          <b-overlay
            id="overlayB"
            :show="isLoading"
            variant="overlay-tdot"
            rounded="true"
            opacity="1"
            class="w-100 d-flex flex-column align-items-stretch justify-content-center my-auto"
            spinner-variant="primary"
            z-index="1"
          >
            <ul>
              <div v-for="(label,i) in stateBLabels" :key="label" class="py-1"
              >
                <li 
                :class="overlapClass[i]" 
                :style="overlapStyle[i]"
                v-html="label"
                />
              </div>
            </ul>
          

          <div class="w-100 d-flex flex-column">
            <div class="mx-auto">
              <input
                type="radio"
                class="btn-check"
                id="btn-b"
                name="selected-scenario"
                autocomplete="off"
                value="B"
                v-model="selected[taskIndex]"
                :disabled="isLoading"
                @click="next('B')"
              />
              <label class="btn btn-lg btn-outline-primary" for="btn-b">
                {{content.selectBtnLabel ?? 'Select'}}
              </label>
            </div>
          </div>
        </b-overlay>
        </div>
      </div>
    </div>

    <GoNextBtn :promptGoNext="canGoNext" />
  </div>
</template>

<script>
import GoNextBtn from "../components/GoNextBtn.vue";
import QuestionCard from "../components/QuestionCard.vue";
import State from "@/components/State.vue";
import axios from "axios";

export default {
  name: "dce",
  components: {
    GoNextBtn,
    QuestionCard,
    State,
  },
  data() {
    return {
      taskIndex: 0,
      startTime: null,
      isLoading: true,
      scenarios: [],
      selected: [],
      statesA: [],
      statesB: [],
      positionA: [],
      results: [],
    };
  },
  beforeMount() {
    this.$store.commit("enableInternalNav");
    axios
      .post(this.$store.state.host + "/api/dce/getset/", {
        studyId: this.$route.params.surveyId,
      })
      .then(
        function (response) {
          if (response.data.success) {
            this.loadScenario(response.data.dceSet);
            this.setIncrProgress();
          } else {
            console.error(response);
          }
        }.bind(this)
      );
  },
  mounted() {
    this.startTime = Date.now();

    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next(null);
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  methods: {
    next(selected = null) {
      this.results[this.taskIndex] = {
        stateA: this.statesA[this.taskIndex]
          .map((s) => parseInt(s[3]) + 1)
          .join(""),
        stateB: this.statesB[this.taskIndex]
          .map((s) => parseInt(s[3]) + 1)
          .join(""),
        // order: this.scenarios.order[this.taskIndex],
        selected: selected ?? this.selected[this.taskIndex],
        taskIndex: this.taskIndex,
        secondsTaken: (Date.now() - this.startTime) / 1000,
        positionA: this.positionA[this.taskIndex],
        choiceId: this.scenarios.states[this.taskIndex].choiceId,
        setId: this.scenarios.stateSetId,
      };

      // task ended
      if (this.taskIndex+1 >= this.scenarios.states.length) {
        this.$store.commit("disableInternalNav");
        this.$store.dispatch('go', 1);
        return;
      }
      
      let wait = 0;

      // if next question hasnt been answered
      if (this.selected[this.taskIndex + 1] == undefined) {
        this.isLoading = true;
        wait = 800;
        this.$store.commit("disableGoNext");
      } else {
        // if next question has been answered
        this.$store.commit("enableGoNext");
      }
      
      setTimeout(() => {
        this.isLoading = false;
        this.taskIndex++;
        this.setIncrProgress();
        this.startTime = Date.now();
      }, wait);
    },
    
    loadScenario(scenarios) {

      this.scenarios = scenarios ?? [];
      scenarios?.states.forEach((s, i) => {
        if (Math.random() < 0.5) {
          this.positionA.push("l");
          this.statesA.push(s.stateA);
          this.statesB.push(s.stateB);
        } else {
          this.positionA.push("r");
          this.statesA.push(s.stateB);
          this.statesB.push(s.stateA);
        }
    });
      this.isLoading = false;
    },
    back() {
      if (this.taskIndex == 0) return;
      this.$store.commit("enableGoNext");
      this.taskIndex--;
    },
    setIncrProgress() {
      let progress =
        (this.$store.state.navigation.currentPage +
          this.taskIndex / (this.scenarios.states.length - 1)) /
        (this.$store.state.navigation.agenda.length - 1);
      this.$store.commit("setCustomPercentage", progress * 100);
    },
  },
  computed: {
    content() {
      return this.$store.state?.survey?.pages?.dce ?? {};
    },
    ds() {
      return this.$store.state?.survey?.ds ?? [];
    },
    dimLabels() {
      return this.$store.state?.survey?.ds?.map((d) => d.name) ?? [];
    },
    stateALabels() {
      if (this.statesA.length == 0) return [];
      return this.ds?.map((d, i) => {
        let lvl = d.levels.find((l) => l.id == this.statesA[this.taskIndex][i])
        return lvl.dceLabel  ?? lvl.label;
      });
    },
    stateBLabels() {
      if (this.statesA.length == 0) return [];
      return this.ds?.map((d, i) => {
        let lvl = d.levels.find((l) => l.id == this.statesB[this.taskIndex][i])
        return lvl.dceLabel  ?? lvl.label;
      });
    },
    overlapClass() {
      return this.stateALabels.map((l, i) => {
        if (l == this.stateBLabels[i])
          return this.content.overlapClass;
        else 
          return null;
      });
    },
    overlapStyle() {
      return this.stateALabels.map((l, i) => {
        if (l == this.stateBLabels[i])
          return this.content.overlapStyle;
        else 
          return null;
      });
    },

    canGoNext() {
      if (this.taskIndex > 0) this.$store.commit("enableGoBack");
      else this.$store.commit("disableGoBack");

      // if (this.selected[this.taskIndex] != undefined) {
      //   this.$store.commit("enableGoNext");
      //   return true;
      // } else {
      //   this.$store.commit("disableGoNext");
      //   return false;
      // }
    },
  },
  beforeUnmount() {
    this.subscribe();
    this.$store.commit("setCustom", {
        property: "dce",
        value: this.results,
      });
    this.$store.commit("disableGoBack");
    this.$store.commit("setCustomPercentage", false);
  },
};
</script>

<style lang="scss">

.btn-outline-primary:hover {
  transform: scale(1.1);
}

#overlayA .spinner-border,
#overlayB .spinner-border {
  height: 7rem;
  width: 7rem;
  border: 0.5em solid currentColor;
  border-right-color: transparent;
  pointer-events: none;
}

.bg-dce-overlay {
  background-color:rgba(245, 246, 247, 0.8);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-dce-overlay {
    background-color: rgba(245, 246, 247, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

</style>
