<template>
  <div class="footer">
    <div class="w-100 d-flex justify-content-evenly flex-wrap text-muted small fw-lighter" style="max-width:400px;">
      
      
      <span class=" valorem-logo" @click="visitHp">
        <!-- Survey powered by  -->
        <span class="fw-normal">
          <span class="logo-1">
            <span class="text-decoration-underline">v</span>alorem</span><span class="logo-2">.health</span>
        </span> 
      </span> 
      <span class="">
        &copy; 2023 Paul Schneider
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterMinimal",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    visitHp() {
      window.open("https://valorem.health", "_blank");
    },
  },
};
</script>

<style scoped>
.footer {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 8px 12px;
}
.accent {
  
}
.logo-1 {
  color: var(--accent);
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  font-size: 1.2em;
}
.logo-2 {
  font-family: 'Inter', sans-serif;
  font-weight: light;
}
.valorem-logo {
  cursor: pointer;
}
</style>
