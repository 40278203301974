<template>
 <div class="details">
  <details>
   <summary 
   class="text-start text-decoration-underline"
   @click="log"
   v-html="label"
   >
   </summary>
   <div class="px-3 border-start py-2">
    <slot> </slot>
   </div>
  </details>
 </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    label: {
      type: String,
      default: "Show more information",
    },
  },
  data() {
    return {
      exanded: false,
    };
  },
  methods: {
    log() {
      if(this.exanded) {
        this.exanded = false;
        return;
      };
      this.exanded = true; 
      let page = this.$store.getters["getCurrentPage"];
      this.$store.commit("pushCustom", {
        property: "detailsExpanded",
        value: page,
      });
    },
  },
}
</script>

<style scoped>
.details {
  margin: 0.5rem 0;
  max-width: 60ch;
}

</style>

