<template>
  <table class="pit-state-table pod-table" >
    <colgroup>
          <col span="1" style="width: 30%" v-if="dimLabels.length"/>
          <col span="1" style="width: 5%" v-if="dimLabels.length"/>
          <col span="1" style="width: 65%" />
        </colgroup>
    <tr>
      <th v-if="dimLabels.length" />
      <th v-if="dimLabels.length" />
      <th scope="col" class="scenario-top scenario-cell" v-html="scenarioTitle">
      </th>
    </tr>

    <tr v-for="(label, index) in labels" :key="index">
      <th v-if="dimLabels.length" scope="row">
        {{dimLabels[index]}} 
        </th >
          <td v-if="dimLabels.length" />
      <td
        scope="row"
        class="scenario-cell"
        :class="{
          'scenario-middle': index != labels.length - 1,
          'scenario-bottom-last': index == labels.length - 1,
        }"
        v-html="label"
      >
        
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "scenarioTable",
  components: {},
  props: {
    dimLabels: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      required: true,
    },
    scenarioTitle: {
      type: String,
      default: "Scenario A",
    },
  },
};
</script>

<style >

</style>