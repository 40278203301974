export default {
  state: {
    userId: null,
    pseudonym: null,
    ds: [],
    ds_inactive: [],
    ownState: {
      state: [],
      overallHealthLevel: null,
    },
    healthStatesChoices: [],
    healthStatesChoicesWithDeath: [],
    positionOfPits: 0,
    positionOfPitsTouched: false,
    startTime: null,
    taskTimeTracked: [],
    timeOfLastTaskChange: null,
    demographicQuestions: [],
    otherQuestions: {},
    consentForm: [],
    lvlRatingOrder: [],
    dimWeightingOrder: [],
    dimRankingCompleted: false,
  },
  mutations: {
    setLifeAndDeathContinuedTouched(state, payload) {
      state.positionOfPitsTouched = payload;
    },

    loadDsDimensions(state, dimensions) {
      state.ds = dimensions;
    },

    setDimRankingCompleted(state, payload) {
      state.dimRankingCompleted = payload;
    },

    setCustom(state, payload) {
      state[payload.property] = payload.value;
    },
    pushCustom(state, payload) {
      if (state[payload.property] === undefined)
        state[payload.property] = [];
      state[payload.property].push(payload.value);
    },

    setLevelRatingOrder(state, payload) {
      if (payload.randomise) {
        var order = Array.from(Array(state.ds.length).keys());
        for (let i = order.length - 1; i >= 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [order[i], order[j]] = [order[j], order[i]];
        }
        state.lvlRatingOrder = order;
      } else {
        state.lvlRatingOrder = Array.from(Array(state.ds.length).keys());
      }
    },
    setDimWeightingOrder(state, payload) {
      if (payload.randomise) {
        let randomOrder = Array.from(Array(state.ds.length).keys()).splice(1).sort(e => Math.random() - 0.5);
        let rollingIndex = 0;
        state.ds.forEach((dim, i) => {
          if (dim.rank === 0) return;
          dim.rank = randomOrder[rollingIndex];
          rollingIndex++;
        });
        state.dimWeightingOrder = randomOrder;
      }
    },

    updateUserId(state, userId) {
      state.userId = userId;
    },
    generatePseudonym(state) {
      if (state.pseudonym === null) {
        state.pseudonym = Math.random().toString(36).slice(2);
      }
    },
    replaceUserIdWithPseudonym(state) {
      if (state.pseudonym === null) {
        state.pseudonym = Math.random().toString(36).slice(2);
      }
      state.userId = state.pseudonym;
    },
    updateDimensionWeight(state, payload) {
      state.ds.find(
        (dimension) => dimension.id == payload.dimension.id
      ).swingWeight = payload.weight;
    },
    updateDimensionZeroWeight(state, payload) {
      state.ds.find(
        (dimension) => dimension.id == payload.dimension.id
      ).zeroWeight = payload.zeroWeight;
    },

    updateDimensionLevelRating(state, payload) {
      state.ds
        .find((dimension) => dimension.id == payload.dimension.id)
        .levelRating.find((level) => level.id == payload.level.id).rating =
        payload.rating;
    },
    setLevelRating(state, payload) {
      state.ds
        .find((dimension) => dimension.id == payload.dimId)
        .levelRating.find((level) => level.id == payload.lvlId).rating =
        payload.rating;
    },

    updateDimensionRank(state, payload) {
      state.ds.find((dimension) => dimension.id == payload.dimension.id).rank =
        payload.rank;
      if (payload.rank == 0) {
        state.ds.find(
          (dimension) => dimension.id == payload.dimension.id
        ).isTheHighestPriority = true;
      } else {
        state.ds.find(
          (dimension) => dimension.id == payload.dimension.id
        ).isTheHighestPriority = false;
      }
    },

    updateDimensionPriority(state, payload) {
      // loop through all dimensions
      for (let i = 0; i < state.ds.length; i++) {
        // if the dimension is the one with the given id
        if (state.ds[i].id == payload.id) {
          state.ds[i].isTheHighestPriority = true;
          state.ds[i].rank = 0;
        } else {
          state.ds[i].isTheHighestPriority = false;
          state.ds[i].rank = 1;
        }
      }
    },
    filterDimensionSelectionResults(state, payload) {
      state.ds_inactive = state.ds;
      state.ds = state.ds.filter((dimension) => payload.includes(dimension.id));
    },
    restoreDimensionSelectionResults(state) {
      let dimsInCurrentDs = state.ds.map((dimension) => dimension.id);
      state.ds_inactive.forEach((dim) => {
        if (!dimsInCurrentDs.includes(dim.id)) {
          state.ds.push(dim);
        }
      }),
        (state.ds_inactive = []);
    },
    updateOverallHealthStatusLevel(state, level) {
      state.ownState.overallHealthLevel = level;
    },
    updatePositionOfPits(state, level) {
      state.positionOfPits = Math.round(level);
    },
    makeHealthStateChoice(state, payload) {
      const choice = {
        choiceSet: payload.choiceSet,
        choice: payload.choice,
        percentile: payload.percentile,
        delta: payload.delta,
      };
      state.healthStatesChoices.push(choice);
    },
    clearHealthStateChoices(state) {
      state.healthStatesChoices = [];
    },

    makePodChoice(state, payload) {
      const choice = {
        hs: payload.hs,
        hsLvlIds: payload.hsLvlIds,
        choice: payload.choice,
        hsPercentile: payload.hsPercentile,
        hsScaledUtility: payload.hsScaledUtility,
      };
      state.healthStatesChoicesWithDeath.push(choice);
    },

    clearPodChoice(state) {
      state.healthStatesChoicesWithDeath = [];
    },
    updateConsentFormQuestions(state, payload) {
      state.consentForm = payload;
    },
    updateOtherQuestions(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.otherQuestions[key] = payload[key];
      });
    },
    updateDemographicQuestions(state, payload) {
      state.demographicQuestions = payload;
    },
    updateScreener(state, payload) {
      state.screener = payload;
    },
    setTtoResults(state, payload) {
      state.ttoResults = payload;
    },

    updateDemographicQuestion(state, payload) {
      for (let i = 0; i < state.demographicQuestions.length; i++) {
        if (state.demographicQuestions[i].label == payload.label) {
          state.demographicQuestions[i].value = payload.value;
          return;
        }
      }
      state.demographicQuestions.push(payload);
    },
  },
  actions: {
    updateTime({ state }, page) {
      let now = Date.now();
      if (!state.startTime) {
        state.startTime = new Date(now)
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        state.timeOfLastTaskChange = now;
        return;
      }
      let timeElapsed = Math.floor((now - state.timeOfLastTaskChange) / 1000);
      let pageIndex = state.taskTimeTracked.findIndex((p) => p.page === page);

      if (pageIndex >= 0) {
        state.taskTimeTracked[pageIndex].time += timeElapsed;
        state.taskTimeTracked[pageIndex].visits += 1;
      } else {
        state.taskTimeTracked.push({
          page: page,
          time: timeElapsed,
          visits: 1,
        });
      }

      state.timeOfLastTaskChange = now;
    },
  },
};
