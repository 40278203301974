<template>
  <div class="home home-80 d-flex flex-column w-100">
    <CustomModal
      :title="'Quota full'"
      :visible="showQuotaFullModal"
      :blocking="true"
    >
      {{ this.$store.state.survey.general.quota.fullText }}
    </CustomModal>

    <div>
      <QuestionCard :content="content" :context="content.html2 ?? null" />
    </div>

    <div v-for="(item, key, index) in questionsByCategory" :key="key">
      <div
        class="card mx-3 mb-3"
        :key="key"
        :ref="'cardRef' + index"
        v-if="index <= taskIndex"
      >
        <div class="card-body mx-3 py-3">
          <h3 class="text-primary">{{ key }}</h3>
          <div>
            <div v-for="question in item" :key="question.id" class="py-4">
              <div v-if="checkCondition(question.condition)">
                <div v-if="question.context" class="pb-3">
                  <div v-html="question.context"></div>
                </div>
                <!-- question types -->
                <div class="fw-bold pb-1" v-html="question.label"></div>
                <div class="">
                  <div v-if="question.type == 'likert'">
                    <form class="mt-2 likert-form">
                      <ul class="likert">
                        <li v-for="option in question.options">
                          <input
                            type="radio"
                            name="likert"
                            :disabled="ptns[question.id]"
                            :value="option.value"
                            v-model="selected[question.id]"
                          />
                          <label>{{ option.text }}</label>
                        </li>
                      </ul>
                    </form>
                  </div>

                  <div v-if="question.type == 'select'">
                    <b-form-select
                      v-model="selected[question.id]"
                      :disabled="ptns[question.id]"
                    >
                      <option
                        v-for="option in question.options"
                        :key="option.value"
                        :value="option.value"
                        :disabled="option.disabled"
                      >
                        {{ option.text }}
                      </option>
                    </b-form-select>
                  </div>

                  <div v-if="question.type == 'radio'">
                    <b-form-radio-group
                      v-model="selected[question.id]"
                      :options="question.options"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-radio-group>
                  </div>

                  <div v-if="question.type == 'checkbox'">
                    <b-form-checkbox-group
                      :class="question.class ?? ''"
                      v-model="selected[question.id]"
                      :options="question.options"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-checkbox-group>
                  </div>

                  <div v-if="question.type == 'text'">
                    <b-form-input
                      v-model="selected[question.id]"
                      :debounce="500"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-input>
                  </div>

                  <div v-if="question.type == 'textarea'">
                    <b-form-textarea
                      v-model="selected[question.id]"
                      :debounce="500"
                      :disabled="ptns[question.id]"
                      rows="3"
                      :placeholder="question.placeholder ?? ''"
                    >
                    </b-form-textarea>
                  </div>

                  <div v-if="question.type == 'numeric'">
                    <b-form-input
                      v-model="selected[question.id]"
                      :disabled="ptns[question.id]"
                      class="w-50"
                      type="number"
                      :placeholder="question.placeholder ?? ''"
                    >
                    </b-form-input>
                  </div>

                  <div v-if="question.supportsPreferNotToSay" class="pt-1">
                    <b-form-checkbox v-model="ptns[question.id]">
                      {{
                        $store.state.survey?.pages?.screener.preferNotToSayLabel
                      }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GoNextBtn :promptGoNext="taskEnded" />
  </div>
</template>

<script>
import QuestionCard from "../components/QuestionCard.vue";
import GoNextBtn from "../components/GoNextBtn.vue";
import CustomModal from "../components/CustomModal.vue";
const axios = require("axios");

export default {
  name: "screener",
  components: {
    GoNextBtn,
    QuestionCard,
    CustomModal,
  },
  data() {
    return {
      selected: {},
      ptns: {},
      newTaskIndex: 0,
      taskEnded: false,
      subscribe: null,
      quotaOpen: true,
      showQuotaFullModal: false,
    };
  },
  mounted() {
    this.$store.state.results.screener
      ? (this.selected = this.$store.state.results.screener?.map(
          (q) => q.response
        ))
      : (this.selected = []);
    this.$store.commit("disableGoNext");

    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (action.type == "go" && action.payload == 1 && !this.quotaOpen) {
          this.$store.commit("enableInternalNav");
          this.$store.commit("setEditingInProgress", false);
          let link = this.$store.state.survey.general.quota.fullLink;

          if (this.$store.state.survey.general.quota.appendUserIdToFullLink) {
            link = link + this.$store.state.results.userId;
          }

          axios
            .post(this.$store.state.host + "/api/screen/screenout", {
              identifier: this.$route.params.surveyId,
            })
            .then((res) => console.log("screenout"))
            .catch((err) => console.log(err))
            .finally(() => {
              console.log("link");
              console.log(link);
              if (link) window.location.href = link;
              else this.showQuotaFullModal = true;
            });
        } else {
          axios
            .post(this.$store.state.host + "/api/screen/pass", {
              identifier: this.$route.params.surveyId,
            })
            .catch((err) => console.log(err));
        }
      }.bind(this),
      { prepend: true }
    );
  },
  beforeUnmount() {
    let payload = {};
    Object.keys(this.selected).forEach((key) => {
      if (key === "germanEducation") {
        payload["educationComputed"] = this.ptns[key]
          ? "preferNotToSay"
          : this.selected[key];
      }
      payload[key] = this.ptns[key] ? "preferNotToSay" : this.selected[key];
    });
    this.$store.commit("updateOtherQuestions", payload);
    this.subscribe();
  },
  methods: {
    computeGermanEducation(lvl) {
      let out;
      if (
        lvl === "grundschule" ||
        lvl === "hauptschule" ||
        lvl === "realschule"
      )
        out = "low";

      if (
        lvl === "lehreHauptschule" ||
        lvl === "lehreRealschule" ||
        lvl === "abitur" ||
        lvl === "fachabitur" ||
        lvl === "lehreAbitur"
      )
        out = "medium";

      if (
        lvl === "bachelorUni" ||
        lvl === "mscUni" ||
        lvl === "phdUni" ||
        lvl === "bachelorFh" ||
        lvl === "mscFh" ||
        lvl === "phdFh"
      )
        out = "high";

      if (!lvl) out = "na";
      return out;
    },
    next() {},
    checkCondition(conditionalId) {
      if (!conditionalId) return true;
      if (this.selected[conditionalId] === true) return true;
      if (this.selected[conditionalId] === "true") return true;
      if (this.selected[conditionalId] === "True") return true;
      if (this.selected[conditionalId] === "yes") return true;
      if (this.selected[conditionalId] === "Yes") return true;
      if (this.selected[conditionalId] === "1") return true;
      if (this.selected[conditionalId] === 1) return true;
      return false;
    },
  },
  computed: {
    content() {
      return this.$store.state?.survey?.pages?.screener;
    },
    questions() {
      return this.content?.questions ?? [];
    },
    questionsByCategory() {
      return this.questions.reduce((acc, question) => {
        if (!acc[question.category]) {
          acc[question.category] = [];
        }
        acc[question.category].push(question);
        return acc;
      }, {});
    },
    taskIndex() {
      let catCounter = 0;
      let completedCounter = 0;
      let totalNumberOfQuestions = 0;
      Object.keys(this.questionsByCategory).forEach((category) => {
        let numberOfQuestions = this.questionsByCategory[category].length;
        totalNumberOfQuestions += numberOfQuestions;
        let numberCompleted = 0;
        this.questionsByCategory[category].forEach((question) => {
          let qResponse = this.selected[question.id];
          if (
            qResponse !== undefined &&
            qResponse !== "" &&
            qResponse !== null
          ) {
            numberCompleted++;
            completedCounter++;
          } else if (this.ptns[question.id]) {
            numberCompleted++;
            completedCounter++;
          } else if (
            question.condition &&
            !this.checkCondition(question.condition)
          ) {
            numberCompleted++;
            completedCounter++;
          } else {
            return;
          }
        });
        if (numberCompleted === numberOfQuestions) {
          catCounter++;
        }
      });

      if (completedCounter === totalNumberOfQuestions) {
        if (this.$store.state.survey.general.quota.check) {
          let criteria = Object.keys(this.selected).map((key) => {
            let out = this.selected[key];
            if (key === "germanEducation")
              out = this.computeGermanEducation(out);
            return { variableId: key, value: out };
          });

          this.$store.commit("updateScreener", criteria);

          axios
            .post(this.$store.state.host + "/api/screen/check/", {
              identifier: this.$route.params.surveyId,
              criteria,
            })
            .then((res) => {
              if (res.data.quotaOpen) {
                // console.log("Quota open.");
                this.quotaOpen = true;
                this.$store.commit("enableGoNext");
                return true;
              } else {
                // console.log("Quota closes.");
                this.quotaOpen = false;
                this.$store.commit("enableGoNext");
                return true;
              }
            })
            .catch((err) => {
              // console.log("error in quota.");
              console.error(err);
              this.quotaOpen = true;
              this.$store.commit("enableGoNext");
              return true;
            });
        } else {
          // NO QUOTA CHECKING
          this.quotaOpen = true;
          this.$store.commit("enableGoNext");
        }

        this.taskEnded = true;
      } else {
        this.$store.commit("disableGoNext");
        this.taskEnded = false;
      }
      return catCounter;
    },
  },
  watch: {
    ptns: {
      handler() {
        Object.keys(this.ptns).forEach((key) => {
          if (this.ptns[key]) {
            this.questions.find((q) => q.id == key)?.type == "checkbox"
              ? (this.selected[key] = [])
              : (this.selected[key] = "");
          }
        });
      },
      deep: true,
    },
    taskIndex(val) {
      if (this.taskEnded) return;
      if (val > this.newTaskIndex) {
        this.newTaskIndex = val;
        setTimeout(() => {
          try {
            this.$refs["cardRef" + val][0].scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          } catch (e) {
            console.log(e);
          }
        }, 300);
      }
    },
  },
};
</script>

<style scoped>
form .likert {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  border-bottom: 2px solid #efefef;
}
form .likert:last-of-type {
  border-bottom: 0;
}
form .likert:before {
  content: "";
  position: relative;
  top: 12px;
  left: 9.5%;
  display: block;
  background-color: #e6e5e5;
  height: 6px;
  width: 78%;
}
form .likert li {
  display: inline-block;
  width: 19%;
  text-align: center;
  vertical-align: top;
}
form .likert li input[type="radio"] {
  display: block;
  position: relative;
  top: -0.2em;
  width: 100%;
  height: 1.5em;
}
form .likert li label {
  display: block;
  position: relative;
  top: -0.4em;
  padding: 0;
  padding-top: 0.5em;
  font-size: 0.9em;
  text-align: center;
}
.likert-form {
  margin-right: -1.5em;
  margin-left: -0.5em;
}
</style>
