<template>
  <div class="Error404 home home-80">
    <div class="mx-auto">
      <div class="display-6 text-dark">Something went wrong...</div>
      <div class="h1 display-1 text-start text-decoration-underline text-primary">
        <span class="fw-bold">404 </span> <span class="text-dark"> Page not found</span>
        </div>
        <div class="display-6 text-start mt-2 mb-5" >
          <span class="fst-italic text-primary fw-light">/{{this.$route.params.surveyId}}</span> is not a valid survey ID
        </div>
        <div class="text-center mb-5">
          <a href="/" class="btn btn-outline-dark btn-lg my-1">
              <span class="accent logo-1">
                <span class="text-decoration-underline">v</span>alorem</span
              >.<span class="fw-lighter">health</span>
            
          </a>
        </div>
</div>
  </div>
</template>


<script>
export default {
  name: "Error404",
  data() {
    return {
      x: 0,
    };
  },
  mounted() {
    this.$store.commit("setEditingInProgress", false)
  },
}
</script>

<style scoped>
.accent {
  color: var(--accent)
}
.logo-1 {
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  font-size: 1.1em;
}

.Error404 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-grow: 1;
  flex-direction: column;
}

</style>