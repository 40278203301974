

function ttoEqvt(
    ttoChoiceArray,
    fhCols = ["#69b761", "#1e9c3a"],
    hsCols = ["#1c9ae0", "#b4ddf3"]) {

    let fhHorizon = 10;       // total time horizon in FH
    let fhYears = 10;        // years in FH
    let taskEnded = false;  // algorithm ended?
    let wtd = false;        // is the evaluation for a worse thand dead state?

    ttoChoiceArray.forEach((choice, i) => {

        if (i === 0) {
            if (choice === 'B') {
                fhYears = 10.5;
                taskEnded = true;
            } else {
                fhYears = 0;
            }
        }

        if (i == 1) {
            if (choice === 'A') {
                wtd = true;
                fhYears = 5;
                fhHorizon = 20;
            } else {
                fhYears = 5;
            }
        }
        if (i >= 2) {
            if (ttoChoiceArray[2] === 'A') {
                let refVal = wtd ? 0 : 1;
                if (fhYears <= refVal) {
                    fhYears = choice === 'A' ? fhYears - 0.5 : fhYears + 0.5;
                    taskEnded = true;
                } else {
                    fhYears = choice === 'A' ? fhYears - 1 : fhYears + 0.5;
                    if (choice === 'B')
                        taskEnded = true;
                }
            } else {
                if (fhYears >= 9) {
                    fhYears = choice === 'A' ? fhYears - 0.5 : fhYears + 0.5;
                    taskEnded = true;
                } else {
                    fhYears = choice === 'A' ? fhYears - 0.5 : fhYears + 1;
                    if (choice === 'A')
                        taskEnded = true;
                }
            }
        }
    });


    function blockColoriser(colors, year, maxYear = 10, startFrom = []) {
        let years = startFrom;
        let colIndex = true;
        let colCounter = -1;
        for (let i = 0; i < maxYear * 4; i++) {
            if (i < year * 4) {
                colCounter++;
                if (colCounter == 4) {
                    colCounter = 0;
                    colIndex = !colIndex;
                }
                years.push(colors[colIndex ? 0 : 1]);
            } else {
                years.push("#fff");
            }
        }
        return years;
    }

    let fhYearCols = blockColoriser(fhCols, fhYears, fhHorizon);

    let hsYearCols = [];
    if (wtd) hsYearCols = blockColoriser(fhCols, 10, 10, hsYearCols);
    hsYearCols = blockColoriser(hsCols, 10, 10, hsYearCols)

    return ({
        hsYearCols,
        fhYearCols,
        fhYears,
        taskEnded,
        fhHorizon,
        wtd
    });
}

function eqvtUtilEval(fhYears, wtd){
    if (wtd) return utils = (fhYears - 10)/10;
    return utils = (fhYears)/10;
    }

module.exports = { ttoEqvt, eqvtUtilEval };
