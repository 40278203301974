<template>
  <div class="home home-60">
    <Description>
      <p>
        {{ $store.state.survey?.pages?.dimFirstRank?.problemCountPre }}
        {{ allWorstLevels.length }}
        {{ $store.state.survey?.pages?.dimFirstRank?.problemCountPost }}
      </p>
      <div v-html="$store.state.survey?.pages?.dimFirstRank?.html"></div>
    </Description>

    <div class="d-flex flex-column">
      <b-form-group
        label-class="fs-4 text-center mx-auto mb-4"
        class="mb-0 mt-1 d-flex flex-column justify-content-stretch"
        label=""
        v-slot="{ prioritizationLabel }"
      >
        <b-form-radio-group
          id="priority-dimension"
          :options="allWorstLevels"
          v-model="selected"
          @change="updateDimensionFirstRank"
          :aria-describedby="prioritizationLabel"
          class="flex-grow-1 d-flex flex-column "
          size="lg"
          button-variant="outline-primary first-rank-btn w-100 shadow bg-white"
          name="radio-btn"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <GoNextBtn :promptGoNext="selected != ''" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "DimFirstRank",
  components: {
    GoNextBtn,
    Description,
  },
  data() {
    return {
      levels: null,
      selected: "",
    };
  },

  methods: {
    updateDimensionFirstRank(event) {
      try {
        this.$store.commit("updateDimensionPriority", {
          id: event,
        });
        this.$store.commit("enableGoNext");
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight - window.innerHeight);
          // ALTERNATIVE: move automatically to next page
          // this.$store.dispatch("go",1);
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    allWorstLevels() {

      if (this.$store.state.survey.ds === undefined) {
        return [];
      }
      let LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let allWorstLevels = this.$store.state.survey.ds.map(
        (dimension, index) => {
          let worstLevel = dimension.levels.reduce((prev, curr) => {
            if (prev.rank > curr.rank) {
              return prev;
            } else {
              return curr;
            }
          });
          if (this.$store.state.survey?.pages?.dimFirstRank.appendLevelLabel) {
            var label =
              LETTERS[index] + ") <b>" + dimension.name + "</b>: " + worstLevel.label;
          } else {
            var label = LETTERS[index] + ") " + worstLevel.label;
          }
          return {
            html: label,
            value: dimension.id,
          };
        }
      );
      return allWorstLevels;
    },
  },
  mounted() {
    this.$store.state.results.ds.forEach((dimension) => {
      if (dimension.isTheHighestPriority) {
        this.selected = dimension.id;
      }
    });
  },
  watch: {
    selected:{
      handler() {
      if (this.selected === "") {
        this.$store.commit("disableGoNext");
      } else {
        this.$store.commit("enableGoNext");
      }
    },
    immediate: true,
    }
  },
  created() {},
};
</script>

<style lang="scss" scoped>
:deep {
  // make button animation flicker when selected
  @keyframes blink {
    50% {
      opacity: 0.75;
    }
  }
  .active {
    -webkit-animation: blink 0.3s ease-in 2 0.1s !important;
    animation: blink 0.3s ease-in 2 0.1s !important;
  }

  .form-check-inline {
    margin-right: 10px;
  }

.first-rank-btn {
  color: black;
  text-align: left;
}
.first-rank-btn:hover {
  color: var(--bs-primary);
}

.first-rank-btn:active, .first-rank-btn.active {
  color: var(--bs-primary);
  font-weight: bold;
}
  
  
}
</style>>

