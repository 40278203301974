<template>
  <div class="home home-120">
    <div
      class="
        d-flex
        align-items-start
        gap-3
        flex-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap
      "
    >
      <div class="mx-3 mt-3" style="flex-basis: 50%; flex-grow: 1">
        <Description>
          <div v-html="$store.state.survey?.pages?.levelRating.html" />
          <div v-if="$store.state.survey?.pages?.levelRating.appendLevelLabel">
            <div class="fw-bold mt-2"> {{ dimension.name }}:</div>
          </div>
          <ul>
            <li class="py-1">
              <transition name="fade" mode="out-in">
                <span
                  :key="taskIndex"
                  class="p-1 rounded-3 border me-1"
                  :style="{
                    backgroundColor: colorMaker(
                      dimension.hexColor,
                      0,
                      dimension.levels.length
                    ),
                    color: fontColorMaker(0, dimension.levels.length),
                  }"
                >
                  {{ bestLevel }}
                </span>
              </transition>
              <span v-html="$store.state.survey?.pages?.levelRating.descrBest" />
            </li>
            <li class="py-1">
              <transition name="fade" mode="out-in">
                <span
                  :key="taskIndex"
                  class="p-1 rounded-3 border me-1"
                  :style="{
                    backgroundColor: colorMaker(
                      dimension.hexColor,
                      dimension.levels.length - 1,
                      dimension.levels.length
                    ),
                    color: fontColorMaker(
                      dimension.levels.length - 1,
                      dimension.levels.length
                    ),
                  }"
                >
                  {{ worstLevel }}
                </span>
              </transition>
              <span v-html="$store.state.survey?.pages?.levelRating.descrWorst" />
            </li>
          </ul>
        </Description>

        <Card spacingClasses="mt-5 mx-0 px-4 py-3">
          <transition name="fade" mode="out-in">
            <div :key="taskIndex">
              

              <div class="d-flex flex-column">
                <div>
                  <div class="my-auto" style="flex-basis: 50%; flex-grow: 1">
                    <div
                      v-if="
                        !completed[dimRatingOrder[taskIndex]] != undefined &&
                        !completed[dimRatingOrder[taskIndex]]
                      "
                      class="pb-3 text-center"
                    >
                          <div class="mt-2 mb-4 fs-5 lh-1">
                            {{ $store.state.survey?.pages?.levelRating.question }}
                          </div>
                          
                      <b-overlay
                        :show="loadingNextLevel[dimRatingOrder[taskIndex]]"
                        variant="white"
                        opacity="0.85"
                        spinner-variant="primary"
                        blur="2px"
                        z-index="1"
                      >
                        <div class="fw-bold my-3">
                          <div
                            class="fs-5 mt-2 px-2 py-2 border rounded-3 border-secondary"
                            :style="{
                              'background-color':
                                colorMaker(
                                  dimension.hexColor,
                                  seqIndex[dimRatingOrder[taskIndex]] ,
                                  dimensions.length
                                ) + ' !important',
                              color:
                                fontColorMaker(
                                   seqIndex[dimRatingOrder[taskIndex]] ,
                                  dimensions.length
                                ) + ' !important',
                            }"
                          >
                            {{ nextLevelLabel[dimRatingOrder[taskIndex]] }}
                          </div>
                        </div>
                      </b-overlay>
                    </div>
                    <div class="py-4 text-center" v-else>
                      {{ $store.state.survey?.pages?.levelRating.allDone }}
                    </div>
                    <div
                      v-if="taskIndex == 0 && loadingNextLevel.length == 0"
                      class="text-secondary small"
                    >
                      {{ $store.state.survey?.pages?.levelRating.clickNote }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </Card>
      </div>

      <div style="flex-basis: 50%; flex-grow: 1">
        <transition-group :name="dimTransistion ?? 'slide-top-dim'">
          <Card
            :key="taskIndex"
            class="card d-flex flex-column align-items-center"
            spacingClasses="mt-2 mx-3 px-4 py-3"
          >
            <div class="fs-5 text-primary fw-bold w-100 ps-5 pb-3">
              {{ dimension.name }}
            </div>
            <LevelRatingSlider
              :dimension="dimension"
              :levelRatings="levelRatings[dimRatingOrder[taskIndex]]"
              :key="dimension.id"
              @promptGoNext="setPromptGoNext($event, dimRatingOrder[taskIndex])"
              @nextLevelLabel="setNextLevelLabel($event, dimRatingOrder[taskIndex])"
              @sliderCompleted="setSliderCompleted($event, dimRatingOrder[taskIndex])"
              @loading="setLoadingNextLevel($event, dimRatingOrder[taskIndex])"
              @seqIndex="setSeqIndex($event, dimRatingOrder[taskIndex])"
              :completed="dimensionsRated.includes(dimension.id)"
              :bestIndicator="
                $store.state.survey?.pages?.levelRating.bestIndicator ?? ''
              "
              :worstIndicator="
                $store.state.survey?.pages?.levelRating.worstIndicator ?? ''
              "
            />

            <div v-html="$store.state.survey?.pages?.levelRating.colorNote"></div>
          </Card>
        </transition-group>
      </div>
    </div>

    <GoNextBtn :promptGoNext="promptGoNext[dimRatingOrder[taskIndex]]" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import LevelRatingSlider from "@/components/LevelRatingSlider.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "LevelRating",
  components: {
    Description,
    Card,
    GoNextBtn,
    LevelRatingSlider,
  },
  data() {
    return {
      styleClass: "levelRatingSliderStyle",
      promptGoNext: [],
      refreshVals: 1,
      bgColor: [],
      fontColor: [],
      nextLevelLabel: [],
      completed: [],
      loadingNextLevel: [],
      seqIndex: [],
      dimensionsRated: [],
      taskIndex: 0,
      dimRatingOrder: [],
      subscribe: null,
      dimTransistion: "none",
    };
  },
  methods: {
    next() {
      if (this.taskIndex < this.dimensions.length - 1) {
        this.dimTransistion = "slide-bottom-dim";
        this.taskIndex++;
        this.$store.commit("setLvlRatingTaskIndex", this.taskIndex);
        setTimeout(() => {
          this.$store.commit("disableGoNext");
        }, 0);
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
    back() {
      if (this.taskIndex > 0) {
        this.dimTransistion = "slide-top-dim";
        this.taskIndex--;
        this.$store.commit("setLvlRatingTaskIndex", this.taskIndex);
        setTimeout(() => {
          this.$store.commit("enableGoNext");
        }, 0);
      } else {
        this.$store.commit("disableInternalNav");
      }
    },
    setNextLevelLabel(label, index) {
      this.nextLevelLabel[index] = label;
    },
    setSliderCompleted(value, index) {
      this.completed[index] = value;
    },
    setLoadingNextLevel(loading, index) {
      this.loadingNextLevel[index] = loading;
    },
    setSeqIndex(lvlIndex, dimIndex) {
      this.seqIndex[dimIndex] = lvlIndex;
    },
    updateDimensionLevelRating(rating, dimension, level) {
      this.refreshVals++;
      this.$store.commit("updateDimensionLevelRating", {
        dimension,
        level,
        rating,
      });
    },
    setPromptGoNext(val, index) {
      this.promptGoNext[index] = val;
    },
    setCol(index, len) {
      index = len - index;
      let val = 10 + 90 * (index / len);
      return val;
    },
    colorMaker(hexCol, index, len) {
      return "hsl(" + hexCol + ", 100%, " + this.setCol(index, len) + "%)";
    },
    fontColorMaker(index, len) {
      return this.setCol(index, len) > 50 ? "black" : "white";
    },
  },
  computed: {
    dimensions() {
      if (this.$store.state.survey.ds === undefined) return [];
      return this.$store.state.survey.ds;
    },
    dimension() {
      return this.dimensions[this.dimRatingOrder[this.taskIndex]];
    },
    bestLevel() {
      return this.dimension.levels.find((level) => level.rank == 0).label;
    },
    worstLevel() {
      return this.dimension.levels.find(
        (level) => level.rank == this.dimension.levels.length - 1
      ).label;
    },
    levelRatings() {
      if (this.$store.state.results.ds === undefined) return [];
      this.refreshVals;
      return this.$store.state.results.ds;
    },
    levelRatingTasksCompleted() {
      if (this.dimensionsRated.length >= this.dimensions.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    levelRatingTasksCompleted() {
      if (this.levelRatingTasksCompleted) {
        this.$store.commit("disableInternalNav");
        this.$store.dispatch("go", 1);
      }
    },
    taskIndex() {
      if (this.taskIndex > 0) {
        this.$store.commit("enableGoBack");
      }
    },
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
    if(this.$store.state.results.lvlRatingOrder.length > 0){
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    } else {
      if(this.$store.state.survey?.pages?.levelRating?.randomiseOrder){
        this.$store.commit("setLevelRatingOrder",{randomise: true});
      } else {
        this.$store.commit("setLevelRatingOrder",{randomise: false});
      }
      this.dimRatingOrder = this.$store.state.results.lvlRatingOrder;
    }
  },
  
  mounted() {
    this.taskIndex = this.$store.state.navigation.lvlRatingTaskIndex;
    if (!this.levelRatingTasksCompleted) {
      this.$store.commit("enableInternalNav");
      this.$store.commit("disableGoNext");
    }
  },
  unmounted() {
    this.subscribe();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
