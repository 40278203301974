<template>
  <div class="home home-100 w-100">
    
    <!--  Question -->
    <QuestionCard :content="content"/>
    

    <!-- info | task -->
    <div
      class="d-flex align-items-start flex-wrap flex-sm-nowrap justify-content-stretch"
    >
      <!-- Info -->
      <div
        class="card  mx-3 mb-3"
        style="flex-basis: 50%; flex-grow: 1"
      >
        <div class="card-body" v-if="content.info" v-html="content.info"></div>
      </div>

      <!-- task -->
      <div class="align-items-stretch">
        <div v-bind:key="index" v-for="(dimension, index) in dimensions">
          <div class="card mx-3 mb-3">
            <div class="card-header lh-sm bg-transparent">
              <div class="hq-title">
                {{ dimension.ownStateLabel ?? dimension.name }}
              </div>
              <div class="small text-secondary" v-if="dimension.ownStateDescription || dimension.description">
                {{ dimension.ownStateDescription ?? (dimension.description ?? null) }}
              </div>
            </div>

            <div class="card-body">
              <b-form-group style="margin-top: 5px">
                <b-form-radio-group
                  v-model="levels[index]"
                  :options="options[index]"
                  stacked
                  size="md"
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GoNextBtn :promptGoNext="taskCompleted" />
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import QuestionCard  from "@/components/QuestionCard.vue";
export default {
  name: "OwnState",
  components: {
    QuestionCard,
    GoNextBtn,
  },
  data() {
    return {
      levels: [],
    };
  },
  methods: {},
  mounted() {
    this.levels = this.$store.state.results.ownState.state;
    this.$store.commit("disableGoNext");
  },
  computed: {
    content() {
      return this.$store.state.survey?.pages?.ownState;
    },
    taskCompleted() {
      let taskIsCompleted = this.questionIndex == this.dimensions.length;
      if (taskIsCompleted) {
        this.$store.commit("enableGoNext");
      } else {
        this.$store.commit("disableGoNext");
      }
      return taskIsCompleted;
    },

    questionIndex() {
      return this.levels.reduce((acc, cur) => {
        if (cur == undefined) {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
    },
    dimensions() {
      return this.$store.state.survey.ds;
    },
    options() {
      return this.dimensions.map((dimension) => {
        return dimension.levels.map((level, index) => {
          return {
            // text: level.ownStateLabel ?? level.label,
            html: level.ownStateLabel ?? level.label,
            value: dimension.id + "_" + index,
          };
        });
      });
    },
  },
};
</script>

<style scoped></style>
