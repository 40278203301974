<template>
 <div :class="classes" :style="styling">
   <slot> </slot>
 </div>
</template>

<script>
export default {
  name: "Card",
  props: {
      spacingClasses: {
        type: String,
        default: "my-3 px-3 pt-4 pb-4",
      },
      additionalClasses: {
        type: String,
        default: " ",
      },
      style: null,
    },
    computed: {
        classes() {
        return "card shadow " + this.spacingClasses + " " + this.additionalClasses;
        },
        styling() {
        return this.style;
        },
    },
}
</script>

<style scoped>
.card {
  flex-grow: 1;
  flex-basis: 100%;
  /* margin: 50px 0;
    max-width: 600px;
    min-width: 300px;
 padding: 50px;
 background: white;
 border-radius: 20px;
 box-shadow: 0 10px 58px 0px rgb(0 0 0 / 3%); */
}
</style>
