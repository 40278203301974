<template>
  <div class="home home-100 w-100">
    
    <!--  Question -->
    <div class="card mb-3 mx-3" style="flex-basis: 100%">
      <div class="card-body">
        <div class="text-primary fw-light" v-html="content.questionLabel"></div>
            <div class="h4" v-html="content.question"/>
      </div>
    </div>

    <!-- info | task-1 -->        
        <div
          class="d-flex flew-column justify-content-stretch align-items-start flex-wrap flex-sm-nowrap"
        >

          <!-- Info -->
          <div
            class="card rounded-3 mx-3 mb-3"
            style="flex-basis: 50%; flex-grow: 1"
          >
            <div class="card-body">
              <div v-html="content.info"/>
              </div>
          </div>


          <!-- State -->
          <div
            class="mx-3 mb-3"
            style="flex-basis: 50%; flex-grow: 1"
          >
            <div class="">
              <State
                :stateId="'deadStage2'"
                :class="'mb-3'"
                :scenarioTitle="'Rate this scenario'"
                :labels="pitLabels"
                :textAlign="'center'"
                :btn="false"
                :appendLvlsWithDims="false"
              />
              </div>
            </div>
        </div>
        

        <!-- VAS -->
        <div
          style="
            flex-basis: 100%;
            flex-grow: 1;
          "
          class="mx-3 mx-sm-4 mb-2 mb-sm-3 mb-md-5"
        >
          <div class="bg-white shadow border border-secodary rounded-3">
            <div
              class="pt-3 px-3 pb-5 d-flex flex-column"
            >
              <div
                class="text-secondary ms-auto pe-5 pb-2 small"
                :style="{ visibility: resultsRevealed ? 'hidden' : 'visible' }"
              >
                {{ $store.state.survey?.pages?.anchorVas?.helpLabel }}
              </div>

              
              <AnchorVasSliderHorizontal
                @set="setValue"
                @update="updateValue"
                styleClass="dead-vas-slider"
                :config="anchorSliderConfig"
                :hiddenOnStart="true"
                :values="initVasValues"
                @movingHandle="movingHandleHandler"
                @handleMadeVisible="revealLine"
              />
            </div>
          </div>
        </div>

        <div class="align-self-end">
          <GoNextBtn :promptGoNext="promptGoNext" />
        </div>
      
    
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";
import ScenarioTable from "@/components/ScenarioTable.vue";
import State from "@/components/State.vue";
import AnchorVasSliderHorizontal from "@/components/AnchorVasSliderHorizontal.vue";
import LeaderLine from "leader-line-vue";

export default {
  name: "AnchorVas2",
  components: {
    ScenarioTable,
    State,
    Card,
    AnchorVasSliderHorizontal,
    LeaderLine,
    Description,
    GoNextBtn,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      initVasValues: [0, 100],
      hiddenOnStart: true,
      vasValue: null,
      choice: null,
      anchorStateAtBottom: null,

      promptGoNext: false,
      resultsRevealed: false,

      bottomLine: null,
      movingLine: null,

      anchorSliderConfig: {
        start: [50, 70],
        connect: false,
        pips: {
          mode: "positions",
          values: [0 ,12.5,  25  ,37.5,  50,  62.5,  75,  87.5, 100],
          density: 2,
          format: {
            to: function (value) {
              let deadDiv = `<div style="margin-right: auto; margin-top: 0px; font-size: 0.8rem;" class="badge bg-dark" id="dead-badge">0<br>${'Dead'}</div>`;
              return value == 0 ? deadDiv : value;
            },
          },
        },
        direction: "ltr",
        orientation: "horizontal",
        behaviour: "unconstrained-tap",
        range: { min: [-100], max: [100] },
        animate: true,
        animationDuration: 100,
      },
      bottomListener: false,
      movingListener: false,
    };
  },

  
  beforeMount() {
    if (this.$store.state.results?.AnchorVas?.vas !== undefined) {
      this.initVasValues = [0, this.$store.state.results.AnchorVas.vas];
      this.hiddenOnStart = false;
    } else {
      this.$store.commit("disableGoNext");
    }
    // if (this.$store.state.results.AnchorVas)
    //   this.$store.commit("setCustom", { property: "AnchorVas", value: null });

  },
  methods: {
    movingHandleHandler(target) {
      let lineEnd = target;
      let lineStart = this.anchorStateAtBottom
        ? "#state-pitStage2"
        : "#state-deadStage2";
      let lineStartEl = document.querySelector(lineStart);
      let lineColor = "transparent";
      this.movingLine = LeaderLine.setLine(lineStartEl, lineEnd, {
        color: lineColor,
        endPlugSize: 1,
        startSocket: "bottom",
        endSocket: "top",
      });
      this.movingListener = true;
      window.addEventListener("resize", this.updateMovingLine);
      if (!this.hiddenOnStart) setTimeout(() => {
        if (this.movingLine) this.movingLine.position()
      }, 500);
    },
    
    revealLine() {
      this.$store.commit("enableGoNext");
      this.promptGoNext = this.resultsRevealed = true;
      if (this.movingLine) this.movingLine.setOptions({ color: "black" });
    },
    updateValue(value) {
      this.vasValue = value;
      this.updateMovingLine();
    },
    setValue(value) {
      this.vasValue = value;
      setTimeout(() => this.updateMovingLine(), 100);
      setTimeout(() => this.updateMovingLine(), 250);
      setTimeout(() => this.updateMovingLine(), 350);
    },
    updateMovingLine() {
      if (this.movingLine) this.movingLine.position();
    },
    updateBottomLine() {
      if (this.bottomLine) this.bottomLine.position();
    },
  },
  computed: {
    ds() {
      return this.$store.state.results.ds;
    },
    pit() {
      return this.ds.map((dim) => {
        return dim.levelRating.reduce((a, b) => {
          return a.rank > b.rank ? a : b;
        });
      });
    },
    
    pitLabels() {
      return this.pit.map((dim) => {
        return dim.label;
      });
    },
    content() {
      return this.$store.state.survey?.pages?.anchorVas ?? {};
    },
    appendLvlsWithDims() {
      return this.$store.state.survey?.pages?.anchorVas?.appendLvlsWithDims;
    },
  },
  beforeUnmount() {

      let vas = Number(this.vasValue) 
      let val = vas / 100;
        // let pitValue = val >0 ? val : 1 - 1 / (1 - val);
        this.$store.commit("setCustom", {
          property: "AnchorVas",
          value: {
            vas: vas,
            pitStateUtility: val,
          },
        });

    if (this.movingListener)
      window.removeEventListener("resize", this.updateMovingLine);
    if (this.movingLine != null) this.movingLine.remove();
  },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>
/* transistion fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.leader-line {
  color: red;
  z-index: 1;
}

/* style used from POD */
#dead-vas-slider {
  cursor: crosshair;
}


::v-deep .dead-vas-slider {
  
    .noUi-base,
  .noUi-connects {
    position: static;
  }
    .noUi-connect {
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 0.5em !important;
    width: 12px !important;
    margin-bottom: 0.6em;
    right: -5px;
    height: 30px !important;
    border: 3px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
    .noUi-pips-horizontal {
    padding: 0;
    margin: 0;
    margin-top: -29px;
    padding-top: 10px;
    pointer-events: none;
  }
  .noUi-value {
    margin-top: 15px;
    color: #0d213a !important;
  }
    .noUi-marker-horizontal.noUi-marker.noUi-marker-large {
    height: 30px !important;
    top: 3px;
  }
  .noUi-marker-horizontal.noUi-marker {
    height: 15px !important;
    right: 15px;
  }
}


//   .noUi-marker-horizontal.noUi-marker.noUi-marker-large {
//     width: 40px !important;
//     left: -1px;
//     right: 0px;
//   }
//   .noUi-marker-horizontal.noUi-marker {
//     width: 20px !important;
//   }
//   .noUi-tooltip {
//     font-weight: bold;
//     border: none !important;
//     background: none !important;
//     z-index: 2 !important;
//     color: var(--bs-primary) !important;
//     text-shadow: white 0px 0px 0px !important;
//     top: 10px;
//   }
// }

.btn-select {
  width: 50%;
  font-size: 1.25em;
  padding: 0.2em 0.5em;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  white-space: nowrap;
}
.btn-select:hover {
  color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
  background-color: var(--bs-primary) !important;
}
</style>
