<template>
  <div class="home home-80">
    <div
      class="d-flex flex-column align-items-center justify-content-center pt-5 my-auto"
    >
      <div
        class="w-100 text-center"
        v-html="$store.state.survey?.pages?.thankYou.html"
      ></div>

      <div
        v-if="
          $store.state.survey.general.sendAwayLink ||
          $store.state.survey.general.completionCode
        "
        class="card px-5 py-3 text-center m-2 mb-5"
      >
        <div v-if="$store.state.survey.general.completionCodeText" class="mb-3">
          <div class="fw-bold mb-1">
            {{ $store.state.survey.general.completionCodeText }}
          </div>
          <span class="fs-5">
            {{ $store.state.survey.general.completionCode }}

            <div
              style="display: inline-block; cursor: pointer"
              :title="CopytoClipboardText"
            >
              <svg
                v-if="!copyIndicator"
                @click="copyToClipboard"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-clipboard"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                />
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </div>
          </span>
        </div>

        <div
          v-if="$store.state.survey.general.sendAwayLink"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-button
            :href="sendAwayLink"
            target="_blank"
            class="btn-lg my-2"
            variant="primary"
          >
            {{ $store.state.survey.general.sendAwayLinkText }}
          </b-button>
        </div>
      </div>

      <!-- Amazon Reward -->
      <div
        v-if="$store.state.survey.general.rewardOnComplete"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <div class="h3 text-center">
          Als Dankeschön, gibt es diesen Amazon.de Gutschein* für Sie:
        </div>

        <div
          class="card py-3 px-4 my-3 d-flex flex-column align-items-center gap-2"
          style="max-width: 450px"
        >
          <div class="fw-bold mt-2">Betrag des Gutscheins:</div>
          <div class="fs-3 fw-bold">{{ $store.state.survey.reward.value }}</div>
          <div class="small text-secondary">
            ABLAUFDATUM: {{ $store.state.survey.reward.expDate }}
          </div>
          <div class="reward mt-3">
            <img :src="require(`@/assets/img/amazonCard.png`)" alt="" />
          </div>
          <div class="border px-2 py-1 my-3 fw-bold">
            Einlösecode: {{ $store.state.survey.reward.code }}
          </div>
          <b-button
            variant="primary"
            class="mb-3"
            :href="$store.state.survey.reward.link"
            target="_blank"
          >
            Jetzt auf Ihr Amazon-Konto anwenden
          </b-button>

          <span class="small text-secondary lh-1">
            *Es gelten Einschränkungen. Die vollständigen Geschäftsbedingungen
            finden Sie auf:
            <a href="amazon.de/gc-legal" target="_blank">amazon.de/gc-legal</a>.
          </span>
        </div>
      </div>

      <div class="card card-body px-4" style="max-width: 800px">
        <div class="fs-5">
          <div
            style="max-width: 40ch"
            class="text-center"
            v-html="$store.state.survey?.pages?.thankYou.contactMsg"
          ></div>
          <div class="mb-3 text-center">
            <div class="fw-bold mt-3">
              {{ $store.state.survey?.general?.authorName }}
            </div>
            {{ $store.state.survey?.general?.authorAffiliation }}<br />
            <a target="_blank" rel="noopener noreferrer" :href="href">
              {{ $store.state.survey?.general?.authorEmail }}
            </a>
          </div>
        </div>
      </div>

      <b-button
        v-if="$store.state.survey?.pages?.thankYou.allowResDownload"
        class="mt-5"
        variant="primary"
        @click="downloadRawResponses"
      >
        Download raw results as JSON
      </b-button>
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";

const axios = require("axios");

export default {
  name: "ThankYou",
  components: {
    HomeHeader,
    Description,
    Card,
  },
  data() {
    return {
      sendAwayUserId: null,
      reward: null,
      CopytoClipboardText: "Copy to clipboard",
      copyIndicator: false,
    };
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.event("page_view", {
        page_title: this.$route.params.surveyId + " - Thank you",
        page_location: "ended",
      });
    }

    if (this.$store.state.survey.general.sendAwayLink) {
      this.sendAwayUserId = this.$store.state.results.userId;
    }

    if (this.$store.state.survey.general.rewardOnComplete) {
      this.rewardUser();
    }

    if (this.$store.state.survey.general.invalidateIdOnComplete) {
      this.egress();
    }

    if (this.$store.state.survey.general.quota.update) {
      console.log("updating quota");
      this.updateQuota();
    }

    this.save();

    this.$store.commit("disableGoBack");
    this.$store.commit("disableGoNext");
    this.$store.commit("setEditingInProgress", false);
  },

  methods: {
    downloadRawResponses() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.$store.state.results));
      const dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "OPUF_raw.json");
      dlAnchorElem.click();
    },

    rewardUser() {
      this.reward = this.$store.state.survey.reward;
    },

    egress() {
      // console.log(this.$store.state.results.userId);
      axios
        .post(this.$store.state.host + "/api/survey/egress/" + this.surveyId, {
          userId: this.$store.state.results.userId,
        })
        .then(function (response) {
          // console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateQuota() {
      console.log("payload", this.$store.state.results.screener);
      axios
        .post(this.$store.state.host + "/api/screen/update/", {
          identifier: this.surveyId,
          update: this.$store.state.results.screener,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    copyToClipboard() {
      this.CopytoClipboardText = "Copied!";
      this.copyIndicator = true;
      setTimeout(() => {
        this.CopytoClipboardText = "Copy to clipboard";
        this.copyIndicator = false;
      }, 2000);
      navigator.clipboard.writeText(
        this.$store.state.survey.general.completionCode
      );

      // dummy.select();
      // document.execCommand("copy");
      // document.body.removeChild(dummy);
    },

    save() {
      if (this.$store.state.survey.general.usePseudoId) {
        this.$store.commit("replaceUserIdWithPseudonym", this.surveyId);
      }

      let store = this.$store.state;
      let results = store.results;
      let sendAwayUserId = this.sendAwayUserId;

      axios
        .post(
          store.host + "/api/survey/results/" + this.surveyId,
          results
        )
        .then(function (response) {

          if (process.env.NODE_ENV === "development") {
            console.log(response);
            console.log(results);
          }

          if (store.survey.general.autoSendAway) {
              let str = store.survey.general.sendAwayLink;
              if (store.survey.general.appendUserIdToSendAwayLink) {
                str = str + sendAwayUserId;
              }
              window.location.href = str;
            }

        })
        .catch(function (error) {
          console.error(error);
        })
    },
  },
  computed: {
    href() {
      return `mailto: ${this.$store.state.survey?.general?.authorEmail}`;
    },
    sendAwayLink() {
      let str = this.$store.state.survey.general.sendAwayLink;
      if (this.$store.state.survey.general.appendUserIdToSendAwayLink)
        str = str + this.sendAwayUserId;
      return str;
    },
    surveyId() {
      return this.$route.params.surveyId;
    },
  },
};
</script>

<style>
.reward img {
  max-width: 100%;
  height: auto;
  overflow: hidden;
}
</style>
