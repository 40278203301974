<template>
    <div style="
width: 100vw;
background-color: #fff;

" class="" >
<div>
    <div class="position-fixed top-0 start-0 navbar-header end-0" style="z-index: 100; max-width: 100vw;
      ">
      <div class="">
        <nav class="nav">

          <div class="d-flex flex-wrap flex-md-nowrap white-space-nowrap container-md
          align-items-end gap-2 gap-md-4 gap-lg-5 justify-content-center justify-content-md-start"
            style="">
            <a role="button" href="/#" 
            id="nav-home"
            >
              <img src="img/vh-logo-text.svg" loading="lazy" alt=""
                style="height: 45px; ">
            </a>
            <router-link to="/">
             Go back
            </router-link>
            




          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="mw-80 container fs-5 py-5" style="max-width: 80ch; padding-top: 100px !important;">
        <p class="c14 title" id="h.jddqvx12nwzl">
          <span class="display-6 source">Valorem health privacy policy</span>
        </p>
        <p class="c4">
          <span class="c8">Version: </span><span class="c8">2024-01-15</span>
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >Valorem health (&quot;Valorem,&quot; &quot;we,&quot;
            &quot;us,&quot; or &quot;our&quot;) is a provider of an online
            survey platform and services for capturing and analysing patient
            experience data, including patient preferences and patient-reported
            outcomes.
          </span>
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span
            >Collecting and processing personal data is at the core of what we
            do. </span
          ><span class="c0"
            >We recognise the importance of respecting and protecting your
            privacy, and we are committed to maintaining transparency regarding
            how we collect, use, and share your personal information.</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span
            >This Privacy Policy outlines the information we collect, how we use
            it, and your rights in relation to your personal data. </span
          ><span
            >If you participate in a survey, a separate Privacy Policy or
            consent form provided by the entity conducting the survey may also
            apply to the collection and use of your personal information. Please
            review those documents carefully as well.</span
          >
        </p>
        <div class="c2" id="h.uxy6fg3jnhim">1 Who we are</div>
        <p class="c4">
          <span class="c0"
            >Valorem health (&quot;Valorem,&quot; &quot;we,&quot;
            &quot;us,&quot; or &quot;our&quot;) is a German limited liability
            company based at Gerwerkenstr 8, 44805 Bochum, Germany. Email:
            contact@valorem.health.
          </span>
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >Our Data Protection Officer is Tommi Tervonen, contact:
            tervonen@valorem.health.</span
          >
        </p>
        <div class="c2" id="h.3j5i11jnlnx4">
          2 Personal Data we collect and the sources
        </div>
        <p class="c4">
          <span class="c0"
            >We group individuals or businesses into the following three
            categories:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_mehhcw8ug3ny-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Visitor</span
            ><span class="c0"
              >: you are just visiting our website, or review a survey as a
              guest</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Respondent</span
            ><span class="c0"
              >: you engage in a survey, form, questionnaire, or app powered by
              Valorem</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Customer:</span
            ><span
              >&nbsp;you subscribe to our platform or contract us to provide
              services.</span
            >
          </li>
        </ul>
        <p class="c1"><span class="c9"></span></p>
        <p class="c4">
          <span
            >We collect different types of personal data depending on whether
            you are a Visitor, Respondent, or Customer.</span
          >
        </p>
        <h2 class="c6" id="h.8mpbqywbdep9">
          2.1 Personal Data from Visitors
        </h2>
        <p class="c4">
          <span class="c0"
            >When you visit our website, we may collect data on your browser
            type, referring website, and device information. We may also collect
            information about the pages you visit on our website, the time you
            spend on each page, and the links you click on.
          </span>
        </p>
        <h2 class="c6" id="h.3jiin3wftgdr">
          <span class="c7">2.2 Personal Data from survey Respondents</span>
        </h2>
        <p class="c4">
          <span class="c0"
            >When you participate in a survey, complete a form or questionnaire,
            or use an app powered by Valorem, we will provide you with a
            separate privacy notice explaining what information we collect from
            you.
          </span>
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >Generally, surveys may collect your contact &nbsp;information
            (email address, unique study identification number, etc),
            demographic data, preferences, opinions, and feedback. It may also
            collect sensitive information, as defined by Article 9(2) of the
            GDPR, including personal data revealing racial or ethnic origin,
            political opinions, religious or philosophical beliefs;
            health-related data; and data concerning a person&rsquo;s sex life
            or sexual orientation.
          </span>
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >In addition to the data that you provide explicitly when completing
            a survey, we may collect technical information about the device you
            use to access the survey (operating system, browser, screen size,
            device set language, manufacturer, type of connection), and record
            event data and timestamps (how long you take to complete each survey
            question, options you click on).</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >If a survey collects any personal data, you will be asked to
            provide informed consent. Participation in our surveys is voluntary
            and you will have the option to not participate or to withdraw your
            consent.</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >In addition to the information we collect directly from you, we may
            also receive personal data about you from third parties, such
            as:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_bpbj68ng1ch3-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Study sponsors, researchers: If you participate in a survey or
              study conducted by a third party, they may provide us with your
              contact information (email address, unique study identification
              number, etc.) and demographic data.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Panel, lead generation partners: If you sign up for a survey or
              study through a panel or &nbsp;lead generation partner, they may
              provide us with your contact information.</span
            >
          </li>
        </ul>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span
            >We will only use personal data that we receive from third parties
            for the purposes for which it was originally collected, or for a
            compatible purpose that is clearly identified and disclosed to
            you.</span
          >
        </p>
        <h2 class="c6" id="h.5hfzasj4wfri">
          <span class="c7">2.3 Data We Collect from Customers</span>
        </h2>
        <p class="c4">
          <span class="c0"
            >When you subscribe to our platform or contract us to provide
            services for you, we collect certain information about you, such
            as:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_q8bothjglzos-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Contact information, such as your name, email address, phone
              number, and mailing address</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Business information, such as your company name, address, and
              industry</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Payment information, if you subscribe to our platform or contract
              us to provide services</span
            >
          </li>
        </ul>
        <p class="c1"><span class="c0"></span></p>
        <h2 class="c6" id="h.4ebc0xy6t0bq">
          <span class="c7">2.4 Additional Personal Data we collect</span>
        </h2>
        <p class="c4">
          <span class="c0"
            >If you contact us directly, such as by sending us an email or phone
            call, we may collect additional personal data about you, such as
            your name and email address, we will only collect this additional
            personal data, if it is necessary to address your inquiry or
            request. We will not use this additional personal data for any other
            purposes.</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span
            >We may also collect personal data from you if you attend a webinar
            or event hosted by Valorem. For example, we may collect your name,
            email address, and company information.</span
          >
        </p>
        <div class="c2" id="h.92ugdqh04dpb">
          3. How We Use Your Personal Data
        </div>
        <p class="c4">
          <span class="c0"
            >We use your personal data for the following purposes:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_wmsza5q856n4-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >To provide our products and services to you. This includes
              processing your surveys, forms, and questionnaires, as well as
              providing you with access to our platform and services.</span
            >
          </li>
        </ul>
        <ul class="c3 lst-kix_iu0v3pv88kkc-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >To improve our products and services. We use your data to analyse
              how our products and services are being used, to identify areas
              for improvement, and to develop new products and services.</span
            >
          </li>
        </ul>
        <ul class="c3 lst-kix_lby4gih5zy50-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >To communicate with you. We may use your data to send you email
              updates about our products and services, to invite you to webinars
              or events, and to respond to your inquiries or requests.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >To comply with legal obligations. We may use your data to comply
              with applicable laws and regulations, such as those relating to
              data privacy, marketing, and telecommunications.</span
            >
          </li>
        </ul>
        <div class="c2" id="h.d0fpfahunoub">
          4. Legal Basis for Processing Personal Data
        </div>
        <p class="c4">
          <span class="c0"
            >We process your personal data based on the following legal
            grounds:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_y6hkmv2c5aj-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Consent: We may process your data if you have given us explicit
              consent to use your personal data for a specific purpose.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Contractual necessity: If you are our customer, we may process
              your personal data as necessary to enter into or perform a
              contract with you.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Legal obligation: We may process your personal data when we are
              legally required to do so, for example, if we need to comply with
              tax laws or respond to a legal process.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Legitimate interests: We may process your personal data when it
              is necessary for our legitimate interests or the legitimate
              interests of a third party, provided that those interests are not
              overridden by your interests or fundamental rights and freedoms.
              For example, we may process your data for the purposes of
              improving our services, preventing fraud, or ensuring the security
              of our IT systems.</span
            >
          </li>
        </ul>
        <div class="c2" id="h.xk2hq4o2n85v">
          5. Sharing Your Personal Data
        </div>
        <p class="c4">
          <span class="c0"
            >We do not sell your personal data to third parties.</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span class="c0"
            >We only share your personal data with third parties in the
            following limited circumstances:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_siw6ahenm3s-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >With your consent. We will only share your personal data with
              third parties with your explicit consent. For example, if you
              agree to share your data with a researcher, we will share it with
              them.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >To subprocessors: We may share your personal data with our
              subprocessors, who provide us with services such as hosting, data
              analysis, and email marketing. Our subprocessors are required to
              use your data in accordance with our privacy policies and to
              protect your privacy.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span
              >As required by law. We may share your personal data with law
              enforcement or other government agencies if we are required to do
              so by law or if we believe that such action is necessary to
              protect our rights or property or to protect the safety of
              others.</span
            >
          </li>
        </ul>
        <div class="c2" id="h.4h74vgpsgfa8">
          6. For How Long Your Personal Data will be Kept
        </div>
        <p class="c4">
          <span class="c0"
            >We will only retain your personal data for as long as is necessary
            for the purposes for which it was collected. In some cases, this may
            mean we retain your data for a period of time after you have ceased
            using our products or services. For example, we may retain your data
            for a period of time to comply with legal obligations or to resolve
            disputes.</span
          >
        </p>
        <p class="c1"><span class="c9"></span></p>
        <p class="c4">
          <span class="c0"
            >In general, this means that we will keep your data for the
            following periods:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_l7wrk6wdwla6-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Visitor data: We will keep your data for a maximum of twelve
              months after you last visited our website.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Survey respondent data: We will keep your data for the duration
              of the survey project, plus an additional twelve months.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c0"
              >Customer data: We will keep your data for as long as you are a
              customer of ours, plus an additional seven years.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span
              >Additional data: We will keep your data for as long as is
              necessary to fulfill the purpose for which it was collected.</span
            >
          </li>
        </ul>
        <div class="c2" id="h.zecubalv2eul">
        7. Your Rights</div>
        <p class="c4">
          <span class="c0"
            >You have the following rights regarding your personal data:</span
          >
        </p>
        <p class="c1"><span class="c0"></span></p>
        <ul class="c3 lst-kix_eo3p459agl9q-0 start">
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Access</span
            ><span class="c0"
              >: You have the right to access your personal data. You can
              request a copy of your personal data from us and verify its
              accuracy.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Correction</span
            ><span class="c0"
              >: You have the right to correct your personal data if it is
              inaccurate or incomplete.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Erasure</span
            ><span class="c0"
              >: You have the right to have your personal data erased in certain
              circumstances. For example, you can request that we erase your
              personal data if you no longer consent to its processing.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Restriction of processing</span
            ><span class="c0"
              >: You have the right to restrict the processing of your personal
              data in certain circumstances. For example, you can request that
              we restrict the processing of your personal data if you believe
              that it is inaccurate or that we are no longer authorised to
              process it.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Data portability</span
            ><span class="c0"
              >: You have the right to receive your personal data in a
              structured, commonly used, and machine-readable format. You can
              also request that we transfer your personal data to another data
              controller.</span
            >
          </li>
          <li class="c4 c5 li-bullet-0">
            <span class="c12">Objection</span
            ><span class="c0"
              >: You have the right to object to the processing of your personal
              data in certain circumstances. For example, you can object to the
              processing of your personal data for marketing purposes.</span
            >
          </li>
        </ul>
        <p class="c1"><span class="c0"></span></p>
        <p class="c4">
          <span>You can exercise your rights by contacting us.</span>
        </p>


        <h1 class="c0" id="h.u7bb3hr7pj46"><span class="c5">8. Cookie Policy</span>
    </h1>





    <p class="c3"><span>We use cookies on our
            website</span><span>&nbsp;(</span><span class="c17"><a class="c13"
                href="https://www.google.com/url?q=https://valorem.health/&amp;sa=D&amp;source=editors&amp;ust=1705312969804555&amp;usg=AOvVaw2LeYWX--_lQSlZxVr7kZsG">https://valorem.health/</a></span><span
            class="c2">) and the software platform.</span></p>
    
    
            <!-- 8. Cookie Policy
We use cookies on our website (https://valorem.health/) and the software platform.
8.1 What are cookies?
Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are used to improve your experience on our website, such as by remembering your preferences and allowing us to analyse how you use our website.

8.2 What types of cookies do we use?
We use the following types of cookies on our website:

Essential cookies: These cookies are essential for the operation of our website and cannot be disabled. They are used to remember your preferences and allow you to log in to our website.
Analytical cookies: These cookies are used to collect information about how you use our website, such as the pages you visit and the time you spend on each page. We use this information to improve our website and make it more user-friendly.
8.3 How to control cookies
We ask you for your consent before placing non-essential cookies on your device. You can also control and manage cookies through your browser settings. Most browsers allow you to view, manage, and delete cookies. 
8.4 Changes to our Cookie Policy
We may update our Cookie Policy from time to time. If we do, we will notify you by posting the updated version on our website. We recommend that you check our Cookie Policy periodically for any changes. -->

<div class="c2" >
8. Cookie Policy
</div>
<p class="c4">

We use cookies on our website (<a href="https://valorem.health/">https://valorem.health/</a>) and the software platform.
</p>

<h2 class="c6" >
8.1 What are cookies?
</h2>
<p class="c4">

Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are used to improve your experience on our website, such as by remembering your preferences and allowing us to analyse how you use our website.
</p>

<h2 class="c6" >

8.2 What types of cookies do we use?
</h2>

<p class="c4">

We use the following types of cookies on our website:
<ul class="c3 lst-kix_9p2fxtl18ak1-0 start">


<li class="c4 c5 li-bullet-0">
  
  <span class="c0"><b>Essential cookies</b>: These cookies are essential for the operation of our website and cannot be disabled. They are used to remember your preferences and allow you to log in to our website.</span>
  </li>
  <li class="c4 c5 li-bullet-0">

  <span class="c0"><b>Analytical cookies</b>: These cookies are used to collect information about how you use our website, such as the pages you visit and the time you spend on each page. We use this information to improve our website and make it more user-friendly.</span>
  </li>

</ul>
</p>

<h2 class="c6" >

8.3 How to control cookies
</h2>

<p class="c4">

We ask you for your consent before placing non-essential cookies on your device. You can also control and manage cookies through your browser settings. Most browsers allow you to view, manage, and delete cookies.
</p>

<h2 class="c6" >

8.4 Changes to our Cookie Policy
</h2>

<p class="c4">

We may update our Cookie Policy from time to time. If we do, we will notify you by posting the updated version on our website. We recommend that you check our Cookie Policy periodically for any changes.
</p>







        <div class="c2" id="h.t1d603qpb9ts">
          9. Contact
        </div>
        <p class="c4"><span class="c9">Valorem Health GmbH </span></p>
        <p class="c4">
          <span class="c0">Gewerkenstr 8, 44805 Bochum, Germany</span>
        </p>
        <p class="c4"><span class="c0">Email: contact@valorem.health</span></p>
        <p class="c1"><span class="c0"></span></p>
        <p class="c1"><span class="c0"></span></p>

        <div>
            <router-link to="/">
             &larr; Go back
            </router-link>
        </div>


  </div>
</div>
</template>

<script setup></script>

<style scoped>
@import url(https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI);
          .lst-kix_mehhcw8ug3ny-7 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-7 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_pa779akd9r86-8 {
            list-style-type: none;
          }
          .lst-kix_mehhcw8ug3ny-6 > li:before {
            content: "-  ";
          }
          .lst-kix_mehhcw8ug3ny-8 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-1 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-1 > li:before {
            content: "-  ";
          }
          .lst-kix_mehhcw8ug3ny-1 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-0 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-0 > li:before {
            content: "-  ";
          }
          .lst-kix_mehhcw8ug3ny-2 > li:before {
            content: "-  ";
          }
          ul.lst-kix_pa779akd9r86-5 {
            list-style-type: none;
          }
          ul.lst-kix_pa779akd9r86-4 {
            list-style-type: none;
          }
          ul.lst-kix_pa779akd9r86-7 {
            list-style-type: none;
          }
          .lst-kix_mehhcw8ug3ny-3 > li:before {
            content: "-  ";
          }
          .lst-kix_mehhcw8ug3ny-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_pa779akd9r86-6 {
            list-style-type: none;
          }
          ul.lst-kix_pa779akd9r86-1 {
            list-style-type: none;
          }
          ul.lst-kix_pa779akd9r86-0 {
            list-style-type: none;
          }
          ul.lst-kix_pa779akd9r86-3 {
            list-style-type: none;
          }
          .lst-kix_mehhcw8ug3ny-4 > li:before {
            content: "-  ";
          }
          ul.lst-kix_pa779akd9r86-2 {
            list-style-type: none;
          }
          .lst-kix_y6hkmv2c5aj-8 > li:before {
            content: "-  ";
          }
          .lst-kix_mehhcw8ug3ny-0 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-2 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-2 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-3 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-4 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-3 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-4 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-6 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-5 > li:before {
            content: "-  ";
          }
          .lst-kix_bpbj68ng1ch3-6 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-5 > li:before {
            content: "-  ";
          }
          .lst-kix_y6hkmv2c5aj-7 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_9p2fxtl18ak1-3 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-2 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-5 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-4 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-1 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-0 {
            list-style-type: none;
          }
          .lst-kix_eo3p459agl9q-2 > li:before {
            content: "-  ";
          }
          .lst-kix_eo3p459agl9q-1 > li:before {
            content: "-  ";
          }
          .lst-kix_eo3p459agl9q-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_l7wrk6wdwla6-8 {
            list-style-type: none;
          }
          .lst-kix_l7wrk6wdwla6-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_l7wrk6wdwla6-7 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-6 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-5 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-4 {
            list-style-type: none;
          }
          .lst-kix_l7wrk6wdwla6-3 > li:before {
            content: "-  ";
          }
          ul.lst-kix_l7wrk6wdwla6-3 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-2 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-1 {
            list-style-type: none;
          }
          ul.lst-kix_l7wrk6wdwla6-0 {
            list-style-type: none;
          }
          .lst-kix_i5t30449xyvq-1 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-6 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_c65w7jpj1m3t-0 {
            list-style-type: none;
          }
          .lst-kix_eo3p459agl9q-3 > li:before {
            content: "-  ";
          }
          .lst-kix_i5t30449xyvq-3 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-4 > li:before {
            content: "-  ";
          }
          ul.lst-kix_c65w7jpj1m3t-1 {
            list-style-type: none;
          }
          .lst-kix_eo3p459agl9q-5 > li:before {
            content: "-  ";
          }
          .lst-kix_i5t30449xyvq-5 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-2 > li:before {
            content: "-  ";
          }
          ul.lst-kix_3fdiv29v0ph4j-7 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-0 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-6 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-2 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-8 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-1 {
            list-style-type: none;
          }
          .lst-kix_wmsza5q856n4-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_lby4gih5zy50-4 {
            list-style-type: none;
          }
          .lst-kix_eo3p459agl9q-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_lby4gih5zy50-3 {
            list-style-type: none;
          }
          .lst-kix_pa779akd9r86-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_lby4gih5zy50-6 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-5 {
            list-style-type: none;
          }
          .lst-kix_wmsza5q856n4-1 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-3 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-7 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-0 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-4 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_olufgdhr1bjb-8 {
            list-style-type: none;
          }
          .lst-kix_l7wrk6wdwla6-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_olufgdhr1bjb-7 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-1 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-0 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-3 {
            list-style-type: none;
          }
          ul.lst-kix_olufgdhr1bjb-4 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-2 {
            list-style-type: none;
          }
          ul.lst-kix_olufgdhr1bjb-3 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-5 {
            list-style-type: none;
          }
          .lst-kix_q8bothjglzos-2 > li:before {
            content: "-  ";
          }
          ul.lst-kix_olufgdhr1bjb-6 {
            list-style-type: none;
          }
          ul.lst-kix_3fdiv29v0ph4j-4 {
            list-style-type: none;
          }
          ul.lst-kix_olufgdhr1bjb-5 {
            list-style-type: none;
          }
          .lst-kix_iu0v3pv88kkc-4 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-2 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-6 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-0 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-8 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-0 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-2 > li:before {
            content: "-  ";
          }
          ul.lst-kix_q8bothjglzos-0 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-1 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-6 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-7 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-8 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-2 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-3 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-4 {
            list-style-type: none;
          }
          ul.lst-kix_q8bothjglzos-5 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-0 {
            list-style-type: none;
          }
          .lst-kix_i5t30449xyvq-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_eo3p459agl9q-1 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-2 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-3 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-4 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-5 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-2 {
            list-style-type: none;
          }
          .lst-kix_h6v41cbxwh30-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_c65w7jpj1m3t-3 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-8 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-6 {
            list-style-type: none;
          }
          ul.lst-kix_c65w7jpj1m3t-7 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-3 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-4 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-6 > li:before {
            content: "-  ";
          }
          ul.lst-kix_eo3p459agl9q-4 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-5 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_eo3p459agl9q-6 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-7 {
            list-style-type: none;
          }
          ul.lst-kix_eo3p459agl9q-8 {
            list-style-type: none;
          }
          .lst-kix_3fdiv29v0ph4j-5 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-4 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-6 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-3 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_bpbj68ng1ch3-2 {
            list-style-type: none;
          }
          ul.lst-kix_mehhcw8ug3ny-4 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-1 {
            list-style-type: none;
          }
          .lst-kix_siw6ahenm3s-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_mehhcw8ug3ny-3 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-0 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-0 {
            list-style-type: none;
          }
          ul.lst-kix_mehhcw8ug3ny-2 {
            list-style-type: none;
          }
          .lst-kix_3fdiv29v0ph4j-0 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-2 > li:before {
            content: "-  ";
          }
          .lst-kix_3fdiv29v0ph4j-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_mehhcw8ug3ny-1 {
            list-style-type: none;
          }
          ul.lst-kix_mehhcw8ug3ny-8 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-3 {
            list-style-type: none;
          }
          .lst-kix_siw6ahenm3s-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_mehhcw8ug3ny-7 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-4 {
            list-style-type: none;
          }
          ul.lst-kix_mehhcw8ug3ny-6 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-1 {
            list-style-type: none;
          }
          .lst-kix_3fdiv29v0ph4j-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_mehhcw8ug3ny-5 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-2 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_bpbj68ng1ch3-8 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-7 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-6 > li:before {
            content: "-  ";
          }
          ul.lst-kix_bpbj68ng1ch3-6 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-5 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-4 {
            list-style-type: none;
          }
          ul.lst-kix_bpbj68ng1ch3-3 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-8 > li:before {
            content: "-  ";
          }
          .lst-kix_9p2fxtl18ak1-7 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-7 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-8 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-3 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-4 > li:before {
            content: "-  ";
          }
          ul.lst-kix_mehhcw8ug3ny-0 {
            list-style-type: none;
          }
          .lst-kix_siw6ahenm3s-2 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-6 > li:before {
            content: "-  ";
          }
          .lst-kix_siw6ahenm3s-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_olufgdhr1bjb-0 {
            list-style-type: none;
          }
          ul.lst-kix_olufgdhr1bjb-2 {
            list-style-type: none;
          }
          .lst-kix_c65w7jpj1m3t-7 > li:before {
            content: "-  ";
          }
          ul.lst-kix_olufgdhr1bjb-1 {
            list-style-type: none;
          }
          .lst-kix_q8bothjglzos-8 > li:before {
            content: "-  ";
          }
          .lst-kix_c65w7jpj1m3t-6 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-7 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-5 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-2 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-6 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-3 > li:before {
            content: "-  ";
          }
          .lst-kix_c65w7jpj1m3t-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_h6v41cbxwh30-7 {
            list-style-type: none;
          }
          .lst-kix_c65w7jpj1m3t-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_h6v41cbxwh30-8 {
            list-style-type: none;
          }
          .lst-kix_olufgdhr1bjb-1 > li:before {
            content: "-  ";
          }
          .lst-kix_c65w7jpj1m3t-2 > li:before {
            content: "-  ";
          }
          ul.lst-kix_h6v41cbxwh30-1 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-8 {
            list-style-type: none;
          }
          .lst-kix_olufgdhr1bjb-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_h6v41cbxwh30-2 {
            list-style-type: none;
          }
          ul.lst-kix_lby4gih5zy50-7 {
            list-style-type: none;
          }
          ul.lst-kix_h6v41cbxwh30-0 {
            list-style-type: none;
          }
          .lst-kix_c65w7jpj1m3t-3 > li:before {
            content: "-  ";
          }
          .lst-kix_c65w7jpj1m3t-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_h6v41cbxwh30-5 {
            list-style-type: none;
          }
          ul.lst-kix_h6v41cbxwh30-6 {
            list-style-type: none;
          }
          ul.lst-kix_h6v41cbxwh30-3 {
            list-style-type: none;
          }
          ul.lst-kix_h6v41cbxwh30-4 {
            list-style-type: none;
          }
          .lst-kix_c65w7jpj1m3t-4 > li:before {
            content: "-  ";
          }
          ul.lst-kix_i5t30449xyvq-1 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-2 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-3 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-4 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-5 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-6 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-7 {
            list-style-type: none;
          }
          ul.lst-kix_i5t30449xyvq-8 {
            list-style-type: none;
          }
          .lst-kix_lby4gih5zy50-5 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-4 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-7 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-4 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-3 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-8 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_i5t30449xyvq-0 {
            list-style-type: none;
          }
          .lst-kix_lby4gih5zy50-1 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-7 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-8 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-0 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-2 > li:before {
            content: "-  ";
          }
          .lst-kix_olufgdhr1bjb-6 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-2 > li:before {
            content: "-  ";
          }
          .lst-kix_i5t30449xyvq-0 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-0 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-8 > li:before {
            content: "-  ";
          }
          .lst-kix_i5t30449xyvq-2 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-6 > li:before {
            content: "-  ";
          }
          ul.lst-kix_wmsza5q856n4-0 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-1 {
            list-style-type: none;
          }
          .lst-kix_pa779akd9r86-5 > li:before {
            content: "-  ";
          }
          .lst-kix_eo3p459agl9q-4 > li:before {
            content: "-  ";
          }
          .lst-kix_i5t30449xyvq-6 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-3 > li:before {
            content: "-  ";
          }
          .lst-kix_lby4gih5zy50-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_9p2fxtl18ak1-7 {
            list-style-type: none;
          }
          ul.lst-kix_9p2fxtl18ak1-6 {
            list-style-type: none;
          }
          .lst-kix_i5t30449xyvq-4 > li:before {
            content: "-  ";
          }
          .lst-kix_eo3p459agl9q-6 > li:before {
            content: "-  ";
          }
          ul.lst-kix_9p2fxtl18ak1-8 {
            list-style-type: none;
          }
          .lst-kix_wmsza5q856n4-8 > li:before {
            content: "-  ";
          }
          .lst-kix_pa779akd9r86-1 > li:before {
            content: "-  ";
          }
          .lst-kix_eo3p459agl9q-8 > li:before {
            content: "-  ";
          }
          ul.lst-kix_wmsza5q856n4-4 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-5 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-2 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-3 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-8 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-6 {
            list-style-type: none;
          }
          ul.lst-kix_wmsza5q856n4-7 {
            list-style-type: none;
          }
          .lst-kix_q8bothjglzos-3 > li:before {
            content: "-  ";
          }
          .lst-kix_c65w7jpj1m3t-0 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-2 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-6 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-4 > li:before {
            content: "-  ";
          }
          .lst-kix_wmsza5q856n4-6 > li:before {
            content: "-  ";
          }
          .lst-kix_q8bothjglzos-1 > li:before {
            content: "-  ";
          }
          .lst-kix_l7wrk6wdwla6-4 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-3 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-5 > li:before {
            content: "-  ";
          }
          ul.lst-kix_siw6ahenm3s-0 {
            list-style-type: none;
          }
          .lst-kix_iu0v3pv88kkc-1 > li:before {
            content: "-  ";
          }
          .lst-kix_iu0v3pv88kkc-7 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-1 > li:before {
            content: "-  ";
          }
          ul.lst-kix_siw6ahenm3s-4 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-3 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-2 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-1 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-8 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-7 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-6 {
            list-style-type: none;
          }
          ul.lst-kix_siw6ahenm3s-5 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-0 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-2 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-1 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-4 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-3 {
            list-style-type: none;
          }
          .lst-kix_i5t30449xyvq-8 > li:before {
            content: "-  ";
          }
          li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt;
          }
          .lst-kix_9p2fxtl18ak1-4 > li:before {
            content: "-  ";
          }
          ul.lst-kix_iu0v3pv88kkc-7 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-8 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-5 {
            list-style-type: none;
          }
          ul.lst-kix_iu0v3pv88kkc-6 {
            list-style-type: none;
          }
          .lst-kix_9p2fxtl18ak1-2 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-3 > li:before {
            content: "-  ";
          }
          .lst-kix_h6v41cbxwh30-7 > li:before {
            content: "-  ";
          }
          .lst-kix_9p2fxtl18ak1-0 > li:before {
            content: "-  ";
          }
          ul.lst-kix_y6hkmv2c5aj-6 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-5 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-8 {
            list-style-type: none;
          }
          ul.lst-kix_y6hkmv2c5aj-7 {
            list-style-type: none;
          }
          .lst-kix_h6v41cbxwh30-5 > li:before {
            content: "-  ";
          }
          ol {
            margin: 0;
            padding: 0;
          }
          table td,
          table th {
            padding: 0;
          }
          .c1 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt;
          }
          .c15 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Source Serif Pro";
            font-style: normal;
          }
          .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Roboto";
            font-style: normal;
          }
          .c7 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Source Serif Pro";
            font-style: normal;
          }
          .c6 {
            padding-top: 18pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          .c9 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Roboto";
            font-style: normal;
          }
          .c11 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 16pt;
            font-family: "Source Serif Pro";
            font-style: normal;
          }
          .c14 {
            padding-top: 0pt;
            padding-bottom: 3pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          .c2 {
            padding-top: 20pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-weight: 600;
            font-family: "Source Serif Pro", serif !important;
          }
          .c10 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 16pt;
            font-family: "Source Serif Pro";
            font-style: normal;
          }
          .c4 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          .c13 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Roboto";
            font-style: normal;
          }
          .c16 {
            background-color: #ffffff;
            max-width: 100%;
          }
          .c8 {
            color: #666666;
            font-size: 10pt;
          }
          .c5 {
            margin-left: 36pt;
            padding-left: 0pt;
          }
          .c3 {
            padding: 0;
            margin: 0;
          }
          .c12 {
            font-weight: 700;
          }
          .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Roboto";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          li {
            color: #000000;
            font-size: 11pt;
            font-family: "Roboto";
          }
          p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Roboto";
          }
          
          h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 12pt;
            padding-bottom: 0pt;
            font-family: "Source Serif Pro";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Roboto";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Roboto";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Roboto";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left;
          }
          h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Roboto";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left;
          }

          .navbar-header {
  background-color: rgba(255, 255, 255, .5);
  -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .navbar-header {
    background-color: rgba(255, 255, 255, .0);
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
  }
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  font-size: 105%;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
}
.source {
  font-family: "Source Serif Pro", serif;
}
</style>
