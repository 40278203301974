<template>
  <div class="ds-alert" v-if="msg">
      <b-alert
      :variant="variant"
      dismissible
      fade
      :show="show"
      @dismissed="show=false"
    >
      {{msg}}
    </b-alert>
  </div>
</template>

<script>
export default {
    name: "Alert",
    data() {
        return {
            show: false,
        };
    },
    props: {
        msg: {
            type: String,
            default: null
        },
        duration: {
            type: Number,
            default: 5000
        },
        variant: {
            type: String,
            default: "danger"
        },
    },
    watch: {
        msg: function (val) {
            if(val ==='') return;
            setTimeout(() => {
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, this.duration);
            }, 500);
        },
    },
};
</script>

<style scoped>
.ds-alert {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
}
</style>