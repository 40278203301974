<template>
  <div
    id="tdot-page"
    class="home-120 home d-flex flex-column align-items-center flex-fill w-100"
  >
    <div id="zoom-note" ref="zoomNote" class="border rounded-3">
      <div class="modal-body d-flex" v-if="showNote">
        <div style="position: absolute; right: 5px; top: 5px">
          <button type="button" @click="closeNote()" class="btn-close"></button>
        </div>
        If the text on this page appears too small or large, press and hold the
        Ctrl key (Cmd key if you are using a mac) and press the + (plus) key or
        - (minus) key to zoom in or out.
      </div>
    </div>

    <CustomModal
      title="<span class='fw-bold'>Instructions - read carefully!</span>"
      :visible="true"
      @closed="triggerShowNote"
    >
      <p>
        In the questions that follow, you will be asked to imagine and compare
        two hypothetical scenarios. Please read each description carefully,
        including the characteristics of the health states in each scenario.
      </p>

      <p>
        Each scenario is fixed for the duration specified and health states
        cannot worsen or be improved (e.g. with health care).
      </p>
      <p>
        Where health states last for a specified duration, this duration ends
        with a quick and painless death.
      </p>
      <p>
        There are no right or wrong answers; we want to know what you think
        about these scenarios.
      </p>
    </CustomModal>

    <b-overlay
      id="tdot-overlay"
      :show="isLoading"
      variant="overlay-tdot"
      rounded="true"
      opacity="1"
      class="w-100 d-flex flex-column align-items-stretch justify-content-center my-auto"
      spinner-variant="primary"
      z-index="1"
    >
      <div class="mx-auto home-60 mb-5 fs-5" v-if="frameF">
        For these two scenarios, we would like you to consider which scenario
        provides the greatest improvement as a result of treatment.
      </div>

      <!-- cards row -->
      <div class="d-flex flex-row align-items-stretch w-100 flex-fill">
        <div
          class="card border shadow mx-3"
          style="flex-basis: 50%"
          :class="{
            'border-primary':
              selected[taskIndex] == 'A' || selected[taskIndex] == 'same',
            'border-2': selected[taskIndex] == 'A',
            'border-1': selected[taskIndex] == 'same',
          }"
        >
          <div
            class="card-header h3 text-center"
            style="white-space: nowrap !important"
          >
            Scenario A
          </div>
          <div
            class="card-body d-flex flex-column align-items-center justify-content-stretch pb-5"
          >
            <div v-html="scenarioAStateLabels.top"></div>

            <div class="my-3 mx-4">
              <div v-for="(lvl, index) in scenarioAStateLabels.labels">
                <div
                  class="px-3 py-2 border-start border-end border-secondary"
                  :style="{
                    'border-top-right-radius': index == 0 ? '0.5em' : '0',
                    'border-top-left-radius': index == 0 ? '0.5em' : '0',
                    'border-bottom-right-radius':
                      index == scenarioAStateLabels.labels.length - 1
                        ? '0.5em'
                        : '0',
                    'border-bottom-left-radius':
                      index == scenarioAStateLabels.labels.length - 1
                        ? '0.5em'
                        : '0',
                    'background-color': scenarioAStateLabels.bgColors[index],
                    'border-top':
                      index > 0 ? 'solid 1px var(--bs-secondary)' : '0',
                  }"
                >
                  {{ lvl }}
                </div>
              </div>
            </div>

            <div
              class="text-center my-auto w-100"
              v-html="scenarioAStateLabels.center"
            ></div>

            <div
              class="text-end small text-secondary px-2 pb-3"
              style="bottom: 0px; right: 0px; position: absolute"
              v-html="scenarioAStateLabels.bottom"
            ></div>
            <div v-if="frameF" class="text-primary fw-bold align-self-end">
              There is a treatment that will improve your health: you will live
              for 10 years in full health instead of the above.
            </div>
          </div>
        </div>

        <div
          class="card border shadow mx-3"
          style="flex-basis: 50%"
          :class="{
            'border-primary':
              selected[taskIndex] == 'B' || selected[taskIndex] == 'same',
            'border-2': selected[taskIndex] == 'B',
            'border-1': selected[taskIndex] == 'same',
          }"
        >
          <div
            class="card-header h3 text-center"
            style="white-space: nowrap !important"
          >
            Scenario B
          </div>
          <div
            class="card-body d-flex flex-column align-items-center justify-content-stretch pb-5"
          >
            <div v-html="scenarioBStateLabels.top"></div>

            <div class="my-3 mx-4">
              <div v-for="(lvl, index) in scenarioBStateLabels.labels">
                <div
                  class="px-3 py-2 border-start border-end border-secondary"
                  :style="{
                    'border-top-right-radius': index == 0 ? '0.5em' : '0',
                    'border-top-left-radius': index == 0 ? '0.5em' : '0',
                    'border-bottom-right-radius':
                      index == scenarioBStateLabels.labels.length - 1
                        ? '0.5em'
                        : '0',
                    'border-bottom-left-radius':
                      index == scenarioBStateLabels.labels.length - 1
                        ? '0.5em'
                        : '0',
                    'background-color': scenarioBStateLabels.bgColors[index],
                    'border-top':
                      index > 0 ? 'solid 1px var(--bs-secondary)' : '0',
                  }"
                >
                  {{ lvl }}
                </div>
              </div>
            </div>

            <div
              class="text-center my-auto w-100"
              v-html="scenarioBStateLabels.center"
            ></div>

            <div
              class="text-end small text-secondary px-2 pb-3"
              style="bottom: 0px; right: 0px; position: absolute"
              v-html="scenarioBStateLabels.bottom"
            ></div>
            <div v-if="frameF" class="text-primary fw-bold align-self-end">
              There is a treatment that will improve your health: you will live
              for 10 years in full health instead of the above.
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex w-100 flex-column">
        <div
          class="align-self-center mt-5 d-flex flex-column align-items-center border bg-white pb-3 px-5 rounded-3 shadow border-secondary"
        >
          <div class="h4 text-center pt-3">{{ question }}</div>

          <div class="d-flex flex-row py-3">
            <div class="px-3">
              <input
                type="radio"
                class="btn-check"
                id="btn-a"
                name="selected-scenario"
                autocomplete="off"
                value="A"
                v-model="selected[taskIndex]"
                :disabled="isLoading"
                @keydown.enter="next"
              />
              <label class="btn btn-lg btn-outline-primary" for="btn-a">
                Scenario A
              </label>
            </div>

            <div class="px-3">
              <input
                type="radio"
                class="btn-check"
                id="btn-b"
                name="selected-scenario"
                autocomplete="off"
                value="B"
                v-model="selected[taskIndex]"
                :disabled="isLoading"
                @keydown.enter="next"
              />
              <label class="btn btn-lg btn-outline-primary" for="btn-b">
                Scenario B
              </label>
            </div>
          </div>
          <!-- <div class="align-self-center">
        <input
          type="radio"
          class="btn-check"
          id="btn-same"
          name="selected-scenario"
          autocomplete="off"
          value="same"
          v-model="selected[taskIndex]"
          :disabled="isLoading"
        />
        <label class="btn btn-lg btn-outline-primary" for="btn-same">
          Both are the same
        </label>
      </div> -->
        </div>
      </div>
    </b-overlay>

    <div class="ms-auto pe-4 mt-3 text-secondary">
      {{ taskIndex + 1 }}/{{ scenarios?.frames?.length }}
    </div>
    <div class="w-100">
      <GoNextBtn :promptGoNext="canGoNext" />
    </div>
  </div>
</template>

<script>
import GoNextBtn from "@/components/GoNextBtn.vue";
import CustomModal from "@/components/CustomModal.vue";
import axios from "axios";

export default {
  name: "ThisDeadOrThat",
  components: {
    GoNextBtn,
    CustomModal,
  },
  data() {
    return {
      selected: [],
      taskIndex: 0,
      subscribed: false,
      isLoading: false,
      scenarios: [],
      startTime: null,
      results: [],
      hues: [90, 82, 74, 66, 58],
      showNote: true,
    };
  },
  beforeMount() {
    this.$store.commit("enableInternalNav");
    axios
      .post(this.$store.state.host + "/api/getStateSet/", {
        tdotId: this.$store.state.survey?.pages?.ThisDeadOrThat?.tdotId ?? "study"
      })
      .then(
        function (response) {
          if (response.data.success) {
            this.loadScenario(response.data.stateSetForUser);
            console.log("Scenarios loaded.");
            this.setIncrProgress();
          } else {
            console.error(response);
          }
        }.bind(this)
      );
  },
  mounted() {
    this.startTime = Date.now();

    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.back();
        }
      }.bind(this),
      { prepend: true }
    );
  },
  beforeUnmount() {
    this.subscribe();
    this.$store.commit("disableGoBack");
    this.$store.commit("setCustomPercentage", false);
  },
  methods: {
    loadScenario(scenarios) {
      const comb = [];
      scenarios.frames.forEach((f) => {
        scenarios.states.forEach((s) => {
          comb.push([f, s]);
        });
      });
      comb.sort(() => Math.random() - 0.5);
      // ADDING 11111 AT THE END
      if (this.$store.state.survey?.pages?.ThisDeadOrThat?.add111111) {
        const fh = ["MO_0", "SC_0", "UA_0", "PD_0", "AD_0"];
        scenarios.frames.forEach((f) => {
          comb.push([f, fh]);
        });
      }
      const frames = comb.map((c) => {
        return c[0];
      });
      const states = comb.map((c) => {
        return c[1];
      });
      const order = comb.map((c) => {
        return Math.random() > 0.5 ? 0 : 1;
      });
      this.scenarios = {
        frames,
        states,
        order,
        id: scenarios.stateSetId,
      };
    },
    next() {
      if (!this.canGoNext) return;

      if (this.showNote) this.closeNote();

      this.results[this.taskIndex] = {
        frame: this.scenarios.frames[this.taskIndex],
        eq5d5lState: this.scenarios.states[this.taskIndex]
          .map((s) => {
            return parseInt(s[3]) + 1;
          })
          .join(""),
        order: this.scenarios.order[this.taskIndex],
        taskIndex: this.taskIndex,
        selected: this.selected[this.taskIndex],
        wtd: this.determineWtd(
          this.selected[this.taskIndex],
          this.scenarios.order[this.taskIndex]
        ),
        secondsTaken: (Date.now() - this.startTime) / 1000,
        setId: this.scenarios.id,
      };

      this.$store.commit("pushCustom", {
        property: "tdot",
        value: this.results[this.taskIndex],
      });

      if (this.taskIndex + 1 >= this.scenarios.frames.length) {
        this.$store.commit("disableInternalNav");
        // this.$store.commit("setCustom", {
        //   property: "tdot",
        //   value: this.results,
        // });
        return;
      }
      let wait = 0;
      if (this.selected[this.taskIndex + 1] == undefined) {
        this.isLoading = true;
        wait = 800;
      }
      setTimeout(() => {
        this.isLoading = false;
        this.taskIndex++;
        this.setIncrProgress();
        this.startTime = Date.now();
      }, wait);
      this.$store.commit("disableGoNext");
    },
    back() {
      if (this.taskIndex == 0) return;

      this.$store.commit("enableGoNext");
      this.taskIndex--;
    },
    determineWtd(selected, order) {
      if (order == 0) {
        return selected == "A";
      } else {
        return selected == "B";
      }
    },
    getOption1(frame) {
      if (frame == "A" || frame == "C")
        return {
          top: "You live for <span class = 'fw-bold u'>10 years</span> in this state:",
          bottom: "Followed by death (quick and painless).",
          center: null,
        };

      if (frame == "B")
        return {
          top: "You live for <span class = 'fw-bold u'>10 years</span> in full health <br> followed by <span class = 'fw-bold u'>10 years</span> in this state:",
          bottom: "Followed by death (quick and painless).",
          center: null,
        };

      if (frame == "D")
        return {
          top: "Living in this state:",
          bottom: "Followed by death (quick and painless).",
          center: null,
        };

      if (frame == "E")
        return {
          top: "You live for <span class = 'fw-bold u'>10 years</span> in this state:",
          bottom: "Followed by death (quick and painless).",
          center: null,
        };

      if (frame == "F")
        return {
          top: null,
          center:
            "You are about to die immediately<br>(quickly and painlessly)",
          bottom: "Followed by death (quick and painless).",
        };

      return null;
    },
    getOption2(frame) {
      if (frame == "A")
        return {
          center: "Immediate death<br>(quick and painless)",
          top: null,
          bottom: null,
        };

      if (frame == "B")
        return {
          center:
            "You live for <span class = 'fw-bold u'>10 years</span> in full health.",
          bottom: "Followed by death (quick and painless).",
          top: null,
        };

      if (frame == "C")
        return {
          center:
            "You live for <span class = 'fw-bold u'>1 week</span> in your current health state.",
          bottom: "Followed by death (quick and painless).",
          top: null,
        };

      if (frame == "D")
        return {
          center: "Being dead.",
          top: null,
          bottom: null,
        };

      if (frame == "E")
        return {
          top: "You live for <span class = 'fw-bold u'>9 years</span> in this state:",
          bottom: "Followed by death (quick and painless).",
          center: null,
        };

      if (frame == "F")
        return {
          top: "You are about to live for <span class = 'fw-bold u'>10 years</span> in this state:",
          center:
            '<div class="w-100 text-end small text-secondary px-2 pb-3">Followed by death (quick and painless).</div>',
          bottom: "Followed by death (quick and painless).",
        };

      return null;
    },
    getStateLabels(scenario) {
      return scenario.map(
        (lvlId, i) =>
          this.$store.state.survey.ds[i].levels.find((lvl) => lvl.id == lvlId)
            .label
      );
    },
    getStateBgCols(scenario) {
      return scenario.map(
        (lvlId) =>
          `hsl(295, 100%, ${this.hues[parseInt(lvlId.substring(3, 4))]}%)`
      );
    },
    getOption1Labels() {
      let frame = this.getOption1(this.scenarios.frames[this.taskIndex]);
      let state = this.scenarios?.states[this.taskIndex];

      return {
        ...frame,
        labels: !this.frameF ? this.getStateLabels(state) : null,
        bgColors: !this.frameF ? this.getStateBgCols(state) : null,
      };
    },
    getOption2Labels() {
      let frame = this.getOption2(this.scenarios.frames[this.taskIndex]);
      let state = this.scenarios?.states[this.taskIndex];
      let frameE = this.scenarios.frames[this.taskIndex] == "E";

      return {
        ...frame,
        labels: frameE || this.frameF ? this.getStateLabels(state) : null,
        bgColors: frameE || this.frameF ? this.getStateBgCols(state) : null,
      };
    },
    closeNote() {
      this.$refs.zoomNote.style.left = "-100vw";
      setTimeout(() => {
        this.showNote = false;
      }, 500);
    },
    triggerShowNote() {
      setTimeout(() => {
        this.$refs.zoomNote.style.left = "5%";
      }, 500);
    },
    setIncrProgress() {
      let progress =
        (this.$store.state.navigation.currentPage +
          this.taskIndex / (this.scenarios.frames.length - 1)) /
        (this.$store.state.navigation.agenda.length - 1);
      this.$store.commit("setCustomPercentage", progress * 100);
    },
  },
  computed: {
    canGoNext() {
      // if (this.taskIndex > 0) this.$store.commit("enableGoBack");
      // else this.$store.commit("disableGoBack");
      this.$store.commit("disableGoBack");

      if (this.selected[this.taskIndex] != undefined) {
        this.$store.commit("enableGoNext");
        return true;
      } else {
        this.$store.commit("disableGoNext");
        return false;
      }
    },
    scenarioAStateLabels() {
      if (!this.scenarios?.order) return [];

      if (this.scenarios.order[this.taskIndex]) return this.getOption1Labels();
      else return this.getOption2Labels();
    },

    scenarioBStateLabels() {
      if (!this.scenarios?.order) return [];

      if (!this.scenarios.order[this.taskIndex]) return this.getOption1Labels();
      else return this.getOption2Labels();
    },

    frameF() {
      if (!this.scenarios) return false;
      if (!this.scenarios.frames) return false;
      if (this.scenarios.frames[this.taskIndex] == "F") return true;

      return false;
    },

    question() {
      if (!this.scenarios) return "Which scenario is better?";

      if (!this.scenarios.frames) return "Which scenario is better?";

      if (this.scenarios.frames[this.taskIndex] == "F")
        return "In which scenario is the improvement greater?";

      return "Which scenario is better?";
    },
  },
};
</script>

<style>
#zoom-note {
  position: absolute;
  background-color: #d1e8f0;
  color: #547d8a;
  bottom: 15%;
  left: -100%;
  max-width: 40ch;
  z-index: 40;
  transition: all 0.5s ease-in;
}

#tdot-overlay .spinner-border {
  height: 7rem;
  width: 7rem;
  border: 0.5em solid currentColor;
  border-right-color: transparent;
}

.bg-overlay-tdot {
  background-color: rgba(245, 246, 247, 0.8);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-overlay-tdot {
    background-color: rgba(245, 246, 247, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

#tdot-page .card:hover {
  transform: scale(1.01);
}
</style>
