import router from '@/router'
import axios from 'axios'


export default {
  state: {
  },
  mutations: {
    
    loadSurvey(state, survey) {
      Object.assign(state, survey);
      state.ds.forEach((dimension, dimIndex) => {
        dimension.levels.forEach((level, levelIndex) => {
          level.id = dimension.id + "_" + levelIndex;
        });
      });
    },
    filterDimensionSelectionSurvey(state, payload) {
      state.ds_inactive = state.ds;
      state.ds = state.ds.filter((dimension) => payload.includes(dimension.id));
    },
    restoreDimensionSelectionSurvey(state) {
      state.ds = state.ds_inactive;
      state.ds_inactive = [];
    },
    updateReward(state, reward) {
      state.reward = reward;
    },
    setLocalSurveyStatus(state, status) {
      state.active = status ?? null;
    }
  },
  actions: {
    fetchSurvey({ dispatch, commit}, payload) {

      var host = process.env.NODE_ENV === "development" ? "http://localhost:4000" : "";
      var skeletonSource = host + "/api/survey/" + payload;
       
    axios
      .get(skeletonSource)
      .catch(function (error) {
        console.error(error);
      })
      .then(
        function (response) {
          if (!response.data.success) {
            
            if(response.data.error) console.error(response.data.error);
            else console.error(payload + " not found.");

            router.push({
              name: "Error404",
              params: { confirmed: true },
            });
            return;
          } else {
            if (response.data.survey.general?.meta)
              document.title = response.data.survey.general?.meta;
            
            const dimensionsCleaned = response.data.survey.ds.map(
              (dimension) => {
                return {
                  id: dimension.id,
                };
              }
            );
            const dimensionsWithLevelsCleaned =
              response.data.survey.ds.map((dimension) => {
                return {
                  id: dimension.id,
                  levelRating: dimension.levels.map((level, index) => {
                    return {
                      id: dimension.id + "_" + index,
                      label: level.label,
                      rank: level.rank,
                    };
                  }),
                };
              });
            let agenda = response.data.survey.agenda;
            if (response.data.survey.general?.randomInsert) {
              try {
              let insertStrings = response.data.survey.general?.randomInsertOptions?.insert;
              let places = response.data.survey.general?.randomInsertOptions?.places;
              // pick a random place
              let place = places[Math.floor(Math.random() * places.length)];
              let placeIndex = agenda.indexOf(place);
              // insert all strings as a group at the random place
                agenda.splice(placeIndex + 1, 0, ...insertStrings);
              } catch (error) {
                console.error(error);
              }
            }

            commit("loadAgenda", agenda);
            commit("loadPreventReturn", response.data.survey.preventReturn);
            commit("loadSurvey", response.data.survey);
            commit("loadDsDimensions", dimensionsWithLevelsCleaned);
            
          }
        }.bind(this)
    );
      
    },
  },
};
