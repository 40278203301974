<template>
  <div class="home home-120 d-flex flex-column" style="overflow: auto">
    
    
    <div v-if="choiceSets.length != 0" class="mt-1" style="">
      <table class="pod-table">
        <colgroup>
          <col span="1" v-if="!hideOnMobile" style="width: 20%" />
          <col span="1" :style="{'width':  screenWidth <= 768 ? '1%' :'5%'}" />
          <col span="1" style="width: 35%" />
          <col span="1" :style="{'width':  screenWidth <= 768 ? '1%' :'5%'}" />
          <col span="1" style="width: 35%" />
        </colgroup>

        <tr>
          <td v-if="!hideOnMobile" colspan="1"></td>
          <td  colspan="1"></td>
          <td colspan="3" class="pb-4">
              <Description class="mx-auto" style="max-width: 60ch">
                <div v-html="$store.state.survey?.pages?.healthStatesChoices?.html"></div>
                <div class="question text-center">
                      {{ $store.state.survey?.pages?.healthStatesChoices.question }}
                </div>
              </Description>
          </td>
        </tr>

        <tr>
          <th v-if="!hideOnMobile" scope="col"></th>
          <th scope="col"></th>
          <th scope="col" class="scenario-top scenario-cell">
            {{ $store.state.survey?.pages?.healthStatesChoices.scenarioALabel }}
          </th>
          <th scope="col"></th>
          <th scope="col" class="scenario-top scenario-cell">
            {{ $store.state.survey?.pages?.healthStatesChoices.scenarioBLabel }}
          </th>
        </tr>

        <tr v-for="(attr, index) in labelsA">
          <!-- dimension row name header -->
          <th v-if="!hideOnMobile" scope="row">{{ dimLabels[index] }}</th>
          <td />
          <!-- scenario A state -->
          <td
            class="scenario-cell"
            v-if="!isLoading"
            :class="[
              index == labelsA.length - 1
                ? 'scenario-bottom'
                : 'scenario-middle',
              taskEnded ? 'disabled-cell' : '',
            ]"
          >
            {{ attr }}
          </td>
          <!-- spinner over scenario A -->
          <td
            v-if="isLoading && index == 0"
            :rowspan="labelsA.length"
            class="scenario-bottom scenario-cell"
          >
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem; border-width: 0.4em"
            ></b-spinner>
          </td>
          <td />
          <!-- scenario B state -->
          <td
            class="scenario-cell"
            v-if="!isLoading"
            :class="[
              index == labelsB.length - 1
                ? 'scenario-bottom'
                : 'scenario-middle',
              taskEnded ? 'disabled-cell' : '',
            ]"
          >
            {{ labelsB[index] }}
          </td>
          <!-- spinner over scenario B -->
          <td
            v-if="isLoading && index == 0"
            :rowspan="labelsB.length"
            class="scenario-bottom scenario-cell"
          >
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem; border-width: 0.4em"
            ></b-spinner>
          </td>
        </tr>
        <tr>
          <th v-if="!hideOnMobile" scope="row"></th>
          <td />
          <td class="scenario-btn-cell scenario-cell">
            <input
            type="radio"
            class="btn-check"
            id="btn-a"
            :disabled="(taskEnded || isLoading) ? true : false"
            name="selected-scenario"
            autocomplete="off"
            value="a"
            v-model="choice"
          />
          <label class="btn btn-lg btn-outline-primary" for="btn-a">
            {{ $store.state.survey?.pages?.healthStatesChoices.selectLabel }}
          </label>
          </td>
          <td />
          <td class="scenario-btn-cell scenario-cell">
            <input
            type="radio"
            class="btn-check"
            id="btn-b"
            :disabled="(taskEnded || isLoading) ? true : false"
            name="selected-scenario"
            autocomplete="off"
            value="b"
            v-model="choice"
          />
          <label class="btn btn-lg btn-outline-primary" for="btn-b">
            {{ $store.state.survey?.pages?.healthStatesChoices.selectLabel }}
          </label>
          </td>
        </tr>
      </table>
    </div>
    <GoNextBtn :promptGoNext="promptGoNext" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

import {
  dsExpand,
  pufScaled,
  stateValuator,
  dceScenarioSelectorAdaptive,
} from "@/utils/puf";

export default {
  name: "Health States Choices",
  components: {
    Description,
    Card,
    GoNextBtn,
  },
  data() {
    return {
      isLoading: false,
      levels: null,
      rankingList: [],
      taskIndex: 0,
      choice: null,
      taskEnded: false,
      promptGoNext: false,
    };
  },
  beforeMount() {
    this.subscribe = this.$store.subscribeAction(
      function (action, state) {
        if (
          action.type == "go" &&
          action.payload == 1 &&
          state.navigation.internalNav == true
        ) {
          this.next();
        }
        if (
          action.type == "go" &&
          action.payload == -1 &&
          state.navigation.internalNav == true
        ) {
          this.$store.commit("disableInternalNav");
        }
      }.bind(this),
      { prepend: true }
    );
  },
  mounted() {
    this.$store.commit("enableInternalNav");
    this.$store.commit("disableGoNext");
    this.$store.commit("clearHealthStateChoices");
  },
  beforeUnmount() {
    this.subscribe();
  },

  methods: {
    next() {
      this.makeChoice(this.choice);
    },
    makeChoice(choice) {
      
      if(this.choice === null) return;
      this.choice = null;

      const payload = {
        choiceSet: this.choiceSets[this.taskIndex],
        choice: choice,
        percentile:
          this.$store.state.survey?.pages?.healthStatesChoices.rankSelection[
            this.taskIndex
          ],
        delta:
          this.$store.state.survey?.pages?.healthStatesChoices.delta[
            this.taskIndex
          ],
      };
      this.$store.commit("makeHealthStateChoice", payload);

      if (this.taskIndex + 1 > this.choiceSets.length - 1) {
        this.$store.commit("disableInternalNav");
      } else {
        this.isLoading = true;
        setTimeout(() => {
          this.taskIndex++;
          this.isLoading = false;
        }, 1500);
      }
    },
  },
  computed: {
    ds() {
      return this.$store.state.results.ds;
    },
    dsExpand() {
      return dsExpand(this.ds);
    },
    allStatesValued() {
      return stateValuator(this.dsExpand, pufScaled(this.ds));
    },
    choiceSets() {
      try {
        let choiceSets = dceScenarioSelectorAdaptive(
          this.$store.state.survey?.pages?.healthStatesChoices.rankSelection,
          this.$store.state.survey?.pages?.healthStatesChoices.delta,
          this.ds,
          this.dsExpand,
          this.allStatesValued
        );
        return choiceSets;
      } catch (error) {
        console.error("ERROR in createChoiceSet: ", error);
        return [];
      }
    },
    labelsA() {
      if (this.choiceSets.length > 0) {
        return this.choiceSets[this.taskIndex].a;
      }
    },
    labelsB() {
      if (this.choiceSets.length > 0) {
        return this.choiceSets[this.taskIndex].b;
      }
    },
    dimLabels() {
      return this.$store.state.survey.ds.map((d) => d.name);
    },
    screenWidth() {
      return screen.width;
    },
    hideOnMobile() {
      if(this.screenWidth <= 768 && this.$store.state.survey?.pages?.healthStatesChoices.hideDimsOnMobile) {
        return true;
      } 
      return false;
    },
  },
  watch: {
    choice() {
      if(this.choice){
        this.$store.commit("enableGoNext");
        this.promptGoNext = true;
      } else {
        this.$store.commit("disableGoNext");
        this.promptGoNext = false;
      }
    },
  },
};
</script>

<style scoped>

.col-4 {
  max-width: 400px;
}

.col-cell-mid {
  background-color: white;
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 0.8em 0.8em !important;
  border-bottom: 1px dotted rgb(230, 230, 230);
}
.col-cell-top {
  border: 1px solid lightgray;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  background-color: var(--bs-primary);
  color: var(--bs-light);
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  padding: 0.7em 0.5em;
}

.col-cell-bottom-btn {
  border: 1px solid lightgray;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  padding: 0.5em 1em;
  padding-right: 1em !important;
}

.col-cell-disabled {
  background-color: rgb(238, 238, 238);
}

.btn-select {
  width: 100%;
  max-width: 150px;
  font-size: 1.25em;
  padding: 0.2em 0.5em;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  white-space: nowrap;
}
.btn-select:hover {
  color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
  background-color: var(--bs-primary) !important;
}
</style>




