<template>
  <div style="height: 300px; position: sticky; top: 45px;">
    <Bar
      :chart-id="chartData.id"
      :chart-type="chartData.type"
      :chart-data="chartData.data"
      :chart-options="chartData.options"
      :height="300"
      :width="100"
    ></Bar>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

import { ref, watch } from "vue";
export default {
  name: "DimWeightsChart",
  components: {
    Bar,
  },
  setup(computed) {
    const chartRef = ref(null);
    const updateMyChart = () => {
      const chartData = computed.chartData;
      // chartRef.value.update()
    };
    return {
      chartRef,
      updateMyChart,
    };
  },
  props: {
    dimWeightingResults: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //chartRef: null,
    };
  },

  watch: {
    dimWeightingResults: {
      handler: function () {
        console.log("Watcher trigger");
        // this.updateChart();
        this.updateMyChart();
      },
      deep: true,
    },
  },
  computed: {
    labels() {
      return this.dimWeightingResults?.map((d) =>
        this.tooltipStringBreaker(d?.name)
      );
    },
    chartData() {
      let data = this.dimWeightingResults?.map((d, i) => d.swingWeight);
      let backgroundColor = this.dimWeightingResults?.map((d, i) => {
          if(d.hexColor == undefined) {
             d.hexColor = this.colorArray(i);
          }
          return "hsl(" + d.hexColor + ", 100%, 50%, .55)"
        }
      );
      let backgroundColorHover = this.dimWeightingResults?.map(
        (d) => "hsl(" + d.hexColor + ", 100%, 50%, .9)"
      );

      return {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              data,
              backgroundColor,
              hoverBackgroundColor: backgroundColorHover,
              barPercentage: 0.8,
              borderRadius: 10,
              borderColor: "black",
              borderWidth: 1,
              hoverBorderWidth: 1.5,
            },
          ],
        },
        options: {
          barPercentage: 0.7,
          min: 0,
          max: 100,
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 100,
            responsiveAnimationDuration: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            y: {
              suggestedMin: 1,
              suggestedMax: 100,

              ticks: {
                count: 6,

                callback: function (value) {
                  return `${value}`;
                },
              },
            },
          },
        },
      };
    },
  },
  methods: {
    tooltipStringBreaker(str) {
      return str.match(/.{1,25}(\s|$)/g);
    },
    colorArray(i) {
      let colorIncrement = 360 / this.dimWeightingResults.length;
      let color = colorIncrement * i;
      return color;
    },
    updateChart() {
      console.log("update");
      console.log("this.chartRef");
      console.log(this.chartRef);
      this.chartRef.update();
      this.chartRef?.value?.update();
    },
  },
  mounted() {
    this.updateMyChart();
  },
};
</script>
