<template>
  <div class="home home-80 d-flex flex-column w-100">
    <div>
      <QuestionCard
        :content="{}"
        :context="$store.state.survey?.pages?.otherQuestionsY.html"
      />
    </div>
    <div v-for="(item, key, index) in questionsByCategory" :key="key">
      <div
        class="card mx-3 mb-3"
        :key="key"
        :ref="'cardRef' + index"
        v-if="index <= taskIndex"
      >
        <div class="card-body mx-3 py-3">
          <h3 class="text-primary">{{ key }}</h3>
          <div>
            <div v-for="question in item" :key="question.id">
              <div v-if="checkCondition(question.condition)" class="py-4">
                <div v-if="question.context" class="pb-3">
                  <div v-html="question.context"></div>
                </div>
                <!-- question types -->
                <div class="fw-bold pb-1" v-html="question.label"></div>
                <div class="">
                  <div v-if="question.type == 'checkbox-matrix'" style="" class="mx-auto px-0 px-md-3">
                    <b-table-simple>
                      <b-thead>
                        <b-tr>
                          <b-th class="col-4"></b-th>
                          <b-th class="col-4 text-center" v-for="col in question.cols">{{
                            col.label
                          }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="row in question.rows">
                          <b-td>{{ row.label }}</b-td>
                          <b-td v-for="(col, index) in question.cols" class="">
                            
                            <b-form-checkbox
                            class="mx-auto"
                            size="lg"
                              v-model="
                                selected[question.id + row.id + col.value]
                              "
                            >
                            </b-form-checkbox>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>

                  <div v-if="question.type == 'likert'">
                    <form class="mt-2 likert-form">
                      <ul class="likert">
                        <li v-for="option in question.options">
                          <input
                            type="radio"
                            name="likert"
                            :disabled="ptns[question.id]"
                            :value="option.value"
                            v-model="selected[question.id]"
                          />
                          <label>{{ option.text }}</label>
                        </li>
                      </ul>
                    </form>
                  </div>

                  <div v-if="question.type == 'select'">
                    <b-form-select
                      v-model="selected[question.id]"
                      :disabled="ptns[question.id]"
                    >
                      <option
                        v-for="option in question.options"
                        :key="option.value"
                        :value="option.value"
                        :disabled="option.disabled"
                      >
                        {{ option.text }}
                      </option>
                    </b-form-select>
                  </div>

                  <div v-if="question.type == 'radio'">
                    <b-form-radio-group
                      v-model="selected[question.id]"
                      :options="question.options"
                      :stacked="question.stacked"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-radio-group>
                  </div>

                  <div v-if="question.type == 'checkbox'">
                    <b-form-checkbox-group
                      :class="question.class ?? ''"
                      :stacked="question.stacked"
                      v-model="selected[question.id]"
                      :options="question.options"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-checkbox-group>
                  </div>

                  <div v-if="question.type == 'text'">
                    <b-form-input
                      v-model="selected[question.id]"
                      :debounce="500"
                      :disabled="ptns[question.id]"
                    >
                    </b-form-input>
                  </div>

                  <div v-if="question.type == 'textarea'">
                    <b-form-textarea
                      v-model="selected[question.id]"
                      :debounce="500"
                      :disabled="ptns[question.id]"
                      rows="3"
                      :placeholder="question.placeholder ?? ''"
                    >
                    </b-form-textarea>
                  </div>

                  <div v-if="question.type == 'numeric'">
                    <b-form-input
                      v-model="selected[question.id]"
                      :disabled="ptns[question.id]"
                      class="w-50"
                      type="number"
                      :placeholder="question.placeholder ?? ''"
                    >
                    </b-form-input>
                  </div>

                  <div v-if="question.supportsPreferNotToSay" class="pt-1">
                    <b-form-checkbox v-model="ptns[question.id]">
                      {{
                        $store.state.survey?.pages?.otherQuestionsY
                          .preferNotToSayLabel
                      }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GoNextBtn :promptGoNext="taskEnded" />
  </div>
</template>

<script>
import GoNextBtn from "../components/GoNextBtn.vue";
import QuestionCard from "../components/QuestionCard.vue";

export default {
  name: "otherQuestionsY",
  components: {
    GoNextBtn,
    QuestionCard,
  },
  data() {
    return {
      selected: {},
      ptns: {},
      newTaskIndex: 0,
      taskEnded: false,
    };
  },
  mounted() {
    let prevResponses = this.$store.state?.results?.otherQuestions ?? {};
    Object.keys(prevResponses).forEach((key) => {
      if (
        prevResponses[key] === "preferNotToSay" ||
        prevResponses[key] === "prefe not to say" ||
        prevResponses[key] === "ptns"
      ) {
        this.ptns[key] = true;
        this.selected[key] = "";
      } else {
        this.selected[key] = prevResponses[key];
      }
    });
    // this.selected = this.$store.state.results.otherQuestions.map((q) => {
    //   return q.response;
    // });
    this.$store.commit("disableGoNext");
  },
  beforeUnmount() {
    let payload = {};
    Object.keys(this.selected).forEach((key) => {
      payload[key] = this.ptns[key] ? "preferNotToSay" : this.selected[key];
    });
    this.$store.commit("updateOtherQuestions", payload);
  },
  methods: {
    next() {},
    checkCondition(conditionalId) {
      if (!conditionalId) return true;
      if (this.selected[conditionalId] === true) return true;
      if (this.selected[conditionalId] === "true") return true;
      if (this.selected[conditionalId] === "True") return true;
      if (this.selected[conditionalId] === "yes") return true;
      if (this.selected[conditionalId] === "Yes") return true;
      if (this.selected[conditionalId] === "1") return true;
      if (this.selected[conditionalId] === 1) return true;
      return false;
    },
  },
  computed: {
    questions() {
      return this.$store.state.survey?.pages?.otherQuestionsY.questions;
    },
    questionsByCategory() {
      return this.questions.reduce((acc, question) => {
        if (!acc[question.category]) {
          acc[question.category] = [];
        }
        acc[question.category].push(question);
        return acc;
      }, {});
    },
    taskIndex() {
      let catCounter = 0;
      let completedCounter = 0;
      let totalNumberOfQuestions = 0;
      Object.keys(this.questionsByCategory).forEach((category) => {
        let numberOfQuestions = this.questionsByCategory[category].length;
        totalNumberOfQuestions += numberOfQuestions;
        let numberCompleted = 0;
        this.questionsByCategory[category].forEach((question) => {
          let qResponse = this.selected[question.id];
          if (
            qResponse !== undefined &&
            qResponse !== "" &&
            qResponse !== null
          ) {
            numberCompleted++;
            completedCounter++;
          } else if (this.ptns[question.id]) {
            numberCompleted++;
            completedCounter++;
          } else if (
            question.condition &&
            !this.checkCondition(question.condition)
          ) {
            numberCompleted++;
            completedCounter++;
          } else if (!question.required) {
            numberCompleted++;
            completedCounter++;
          } else {
            return;
          }
        });
        if (numberCompleted === numberOfQuestions) {
          catCounter++;
        }
      });
      if (completedCounter === totalNumberOfQuestions) {
        this.$store.commit("enableGoNext");
        this.taskEnded = true;
      } else {
        this.$store.commit("disableGoNext");
        this.taskEnded = false;
      }
      return catCounter;
    },
  },
  watch: {
    ptns: {
      handler() {
        Object.keys(this.ptns).forEach((key) => {
          if (this.ptns[key]) {
            this.questions.find((q) => q.id == key)?.type == "checkbox"
              ? (this.selected[key] = [])
              : (this.selected[key] = "");
          }
        });
      },
      deep: true,
    },
    taskIndex(val) {
      if (this.taskEnded) return;
      if (val > this.newTaskIndex) {
        this.newTaskIndex = val;
        setTimeout(() => {
          try {
            this.$refs["cardRef" + val][0].scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          } catch (e) {
            console.log(e);
          }
        }, 300);
      }
    },
  },
};
</script>

<style scoped>

.form-control-lg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

form .likert {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  border-bottom: 2px solid #efefef;
}
form .likert:last-of-type {
  border-bottom: 0;
}
form .likert:before {
  content: "";
  position: relative;
  top: 12px;
  left: 9.5%;
  display: block;
  background-color: #e6e5e5;
  height: 6px;
  width: 78%;
}
form .likert li {
  display: inline-block;
  width: 19%;
  text-align: center;
  vertical-align: top;
}
form .likert li input[type="radio"] {
  display: block;
  position: relative;
  top: -0.2em;
  width: 100%;
  height: 1.5em;
}
form .likert li label {
  display: block;
  position: relative;
  top: -0.4em;
  padding: 0;
  padding-top: 0.5em;
  font-size: 0.9em;
  text-align: center;
}
.likert-form {
  margin-right: -1.5em;
  margin-left: -0.5em;
}
</style>
