<template>
  <div class="home home-80">
    
    <Description>
      <div v-html="$store.state.survey?.pages?.ownState?.html ?? ''"></div>
    </Description>

    <div class="align-items-stretch pt-2">
      <div v-bind:key="index" v-for="(dimension, index) in dimensions">
        <transition name="fade">
          <Card
            v-if="index <= questionIndex"
            spacingClasses="mx-0 px-3 px-md-4 my-4 py-4"
          >
            <div>
              <div class="hq-title" :style="{'text-transform': $store.state.survey?.pages?.ownState.uppercaseDimLabels ? 'uppercase' : ''}">
                {{ dimension.name }}
              </div>
              <div class="hq-subtitle" v-if="dimension.description != null">
                {{ dimension.description ?? null }}
              </div>
            </div>

            <b-form-group style="margin-top: 5px; font-size: 1.2em;">
              <b-form-radio-group
                v-model="levels[index]"
                :options="options[index]"
                @change="focusInput"
                stacked
                size="lg"
              >
              </b-form-radio-group>
            </b-form-group>
          </Card>
        </transition>
      </div>
      <GoNextBtn :promptGoNext="taskCompleted" />
    </div>
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "OwnState",
  components: {
    Description,
    Card,
    GoNextBtn,
  },
  data() {
    return {
      levels: [],
      focusIndex: 0,
    };
  },
  methods: {
    focusInput() {
      setTimeout(() => {
      if(this.focusIndex == this.levels.length){
        return
      } else {
        this.focusIndex = this.levels.length
          window.scrollTo(0, document.body.scrollHeight);
      }
        }, 200);
    },
    // selectDimensionLevel(dimension, level) {
    //   this.$store.commit("updateOwnStateDimensionLevel", {
    //     dimension,
    //     levelId: level.id,
    //   });

    //   if (!this.taskCompleted) {
    //     setTimeout(() => {
    //       window.scrollTo(0, document.body.scrollHeight);
    //     }, 50);
    //   } else {
    //     this.$store.commit("enableGoNext");
    //   }
    // },
  },
  mounted() {
    this.levels = this.$store.state.results.ownState.state;
    this.$store.commit("disableGoNext");
  },
  computed: {
    taskCompleted() {
      let taskIsCompleted = this.questionIndex == this.dimensions.length;
      if (taskIsCompleted) {
        this.$store.commit("enableGoNext");
      } else {
        this.$store.commit("disableGoNext");
      }
      return taskIsCompleted;
    },

    questionIndex() {
      return this.levels.reduce((acc, cur) => {
        if (cur == undefined) {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
    },
    dimensions() {
      return this.$store.state.survey.ds;
    },
    options(){
      return this.dimensions.map((dimension) => {
        return dimension.levels.map((level, index) => {
          return {
            text: level.label,
            value: dimension.id + "_" + index,
          };
        });
      });
    }
  },
};
</script>

<style>

.hq-title {
  font-size: 1.3em;
  color: var(--bs-primary);
  letter-spacing: 0.01em;
}
.hq-subtitle {
  color: var(--bs-secondary);
}
</style>
