const dsExpand = function (ds) {
  let lvlsArray = ds.map((dim) => {
    lvls = dim.levelRating.map((level, i) => {
      return i;
    });
    return lvls;
  });

  var r = [],
    max = lvlsArray.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = lvlsArray[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(lvlsArray[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);

  return r;
};

// function to get the scaled personal scaled utility function
// from swing weights and level ratings
// i.e. coefficiants of the utility function which can then be used
// to compute the 0-1 scaled value of any health state
const pufScaled = function (ds) {
  // get total weight to scale to 1
  totalWeight = ds
    .map((dim) => {
      return parseFloat(dim.swingWeight);
    })
    .reduce((a, b) => {
      return a + b;
    });

  // get all level ratings and multiply by scaled swing weight
  let pufScaled = ds.map((dim) => {
    let weight = parseFloat(dim.swingWeight) / totalWeight;
    levels = dim.levelRating;
    levels_rating = levels.map((level) => {
      let rating = (weight * (100 - parseFloat(level.rating))) / 100;
      return rating;
    });
    return levels_rating;
  });
  return pufScaled;
};

// helper function to compute the value of all possible health states
const stateValuator = function (states, valMat) {
  stateValues = states.map((state) => {
    let stateVal = 1; // subtract disutilities from full health
    for (let i = 0; i < state.length; i++) {
      stateVal = stateVal - valMat[i][state[i]];
    }
    return stateVal;
  });
  return stateValues;
};

// FUNCTIONS
// select DCE scenarios based on user responses
const dceScenarioSelectorAdaptive = function (
  utilSelection,
  diffs,
  ds,
  dsExpanded,
  allStatesValued
) {
  choiceSet = [];

  for (let i = 0; i < utilSelection.length; i++) {
    let util = utilSelection[i];

    // diff between util and all (user valued) states
    utilDiff = allStatesValued.map((state) => {
      return Math.abs(state - util);
    });

    // get minimum diff
    minDiff = Math.min(...utilDiff);

    // get all indices of states with min diff
    index_a = utilDiff
      .map((diff, i) => {
        if (diff == minDiff) {
          return i;
        }
      })
      .filter((x) => {
        return x != undefined;
      });

    // if indices are unique, select scenario randomly
    if (index_a.length > 1) {
      index_a = [index_a[Math.floor(Math.random() * index_a.length)]];
    }

    // ignore any state that is dominant or dominated by state A
    // if possible
    stateIsDominant = [];
    dsExpanded_a = dsExpanded[index_a];

    for (let j = 0; j < dsExpanded.length; j++) {
      // check if state is dominated by state A
      lvlDiffScore = dsExpanded[j].map((level, k) => {
        try {
          return ds[k].levelRating[level].rank - ds[k].levelRating[dsExpanded_a[k]].rank;
        } catch (err) {
          return 0;
        }
      });

      SumRaw = lvlDiffScore.reduce((a, b) => {
        return a + b;
      });

      sumAbs = lvlDiffScore.reduce((a, b) => {
        return a + Math.abs(b);
      });

      if (SumRaw == sumAbs) {
        stateIsDominant.push(true);
      } else {
        stateIsDominant.push(false);
      }
    }
    numberOfStatesDom = stateIsDominant.reduce((a, b) => {
      return a + b;
    });

    if (numberOfStatesDom == dsExpanded.length) {
      stateIsDominant = stateIsDominant.map((dom, i) => {
        if (i == index_a) {
          return true;
        } else {
          return false;
        }
      });
    }


    // get scenario B
    // compute diff between scenario A and all other (user valued) states
    // and compare the difference with the specified diff[i]
    utilDiffDiff = allStatesValued.map((val, index) => {
      if (stateIsDominant[index] == false) {
        var diff_ab = Math.abs(val - allStatesValued[index_a[0]]);
      } else {
        var diff_ab = 99;
      }
      return Math.abs(diff_ab - diffs[i]);
    });

    // get minimum diffdiff
    minDiffDiff = Math.min(...utilDiffDiff);

    // get all indices of the states with the min diffdiff
    index_b = utilDiffDiff
      .map((diff, i) => {
        if (diff == minDiffDiff) {
          return i;
        }
      })
      .filter((x) => {
        return x != undefined;
      });

    // if indices are not unique, select scenario randomly
    if (index_b.length > 1) {
      index_b = [index_b[Math.floor(Math.random() * index_b.length)]];
    }
    
    // push scenarios to choiceSet
    scenario_a = [];
    scenario_b = [];
    err_a = [];
    err_b = [];
    let utilB;
    for (let j = 0; j < ds.length; j++) {
      try {
        label_a = ds[j].levelRating[dsExpanded[index_a[0]][j]].label;
        err_a.push(false)
      } catch (err) {
        err_a.push(true)
        label_a = ds[j].levelRating[Math.floor(Math.random() * dsExpanded[0].length+0) ].label;
      }
      scenario_a.push(label_a);
      try {
        label_b = ds[j].levelRating[dsExpanded[index_b[0]][j]].label;
        err_b.push(false)
      } catch (err) {
        err_b.push(true)
        label_b = ds[j].levelRating[Math.floor(Math.random() * dsExpanded[0].length+0) ].label;
      }
      scenario_b.push(label_b);
    }

    utilB = allStatesValued[index_b[0]];

    choiceSet.push({
      a: scenario_a,
      b: scenario_b,
      utilA: util,
      utilB: utilB,
      diffs: diffs[i],
      error_a: err_a.some(err => err),
      error_b: err_b.some(err => err)
    });
  }

  return choiceSet;
};

module.exports = {
  pufScaled,
  dsExpand,
  stateValuator,
  dceScenarioSelectorAdaptive,
};
