<template>
  <div
  :id="'state-' + stateId"
    :class="class"
    :style="style"
    style="flex-grow: 1; min-width: 150px; width: 100%;"
    class="bg-primary d-flex flex-column bg-white flex-grow-1 justify-content-stretch"
  >

    <!-- scenario title -->
    <div
      v-if="scenarioTitle"
      class="px-3 py-2 border-start border-end border-secondary fw-bold fs-5 text-center"
      :style="{'background-color': titleColor}"
      style="
        border-top-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        color: white;
      "
    >
      {{ scenarioTitle }}
    </div>

    <!-- lvl labels -->
    <b-spinner
    v-if="isLoading"
              variant="primary"
              style="width: 3rem; height: 300px; border-width: 0.4em"
            >
          </b-spinner>
    <div
    v-else
      v-for="(lvl, index) in labels"
      :key="index"
      class="px-1 px-sm-2 px-md-3 py-2 border-start border-end border-secondary flex-grow-1 d-flex flex-column justify-content-center align-items-stretch"
      style="vertical-align: middle; flex-basis: 100%"
      :style="{
        'border-top-right-radius': !scenarioTitle && index == 0 ? '0.5em' : '0',
        'border-top-left-radius': !scenarioTitle && index == 0 ? '0.5em' : '0',
        'border-bottom-right-radius': !btn && index == labels.length - 1 ? '0.5em' : '0',
        'border-bottom-left-radius': !btn && index == labels.length - 1 ? '0.5em' : '0',
        'background-color': bgColors[index],
        'border-top': 'solid 0.11em var(--bs-secondary)',
        'border-bottom':
          index == labels.length - 1 ? 'solid 1px var(--bs-secondary)' : 0,
        'text-align': textAlign,
      }"
    >
      <div>
        <span v-if="appendLvlsWithDims" class="fw-bold">
          {{dimLabels[index]}}:
        </span> 
        <span v-html="lvl" />
      </div>
    </div>

    <!-- bottom btn -->
    <div 
    v-if="btn"
    class="d-flex justify-content-center align-items-center px-3 py-2 border border-secondary"
    style="
        border-top: none !important;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0.5em;
          border-bottom-left-radius: 0.5em;
        "
    >
      <input
        type="radio"
        class="btn-check"
        :id="stateId"
        name="selected-scenario"
        autocomplete="off"
        :value="stateId"
        style="color: red !important"
        v-model="selected"
        @click="$emit('select', stateId)"
        :disabled="isLoading"
      />
      <label
        class="btn btn-lg btn-outline-primary"
        :for="stateId"
      >
        {{ btnText }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "State",
  props: {
    stateId: {
      type: String,
      required: true,
    },
    dimLabels: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      required: true,
    },
    appendLvlsWithDims: {
      type: Boolean,
      default: false,
    },
    scenarioTitle: {
      type: String,
      default: "Scenario A",
    },
    btn: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: "Select",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    titleColor: {
      type: String,
      default: "var(--bs-primary)",
    },
    bgColors: {
      type: Array,
      default: () => [],
    },
    class: {
      type: String,
      default: "",
    },
    style: {
      type: Object,
      default: () => ({}),
    },
    textAlign: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
};
</script>

<style></style>
