<template>
  <div class="home home-80">
    <Description>
      <div
        v-html="$store.state.survey?.pages?.ownStateByCategory?.html ?? ''"
      ></div>
    </Description>

<div v-for="(category, i) in dimsByCategory">
    <Card class="mt-5" v-if="i <= categoryIndex">
      <h3>{{ category.category }}</h3>
      <table class="mt-2 ownState-table" style="wh">
        <colgroup>
          <col span="1" style="width: 30%" />
        </colgroup>
        <tr>
          <td></td>
          <th
            v-for="level in category.levels"
            class="align-middle text-center"
          >
            {{ level }}
          </th>
        </tr>
        <tr v-bind:key="index" v-for="(dimension, index) in category.dimensionNames">
          <td>
            {{ dimension }}
          </td>
          <td
          v-bind:key="index2"
            v-for="(level,index2) in category.levels"
            style="text-align: center"
          >
            <input
              class="form-check-input fs-4"
              type="radio"
              :name="category.dimensionIds[index]"
              :checked="index2 == selected[category.questionIndex[index]]?.slice(-1)"
              :data-col="index2 + 1"
              @change="updateOwnStateByCategory(category.dimensionIds[index], index2)"
            />
              <!-- :value="selected[category.questionIndex[index]][selected[category.questionIndex[index]].length-1] == index2 ? true : false" -->
          </td>
        </tr>
      </table>
    </Card>
    </div>
      <GoNextBtn :promptGoNext="taskCompleted" />
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "OwnStateByCategory",
  components: {
    Description,
    Card,
    GoNextBtn,
  },
  data() {
    return {
      levels: [],
      selected: [],
      focusIndex: 0,
      categoryIndex: 0,
    };
  },
  methods: {
    updateOwnStateByCategory(dimensionId, index) {
      this.dimensions.forEach( (dimension, dimIndex) => {
        if (dimension.id == dimensionId) {
          this.selected[dimIndex] = dimensionId + "_" + index;
        }
      });
      var shownQuestions = 0;

      for (let j = 0; j <= this.categoryIndex; j++) {
        shownQuestions = shownQuestions + this.dimsByCategory[j].dimensionIds.length;
      }
      let answerCount = this.selected.reduce(c => c + 1, 0);
      if(answerCount == shownQuestions) {
        if(this.categoryIndex < this.dimsByCategory.length - 1) {
          this.categoryIndex++;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 200)
      }
      }
    },
  },
  mounted() {
    this.selected = this.$store.state.results.ownState.state;
    let answerCount = this.selected.reduce(c => c + 1, 0);
    this.dimsByCategory.forEach( (category, i) => {
      answerCount = answerCount - category.questionIndex.length;
      if(answerCount>=0){
        if(this.categoryIndex < this.dimsByCategory.length - 1) {
        this.categoryIndex++;
        }
      }
    })

    this.$store.commit("disableGoNext");
  },
  computed: {
    taskCompleted() {
      let taskIsCompleted = this.questionIndex == this.dimensions.length;
      if (taskIsCompleted) {
        this.$store.commit("enableGoNext");
      } else {
        this.$store.commit("disableGoNext");
      }
      return taskIsCompleted;
    },

    questionIndex() {
      return this.selected.reduce((acc, cur) => {
        if (cur == undefined) {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
    },
    dimensions() {
      return this.$store.state.survey.ds;
    },
    dimsByCategory() {
      function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
      }
      let categories = this.dimensions.map((dimension) => {
        return dimension.category;
      });
      let uniqCategories = [...new Set(categories)];

      let dimsByCategory = [];
      let fromQCount = 0;
      for (let index = 0; index < uniqCategories.length; index++) {
        let catDims = this.dimensions.filter((dimension) => {
            return dimension.category == uniqCategories[index];
          });
          let toQCount = fromQCount + catDims.length-1;
          dimsByCategory[index] = {
            category: uniqCategories[index],
            dimensionIds: catDims.map(dimension => dimension.id),
            dimensionNames: catDims.map(dimension => dimension.name),
            levels: catDims[0].levels.map(level => level.label),
            questionIndex: range(fromQCount, toQCount),
          };
          fromQCount = toQCount + 1;
      }
      return dimsByCategory;
    },

  },
};
</script>

<style lang="scss">
.hq-title {
  font-size: 1.3em;
  color: var(--bs-primary);
  letter-spacing: 0.01em;
}
.hq-subtitle {
  color: var(--bs-secondary);
}
.ownState-table {
  white-space: normal;
  overflow: visible;
  border-collapse: separate;
  flex-grow: 1;
  flex-shrink: 1;
  border-bottom: 1px solid var(--bs-secondary);

.form-check-input {
  margin-top: 0;
}

  td {
    padding: 0.75em 0.3em !important;
  }

  th {
    padding: 0.3em 0.5em;
    border-bottom: 1px solid var(--bs-secondary);
  }
}
</style>
