<template>
  <div
    style="
      width: 100vw;
      background-image: url('img/bg-living.svg');
      background-position: 0 0;
      background-size: 120% 120%;
      overflow-y: scroll;
    "
    class=""
  >
  <CookieConsent />
  
    <div>
      <div
        class="position-fixed top-0 start-0 dnavbar-header end-0"
        style="z-index: 100; max-width: 100vw"
      >
        <div class="">
          <nav class="nav">
            <div
              class="d-flex flex-wrap flex-md-nowrap white-space-nowrap container-md align-items-end gap-2 gap-md-4 gap-lg-5 justify-content-center justify-content-md-start"
              style=""
            >
              <a role="button" href="/#" id="nav-home">
                <img
                  src="img/vh-logo-text.svg"
                  loading="lazy"
                  alt=""
                  style="height: 45px"
                />
              </a>
              <a
                role="button"
                class="nav-item"
                id="nav-solution"
                href="#solution"
                >Solution</a
              >
              <a
                role="button"
                class="nav-item"
                href="#usecases"
                id="nav-usecases"
                >Use cases</a
              >
              <a
                role="button"
                class="nav-item"
                href="#research"
                id="nav-research"
                >Research</a
              >
              <a role="button" class="nav-item" href="#about" id="nav-about"
                >About</a
              >
              <a role="button" class="nav-item" href="#contact" id="nav-contact"
                >Contact</a
              >
            </div>
          </nav>
        </div>
      </div>
    </div>

    <main>
      <!-- home section -->
      <section id="home">
        <div class="" style="padding-top: 35px">
          <div class="">
            <div class="">
              <div class="container mx-auto mw-80ch pb-5">
                <div
                  class="hero pt-5 text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="pb-5 display-4 source fw-bold"
                    style="padding-top: 100px"
                  >
                  Software for Better Health Outcomes Research
                  </div>

                  <div class="roboto fs-4 text-secondary mw-50 pb-5">
                    Integrated software platforms to elicit <br> patient preferences and expert judgements.

                  </div>

                  <div class="">
                    <a
                      role="button"
                      class="btn btn-primary btn-lg rounded-pill px-4 py-2 fs-5 mb-5 text-white"
                      href="#contact"
                    >
                      Get in touch to learn more
                    </a>

                    <!-- <div class="position-relative" style="width: 100vw">
                      <div
                        class="position-absolute start-50 w-100"
                        style="z-index: 9; transform: translateX(-50%)"
                      >
                        <img
                          loading="lazy"
                          src="img/hero.webp"
                          alt="hero image"
                          style="max-width: 800px; width: 75%"
                          class="drop-shadow-md"
                        />
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="top-clip bg-white"></div>

      <section id="solution" class="py-5 bg-white" style="margin-top: -1px">
        <div
          class="container mw-100ch px-2 px-sm-3 px-xl-0 mt-5"
        >
          <!-- style="margin-top: min(30vw, 250px)" -->
          <div class="alt-h2">Solution</div>
          <div class="d-flex flex-wrap flex-md-nowrap align-items-start gap-1">
            <div class="w-100 w-md-50 fs-5" style="z-index: 1">
              <p>
                The Valorem health platform provides a comprehensive suite of software tools to capture, analyse, and visualise patient preference data, and expert insights.
              </p>
              <p>Our applications are:</p>
              <ul>
                <li class="pb-1">
                  Web-based, mobile-friendly, and designed to be intuitive and
                  easy to use.
                </li>
                <li class="pb-1">
                  Secure and compliant with government standards such as HIPAA,
                  FDA CFR Part 11, GDPR, and EU GxP Annex 11.
                </li>
                <li class="pb-1">
                  Scalable and flexible, to easily integrate with existing
                  systems and processes.
                </li>
              </ul>
            </div>

            <div
              class="w-100 w-md-50 d-flex flex-column justify-content-center align-items-center"
            >
              <img
                src="img/aws-diagram.png"
                alt=""
                style="width: 120%; max-width: 100vw; z-index: 0"
              />
              <div
                class="d-flex flex-wrap flex-md-nowrap gap-3 mt-4 justify-content-evenly align-items-center w-100 px-3"
              >
                <!-- <div class="img-fluid" style="height: 30px; width: auto;">
                <img src="img/aws.png" loading="lazy alt="" height="30px" width="auto">
              </div>
               -->

                <div class="img-fluid" style="height: 50px; width: auto">
                  <img
                    src="img/cert4.png"
                    loading="lazy"
                    style="height: 50px; width: auto"
                    alt=""
                  />
                </div>

                <div class="img-fluid" style="height: 50px; width: auto">
                  <img
                    src="img/cert3.jpg"
                    loading="lazy"
                    style="height: 50px; width: auto"
                    alt=""
                    height="50px"
                    width="auto"
                  />
                </div>

                <div class="img-fluid" style="height: 50px; width: auto">
                  <img
                    src="img/gdpr.png"
                    loading="lazy"
                    alt=""
                    style="height: 50px; width: auto"
                    height="50px"
                    width="auto"
                  />
                </div>
                <div class="img-fluid" style="height: 50px; width: auto">
                  <img
                    src="img/cert1.png"
                    loading="lazy"
                    alt=""
                    style="height: 50px; width: auto"
                    height="50px"
                    width="auto"
                  />
                </div>
                <div
                  class="img-fluid"
                  style="height: 40px; width: auto; margin-top: 5px"
                >
                  <img
                    src="img/aws.png"
                    loading="lazy"
                    style="height: 45px; width: auto"
                    alt=""
                    height="45px"
                    width="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style="margin-top: -1px" class="bottom-clip bg-white"></div>
      <section id="usecases" class="pt-5">
        <div class="container mw-80ch py-5 fs-5 px-2 px-sm-3 px-xl-0">
          <div class="alt-h2">Use cases</div>
          <div class="d-flex flex-column gap-5">
            <!-- case 1 -->
            <div
              class="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between"
            >
              <div class="col-12 col-md-6">
                <div class="alt-h3">
                  #1 Discrete Choice Experiments (DCE)
                </div>
                <p>
                  Understanding what matters to patients is crucial at any stage of the medical product lifecycle. Our DCE survey platform allows our clients to capture patient preference data that can inform various aspects of healthcare, including product development, trial design, and regulatory and reimbursement decisions.
                </p>
              </div>
              <div
                class="flex-fill d-flex flex-column align-items-center col-12 col-md-6"
              >
                <img
                  src="img/case1_new.webp"
                  alt=""
                  class="drop-shadow-md rounded-3"
                  style="width: 75%"
                  loading="lazy"
                />
              </div>
            </div>

            <!-- case 2 -->
            <div
              class="d-flex flex-wrap flex-md-nowrap align-items-start justify-content-between"
            >
              <div class="col-12 col-md-6 mt-5">
                <div class="alt-h3">#2 Multidimensional Thresholding (MDT)</div>
                <p>
                  Valuing health outcomes using e.g. EQ-5D or SF-6D is an
                  essential component in health technology assessments. In
                  addition to standard valuation methods, such as time
                  trade-off, we offer Online elicitation of Personal Utility
                  Functions (OPUF), which allows capturing individual (patient)
                  preferences and use them even for valuing benefits of
                  treatments for rare diseases.
                </p>
              </div>
              <div class="d-flex flex-column align-items-start col-12 col-md-6">
                <img
                  src="img/case2_new.webp"
                  alt=""
                  class="mx-auto rounded-3 drop-shadow-md"
                  style="width: 50%"
                  width="50%"
                  loading="lazy"
                />
              </div>
            </div>

            
            <!-- case 4 -->
            <div
              class="d-flex flex-wrap flex-md-nowrap align-items-start justify-content-between"
            >
              <div class="col-12 col-md-6 mt-3">
                <div class="alt-h3">#3 Value assessment</div>
                <p>
                  Valuing health outcomes using e.g. EQ-5D or SF-6D is an essential component in health technology assessments. In addition to standard valuation methods, such as time trade-off, we offer Online elicitation of Personal Utility Functions (OPUF), which allows capturing individual (patient) preferences and use them even for valuing benefits of treatments for rare diseases.
                </p>
              </div>
              <div
                class="d-flex flex-column align-items-center col-12 col-md-6"
              >
                <img
                  src="img/case4_new.webp"
                  alt=""
                  class="mx-auto rounded-3 shadow mt-3"
                  width="75%"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style="margin-top: -1px" class="top-clip bg-white"></div>

      <section id="research" class="bg-white">
        <div class="container mw-60ch py-5 fs-5 px-2 px-sm-3 px-xl-0">
          <div class="alt-h2">Research</div>
          <p>
            We do methodological research and regularly contribute to scientific
            publications, panels, and workshops. We have published in leading
            methodological journals such as Health Economics, Value in Health,
            and Medical Decision Making, and in leading clinical journals such
            as Thorax and Multiple Sclerosis Journal.
          </p>
          <p>
            If you have an idea for a research project, or if you would like to
            use our software for your research, please do get in touch &#8211;
            we offer significant discounts for academics and are keen to
            collaborate on interesting projects.
          </p>

          <div class="py-4">
            <div class="alt-h3">Featured publications</div>
            <div class="reference">
              Schneider P, Blankart K, Brazier J, van Hout B, Devlin N. Using the Online Elicitation of Personal Utility Functions Approach to Derive a Patient-Based 5-Level Version of EQ-5D Value Set: A Study in 122 Patients With Rheumatic Diseases From Germany. Value in Health. 2024 Mar 1;27(3):376-82.
            </div>
            <div class="reference">
              Heidenreich S, Postmus D, Tervonen T. Multi-Dimensional Thresholding for Individual-Level Preference Elicitation. Value in Health. 2024 Feb 28.
            </div>
            <div class="reference">
              Tervonen T, Pignatti F, Postmus D. From Individual to Population
              Preferences: Comparison of Discrete Choice and Dirichlet Models
              for Treatment Benefit-Risk Tradeoffs. Medical Decision Making.
              2019;39: 879-85.
            </div>
            <div class="reference">
              Schneider PP, van Hout B, Heisen M, Brazier J, Devlin N. The
              Online Elicitation of Personal Utility Functions (OPUF) tool: a
              new method for valuing health states. Wellcome Open Research.
              2022;7:14.
            </div>
            <div class="reference">
              Fox RJ, Tervonen T, Phillips-Beyer A, Sidorenko T, Boyanova N,
              Brooks A, Hennessy B, Jamieson C, Levitan B. The relevance of
              fatigue to relapse rate in multiple sclerosis: applying patient
              preference data to the OPTIMUM trial, Multiple Sclerosis Journal.
              2023;29(3):427-35.
            </div>
            <div class="reference">
              Schneider PP. Social tariffs and democratic choice—Do
              population‐based health state values reflect the will of the
              people? Health Economics. 2021;30(1):104-12.
            </div>

            <div class="mt-3">
              Contact us for a full list of our publications.
            </div>
          </div>
        </div>
      </section>

      <div style="margin-top: -1px" class="bottom-clip bg-white"></div>

      <section id="about" class="">
        <div class="container mw-80ch py-5">
          <div class="alt-h2">About us</div>
          <div class="mw-60ch fs-5">
            <p>
              Valorem health was founded by Dr Paul Schneider and Dr Tommi
              Tervonen in 2023. During our previous roles in consultancy and
              academia, we led dozens of scientific patient preference studies
              to support development and commercialization of innovative
              therapies.
            </p>
            <p>
              We saw that the lack of suitable software was a major barrier to
              the wider availability and use of patient experience data &#8211;
              in rare diseases and in clinical trials, it even often meant that
              the patient perspective was omitted completely.
            </p>

            <!-- The patient's perspective should be at the center of decision making in clinical care, health policy, and medical product development. For this, relevant patient preference information needs to be much more widely available. It then just doesn't make sense that collecting patient preference studies is so difficult, costly, and time consuming...  -->

            <p class="fw-bold">
              Our mission is to make it easy for organisations to capture,
              analyse, and visualise patient experience data at any stage of the
              medical product lifecycle.
            </p>
          </div>

          
          
        </div>
      </section>

      <div class="top-clip bg-white" style="margin-bottom: -1px"></div>

      <section id="contact" class="bg-white pb-5">
        <div class="container mw-100ch pt-5 d-flex flex-wrap flex-sm-nowrap gap-2 gap-lg-4">
          <div class="">
            <div class="alt-h2">Contact</div>
            <div class="mw-60ch fs-5">
              <p>
                Get in touch to discuss your project and find out how we can
                help.
              </p>
              <div class="lead mt-5">
                <div class="fw-bold">Valorem health GmbH</div>
                <div>Gewerkenstrasse 8</div>
                <div>44805 Bochum, Germany</div>
                <div class="mt-1">
                  <a
                    href="mailto:contact@valorem.health"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact@valorem.health</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="container w-100 pt-5">
            <!-- a contact form  using bootstrap vue components -->

            <div class="form-group">
              <label for="name" class="fs-5"
                >Name <sup><span class="text-primary">* </span></sup></label
              >
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="name"
                :class="{ 'is-invalid': invalidName }"
                :disabled="success"
              />
              <div class="invalid-feedback">Please enter your name.</div>
              <label for="email" class="pt-3 fs-5"
                >Email
                <sup><span class="text-primary">* </span></sup>
              </label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
                :class="{ 'is-invalid': invalidEmail }"
                :disabled="success"
              />
              <div class="invalid-feedback">
                Please enter a valid email address.
              </div>
              <label for="subject" class="pt-3 fs-5">Subject</label>
              <input
                type="text"
                class="form-control"
                id="subject"
                v-model="subject"
                :disabled="success"
              />
              <label for="message" class="pt-3 fs-5"
                >Message <sup><span class="text-primary">* </span></sup></label
              >
              <textarea
                class="form-control pt-3 fs-5"
                id="message"
                rows="8"
                :disabled="success"
                v-model="message"
                :class="{ 'is-invalid': invalidMessage }"
              ></textarea>
              <div class="invalid-feedback">Please enter a message.</div>
              <button
                type="submit"
                class="btn btn-primary mt-3 btn-lg"
                :disabled="success"
                @click="submitCheck"
              >
                Send message
              </button>
              <div
                class="alert alert-success mt-3"
                role="alert"
                :style="{ opacity: success ? 1 : 0 }"
              >
                Thank you for your message! We will get back to you shortly.
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>


    <footer class="bg-beige border-top shadow pt-4 pb-2">
      <div class="container pt-4">
        <div
          class="d-flex flex-wrap flex-md-nowrap justify-content-start align-items-start"
        >
          <div class="pe-3 mb-3">
            <img src="img/favicon.png" alt="" height="120px" />
          </div>

          <div class="lh-sm me-auto">
            <div class="fw-bold pb-1">Valorem health GmbH</div>
            <div>Gewerkenstr 8</div>
            <div>44805 Bochum, Germany</div>
            <a href="mailto:contact@valorem.health"> contact@valorem.health</a>
            <div>HRB 20709, Amtsgericht Bochum.</div>
          </div>

          <div
            class="d-flex flex-column px-3 ms-2 border-start lh-sm me-auto mb-3"
          >
            <a class="text-dark" href="#home">Home</a>
            <a class="text-dark" href="#solution">Solution</a>
            <a class="text-dark" href="#usecases">Use cases</a>
            <a class="text-dark" href="#research">Research</a>
            <a class="text-dark" href="#about">About</a>
            <a class="text-dark" href="#contact">Contact</a>
            <router-link
              to="/privacy"
              class="a text-decoration-underline text-secondary w-100 mt-3"
              >Privacy policy</router-link
            >
          </div>

          <div class="ms-auto ps-4 d-flex flex-column ms-auto">
            <div class="d-flex flex-wrap ms-auto">
              <div class="text-decoration-underline text-secondary small w-100">
                Network:
              </div>
              <img
                src="img/network-logo-2.webp"
                loading="lazy"
                alt=""
                height="60px"
                class=""
              />
              <img
                src="img/network-logo-1.png"
                loading="lazy"
                alt=""
                height="50px"
              />
              <img
                src="img/network-logo-3.png"
                loading="lazy"
                alt=""
                class="pt-2"
                height="40px"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="small text-secondary pb-2 ps-4">
        &copy; Valorem health 2023. All Rights Reserved.
      </div>
    </footer>
  </div>
</template>

<script>
// axios for sending form data to server
import axios from "axios";

// impoirt cookieconsent component
import CookieConsent from "@/components/CookieConsent.vue";
export default {
  name: "Info",
  components: {
    CookieConsent,
  },




  data() {
    return {
      name: "",
      invalidName: false,
      email: "",
      invalidEmail: false,
      message: "",
      invalidMessage: false,
      subject: "",
      success: false,
    };
  },

  created() {
    if (this.$gtag) {
      this.$gtag.event("page_view", {
        page_title: "Info",
        page_location: "/",
      });
    }
  },

  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    isValidEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    submitCheck(event) {
      console.log("checking form");
      event.preventDefault();
      let anyInvalid = false;
      if (this.name === "") {
        this.invalidName = true;
        anyInvalid = true;
      } else {
        this.invalidName = false;
      }
      if (!this.isValidEmail(this.email)) {
        this.invalidEmail = true;
        anyInvalid = true;
      } else {
        this.invalidEmail = false;
      }
      if (this.message === "") {
        this.invalidMessage = true;
        anyInvalid = true;
      } else {
        this.invalidMessage = false;
      }
      if (anyInvalid) {
        return console.log("invalid form");
      } else {
        this.submitForm();
      }
    },
    submitForm() {
      console.log("submitting form");
      const formData = {
        sender: this.name,
        senderEmail: this.email,
        subject: this.subject,
        message: this.message,
      };
      axios
        .post(
          "https://s9xdjew0t4.execute-api.eu-central-1.amazonaws.com/prod/contact",
          formData
        )
        .then((response) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
            this.name = "";
            this.email = "";
            this.subject = "";
            this.message = "";
          }, 15000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    const navLinkController2 = (e) => {
      var elems = document.querySelector(".active");
      if (elems !== null) {
        elems.classList.remove("active");
      }
      if (e !== undefined && e.className == "nav-item") {
        e.className = "nav-item active";
      }
    };

    const highlightCurrentNav = () => {
      let currentY = window.scrollY + window.innerHeight / 2;
      const sections = document.querySelectorAll("section");
      // which section is currently in view?
      let currentSection = sections[0];
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].offsetTop <= currentY) {
          currentSection = sections[i];
        }
      }
      let currentNavId = "nav-" + currentSection.id;
      let currentNav = document.getElementById(currentNavId);
      console;
      if (currentNav !== null) {
        navLinkController2(currentNav);
      }
    };

    document.addEventListener("DOMContentLoaded", function () {
      document.addEventListener("scroll", highlightCurrentNav);
    });
  },
};
document.title = "Valorem health";
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap'); */

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
}
.source {
  font-family: "Source Serif Pro", serif;
}
.alt-h2 {
  font-family: "Source Serif Pro", serif;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.alt-h3 {
  font-family: "Source Serif Pro", serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.vh-primary {
  color: #ff5a46;
}

.bg-beige {
  background-color: #f7f5ee !important;
}
.bg-light-blue {
  background-color: #dce8e8 !important;
  position: relative;
}
.h-100 {
  height: 100vh !important;
}
.text-primary {
  color: #ff5a46 !important;
}
.btn-primary {
  background-color: #ff5a46 !important;
  border-color: #ff5a46 !important;
  color: white !important;
}
/* change outline and shaodw of btn to match col */
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  outline: #ff5a46 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 70, 0.5) !important;
  color: white !important;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
.w-75 {
  width: 75%;
}
.mw-100ch {
  max-width: 110ch !important;
}
.mw-80ch {
  max-width: 90ch !important;
}
.mw-60ch {
  max-width: 70ch !important;
}
.mw-50 {
  max-width: 50ch;
}

.bottom-clip {
  clip-path: polygon(0 0, 0 100%, 100% 0);
  height: 10vw;
}
.top-clip {
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
  height: 10vw;
}

.navbar-header {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(1em);
  backdrop-filter: blur(1em);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .navbar-header {
    background-color: rgba(255, 255, 255, 0);
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
  }
}
.nav-item {
  font-size: 1.2rem;
  padding-bottom: 5px;
  font-weight: 400;
  color: #3f3f3f !important;
  text-decoration: none !important;
  white-space: nowrap;
  /* white shadow behind charaters */
  text-shadow: 0 0 0.1rem rgba(255, 255, 255, 0.5);
}
.nav-item.active {
  color: #ff5a46 !important;
  border-bottom: 3px solid #ff5a46 !important;
  font-weight: 600;
}
.nav-item:hover {
  color: #ff5a46 !important;
  font-weight: 500;
}
a {
  color: #ff5a46 !important;
}
a:hover {
  color: #ff5a46 !important;
}

.reference {
  padding-left: 2.5rem;
  padding-right: 1rem;
  text-indent: -1rem !important;
  text-align: justify;
  padding-bottom: 1rem;
  line-height: 1.3;
}

.avatar {
  vertical-align: middle;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.filter-primary {
  filter: invert(77%) sepia(68%) saturate(7246%) hue-rotate(334deg)
    brightness(106%) contrast(101%);
}

.drop-shadow-light {
  -webkit-filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
}
.drop-shadow-md {
  -webkit-filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.3));
}
</style>
