<template>
  <div
     class="cookie-consent-banner-custom text-secondary d-flex mb-0 mb-md-3 mb-lg-5 align-items-center justify-content-center
     rounded-responsive
     "
    style="
      position: fixed !important;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 800px;
        background-color: #fff;
        padding: 12px;
        z-index: 100;
        border: 1px solid #ccc;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        line-height: 1.2;
    "
    :style="{ display: clicked ? 'none !important' : 'block' }"
  >
  <div>
    <div class="fw-bold pb-1">About Cookies On This Site</div>
    <div>
        We use cookies to learn how our site is used and improve the user experience. 
        
        For further information, please see our <router-link to="/privacy">Privacy Policy</router-link>.
    </div>
  </div>
    <div class="d-flex flex-row justify-content-center ps-1 ps-md-3 ps-lg-5 pe-3 gap-2 mt-auto">
    <button class="btn btn-dark" @click="acceptCookies">Accept</button>
    <button class="btn btn-dark ms-1" @click="rejectCookies">Reject</button>
    </div>
  </div>
</template>

<script>
export default {
    name: "CookieConsent",
    data() {
        return {
            clicked: false,
        };
    },
  methods: {
      acceptCookies() {
        console.log("acceptCookies");
        gtag("consent", "update", {
        analytics_storage: "granted",
        });
        // set to current time + 1 year
        localStorage.setItem("cookie_consent", Date.now() + 31536000000);
        this.clicked = true;
    },
      rejectCookies() {
          this.clicked = true;
      return;
    },
    },
    mounted() {
        if (localStorage.getItem("cookie_consent")) {
            if (localStorage.getItem("cookie_consent") < Date.now()) {

                this.clicked = false;
            } else {
                localStorage.removeItem("cookie_consent");
                this.clicked = true;
            }
        }
    },
};
</script>

<style scoped>
.cookie-consent {
  z-index: 100;
  border-top: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.rounded-responsive {
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .rounded-responsive {
    border-radius: 0;
  }
}
</style>
