<template>
  <div :style="cssVars">
    <div :class="styleClass">
      <div 
      :id="sliderId" 
      class="vue-nouislider" 
      @click="revealOnClick ? revealHandle : null"
      ></div>
    </div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
export default {
  name: "Nouislider",
  props: {
    dimension: {
      type: Object,
    },
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default() {
        return "id-" + Math.random().toString(36).substr(2, 4);
      },
    },
    styleClass: {
      type: String,
      default: "",
    },
    revealOnClick: {
      type: Boolean,
      default: false,
    },
    hiddenOnStart: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      init: false,
      handleMadeVisible: false,
      sliderHandle: null,
    };
  },
  emits: ["update", "handleMadeVisible", "set"],
  computed: {
    cssVars() {
      return {
        "--dimension-color": 'hsl(' + this.dimension?.hexColor + ', 100%, 50%)'
      };
    },
    sliderId() {
      if (this.id === undefined) {
        return this.uniqueId();
      }
      return this.id;
    },
    slider() {
      return document.getElementById(this.id);
    },
  },
  mounted() {
    this.config.start = this.values;
    noUiSlider.create(this.slider, this.config);
    if (this.disabled) {
      this.slider.setAttribute("disabled", true);
      this.slider.style.opacity = 0.65;
      this.slider.style.backgroundColor = "lightgray";
    }
    this.sliderHandle = this.slider.querySelector(".noUi-origin .noUi-handle");
    if(this.hiddenOnStart) {
      this.sliderHandle.style.visibility = "hidden";
    }
    this.slider.noUiSlider.on("update", this.updateValue);
    this.slider.noUiSlider.on("set", this.updateValue2);
  },
  methods: {
    updateValue(value, handle) {
      this.values[handle] = value[handle];
      this.$emit("update", this.values);
      if(this.values>0) this.handleMadeVisible = true;
      
    },
    updateValue2(value, handle) {
      this.values[handle] = value[handle];
      this.$emit("set", this.values);    
      if(this.values>0) this.handleMadeVisible = true;
    },
    
    revealHandle() {
        if(this.sliderHandle.style.visibility = "hidden") {
          this.sliderHandle.style.visibility = "visible";
        }
    },

    uniqueId() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return `vue-nouislider-${s4()}${s4()}`;
    },
  },
  watch: {
    handleMadeVisible(value) {
      if (value) {
        this.$emit("handleMadeVisible", value);
        this.revealHandle();
      }
    },
  }
};
</script>

<style lang="scss">
@import "~nouislider/dist/nouislider.min.css";

.noUi-origin .noUi-handle {
  cursor: grab;
}

.vue-nouislider .noUi-connect {
  background-color: var(--dimension-color) ;
  box-shadow: none ; 
}

.noUi-connects  {
 cursor: crosshair;
 z-index: 2 !important;
}
.noUi-pips{
  pointer-events: none;
  z-index: 1 !important;
}
[disabled] .noUi-handle, [disabled].noUi-target, [disabled] .noUi-connects {
  cursor: not-allowed !important;
}
[disabled] .noUi-connects {
  opacity: 0.5;
}

// hack to increase cliackable area size:
.noUi-horizontal {
  .noUi-connects {
    height: 62px !important;
    top: -25px !important;
  }
  .noUi-connect {
    height: 16px !important;
    top: 25px !important;
    border-radius: 4px !important;
  }
}

.noUi-vertical {
  .noUi-connects {
    position: absolute !important;
    width: 62px !important;
    right: -25px !important;
  }
  .noUi-connect {
    width: 16px !important;
    right: 26px !important;
    border-radius: 4px !important;
  }
}




</style>
