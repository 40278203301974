<template>
  <div class="d-flex flex-column me-3 me-lg-4 me-lg-5">
    
    <div class="GoNextBtn mt-4 ms-auto d-flex">
      <div
      class="me-5 my-auto text-go-next"
      :style="{ visibility: promptGoNext ? 'visible' : 'hidden' }"
      >
      {{
        customMsg == "" ? $store.state.survey.navText?.promptGoNext : customMsg
      }}
    </div>
    
      <span
      id="goNextBtn-tooltip"
      data-bs-html="true"
      :title="$store.state.survey.navText?.noGoNextTooltip"
    >


      <b-button
        ref="goNextBtn"
        class="button btn-go-next"
        variant=""
        @click="nextPage"
        :class="{disabled: !canGoNext}"
      >
        <!-- :class="{
          disabled: !canGoNext,
          'btn-primary': canGoNext,
          'btn-outline-primary': !canGoNext,
        }" -->
        {{$store.state.survey.navText?.continueStr ?? 'Continue'}}
      </b-button>
    </span>
  </div>
  <div class="ms-auto mt-3 me-5">
    <span
        id="goBackBtn-tooltip"
        data-bs-html="true"
        :title="$store.state.survey.navText?.noGoBackTooltip"
      >
    <div 
    @click="previousPage" 
    class="text-go-back"
    :style="{ visibility: canGoBack ? 'visible' : 'hidden' }"
    >
    &#60; <span class="text-decoration-underline">  
      {{$store.state.survey.navText?.backStr ?? 'Go back'}}
    </span>
    </div>
    </span>
  </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
  name: "GoNextBtn",
  components: {},
  data() {
    return {
      tooltip: null,
      tooltipBack: null,
    };
  },
  props: {
    promptGoNext: {
      type: Boolean,
      default: false,
    },
    customMsg: {
      type: String,
      default: "",
    },
  },
  computed: {
    nav() {
      return this.$store.state?.navigation;
    },
    canGoNext() {
      if (this.nav.canGoNext) {
        this.tooltip?.hide();
        this.tooltip?.disable();
      } else {
        this.tooltip?.enable();
      }
      return this.nav.canGoNext;
    },
    canGoBack() {
        return this.nav.canGoBack;
    },
  },
  methods: {
    nextPage() {
      if (this.canGoNext) this.$store.dispatch("go", 1);
    },
    previousPage() {
      if (this.canGoBack);
      this.$store.dispatch("go", -1);
    },
  },
  mounted() {
    var exampleEl = document.getElementById("goNextBtn-tooltip");
    this.tooltip = new Tooltip(exampleEl);
    if(process.env.NODE_ENV === "development"){
      window.addEventListener('keypress', (e) => {
        if(e.key === 'Enter'){
          this.$store.dispatch("go", 1);
        }
      });
    }
  },
};
</script>


<style scoped>

.text-go-next {
  color: #4d5359;
  font-weight: 300;
  font-size: 1.1em !important;
  text-align: center !important;
  text-shadow: 1px 1px 1px #fff;
}
@media (max-width: 768px) {
  .text-go-next {
    font-size: 0.9rem !important;
    margin-left: auto !important;
    margin-right: 20px !important;
    text-align: center !important;
  }
};
.text-go-back {
  margin-right: 15px;
  color:rgb(117, 117, 117);
  cursor: pointer;
}
.hidden{
  color: transparent;
  pointer-events: none;
}
.btn-go-next {
    background: #fff;
    background-image: linear-gradient(to right bottom, #ffffff,  #ffedfe, #d1d9fe);
    background-repeat: no-repeat;
    background-size: 250% 100%;
    color: #333;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 10px 28px;
    border-radius: 18px;
    border: #d1d1d1 1px solid;
    margin-right: 10px;
    position: relative;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in;
}

.btn-go-next.disabled {
    outline: none;
    opacity: 0.5;
    color: rgb(131, 131, 131);
}

.btn-go-next:hover {
  /* make the background slightly gray */
  background-position: right center;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.4);
}
/* background-color: rgba(30,160,241,0.7); */

.GoNextBtn {
  
}
/* @media (max-width: 400px) {
  .GoNextBtn {
    display: none;
  }
} */

</style>
