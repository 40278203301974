<template>
  <div class="home home-60">

    Redirector

    A) redirect based on screen

    B) redirect randomly

    
    <!--  select input -->
    <div 
    v-if="true"
    class="d-flex flex-column"
    >
    C) redirect based on selected
        <b-form-select
         v-model="selected" 
         :options="options"
         />
        <button 
        class="btn btn-primary mt-2 ms-auto"
        @click="redirectToExternalUrl">
        Ok
        </button>
    </div>


  </div>
</template>

<script>
export default {
    name: 'Redirector',
    data() {
        return {
        selected: null,
        options: [
          { value: null, text: 'Please select an option' },
          { value: 'test-a', text: 'This is First option' },
          { value: 'b', text: 'Selected Option' },
          { value: { C: '3PO' }, text: 'This is an option with object value' },
          { value: 'd', text: 'This one is disabled', disabled: true }
        ],
        }
    },
    methods: {
        updateIdentiferSuffix() {
            // this.$store.commit('updateIdentifierSuffix', this.$route.params.identifierSuffix)
        },
        redirectToExternalUrl() { 
            this.$store.commit("setEditingInProgress", false);
            // update suffix
            let url;
            if(true){
                url = 'http://localhost:8080/' + this.selected;
            }
          window.location.href = url;
        },
    },
    computed: {
        //
    }
}
</script>

<style>

</style>