<template>
  <div class="home home-100 w-100">

    <QuestionCard :content="content"/>
    
    <!-- info | task -->
    <div
      class="d-flex align-items-start flex-wrap flex-sm-nowrap justify-content-stretch"
    >
      <!-- Info -->
      <div
        class="card mx-3 mb-3"
        style="flex-basis: 50%; flex-grow: 1"
      >
        <div class="card-body" v-html="content.info"></div>
      </div>

      <!-- Task -->
      <div
        class="card mx-3"
        style="flex-basis: 50%; flex-grow: 1"
      >
        <div class="card-body">
            <b-form-group
              label-class="text-center mx-auto"
              class="d-flex flex-column justify-content-stretch"
              label=""
              v-slot="{ prioritizationLabel }"
            >
              <b-form-radio-group
                id="priority-dimension"
                :options="allWorstLevels"
                v-model="selected"
                @change="updateDimensionFirstRank"
                :aria-describedby="prioritizationLabel"
                class="d-flex flex-column"
                size="md"
                button-variant="div first-rank-btn my-1 w-100"
                name="radio-btn"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          
        </div>
      </div>
    </div>

    <GoNextBtn :promptGoNext="selected != ''" />
  </div>
</template>

<script>
import QuestionCard from "@/components/QuestionCard.vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

export default {
  name: "DimFirstRank",
  components: {
    GoNextBtn,
    QuestionCard,
  },
  data() {
    return {
      levels: null,
      selected: "",
    };
  },

  methods: {
    updateDimensionFirstRank(event) {
      try {
        this.$store.commit("updateDimensionPriority", {
          id: event,
        });
        this.$store.commit("enableGoNext");
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight - window.innerHeight);
          // ALTERNATIVE: move automatically to next page
          // this.$store.dispatch("go",1);
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    content() {
      return this.$store.state?.survey?.pages?.dimFirstRank ?? {};
    },
    allWorstLevels() {
      if (this.$store.state.survey.ds === undefined) {
        return [];
      }
      // let LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let allWorstLevels = this.$store.state.survey.ds.map(
        (dimension, index) => {
          let worstLevel = dimension.levels.reduce((prev, curr) => {
            if (prev.rank > curr.rank) {
              return prev;
            } else {
              return curr;
            }
          });
          if (this.$store.state.survey?.pages?.dimFirstRank.appendLevelLabel) {
            var label =
              // LETTERS[index] + ") " +  
              `<b>${dimension.name}</b>: ${worstLevel.label}`;
          } else {
            var label =
              // LETTERS[index] + ") " + 
              worstLevel.label;
          }
          return {
            html: label,
            value: dimension.id,
          };
        }
      );
      return allWorstLevels;
    },
  },
  mounted() {
    this.$store.state.results.ds.forEach((dimension) => {
      if (dimension.isTheHighestPriority) {
        this.selected = dimension.id;
      }
    });
  },
  watch: {
    selected: {
      handler() {
        if (this.selected === "") {
          this.$store.commit("disableGoNext");
        } else {
          this.$store.commit("enableGoNext");
        }
      },
      immediate: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
:deep {
  // make button animation flicker when selected
  @keyframes blink {
    50% {
      opacity: 0.75;
    }
  }
  .active {
    -webkit-animation: blink 0.3s ease-in 2 0.1s !important;
    animation: blink 0.3s ease-in 2 0.1s !important;
  }

  .form-check-inline {
    margin-right: 10px;
  }

  .first-rank-btn {
    color: black;
    text-align: left;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  .first-rank-btn:hover {
    background-color: var(--bs-primary);
  }
  .first-rank-btn:active, .first-rank-btn.active {
    background-color: var(--bs-primary);
    font-weight: bold;
  }

}
</style>
>
