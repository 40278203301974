
<template>
 <div class="header bg-white">
   <div class="container px-1 py-3 d-flex justify-content-around align-items-center">
    
    <img class = "mx-4" :src="logoLeftPath"/>
 
    <div class="title-container ps-2 ">
      <div class="title fs-5 fw-bold text-uppercase text-primary">{{ title }}</div>
      <div class="subtitle fw-lighter text-dark">{{ subtitle }}</div>
    </div>
    <img class = "mx-4" :src="logoRightPath"/>
</div>
</div>
</template>

<script>
export default {
 name: "HomeHeader",
 props: {
  title: {
   type: String,
   required: false,
   default: "",
  },
  subtitle: {
   type: String,
   required: false,
   default: "",
  },
  logoLeft: null,
  logoRight: null,
 },
 computed: {
  logoLeftPath() {
   if(this.logoLeft){
     try {
       return require(`@/assets/img/${this.logoLeft}`);
     } catch (error) {
       return "";
     }
   }
  },
  logoRightPath() {
   if(this.logoRight){
     try {
       return require(`@/assets/img/${this.logoRight}`);
     } catch (error) {
       return "";
     }
   }
  },

 },
};
</script>

<style scoped>
.header {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 z-index: 100;
 box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.title-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 flex-basis: 1;
 flex-shrink: 1;
 white-space: nowrap;
 padding-left: 0 1.5em;
 padding-right: 5em;
 overflow: visible;
}

.subtitle {
  font-size: calc(0.9rem + 1.5vw);
}
.header img {
 max-height: 70px;
 height: auto;
 overflow: hidden;
}
@media (max-width: 767px) {
        .header img {
          display: none;
        }
}
</style>
