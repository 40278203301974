<template>
  <div class="bg-clear pt-3">
    <div class="mx-auto d-flex flex-column flex-fill home home-80 mt-5 pt-3">
      <div class="d-flex mb-5">
        <!-- <span style="color: transparent" class="mono"> 00 </span> -->
        <div class="fs-4 px-3 mx-3 mt-3" style="max-width: 50ch">
          <div class="text-start align-self-start lh-1 display-1 py-1">
            <div class="">
              <span class="accent logo-1">
                <span class="text-decoration-underline">v</span>alorem</span
              >.<span class="fw-light logo-2">health</span>
              <!-- <div
                style="font-size: 12pt"
                class="text-dark fs-light text-start py-3 lh-sm"
              >
                An open survey platform for eliciting health preferences and
                values <br />
                by Paul Schneider, University of Sheffield, UK
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div
          class="bg-dark"
          style="left: 0; width: 75%; position: absolute; height: 2px"
        />
      </div>



      <div class="d-flex my-4">
        <!-- <span class="fs-4 mono"> 01 </span> -->
        <div
          class="fs-4 px-3  mx-3"
          style="max-width: 50ch"
        >
          <div>
            <h3>An open platform for eliciting health preferences and values</h3
            >
            <div class="fs-5">
              The <span class="fw-bold"><u>O</u></span
              >nline elicitation of <span class="fw-bold"><u>P</u></span
              >ersonal <span class="fw-bold"><u>U</u></span
              >tility <span class="fw-bold"><u>F</u></span
              >unctions (<b><u>OPUF</u></b
              >) is a new method for eliciting health preferences and values.
              Unlike many traditional methods (like DCE, TTO, etc), OPUF does not
              require hundreds of participants, but works in small groups, and
              even on the individual person level; it aims to provide a modern
              toolkit to make evidence on patient preferences widely available
              to researchers and decision-makers.
            </div>
          </div>
        </div>
      </div>

        <div class="d-flex my-4">
          <!-- <span class="fs-4 mono"> 02 </span> -->
          <div class="ps-3 ms-3  border-dark">
            <h3>OPUF demo surveys</h3>
            <ul class="ms-3 fs-5">
              <li>
                <a href="#" @click="openLink('eq5d5l')">
                  EQ-5D-5L</a
                >
              </li>
              <li><a href="#" @click="openLink('sf6d')"> SF-6Dv2 </a></li>
              <li>
                <a href="#" @click="openLink('chu9d')">
                  CHU9D</a
                >
              </li>
              <li><a href="#" @click="openLink('fact-g')">FACT-G (old survey version)</a></li>
            </ul>
          </div>
        </div>

        <div class="d-flex my-4">
          <!-- <span class="fs-4 mono"> 03 </span> -->
          <div class="ps-3 ms-3 ">
            <h3>Selected resources</h3>
            <ul class="ms-3 fs-5 lh-1" style="max-width: 50ch">
              <li class="my-2">
                <a
                  href="https://doi.org/10.12688/wellcomeopenres.17518.1"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Schneider P, van Hout B, Heisen M, Brazier J, Devlin N. The
                  Online Elicitation of Personal Utility Functions (OPUF) tool:
                  a new method for valuing health states. Wellcome Open
                  Research. 2022 Jan 14;7(14):14.</a
                >
              </li>

              <li class="my-3">
                <a
                  href="https://bitowaqr.github.io/files/opuf_poster.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ePoster for the SMDM 2021 conference</a
                >
              </li>

              <li class="my-3">
                <a
                  href="https://www.youtube.com/watch?v=FFYklqDgx-Q"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Virtual presentation at iHEA Septmeber 2021
                </a>
                (<a
                  href="https://bitowaqr.github.io/files/pres_ihea.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Slides</a
                >
                ).
              </li>

              <li class="my-3">
                <a
                  href="https://doi.org/10.1007/s10198-018-0993-z"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Devlin NJ, Shah KK, Mulhern BJ, Pantiri K, van Hout B. A new
                  method for valuing health: directly eliciting personal utility
                  functions. The European Journal of Health Economics. 2019
                  Mar;20(2):257-70.</a
                >
              </li>

              <li class="my-3">
                <a
                  href="https://bitowaqr.github.io/files/opuf_uk.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schneider P, Brazier J, van Hout B, Devlin N. Not just another
                  EQ-5D-5L value set for the UK: using the 'OPUF' approach to
                  study health preferences on the societal-, group-, and
                  individual person-level. 2022.</a
                >
              </li>

              <li class="my-3">
                <a
                  href="https://github.com/bitowaqr/opuf_demo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open source R code repository</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex my-4">
            <!-- <span class="fs-4 mono"> 04 </span> -->
            <div class="ps-3 ms-3 ">
              <h3>Testimonials</h3>
              <div class="fs-5">
                Some feedback we received from OPUF survey respondents.
              </div>
              <div class="ms-3 fs-6">
                <div class="feedback-quote">
                  "An excellent and very informative study. A real strength of
                  this is the depth of feedback you give. Truly excellent."
                </div>

                <div class="feedback-quote">"an eye-opener"</div>

                <div class="feedback-quote">
                  "Brilliant to get personalised results (and in downloadable
                  format to boot!) - really made me feel part of the research
                  project, and understand the importance of the work"
                </div>

                <div class="feedback-quote">
                  "i found this to be the best survey i have ever done, it was
                  easy to follow thought provoking but highly enjoyable"
                </div>

                <div class="feedback-quote">
                  "very interesting and very thought provoking"
                </div>

                <div class="feedback-quote">
                  "I found the survey interesting. It made me think about my
                  priorities. I would be happy to take part in any further
                  research based on this model."
                </div>

                <div class="feedback-quote">
                  "One of the most well-designed health surveys I have done in
                  recent years. I must say thank you to the researcher for
                  putting so much effort and thought behind structuring the
                  survey. I learnt a lot about my health versus the population
                  as well. I hope the NHS can learn from you!"
                </div>

                <div class="feedback-quote">
                  I thought your survey was very interesting! I liked the way it
                  was presented and executed. It certainly made me think and
                  weigh up my answers. Thank you!
                </div>

                <div class="feedback-quote">
                  "This was an extremely thought provoking study and I found it
                  very interesting looking at the results at the end and
                  comparing to others' responses. Thank you."
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex my-4">
          <!-- <span class="fs-4 mono"> 05 </span> -->
          <div class="ps-3 ms-3 ">
            <h3>Talks and presentations</h3>
            <div class="fs-5 mb-2">
              Events at which OPUF was presented and discussed.
            </div>
            <ul class="ms-2 fs-6 lh-sm">
              <li>
                <span class="date">11/2022 </span> ISPOR Europe, Vienna, AT
              </li>
              <li><span class="date">11/2022 </span> SBCA, Paris, FR</li>
              <li><span class="date">10/2022 </span> ISOQOL, Prague, CZ</li>
              <li><span class="date">09/2022 </span> IAHPR, Berlin, DE</li>
              <li><span class="date">07/2022 </span> EUHEA, Oslo, NO</li>
              <li><span class="date">06/2022 </span> HTAi Utrecht, NL</li>
              <li><span class="date">06/2022 </span> HESG Sheffield, UK</li>
              <li>
                <span class="date">05/2022 </span> lolaHESG, Maastricht, NL
              </li>
              <li>
                <span class="date">05/2022 </span> ISPOR 2022 (poster),
                Washington, US
              </li>
              <li>
                <span class="date">04/2022 </span> ISPH Priorities, Bergen, NO
              </li>
              <li>
                <span class="date">04/2022 </span> EuroQol Academy Meeting,
                Noordwijk, NL
              </li>
              <li>
                <span class="date">03/2022 </span> dggö Conference, Hamburg, DE
              </li>
              <li>
                <span class="date">03/2022 </span> University of Manchester,
                MCHE Seminar Series, UK
              </li>
              <li>
                <span class="date">03/2022 </span> University of Oxford, HERC
                Seminar Series, UK
              </li>
              <li>
                <span class="date">03/2022 </span> University Duisburg/Essen,
                Wasem Jour Fix, DE
              </li>
              <li>
                <span class="date">02/2022 </span> Institute for Quality and
                Efficiency in Health Care (IQWIG), DE
              </li>

              <li>
                <span class="date">01/2022 </span> Health Economics Association
                Ireland (HEAi) Seminar Series, IE
              </li>

              <li>
                <span class="date">01/2022 </span> Bielefeld University, School
                of Public Health, DE
              </li>
              <li>
                <span class="date">11/2021 </span> Virtual ISPOR Conference,
                Podium discussion
              </li>
              <li>
                <span class="date">11/2021 </span> dggö resource allocation
                workshop, Mannheim, DE
              </li>
              <li>
                <span class="date">10/2021 </span> SMDM Virtual conference
                (poster)
              </li>
              <li>
                <span class="date">09/2021 </span> EuroQol Topical Online
                Meeting
              </li>
              <li>
                <span class="date">09/2021 </span> iHEA online seminar series
              </li>

              <li>
                <span class="date">09/2021 </span> Office of Health Economics
                (OHE) Online Brown Bag Seminar
              </li>
              <li>
                <span class="date">08/2021 </span> SPUDM Virtual Conference
                (poster)
              </li>
            </ul>
          </div>
        </div>
      

      <div class="d-flex my-4">
        <!-- <span class="mono fs-4"> 06 </span> -->
        <div class=" ps-3 ms-3 pb-2">
          <h3>Get in touch</h3>
          <div class="fw-light">
            <div class="fs-5">Paul Schneider</div>

            <div class="lh-sm fw-light">
              University of Sheffield, UK <br />
              Valorem Health, Germany <br />

              <!--email -->
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none"
                href="mailto: schneider@valorem.health"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
                  />
                </svg>
                schneider@valorem.health</a
              >
              
              <br />
              

              <!-- homepage -->
              <a
                href="https://bitowaqr.github.io/"
                target="_blank"
                class="text-decoration-none"
                rel="noopener noreferrer"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-house"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                  />
                </svg>
                https://bitowaqr.github.io/</a
              >
              <br />
              <!-- github -->
              <a
                href="https://github.com/bitowaqr"
                target="_blank"
                class="text-decoration-none"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-github"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                  />
                </svg>
                bitowaqr</a
              >
              <br />
              <!--twitter -->
              <a
                target="_blank"
                class="text-decoration-none"
                rel="noopener noreferrer"
                href="https://twitter.com/waq0r"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                  />
                </svg>
                @waq0r</a
              >
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  created() {
    if (this.$gtag) {
      this.$gtag.event("page_view", {
        page_title: "Info",
        page_location: "/",
      });
    }
  },
  mounted() {
    document.querySelector("body").classList.add("bg-white");
  },
  beforeUnmount() {
    document.querySelector("body").classList.remove("bg-white");
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
document.title = "valorem.health";
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&EB+Garamond&display=swap"); */

.bg-clear {
  background-image: linear-gradient(
    160deg,
    rgb(255, 232, 222),
    rgb(255, 254, 218),
    rgb(189, 211, 255)
  );
  /* background-image: linear-gradient(160deg, rgb(255, 255, 255), rgb(224, 234, 254), rgb(207, 211, 255)); */
  /* background-image: linear-gradient(150deg, rgb(255, 255, 255), rgb(255, 251, 228), rgb(255, 247, 217), rgb(255, 239, 197), rgb(255, 235, 200), rgb(255, 224, 188),rgb(255, 228, 206), rgb(255, 208, 181),rgb(255, 212, 192), rgb(255, 201, 178)); */
  margin-top: -50px;
  margin-bottom: -50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

h3 {
  font-family: "EB Garamond", serif;
}

.accent {
  color: var(--accent)
}

.logo-1 {
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  font-size: 1.1em;
}
.logo-2 {
  font-family: 'Inter', sans-serif;
}

a {
  color: rgb(97, 97, 97);
}
a:hover {
  color: var(--accent);
}

h3,
li,
ul .mono,
p {
  color: black;
}

.mono {
  font-family: serif;
}
.date {
  font-family: monospace;
}

.feedback-quote {
  /* italics */
  font-style: italic;
  /* light */
  font-weight: 300;
  border-left: 3px solid rgb(204, 223, 255);
  padding-left: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  max-width: 80ch;
}
</style>
