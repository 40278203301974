<template>

  <div class="home home-100">

    <Description class="mx-auto" style="max-width: 60ch">
      <div
      v-html="$store.state.survey?.pages?.positionOfPits?.html"
      >
      </div>
    
    <div class="question text-center fs-5 mx-auto mt-4 mb-5 w-100"
    v-html="$store.state.survey?.pages?.positionOfPits?.question"
    >
    </div>
    </Description>


    <div
      class="
        d-flex
        flew-row
        flex-wrap
        align-items-start
        justify-content-center
        gap-3
      "
    >

<div 
class="mb-3"
style="flex-basis: 60%; flex-grow: 1; flex-shrink: 1;;"
>
      <ScenarioTable :dimLabels="dimLabels" :labels="labels"></ScenarioTable>
</div>


      <div 
      style="flex-basis: 35%; flex-grow: 1; flex-shrink: 1; max-width: 55%;"
      >
        <Card spacingClasses="px-2 pt-2 pb-3 mx-3 mb-5">
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
          <div
          class="text-primary px-2 w-100 lh-sm small"
          v-show="!resultsRevealed"
          >
          {{$store.state.survey?.pages?.positionOfPits.helpLabel}}
          </div>



            <div style="margin: 0.75rem 0" class="badge bg-light text-dark border fs-6">
              {{$store.state.survey?.pages?.positionOfPits.to}}
              </div>
            <slider
              @update="updateValue"
              @set="updateValue2"
              @handleMadeVisible="revealResult"
              :hiddenOnStart="!resultsRevealed"
              styleClass="dead-vas-slider"
              id="dead-vas-slider"
              :revealOnClick="true"
              :revealOnStart="resultsRevealed"
              :config="sliderConfigDeath"
              :values="[$store.state.results?.positionOfPits ?? 0]"
            />
            <div style="margin: 0.75rem 0" class="badge bg-dark fs-6">
              {{$store.state.survey?.pages?.positionOfPits.from}}
              </div>
          </div>
        </Card>
      </div>
    </div>
      <GoNextBtn :promptGoNext="resultsRevealed"/>
  </div>
</template>

<script>
import Description from "@/components/Description.vue";
import Card from "@/components/Card.vue";
import ScenarioTable from "@/components/ScenarioTable.vue";
import Slider from "@/components/Slider.vue";
import LeaderLine from "leader-line-vue";
import GoNextBtn from "@/components/GoNextBtn.vue";

let sliderConfigDeath = {
  tooltips: [wNumb({ decimals: 0, suffix: "%" })],
  start: 40,
  connect: "lower",
  pips: {
    mode: "positions",
    values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    density: 2,
  },
  direction: "rtl",
  orientation: "vertical",
  range: { min: [0], max: [100] },
  animate: true,
  animationDuration: 100,
};

export default {
  name: "deadVas",
  components: {
    ScenarioTable,
    Description,
    Card,
    Slider,
    LeaderLine,
    GoNextBtn
  },
  data() {
    return {
      resultsRevealed: false,
      sliderConfigDeath,
      line: null,
    };
  },

  methods: {
    updateValue(value) {
      this.$store.commit("updatePositionOfPits", value[0]);
      if (this.line != null) {
        this.line.position();
      }
    },
    updateValue2(value) {
      setTimeout(() => {
        this.$store.commit("updatePositionOfPits", value[0]);
        if (this.line != null) {
          this.line.position();
        }
      }, 200);
    },

    revealResult() {
      this.$store.commit("setLifeAndDeathContinuedTouched", true);
      this.$store.commit("enableGoNext");
      if(this.line != undefined) {
      this.line.setOptions({ color: "black" });
      }

      if (!this.resultsRevealed) {
        this.resultsRevealed = true;
        this.$store.commit("enableGoNext");
      }
    },
    updateLine(){
      if (this.line != null) {
          this.line.position();
        }
    },
    worstLevel(dimension) {
      return dimension.levels.reduce((curr, next) => {
        return curr.rank > next.rank ? curr : next;
      }).label;
    },
  },
  mounted() {
    this.resultsRevealed = this.$store.state.results.positionOfPitsTouched;
    if (!this.resultsRevealed){
     this.$store.commit("disableGoNext");
    }
    let lineEnd = document.querySelector("#dead-vas-slider .noUi-handle");
    let lineStart = document.querySelector(".pit-state-table");
    let color = this.resultsRevealed ? "black" : "transparent";
    // wait for animation to finish
    setTimeout(() => {
    this.line = LeaderLine.setLine(lineStart, lineEnd, {
      color: color,
      endPlugSize: 1,
      startSocket: window.innerWidth > 740 ? "right" : "bottom",
      endSocket:  window.innerWidth > 740 ? "left" : "right",
    });

    window.addEventListener("resize", this.updateLine);
    }, 400);

  },

  unmounted() {
        window.removeEventListener('resize', this.updateLine);
        if(this.line != null) this.line.remove();
  },

  computed: {
    dimLabels() {
      return this.$store.state.survey.ds.map(
        (d) => d.name
      );
    },
    labels() {

      if(this.$store.state.survey?.pages?.positionOfPits.appendLevelLabel) {
        return this.$store.state.survey.ds.map(
            (d) => d.name + ": " + this.worstLevel(d)
          );
      }

      if (
        this.$store.state.results.healthStatesChoicesWithDeath[0]?.optionA !=
        undefined
      ) {
        return this.$store.state.results.healthStatesChoicesWithDeath[0]
          .optionA;
      } else {
        return this.$store.state.results.ds.map(
          (d) => d.levelRating[d.levelRating.length - 1].label
        );
      }
    },
  },
};
</script>

<style lang="scss">

.leader-line {
  color: red;
  z-index: 0;
}

/* style used from POD */
#dead-vas-slider  {
  height: 350px;
  cursor: crosshair;
}
#dead-vas-slider .noUi-base:after {
  content: "";
  position: absolute;
  top: -5%;
  left: -75%;
  bottom: 0;
  width: 300%;
  opacity: 0.2;
  cursor: crosshair;
}


.dead-vas-slider {


  .noUi-base, .noUi-connects {
    position: static;
  }

  .noUi-connect {
    background: var(--bs-primary);
    box-shadow: none !important;
    opacity: 0.8;
  }
  .noUi-origin .noUi-handle {
    background: var(--bs-primary);
    border-radius: 0.5em !important;
    width: 30px !important;
    margin-bottom: 0.6em;
    height: 12px !important;
    border: 3px solid snow !important;
    box-shadow: 0 3px 7.26px 0 rgba(0, 0, 0, 0.12) !important;
    visibility: hidden;
    cursor: grab;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-base {
    border-radius: 0;
    box-shadow: none;
  }
  .noUi-target {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .noUi-pips-vertical {
    padding: 0;
    margin: 0;
    margin-left: -29px;
    padding-left: 10px;
    pointer-events: none;
  }
  .noUi-value {
    margin-left: 15px;
    color: #0d213a !important;
  }
  .noUi-marker-vertical.noUi-marker.noUi-marker-large {
    width: 40px !important;
    left: -1px;
    right: 0px;
  }
  .noUi-marker-vertical.noUi-marker {
    width: 20px !important;
  }
  .noUi-tooltip {
    font-weight: bold;
    border: none !important;
    background: none !important;
    z-index: 2 !important;
    color: var(--bs-primary) !important;
    text-shadow: white 0px 0px 0px !important;
    top: 10px;
  }
}


</style>
