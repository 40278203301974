<template>
  <div class="home home-80" id="consentForm">
    <div class="card card-body px-4 mb-5">
      <div v-html="$store.state.survey?.pages?.consent?.introMsg"></div>

      <div v-html="$store.state.survey?.pages?.consent?.contactMsg"></div>

      <div class="mt-3">
        <div class="">
          {{ $store.state.survey?.general?.authorName }} <br />
          {{ $store.state.survey?.general?.authorAffiliation }} <br />
          <a target="_blank" rel="noopener noreferrer" :href="href">
            {{ $store.state.survey?.general?.authorEmail }}
          </a>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body d-flex flex-column">
        <div class="d-flex align-items-start">
          <div class="fw-light display-6 mb-3 ps-2">
            {{ $store.state.survey?.pages?.consent?.formTitle }}
          </div>
          <div class="ms-auto mt-1">
            <button
              v-print="printObj"
              @click="printState"
              class="btn btn-outline-primary btn-sm me-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
                />
              </svg>
              {{ $store.state.survey?.pages?.consent?.printBtn }}
            </button>
          </div>
        </div>

        <div v-for="(question, qindex) in questions" :key="question.id" style="max-width: 70ch;">
          <div class="fw-bold px-2 py-1" v-if="qindex <= taskIndex">
            {{ qindex + 1 }}) {{ question.label }}
            <div v-if="question.type == 'radio'" class="mt-1 mb-4">
              <b-form-radio-group
                :id="question.id"
                :ref="question.id"
                v-model="selected[qindex]"
                :options="question.options"
                :state="validated[qindex]"
              >
              </b-form-radio-group>
            </div>
            <b-form-invalid-feedback :state="validated[qindex]">
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-body mt-5" v-if="noParticipation">
      <div
        v-html="$store.state.survey?.pages?.consent?.noParticipationMsg"
      ></div>
    </div>

    <GoNextBtn
      :promptGoNext="taskEnded"
      :customMsg="$store.state.survey?.pages?.consent?.startMsg"
    />
  </div>
</template>

<script>
import GoNextBtn from "../components/GoNextBtn.vue";
import Description from "../components/Description.vue";
import print from "vue3-print-nb";
const axios = require("axios");

export default {
  name: "consent",
  components: {
    GoNextBtn,
    Description,
  },
  data() {
    return {
      selected: [],
      taskIndexPrint: false,
      printObj: {
        id: "consentForm",
        title: "Consent Form",
        closeCallback: () => {
          this.taskIndexPrint = false;
        },
      },
    };
  },
  directives: {
    print,
  },
  mounted() {
    this.selected = this.$store.state.survey?.pages?.consent?.questions.map(
      (n) => null
    );
    if (this.$store.state.results.consentForm.length > 0) {
      this.selected = this.$store.state.results.consentForm.map((q) => {
        return q.response ?? null;
      });
    }
    this.$store.commit("disableGoNext");
  },
  beforeUnmount() {
    let payload = this.questions.map((question, index) => {
      return {
        id: question.id,
        question: question.label,
        response: this.selected[index],
      };
    });
    this.$store.commit("updateConsentFormQuestions", payload);
    if (this.taskEnded) {
      // safety check
      let surveyId = this.$route.params.surveyId;

      if (this.$store.state.survey.general.usePseudoId) {
        this.$store.commit("generatePseudonym");
      }

      axios.post(this.$store.state.host + "/api/survey/consent/" + surveyId, {
        userId: this.$store.state.results.userId,
        pseudonym: this.$store.state.results.pseudonym,
        time: new Date(Date.now()).toISOString().split(".")[0],
      });
      // .then(function (response) {
      //   console.log(response);
      // });
    }
  },
  methods: {
    printState() {
      this.taskIndexPrint = true;
    },
  },
  computed: {
    questions() {
      return this.$store.state.survey?.pages?.consent?.questions;
    },
    validated() {
      return this.selected.map((item) => {
        return item === undefined ? false : item;
      });
    },
    taskEnded() {
      let valCount = this.validated.reduce((acc, curr) => {
        return curr ? acc + 1 : acc;
      }, 0);
      if (valCount == this.questions.length) {
        this.$store.commit("enableGoNext");
        return true;
      } else {
        if (this.$store.state.navigation.canGoNext) {
          this.$store.commit("disableGoNext");
        }
        return false;
      }
    },
    noParticipation() {
      let screenOut = this.validated.some((item) => {
        return item == false;
      });
      if (screenOut) {
        this.$store.commit("setEditingInProgress", false);
        return true;
      } else {
        this.$store.commit("setEditingInProgress", true);
        return false;
      }
    },
    taskIndex() {
      if (this.taskIndexPrint) {
        return 99;
      }
      if (this.selected.indexOf(false) > -1) {
        return this.selected.indexOf(false);
      }
      let index = this.validated.reduce((acc, item) => {
        return acc + (item ? 1 : 0);
      }, 0);
      if (index >= this.questions.length) {
        index = this.questions.length - 1;
      }
      setTimeout(() => {
        this.$refs[this.questions[index]?.id][0]?.$el.scrollIntoView();
      }, 200);
      return index;
    },
    href() {
      return `mailto: ${this.$store.state.survey?.general?.authorEmail}`;
    },
  },
};
</script>

<style></style>
