<template>
  <div class="home home-100 w-100">
    <!-- custom model -->

    <CustomModal
      :title="content?.modalTitle ?? ''"
      :visible="content?.modalText ?? false"
    >
      <div v-html="content?.modalText"></div>
    </CustomModal>

    <!--  Question -->
    <QuestionCard :content="content" />

    <!-- info 1 + task part 1 | info 2 + task part 2 -->
    <div
      class="d-flex align-items-start flex-nowrap flex-sm-nowrap justify-content-stretch"
    >
      <!-- Info + task 1 -->
      <div class="d-flex flex-column" style="flex-basis: 100%">
        <!-- Info -->
        <div v-if="content?.info" class="card mx-3 mb-3">
          <div class="card-body" v-html="content?.info"></div>
        </div>

        <div class="d-flex mx-3">
          <!-- task part 1 -->
          <div class="card mb-3 w-100">
            <div class="card-body">
              <draggable
                v-if="sourceList.length > 0"
                :list="sourceList"
                :disabled="!enabled"
                :move="checkMove"
                item-key="label"
                group="people"
                id="source-list"
                class="list-group"
                style="border: 1px solid gray"
                ghost-class="ghost"
                @start="dragging = true"
                @end="drop"
              >
                <template #item="{ element }">
                  <div
                    class="list-group-item"
                    :class="{ 'not-draggable': !enabled }"
                  >
                    <div v-html="element.label"></div>
                  </div>
                </template>
              </draggable>

              <div v-else class="text-center text-secondary d-flex flex-column" style="height:100%;">
                <div class="my-auto">
                  {{ content.allDoneStr ?? "All done." }}
                </div>
              </div>
            </div>
          </div>

          <!-- spacer -->
          <div class="mx-1 mx-sm-2 mx-md-3"></div>

          <!-- task part 2 -->
          <div class="card mb-3 w-100">
            <div class="card-body">
              <draggable
                :list="dropList"
                :disabled="!enabled"
                :move="checkMove"
                group="people"
                id="drop-list"
                ref="dropList"
                item-key="label"
                class="list-group"
                
                style="border: 1px solid gray"
                ghost-class="ghost"
                @start="dragging = true"
                @end="drop"
              >
                <template #item="{ element, index }">
                  <div
                    class="list-group-item"
                    :class="{ 'not-draggable': !enabled }"
                  >
                    <span class="text-primary"> {{ index + 1 }}. </span>
                    <span v-if="index == 0" class="text-primary">{{
                      $store.state.survey?.pages?.dimensionRanking
                        ?.worstRankIndicator ?? ""
                    }}</span>
                    <span v-html="element.label"></span>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GoNextBtn :promptGoNext="sourceList.length == 0" />
  </div>
</template>

<script>
import QuestionCard from "@/components/QuestionCard.vue";
import draggable from "vuedraggable";
import GoNextBtn from "@/components/GoNextBtn.vue";
import CustomModal from "@/components/CustomModal.vue";

export default {
  name: "Dimension Ranking",
  components: {
    QuestionCard,
    draggable,
    GoNextBtn,
    CustomModal,
  },
  data() {
    return {
      levels: null,
      rankingList: [],
      drag: false,
      enabled: true,
      sourceList: [],
      dropList: [],
      dropListTextEl: null,
    };
  },

  methods: {
    drop() {
      this.dragging = false;
      if (this.dropListTextEl)
        this.dropListTextEl.style.position = "absolute";
          // this.dropList.length == 0 ? "block" : "block";
    },
    checkMove(el) {
      if (el.to.id == "drop-list") {
        return true;
      }
      return false;
    },
  },
  computed: {
    content() {
      return this.$store?.state?.survey?.pages?.dimensionRanking ?? {};
    },
    dropListText() {
      return (
        this.$store.state.survey?.pages?.dimensionRanking?.dropListText ?? ""
      );
    },
    dimensions() {
      return this.$store.state.results.ds;
    },
    dimRankingList() {
      return this.dimensions
        .map((d) =>
          d.levelRating.filter((l) => l.rank === d.levelRating.length - 1)
        )
        .map((d, index) => {
          let prefix = "";
          if (this.content?.appendLevelLabel) {
            prefix =
              "<b>" +
              this.$store.state.survey.ds.find(
                (d) => d.id == this.dimensions[index].id
              ).name +
              "</b>: ";
          }

          return {
            label: prefix + d[0].label,
            id: d[0].id,
            dim: this.dimensions[index].id,
            rank: this.dimensions[index].rank,
          };
        })
        .sort((a, b) => a.rank - b.rank);
    },
  },
  beforeMount() {
    this.$store.commit("disableGoNext");
  },
  mounted() {
    if (this.$store.state.results.dimRankingCompleted) {
      this.dropList = this.dimRankingList;
      this.$store.commit("enableGoNext");
    } else {
      this.sourceList = this.dimRankingList;
      let dropListEl = document.querySelector("#drop-list");
      dropListEl.innerHTML = `<div class='dropList-text text-center px-4 drop-list-text'> ${this.dropListText} </div>`;
      this.dropListTextEl = document.querySelector(".dropList-text");

      // fix minHeight of drop list to match source list at start
      setTimeout(() => {
        const sourceListEl = document.querySelector("#source-list");
        dropListEl.style.minHeight =
          (sourceListEl.offsetHeight > 150 ? sourceListEl.offsetHeight : 150) +
          "px";
      }, 50);
    }
  },
  beforeUnmount() {
    this.dropList.forEach((item, index) => {
      this.$store.commit("updateDimensionRank", {
        rank: index,
        dimension: { id: item.dim },
      });
    });
  },
  watch: {
    sourceList: {
      handler: function (val) {
        if (val.length == 0) {
          this.$store.commit("setDimRankingCompleted", true);
          this.$store.commit("enableGoNext");
        } else {
          this.$store.commit("disableGoNext");
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.list-group-item {
  cursor: move;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}
.list-group-item:hover {
  background-color: #e9ecef;
}
::v-deep .drop-list-text {
  color: #ccc;
  font-size: 1.1rem;
  /* position absolute center */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  position: absolute;
}
</style>
