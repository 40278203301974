import { createStore } from "vuex";

import navigation from "./navigation";
import survey from "./survey";
import results from "./results";
import admin from "./admin";

export default createStore({
  state: {
    host: process.env.NODE_ENV == "development" ? "http://localhost:4000" : "",
  },
  getters: {},
  mutations: {},
  actions: {
  },
  modules: {
    navigation,
    survey,
    results,
    admin,
  },
});
