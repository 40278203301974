<template>
  <div>
    <div class="app-frame w-100 d-flex justify-content-center">
      <Header v-if="$store.state.navigation.currentPage > -1" />
      <router-view v-slot="{ Component, route }">
        <transition
          :name="route?.meta?.transition ?? 'none'"
          :mode="route.name == 'Error404' ? '' : 'out-in'"
        >
          <component :is="Component" :key="route.path"></component>
        </transition>
        <div class="my-auto"></div>
      </router-view>
    </div>
    <FooterMinimal v-if="$store.state.navigation.currentPage > -1" />
  </div>
</template>

<script>
import FooterMinimal from "@/components/FooterMinimal.vue";
import Header from "@/components/Header.vue";

export default {
  name: "Home",
  components: {
    FooterMinimal,
    Header,
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmLeave);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmLeave);
  },
  methods: {
    confirmLeave(event) {
      if (this.$store.state.navigation.editingInProgress) {
        event.preventDefault();
        event.returnValue = "";
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.app-frame {
  min-height: 100vh;
}
</style>
