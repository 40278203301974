<template>
  <div :style="cssVars">
    <div :key="sliderId" class="px-2 py-4">
      <div
        :key="sliderId"
        :id="sliderId"
        class="vue-nouislider animated-noUi lvl-rating-slider"
      ></div>
    </div>
    <div
      class="d-flex justify-content-between"
      style="padding: 0px; width: 100%"
    >
      
    </div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import { lvlRatingSeqHandler } from "../utils/lvlRating.js";

export default {
  name: "LevelRatingSlider",
  emits: [
    "promptGoNext",
    "nextLevelLabel",
    "sliderCompleted",
    "loading",
    "seqIndex",
  ],
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    dimension: {
      type: Object,
    },
    levelRatings: {
      type: Object,
    },
    id: {
      type: String,
      default() {
        return Math.random().toString(36).substr(2, 4);
      },
    },
    bestIndicator: {
      type: String,
      default: "",
    },
    worstIndicator: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadingNextLevel: false,
      sliderConfig: {
        step: 1,
        connect: [],
        tooltips: [],
        // start: [0, 100],
        pips: {
          mode: "positions",
          values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          density: 2,
          format: {
            to: (v) => {
              if(v == 100)
                return v + " " + this.bestIndicator;
              if(v == 0)
                return v  + " " +  this.worstIndicator;
              return v;
              },
            from: (v) => v == 0 ? v + this.worstIndicator : 0,
          },
        },
        format: {
          to: (v) => v | 0,
          from: (v) => v | 0,
        },
        margin: 1,
        direction: "rtl",
        orientation: "vertical",
        range: { min: [0], max: [100] },
        animate: true,
        animationDuration: 100,
      },
      options: null,
      values: null,
      vModelValues: null,
      isHandleHidden: true,
      backupCompleted: false,
      min: 0,
      max: 100,
      taskIndex: 0,
      indexOnSlider: null,
      indexOnDimension: null,
      mirrorIndicesOnDimension: null,
      direction: 1,
    };
  },

  computed: {
    cssVars() {
      return {
        "--dimension-color": this.dimension.hexColor,
      };
    },
    sliderId() {
      return this.id;
    },
    slider() {
      return document.getElementById(this.id);
    },
    sliderToDimension() {
      let levels = this.$store.state.results.ds.filter(
        (d) => d.id === this.dimension.id
      )[0].levelRating;
      var allRatings = levels.map((l) => l.rating);
      var nonNullRatings = allRatings.filter((r) => r != null).length;
      
      var hiddenHandleRank = this.seqIndex;
      let len = levels.length;
      if(nonNullRatings==0){
        levels.forEach((l,index) => {
          if(l.rank == 0)
            levels[index].rating = 100;
          if(l.rank == len - 1)
            levels[index].rating = 0;
        })
        allRatings = levels.map((l) => l.rating);
        nonNullRatings = 2;
      }  
      let master = [];
      let indexOnSlider = 0;
      for (let i = len-1; i >= 0; i--) {
        for (let j = 0; j < len; j++) {

          if(levels[j].rank == i && ( hiddenHandleRank == i || levels[j].rating != undefined)){
            master.push(
              {
                rating: levels[j].rating,
                rank: i,
                indexOnDimension: j,
                indexOnSlider: indexOnSlider,
                isHiddenHandle: hiddenHandleRank == i,
              });
              indexOnSlider++;
          };
        }
      }
      return master;
    },

// THIS NEEDS CLEANING UP ------------ !!!!!
    
    currentRatings() {
      let levelRatingsInStore = this.$store.state.results.ds.filter(
        (d) => d.id === this.dimension.id
      )[0].levelRating;

      let levelRatingsInComponent = [];
      for (let i = levelRatingsInStore.length - 1; i >= 0; i--) {
        let val =
          levelRatingsInStore[i].rating == undefined
            ? null
            : parseInt(levelRatingsInStore[i].rating);
        levelRatingsInComponent.push(val);
      }
      levelRatingsInComponent[0] = 0;
      levelRatingsInComponent[levelRatingsInComponent.length - 1] = 100;
      return levelRatingsInComponent;
    },
    seqIndex() {
      return lvlRatingSeqHandler(this.dimension.levels.length, this.taskIndex);
    },
  },

  mounted() {
    let nonNullRatings = this.currentRatings.reduce((r, a) => {
      return r + (a == null ? 0 : 1);
    }, 0);

    if (nonNullRatings == this.currentRatings.length) {
      this.backupCompleted = true;
      this.$emit("sliderCompleted", true);
    } else {
      this.taskIndex = nonNullRatings - 2;
    }

    if (this.completed) {
      this.backupCompleted = true;
      this.isHandleHidden = false;
    } else {
      // edgecase: if the user has completed the task, but they went back before clicking ok
      if (this.backupCompleted) {
        this.$store.commit("enableGoNext");
        this.isHandleHidden = false;
        setTimeout(() => {
          this.$store.commit("enableGoNext");
        }, 50);
      }

      // setTimeout(() => {
      //   console.log("1")
      //   window.scrollTo(
      //     0,
      //     document.body.scrollHeight - window.innerHeight - 70
      //   );
      // }, 501);
    }

    this.buildSlider();
  },

  unmounted() {
    setTimeout(() => {
      this.slider.noUiSlider.destroy();
    }, 500);
  },
  beforeMount() {
    
  },
  beforeDestroy() {
    this.slider.noUiSlider.destroy();
    //this.$root.$el.parentNode.removeChild(this.$root.$el);
  },
  methods: {
    lockOriginHandles() {
      let origins = this.slider.getElementsByClassName("noUi-origin");
      origins[0].setAttribute("disabled", true);
      origins[origins.length - 1].setAttribute("disabled", true);
    },
    unveilHandle(values) {
      this.isHandleHidden = false;
      let handleElements = this.slider.getElementsByClassName("noUi-handle");
      handleElements[this.indexOnSlider].style.visibility = "visible";
      this.vModelValues = values;
      this.updateDimensionValue(values, this.dimension);
      this.hideAllConnects("0.5");
      if (values.length == this.currentRatings.length) {
        this.hideAllConnects("0");
        this.$emit("sliderCompleted", true);
      }
      this.preNext();
      return;
    },

    hideAllConnects(opacity = "0.5") {
      let connects = this.slider.getElementsByClassName("noUi-connect");
      for (let i = 0; i < connects.length; i++) {
        connects[i].style.visibility = "visible";
        connects[i].style.opacity = opacity;
      }
    },
    setCol() {
      let val = 10 + 90 * ((this.currentRatings.length - this.seqIndex) / this.currentRatings.length);
      return val;
    },
    colorMaker() {
      let bgColor =
        "hsl(" + this.dimension.hexColor + ", 100%, " + this.setCol() + "%)";
      this.$emit("bgColor", bgColor);
      return bgColor;
    },
    fontColorMaker() {
      let fontColor = this.setCol() > 50 ? "black" : "white";
      this.$emit("fontColor", fontColor);
      return fontColor;
    },

    buildSlider() {
      if (this.slider.noUiSlider != undefined) {
        this.slider.noUiSlider.destroy();
      }

      let values = this.sliderToDimension.map((m) => m.rating);

      function tooltipStringBreaker(str) {
        return str.match(/.{1,25}(\s|$)/g).join("<br>");
      }

      let options = this.sliderConfig;
      options.connect = [];
      options.tooltips = [];
      var tooltipSeq = this.sliderToDimension.map((m) => m.indexOnDimension);
      values.forEach((val, index) => {
        // let dimLab = this.dimension.levels[tooltipSeq[index]].label;
        let dimLab = tooltipStringBreaker(
          this.dimension.levels[tooltipSeq[index]].label
        );
        let tooltip = {
          to: function (value) {
            return "<div class ='tooltip-label'>" + dimLab + "</div>";
          },
        };
        options.tooltips.push(tooltip);

        if (val != undefined || val != null) {
          options.connect.push(false);
        } else {
          options.connect.push(true, true);
        }
      });

      if (options.connect.length == values.length) {
        options.connect.push(false);
      }

      if (this.completed || this.backupCompleted) {
        options.connect = options.connect.map((val) => !val);
        options.animate = false;
      }

      // this.mirrorIndicesOnDimension = mirrorIndicesOnDimension;
      let indexOfNull = values.indexOf(undefined);
      if (indexOfNull > -1) {
        // this.indexOnDimension = this.seqIndex;
        let newValue = Math.ceil(
          values[indexOfNull - 1] +
            (values[indexOfNull + 1] - values[indexOfNull - 1]) / 2
        );
        this.indexOnSlider = indexOfNull;
        values[indexOfNull] = newValue;
      }
      options.start = values;
      this.vModelValues = values;
      noUiSlider.create(this.slider, options);

      this.lockOriginHandles();
      if (this.isHandleHidden) {
        let handleElements = this.slider.getElementsByClassName("noUi-handle");
        if (handleElements != undefined) {
          handleElements[this.indexOnSlider].style.visibility = "hidden";
        }
        if (!this.slider.classList.contains("animate-connect")) {
          this.slider.classList.add("animate-connect");
        }
      } else {
        if (this.slider.classList.contains("animate-connect")) {
          this.slider.classList.remove("animate-connect");
          this.slider.classList.add("animate-connect-complete");
        }
      }

      // set colors
      let tooltipElements = this.slider.getElementsByClassName("noUi-tooltip");
      let tooltipLabelElements = this.slider.getElementsByClassName("tooltip-label");

      let colorSteps = [...Array(this.currentRatings.length).keys()];
      for (let x = 0; x < tooltipElements.length - 1; x++) {
        let setCol = 10 + 90 * (colorSteps[x + 1] / this.currentRatings.length);
        tooltipElements[x].style.setProperty(
          "background",
          "hsl(" + this.dimension.hexColor + ", 100%, " + setCol + "%)",
          "important"
        );
        tooltipLabelElements[x].style.setProperty(
          "color",
          setCol > 50 ? "black" : "white",
          "important"
        );
      }

      let connectElements = this.slider.getElementsByClassName("noUi-connect");
      if (this.completed || this.backupCompleted) {
        let colorSteps = [...Array(this.currentRatings.length).keys()];
        for (let x = 0; x < connectElements.length; x++) {
          let setCol = 10 + 90 * (colorSteps[x] / this.currentRatings.length);
          connectElements[x].style.setProperty(
            "background",
            "hsl(" + this.dimension.hexColor + ", 100%, " + setCol + "%)",
            "important"
          );
          connectElements[x].style.cursor = "crosshair";
        }
      } else {
        for (let x = 0; x < connectElements.length; x++) {
          connectElements[x].style.setProperty(
            "background",
            "hsl(" +
              this.dimension.hexColor +
              ", 100%, " +
              this.setCol() +
              "%)",
            "important"
          );
        }
      }

      let isDragged = false;
      this.slider.noUiSlider.on("start", () => (isDragged = true));
      this.slider.noUiSlider.on("end", () => (isDragged = false));

      this.slider.noUiSlider.on("set", (values, handle) => {
        let oldValues = this.vModelValues;
        this.vModelValues = values;

        if (this.completed || this.backupCompleted) {
          this.updateDimensionValue(values, this.dimension);
          return;
        }

        // check if unveiling is needed
        if (this.isHandleHidden) {
          if (handle == this.indexOnSlider) {
            this.unveilHandle(this.vModelValues);
            return;
          }
          if (isDragged) {
            return;
          }

          if (
            values[this.indexOnSlider - 1] > oldValues[this.indexOnSlider - 1]
          ) {
            this.vModelValues[this.indexOnSlider] =
              values[this.indexOnSlider - 1];
            this.vModelValues[this.indexOnSlider - 1] =
              oldValues[this.indexOnSlider - 1];
            this.slider.noUiSlider.set(this.vModelValues, false);
            this.unveilHandle(this.vModelValues);
            return;
          }

          if (
            values[this.indexOnSlider + 1] < oldValues[this.indexOnSlider + 1]
          ) {
            this.vModelValues[this.indexOnSlider] =
              values[this.indexOnSlider + 1];
            this.vModelValues[this.indexOnSlider + 1] =
              oldValues[this.indexOnSlider + 1];
            this.slider.noUiSlider.set(this.vModelValues, false);
            this.unveilHandle(this.vModelValues);
            return;
          }
        }
      });
    },

    updateDimensionValue(values, dimension) {
      this.sliderToDimension.forEach((m, index) => {
        if(m.isHiddenHandle && this.isHandleHidden){
          return;
        }
        this.$store.commit("updateDimensionLevelRating", {
          dimension: dimension,
          level: dimension.levels[m.indexOnDimension],
          rating: values[m.indexOnSlider]
          });
      })
    },
    setLoadingNextLevel(val) {
      this.$emit("loading", true);
      this.slider.setAttribute("disabled", true);
      setTimeout(() => {
        this.$emit("loading", false);
        this.slider.removeAttribute("disabled");
        this.next();
      }, 1000);
    },

    preNext() {
      let values = this.slider.noUiSlider.get(true);
      if (values.length == this.dimension.levels.length) {
        this.updateDimensionValue(values, this.dimension);
        this.$store.commit("enableGoNext");
        // setTimeout(() => {
        //   window.scrollTo(0, document.body.scrollHeight - window.innerHeight);
        // }, 600);
        setTimeout(() => {
          this.backupCompleted = true;
          this.$emit("promptGoNext", true);
          this.buildSlider();
        }, 10);
      } else {
        this.setLoadingNextLevel();
      }
    },

    next() {
      let values = this.slider.noUiSlider.get(true);
      this.updateDimensionValue(values, this.dimension);
      this.$emit("promptGoNext", false);

      if (this.completed || this.backupCompleted) {
        return;
      }
      this.$store.commit("disableGoNext");
      this.taskIndex++;
      this.isHandleHidden = true;
      this.buildSlider();
    },
  },
  watch: {
    taskIndex: {
      handler: function (val) {
        let seqIndex = lvlRatingSeqHandler(
          this.dimension.levels.length,
          this.taskIndex
        );
        this.$emit("seqIndex", seqIndex);
        var nextLevel = this.dimension.levels.filter(l => l.rank == seqIndex);
        if(nextLevel != undefined){
          this.$emit("nextLevelLabel",nextLevel[0].label ?? "")
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~nouislider/dist/nouislider.min.css";

.noUi-vertical {
  height: 380px;
}

:deep {
  .tooltip-label {
    text-align: right;
    font-size: 0.7rem;
    font-weight: bold;
    line-height: 1;
  }

  .tooltip-label:hover{
    font-size: 1rem;
    padding: 0.2em 0.4em;
  }
  .noUi-tooltip {
    padding: 0.1em 0.2em;
  }
  
  .slider-level-label {
    flex-basis: 30%;
    flex-shrink: 1;
    flex-grow: 0;
    font-size: 0.75em;
  }

  
.noUi-vertical  {
  height: 380px;
  width: 20px;

  .noUi-connects {
    cursor: not-allowed !important;
    position: absolute !important;
    width: 20px !important;
    right: 0 !important;
    left: 0 !important;
  }
  .noUi-connect {
    width: 18px !important;
    right: 0 !important;
    left: 0 !important;
    border-radius: 0 !important;
    cursor: crosshair !important;
  }
}

  .animate-connect .noUi-connect {
    animation-name: flicker;
    animation-duration: 0.2s;
    animation-iteration-count: 3;
    animation-timing-function: ease-in-out;
  }

  .animate-connect-complete .noUi-connect {
    opacity: 0;
    animation-name: appear;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes flicker {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
